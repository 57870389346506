import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, TableHead, TableBody, Panel, PanelHeader, PanelFooter, Modal, Search } from '../../shared'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  getEmergencies,
  createEmergency,
  validateInputs,
  selectEmergency,
  setEmergency,
  clearForm,
  updateEmergency, deleteOrganization
} from './emergency-slice'
import {setDate} from '../../services/utils'
import './emergency.module.scss'
import {Button} from "../../ui-components";
import {CloseIcon, PlusIcon, EditIcon} from "../../assets";
import {togglePopover} from "../../admin/app-slice";
import EmergencyForm from "./emergency-form";

const Emergency = () => {
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.adminApp)
  const {form, emergencies, emergency} = useAppSelector(state => state.adminEmergency)
  const head = ['id', 'Название', 'Адрес', 'Телефон', 'Почта', 'Дата', '']
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [emergenciesList, setEmergenciesList] = useState<any[]>([])
  useEffect(() => {
    dispatch(getEmergencies())
  }, [dispatch])

  useEffect(()=>{
    setEmergenciesList(emergencies)
  }, [emergencies])

  const submitForm = () => {

    for (const k in form) {
      dispatch(validateInputs({key: k, value: form[k]}))
    }

    if(emergency){
      return dispatch(updateEmergency())
        .then((res) => {
          if (res && res.length) {
            setEmergenciesList(res)
          }
        } )
    }
    return dispatch(createEmergency())
  }

  const closePopover = () => {
    dispatch(setEmergency(null))
    dispatch(clearForm())
    dispatch(togglePopover('adminAddOrganization'))
  }

  const deleteOrg = async (organization) => {
    dispatch(clearForm())
    dispatch(setEmergency(null))
    await dispatch(deleteOrganization(organization.uuid))
    setDeleteModalOpen(false)
    dispatch(togglePopover('adminAddOrganization'))
    dispatch(getEmergencies())
  }

  const rows = emergenciesList.map((val: any) => {
    if(!val.deleted) {
      return (
        <div className='row' key={val.id}>
          <div className='item'>
            <Link to={`/organization/${val.uuid}`}>
            {val.id}
            </Link>
            </div>
          <div className='item' title={val.name}>
            <Link to={`/organization/${val.uuid}`}>
              <strong>{val.name}</strong>
            </Link>
          </div>
          <div className='item' title={val.address}>
            <Link to={`/organization/${val.uuid}`}>
              {val.address}
            </Link>
            </div>
          <div className='item' title={val.phone}>
            <Link to={`/organization/${val.uuid}`}>
              {val.phone}
            </Link>
            </div>
          <div className='item' title={val.email}>
            <Link to={`/organization/${val.uuid}`}>
              {val.email}
            </Link>
            </div>
          <div className='item' title={val.created_at}>
            <Link to={`/organization/${val.uuid}`}>
            {setDate(val.created_at, 'DD/MM/YY')}
            </Link>
          </div>
          <div className='item'>
            <EditIcon onClick={() => {
              dispatch(selectEmergency(val))
              dispatch(togglePopover('adminAddOrganization'))
            }}/>
          </div>
        </div>
      )
    }
  })

  const filterChange = (value: string) => {
    if (value) {
      setSearchQuery(value)
      // @ts-ignore
      setEmergenciesList(emergencies.filter(h => h.name.toLowerCase().includes(value.toLowerCase())))
    } else {
      setSearchQuery('')
      setEmergenciesList(emergencies)
    }
  }

  const popoverTitle = emergency ? 'Сохранить' : 'Создать'

  return (
    <Fragment>
      <div id='emergency-list'>
        <div className={'mb-20 flex-end d-flex'}>
          <Search handleChange={e => {
            filterChange(e.target.value)
          }} value={searchQuery}/>
          <Button type='contained'
                  color='secondary'
                  onClick={() => dispatch(togglePopover('adminAddOrganization'))}
                  startIcon={<PlusIcon/>}>Добавить</Button>
        </div>
        <Table>
          <TableHead>
            {head.map((el, i) => <div className='item' key={i}>{el}</div>)}
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </div>
      <Panel open={app.adminAddOrganization}
             handleClose={() =>closePopover()}>
        <PanelHeader className='simple-header'>
          <p>{popoverTitle} Скорую Помощь</p>
          <CloseIcon onClick={() => closePopover()}/>
        </PanelHeader>
        <EmergencyForm emergency={emergency}/>
        <PanelFooter className='action-box'>
          {emergency && <Button type='contained' onClick={() => setDeleteModalOpen(true)} className='ml-20'>Удалить</Button>}
          <Button type='simple' onClick={() => closePopover()}>Отмена</Button>
          <Button type='contained' onClick={submitForm} className='ml-20'>{popoverTitle}</Button>
        </PanelFooter>
      </Panel>
      <Modal open={deleteModalOpen}
             closeIcon
             title={'Удалить организацию?'}
             handleClose={() => {
               setDeleteModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите удалить эту организацию?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => deleteOrg(emergency)}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setDeleteModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default Emergency
