import React, {useMemo} from 'react'
import {Button} from "../../../ui-components";
import {InfoTooltipTrigger, PanelFooter} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {changeCustomerErCallStatus} from "../../requests/er-thunk";
import CustomerDpInfo from "../CustomerDpInfo";
import BrigadeInfo from "../../brigade/brigadeInfo";
import HospitalizationBrigadeInfo from "./HospitalizationBrigadeInfo";
import CustomerActionsTooltip from "./customerActionsTooltip";
import DpDcActionsPopover from "./DpDcActionsPopover";

const OnWayCustomerRequest = () => {
  const app = useAppSelector(state => state.app)
  const {er_call, request} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const selectedRequestBrigade = useMemo(() => {
    if (request) {
      return request?.requestedBrigades?.filter((b) => b.request_uuid === request?.uuid)
    }
  }, [request])
  return (
    <div id='on-way-request' className={`request-info-container ${er_call.status < 13 || er_call.status === 21 ? 'pb-80' : ''}`}>
      <HospitalizationBrigadeInfo/>
      <CustomerDpInfo/>
      {request && request?.requestedBrigades && selectedRequestBrigade.map((item) => {
        return <BrigadeInfo br={item}/>
      })}
      {er_call.status < 13 &&
      <>
        <PanelFooter className='footer'>
          <Button type='contained' fullWidth onClick={() => dispatch(changeCustomerErCallStatus(4))}>Прибыл</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      </>}
      <DpDcActionsPopover/>

      {er_call.status === 21 &&
        <PanelFooter>
          <Button type='contained' onClick={() => dispatch(changeCustomerErCallStatus(14))} fullWidth>Передать другой СМП</Button>
        </PanelFooter>
      }
    </div>
  )
}

export default OnWayCustomerRequest
