import React, {useMemo, useState} from 'react'
import styles from './styles.module.scss'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {replaceErCall} from "../../requests/er-thunk";
import {getAddressesList} from "../../../app/app-slice";
import {debounce} from 'lodash'
import {ER_CALL_STATUSES} from "../../../services/constants";

const ReplaceErForm = () => {
  const [reason, setReason] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)
  const {er_call} = useAppSelector(state => state.requests)

  const reasons = [
    {
      key: 1,
      value: 'Авария'
    },
    {
      key: 2,
      value: 'Поломка'
    },
    {
      key: 3,
      value: 'Пробка'
    }
  ]

  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      setLoading(true)
      dispatch(getAddressesList(query))
        .then(res => res && setLoading(false))
    }, 1000),
    [dispatch]
  );

  // replace emergency call
  const submit = ():void => {
    if (reason) {
      setClickDisabled(true)
      dispatch(replaceErCall(reason, comment, address))
        .then(res => setClickDisabled(false))
    }
  }

  return (
    <div id={styles.erReplaceForm}>
      <Autocomplete optionLabel={'value'}
                    optionValue={'value'}
                    className='mb-40'
                    value={reason}
                    label={'Причина передачи'}
                    onChange={data => setReason(data.value)}
                    onKeyUp={(e) => e.which === 13 && submit()}
                    options={reasons}/>
      {er_call.status >= ER_CALL_STATUSES.onRouteToHospital &&
        <Input name='address' onChange={e => setAddress(e.target.value)}
               className='mb-40'
               value={address}
               label="Новый Адрес"/>

        // <Autocomplete options={app.addresses}
        //              className='mb-40'
        //              isLoading={loading}
        //              value={address}
        //              onChange={val => setAddress(val?.fullAddress)}
        //              onInputChange={data => data && debouncedChangeHandler(data)}
        //              label='Новый Адрес'
        //              noOptionsMessage={'Введите Адрес'}
        //              optionValue={'fullAddress'}
        //              optionLabel={'fullAddress'}/>
      }
      <Input label='Добавить комментарий'
             className='mb-40'
             onChange={e => setComment(e.target.value)}
             onKeyUp={(e) => e.which === 13 && submit()}
             value={comment}/>
      <Button type='contained'
              className={clickDisabled ? 'click-disabled' : ''}
              onClick={submit}
              disabled={!reason} fullWidth>Передать</Button>
    </div>
  )
}

export default ReplaceErForm
