/*
import React, { Component } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import moment from "moment";
import {TimeIcon} from "../../assets";
import styles from './timer.module.scss'
import { updateListItem } from "../../modules/requests/requests-slice";
import { playNotificationSound } from '../../modules/notification-sound-manager/notifications-sound-manager-slice'

const HOUR = 3600000


interface OwnProps {
  date: string | undefined
  request_uuid: string
  column: string
  timeLimit: number
}

interface ConnectDispatchProps {
  updateListItem: (data) => void
  playNotificationSound: (data) => void
}

interface MainState{
  difference: any
  classname: string
  shouldRunTimer: boolean,
  timePassed: boolean,
  triggerPlay: boolean
  timerClassname: string,
  timeLeft: string,
}

interface ConnectStateProps {}

type HeaderProps = ConnectDispatchProps & ConnectStateProps & OwnProps

class Timer extends Component<HeaderProps, MainState> {
  diffRef: any;
  timer: any;

  constructor(props) {
    super(props);
    const date = moment().valueOf() - moment(props.date).valueOf()
    const timePassed = date > this.props.timeLimit
    this.diffRef = date
    this.timer = null
    this.state = {
      difference: date,
      classname: timePassed ? styles.timerPassed : styles.timerOk,
      shouldRunTimer: true,
      timerClassname: styles.smallTimer,
      timePassed: timePassed,
      triggerPlay:  false,
      timeLeft: '',
    }
  }

  componentDidMount () {
    const {
      difference,
      shouldRunTimer
    } = this.state
    if (difference > HOUR) {
      this.setState({timerClassname: styles.bigTimer})
    }
    this.diffRef = difference
    if(shouldRunTimer){
      this.timer = this.timerHandler()
    }
  }

  componentWillUnmount() {
    if(this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
  }

  timerHandler = async () => {
    const {
      difference,
      timePassed
    } = this.state

    const {
      timeLimit,
      request_uuid,
      column
    } = this.props

    const updatingData = {}

    if (difference > timeLimit && !timePassed) {
      Object.assign(updatingData, {
        classname: styles.timerPassed
      })
      const data = {
        key: 'problematic',
        value: true
      }
      console.log('Should Play Sound');
      this.props.playNotificationSound('timerNotificationSound')
      this.props.updateListItem({request_uuid, column, data})
      Object.assign(updatingData,{
        timePassed: true
      })
    }

    // if (difference - timeLimit <= 1500 && difference - timeLimit > 0 ) {
    //   this.props.playNotificationSound('timerNotificationSound')
    // }

    const newDifference = difference + 1000
    Object.assign(updatingData, {
      difference: newDifference
    })

    const time = newDifference > HOUR
      ? moment.utc(newDifference).add(moment.duration(1000)).format('HH:mm:ss')
      : moment(newDifference).add(moment.duration(1000)).format('mm:ss')

    Object.assign(updatingData, {
      timeLeft: time
    })

    await this.setState({...updatingData})
    return setTimeout(this.timerHandler, 1000);
  }

  render () {
    const {
      timeLeft,
      timerClassname,
      classname
    } = this.state

    const {
      request_uuid
    } = this.props
    return (
      <p
        data-timeLeft={timeLeft}
        data-reqUuid={request_uuid}
        className={`${styles.timer} ${timerClassname} ${classname}`}>
        <TimeIcon className='mr-5'/>{timeLeft}
      </p>
    )
  }
}

type ConnectStateMapper = MapStateToProps<ConnectStateProps, Object>

const mapStateToProps: ConnectStateMapper = () => {
  return {

  }
}

type ConnectDispatchMapper = MapDispatchToProps<ConnectDispatchProps, Object>

const mapDispatchToProps: ConnectDispatchMapper = (dispatch: any) => {
  return {
    updateListItem: (data: any) => dispatch(updateListItem(data)),
    playNotificationSound: (data: any) => dispatch(playNotificationSound(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)
*/


import React, {useEffect, memo, FC, useState, useRef} from "react";
import moment from "moment";
import {TimeIcon} from "../../assets";
import styles from './timer.module.scss'
import {useAppDispatch} from "../../core/hooks";
import {updateListItem} from "../../modules/requests/requests-slice";
import { playNotificationSound } from '../../modules/notification-sound-manager/notifications-sound-manager-slice'

const HOUR = 3600000

interface Props {
  date: string | undefined
  request_uuid: string
  column: string
  timeLimit: number,
  isSentry?: boolean,
}


const Timer: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [differance, setDifferance] = useState(moment().valueOf() - moment(props.date).valueOf())
  const [classname, setClassname] = useState(styles.timerOk)
  const [timerClassname, setTimerClassname] = useState(styles.smallTimer)
  const [shouldRunTimer, setShouldRunTimer] = useState(false)
  const [timeLeft, setTimeLeft] = useState('')
  const [timePassed, setTimePassed] = useState<boolean>(false)
  const [triggerPlay, setTriggerPlay] = useState<boolean>(false)
  const {request_uuid, column} = props

  let diffRef: any = useRef(moment().valueOf() - moment(props.date).valueOf())
  diffRef.current = moment().valueOf() - moment(props.date).valueOf()

  useEffect(()=>{
    document.addEventListener("visibilitychange", (event) => {
      setShouldRunTimer(document.visibilityState === "visible")
    });
    setShouldRunTimer(true)
    return () => {
      document.removeEventListener("visibilitychange", ()=>{
        setShouldRunTimer(true)
      })
    }
  }, [])

  useEffect(() => {
    setClassname(styles.timerOk)
    setTimePassed(false)
    setDifferance(moment().valueOf() - moment(props.date).valueOf())
    if (props?.isSentry) {
      setTimerClassname(styles.blueTimer)
    }
  }, [props.date])
  useEffect(() => {
    if (differance > HOUR) {
      setTimerClassname(styles.bigTimer)
    }
    diffRef.current = differance
    let timer = null as any
    if(shouldRunTimer){
      timer = setInterval(() => {
        if (differance > props.timeLimit && !timePassed) {
          setClassname(styles.timerPassed)
          const data = {
            key: 'problematic',
            value: true
          }
          // dispatch(playNotificationSound('timerNotificationSound'))
          dispatch(updateListItem({request_uuid, column, data}))
          setTimePassed(true)
        }

        if (differance - props.timeLimit <= 1500 && differance - props.timeLimit > 0 ) {
          setTriggerPlay(!triggerPlay)
        }

        setDifferance(diffRef.current + 1000);
        const time = differance > HOUR
          ? moment.utc(diffRef.current).add(moment.duration(1000)).format('HH:mm:ss')
          : moment(diffRef.current).add(moment.duration(1000)).format('mm:ss')
        setTimeLeft(time)
      }, 1000);
    }
    return () => {
      if(timer){
        clearInterval(timer)
        timer = null
      }
    }
  }, [shouldRunTimer, differance]);

  useEffect(() => {
    if(triggerPlay){
      dispatch(playNotificationSound('timerNotificationSound'))
    }
  }, [dispatch, triggerPlay])

  return (
    <p
      data-timeLeft={timeLeft}
      data-reqUuid={request_uuid}
      className={`${styles.timer} ${timerClassname} ${classname}`}>
      <TimeIcon className='mr-5'/>{timeLeft}
    </p>
  )
}

export default Timer
