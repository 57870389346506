import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../app/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getRegion, IGlobalFilter} from "../../../services/utils";


interface Partnerships {
  name: string,
  address: string,
  status: number,
  phone: string,
  email: string,
  priority: number,
  uuid: string
}

interface IPartnerships {
  partnerships: Array<Partnerships>
  filter: IGlobalFilter
}

const initialState: IPartnerships = {
  partnerships: [],
  filter: {
    q: '',
    from: '',
    to: '',
  }
};

const partnerships = createSlice({
  name: 'partnerships',
  initialState: initialState,
  reducers: {
    setPartnerships: (state, action: PayloadAction<Array<Partnerships>>) => {
      state.partnerships = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: string}>) => {
      state.filter[action.payload.key] = action.payload.value
    }
  }
});

export const {
  setPartnerships,
  handleFilterChange
} = partnerships.actions;

export const getPartnerships = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion } = getState().app
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.partnerships.get('/emergency', {region})
      dispatch(setPartnerships(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const approveConnection = (data) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.put('/approve', data)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const rejectConnection = (uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.put('/reject', { uuid })
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const cancelConnection = (uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.delete('', { uuid })
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export default partnerships.reducer;
