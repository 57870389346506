import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../admin/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getKeyValueStringFromObj, getRegion, IGlobalFilter} from "../../../services/utils";
import {omit} from 'lodash'


interface PendingTickets {
  name: string,
  policy_number: string,
  complaints: string,
  org_name: string,
  request_uuid: string
  address: string
  reason: string
  age: number
  sex: string
  created_date: string
  creator: string
  status: number
  executor: string
  number: string
  offers_count: number
}


interface IPendingTickets {
  pendingTickets: Array<PendingTickets>
  filter: IGlobalFilter,
  allDataLoaded: boolean
}

const initialState: IPendingTickets = {
  pendingTickets: [],
  filter: {
    offset: 0,
    limit: 50,
    q: '',
    from: '',
    to: '',
    data: {
      age: '',
      status: '',
    }
  },
  allDataLoaded: true
};

const pendingTickets = createSlice({
  name: 'pendingTickets',
  initialState: initialState,
  reducers: {
    setPendingTickets: (state, action: PayloadAction<Array<PendingTickets>>) => {
      state.pendingTickets = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: any}>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.filter.data![key] = data.value
      } else {
        state.filter[data.key] = data.value
      }
    },
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded =  action.payload
    }
  }
});

export const {
  setPendingTickets,
  handleFilterChange,
  setDataLoaded
} = pendingTickets.actions;

export const getPendingTickets = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter} = getState().pendingTickets
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/requests-for-info', {...updatedFilter, region,  offset: 0,  filter: additionalData})
      dispatch(handleFilterChange({key: 'offset', value: 0}))
      res.length < filter.limit  ? dispatch(setDataLoaded(true)) : dispatch(setDataLoaded(false))
      dispatch(setPendingTickets(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const loadMoreData = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter, pendingTickets} = getState().pendingTickets
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/requests-for-info', {...updatedFilter, region, filter: additionalData})
      const updatedTickets = [...pendingTickets, ...res]
      filter.limit > res.length && dispatch(setDataLoaded(true))
      dispatch(setPendingTickets(updatedTickets))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default pendingTickets.reducer;
