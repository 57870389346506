import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import SuggestionsList from "../components/SuggestionsList";
import SuggestionCard from "../components/SuggestionCard";
import {useAppDispatch} from "../../../core/hooks";
import {toggleRightMainPanel} from "../../../app/app-slice";

const SuggestionsListContainer = ({ onClick, list, getCustomerSingle }) => {
  const dispatch = useAppDispatch()
  const [selectedIndex, setIndex] = useState(0);

  const onClickItem = useCallback((ind) => {
    getCustomerSingle(ind)
    // dispatch(toggleRightMainPanel())
    setIndex(ind);
  }, [dispatch]);

  return (
    <SuggestionsList length={list?.length || 0}>
      {list?.map((suggestion, index) => (
        <SuggestionCard
          onClick={onClick}
          onResolveOrCancel={() => {}}
          key={suggestion.id}
          suggestion={suggestion}
          index={index}
          selectedIndex={selectedIndex}
          onClickItem={onClickItem}
        />
      ))}
    </SuggestionsList>
  );
};

SuggestionsListContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SuggestionsListContainer;
