import React, {useEffect, useMemo} from 'react'
import {Table, TableHead, TableBody, PanelHeader, Panel, Search, DateRange, Diagnose} from '../../../../shared'
import {useAppDispatch, useAppSelector} from '../../../../core/hooks'
import Header from '../../../insurance-request/header'
import Content from '../../../insurance-request/content'
import {getHistory, handleFilterChange, loadMore} from '../history-slice'
import {setData, toggleMainPanel} from '../../../../app/app-slice'
import {getSingleRequest} from '../../../requests/insurance-thunk'
import './insurance-history.scss'
import {ACTIVE_TICKETS_STATUS_NAMES, HISTORY_HEAD, HISTORY_STATUS_FILTER} from '../../table-constants'
import styles from "../../styles.module.scss";
import {debounce} from 'lodash'
import { ExcelIcon, ExportIcon } from '../../../../assets'
import moment from 'moment'
import {CSVLink} from "react-csv";
import {useHistory} from "react-router-dom";
import {Autocomplete, Button, Input} from "../../../../ui-components";
import { ExportToExcel } from "../../exportToExcel"

const InsuranceHistory = () => {
  const dispatch = useAppDispatch()
  const {history, filter, allDataLoaded} = useAppSelector(state => state.history)
  const app = useAppSelector(state => state.app)
  const historyNav = useHistory()
  const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'История'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getHistory())
  }, [dispatch, historyNav.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getHistory()), 700),
    [dispatch]
  );

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMoreData = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMore())
  }

  const headers = [
    {label: "Номер карточки", key: "number"},
    {label: "ФИО", key: "name"},
    {label: "Д/В", key: "age"},
    {label: "Полис", key: "policy_number"},
    {label: "Дата", key: "created_date"},
    {label: "Длительность прибытия", key: "arrival_time"},
    {label: "Длительность вызова", key: "end_time"},
    {label: "Диагноз", key: "diagnosis"},
    {label: "Результат", key: "status"},
    {label: "Комментарии", key: "comment"},
    {label: "Время отзвона", key: "end_date"},
    {label: "Название СМП", key: "org_name"}
  ];

  const xlsxHeaders = [
    "Номер карточки",
    "ФИО",
    "Д/В",
    "Полис",
    "Дата",
    "Длительность прибытия",
    "Длительность вызова",
    "Диагноз",
    "Результат",
    "Комментарии",
    "Время отзвона",
    "Название СМП"
  ];

  const filteredHistory = [] as any
  const csv = [] as any
  const xlsx = [] as any
  const uuids = [] as any
  for (let h of history) {
    uuids.push(h.request_uuid)
    filteredHistory.push({
      number: h.number,
      name: h.name,
      age: h.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: h.policy_number,
      created_date: moment(h.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${h.arrival_time}`,
      end_time: `${h.end_time}`,
      diagnosis: h.diagnosis,
      status: <p className={styles.statusText} title={ACTIVE_TICKETS_STATUS_NAMES[h.status - 1]}>
        {ACTIVE_TICKETS_STATUS_NAMES[h.status - 1]}
      </p>,
      comment: h.comment,
      end_date: h.end_date ? moment(h.end_date).format('DD/MM/YYYY HH:mm') : '-',
      org_name: h.org_name,
    })
    csv.push({
      number: h.number,
      name: h.name,
      age: h.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: h.policy_number,
      created_date: moment(h.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${h.arrival_time}`,
      end_time: `${h.end_time}` || '',
      diagnosis: h.diagnosis,
      status: ACTIVE_TICKETS_STATUS_NAMES[h.status - 1],
      comment: h.comment,
      end_date: h.end_date ? moment(h.end_date).format('DD/MM/YYYY HH:mm') : '-',
      org_name: h.org_name,
    })
    xlsx.push([
      h.number,
      h.name,
      h.age < 18 ? 'Д(0-18)' : 'В(18+)',
      h.policy_number,
      moment(h.created_date).format('DD/MM/YYYY HH:mm'),
      h.arrival_time,
      h.end_time || '',
      h.diagnosis,
      ACTIVE_TICKETS_STATUS_NAMES[h.status - 1],
      h.comment,
      h.end_date ? moment(h.end_date).format('DD/MM/YYYY HH:mm') : '-',
      h.org_name
      ])
  }

  const csvReport = {
    data: csv,
    headers: headers,
    filename: 'История.csv'
  };

  const bodyRow = filteredHistory.map((el, i) => Object.values(el))
  return (
    <div id={styles.historyList}>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange('q', e.target.value)
          debouncedChangeHandler()
        }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getHistory())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getHistory())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <Input label='Возраст'
                 className='ml-20 mr-20'
                 onChange={e => {
                   filterChange('data[age]', e.target.value)
                   debouncedChangeHandler()
                 }} value={filter.data!.age}/>

          <Diagnose value={filter.data!.diagnosis}
                    handleChange={(value) => {
                      dispatch(filterChange('data[diagnosis]', value))
                      dispatch(getHistory())
                    }}/>
          <Autocomplete label='Статус'
                        isClearable
                        onChange={(data) => {
                          dispatch(filterChange('data[status]', data.key))
                          dispatch(getHistory())
                        }}
                        value={filter.data!.status}
                        className={`${styles.selectWrapper} ml-20 mr-20`}
                        options={HISTORY_STATUS_FILTER}
                        optionLabel={'value'} optionValue={'key'}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'История СК', xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {HISTORY_HEAD.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filteredHistory.map((el, i) =>
              <div key={i} onClick={() => dispatch(getSingleRequest(uuids[i]))} className='row'>
                {
                  bodyRow[i].map((el, index) =>
                    <div key={index} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMoreData} className={styles.loadMoreBtn}>Загрузить еще</Button>}
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        <Content/>
      </Panel>
    </div>
  )
}

export default InsuranceHistory
