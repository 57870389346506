import React, {ChangeEvent, useCallback, useState} from "react";
import styles from "./PayInfo.module.scss"
import {Button, Input} from "../../ui-components";
import {Modal, TooltipBlue} from "../../shared";
import {omit} from "lodash";
import {
  addCustomerPaymentRequests,
  confirmCustomerPaymentRequests,
  removeCustomerPaymentRequests
} from "../requests/er-thunk";
import {useAppDispatch} from "../../core/hooks";
import { DotsIcon} from "../../assets";
import {Link} from "react-router-dom";
import InputMask from "react-input-mask";

const PayInfo = ({requestStatus, requestType, payment, selectedPayment, erCall, contract}) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [form, setForm] = useState<any>({
    text: "",
    amount: "",
  })

  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    switch (element.name) {
      case 'text':
        setForm({
          ...form,
          text: element.value
        })
        break
      case 'amount':
        setForm({
          ...form,
          amount: element.value
        })
        break
    }
  }

  const isValid = (data) => {
    return Object.keys(data).every((k) => {
      return data[k]
    })
  }

  const handleSubmit = useCallback(() => {
    if (isValid(omit(form, ['payment']))) {
      dispatch(addCustomerPaymentRequests({
          text: form.text,
          amount: form.amount,
          brigade_uuid: selectedPayment?.uuid,

      })).then(res => {
        // @ts-ignore
        if(res && res.added) {
          setForm({
            text: "",
            amount: "",
          })
          setModalOpen(false)
        }
      })
    } else {
      // setErrorMessage('*Заполните все поля.')
    }
  }, [dispatch, form, selectedPayment?.uuid])

  const handleRemovePayment = useCallback((i: any) => {
    dispatch(removeCustomerPaymentRequests({index: i?.index}))
    setForm({
      text: "",
      amount: "",
    })
  }, [dispatch])

  return <div className={styles.container}>
    <div style={{alignSelf: 'stretch', borderBottom: '1px #E7E7E7 solid', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
      <div style={{paddingLeft: 24, paddingRight: 24, paddingTop: 14, paddingBottom: 14,  justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
        <div style={{color: 'black', fontSize: 12,  fontWeight: 700,  wordWrap: 'break-word'}}>Данные о платежах</div>
      </div>
    </div>
    <div className={styles.bodyContainer}>
      <div className={styles.head}>
        <Button disabled={requestStatus===5 || requestStatus===7 || !contract || erCall?.status === 12|| erCall?.status === 15|| erCall?.status === 17|| erCall?.status === 18} onClick={() => setModalOpen(true)} className={styles.button} type='outlined' fullWidth>Добавить услугу</Button>
      </div>
      <div className={styles.bodyWrapper}  >
        {selectedPayment && <div className={styles.titleText}>{requestType === 3 ? "Дежурство" : requestType === 2 ? "Плановый" : "Экстренный"}</div>}
        {selectedPayment && selectedPayment.invoice.map((i, index) => {
          return <div className={styles.bodyContentWrapper} key={index}>
            <div className={styles.bodyContent}>
              <div style={{flex: '1 1 0', height: 20, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: '1 1 0', color: '#636F77', fontSize: 12,  fontWeight: 500,  wordWrap: 'break-word'}}>{i?.text || "Вызов скорой:"}</div>
                {i?.etaText && <div style={{flex: '1 1 0', color: '#636F77', fontSize: 12,  fontWeight: 500,  wordWrap: 'break-word'}}>{i?.etaText}</div>}
              </div>
              <div className={styles.priceWrapper} >
                <div className={styles.priceAmount}>{i?.brigade_price} руб.*</div>
                <div className={styles.priceStatus} style={i.status === 1 ? {color: "#52AA4E" }: {color:"#E7A249"}}>{i.status === 1 ? "оплачено" : i.status === 2 ? "Возврат" : "Ожидает оплаты"}</div>
              </div>
              {i.status !== 1 && <div className={styles.dotsWrapper}>
                <div data-tip data-for={`price-menu${i.status}`}
                     data-event='click'>
                  <DotsIcon/>
                </div>
                <TooltipBlue id={`price-menu${i.status}`} offsetLeft={85}
                             afterHide={() => setForm({
                               text: "",
                               amount: "",
                             }  )}
                             >
                  <ul className='menu-list'>
                    <li>
                      <p onClick={() => handleRemovePayment(i)}>Удалить</p>
                    </li>
                    <div className="line"/>
                  </ul>
                </TooltipBlue>
              </div>}
            </div>
          </div>
        })}
      </div>
      <div style={{width: "100%",paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16, background: 'white', borderTop: '1px #E7E7E7 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'flex'}}>
        <div style={{alignSelf: 'stretch', height: 48, paddingLeft: 8, paddingRight: 8, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'flex', width: "100%"}}>
          <div style={{width: "100%",alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
            <div style={{flex: '1 1 0', height: 20, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
              <div style={{flex: '1 1 0', color: '#636F77', fontSize: 16,  fontWeight: 400,  wordWrap: 'break-word'}}>ИТОГО</div>
            </div>
            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
              <div style={{alignSelf: 'stretch', textAlign: 'right', color: '#192233', fontSize: 16,  fontWeight: 400,  wordWrap: 'break-word'}}>{payment?.amount} руб.*</div>
            </div>
          </div>
          <div style={{width: "100%",alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
            <div style={{flex: '1 1 0', height: 20, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
              <div style={{flex: '1 1 0', color: '#636F77', fontSize: 16,  fontWeight: 400,  wordWrap: 'break-word'}}>К оплате</div>
            </div>
            <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
              <div style={{alignSelf: 'stretch', textAlign: 'right', color: '#192233', fontSize: 16,  fontWeight: 700,  wordWrap: 'break-word'}}>{payment?.amount - payment?.paid_amount} руб.*</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal open={modalOpen}
           closeIcon
           title={'Добавить услугу'}
           handleClose={() => {
             setForm({
               text: "",
               amount: "",
             })
             setModalOpen(false)
           }}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Input name='text'
               onChange={handleInputChange}
               value={form.text}
               // error={!form.text}
               className={styles.input}
               label="Услуга"/>
        <InputMask mask="9999999999" maskChar=""
                   className={styles.input}
                   label="Цена"
                   name='amount'
                   onChange={handleInputChange}
                   value={form.amount}
        >
          {(inputProps) => <Input  name='amount' {...inputProps} />}
        </InputMask>

      </div>

      <div style={{display: "flex", marginTop: 30, justifyContent: "flex-end"}}>
        <Button type='contained' onClick={handleSubmit}
                disabled={(!form.text || !form.amount )}
                >Добавить</Button>
      </div>
    </Modal>
  </div>
}

export default PayInfo
