import React from 'react'
import {BrigadeInfo, PatientInfo} from "../components";
import './styles.scss'

const WaitingErRequest = () => {
  return (
    <div id='waiting-er-request' className='request-info-container'>
      <BrigadeInfo/>
      <PatientInfo/>
    </div>
  )
}

export default WaitingErRequest
