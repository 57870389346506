import React from 'react'
import {InfoTooltipTrigger, PanelFooter} from '../../../shared';
import {Button} from '../../../ui-components';
import {ActionsPopovers, ActionsTooltip, BrigadeInfo, DiagnoseInfo, HospitalInfo, PatientInfo} from "../components";
import {useAppDispatch} from "../../../core/hooks";
import { changeErCallStatus, endErCallFromHospitalizationFrom } from '../../requests/er-thunk'
import { ER_CALL_STATUSES } from '../../../services/constants'

const BookingApprovedRequest = () => {
  const dispatch = useAppDispatch()

  return (
    <div id='closed-request' className='request-info-container pb-80'>
      <HospitalInfo/>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter className='d-flex flex-space-around'>
        <Button type='contained' onClick={() => dispatch(changeErCallStatus(10))}>Начать Госпитализацию</Button>
        <Button type='outlined' onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedLeftInPlace))} >На месте</Button>
        <Button type='outlined' onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedRefusedHospital))}>Отказ от Гсп.</Button>
        <InfoTooltipTrigger dataFor={'actions-menu'} className='ml-15'/>
      </PanelFooter>
      <ActionsTooltip id='actions-menu' offsetLeft={150} offsetTop={-20}/>
      <ActionsPopovers/>
    </div>
  )
}

export default BookingApprovedRequest
