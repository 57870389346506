import BaseApi from "../base";

export default class Admin extends BaseApi {
  login(data) {
    return this.post('/login', data)
  }

  createUser(data) {
    return this.post('/user', data)
  }

  updateUser(uuid, data) {
    return this.put(`/user/${uuid}`, data)
  }

  getUser(uuid) {
     return this.get(`/user/${uuid}`)
  }

  getUsers(filter) {
    return this.get('/user/list', filter)
  }

  deleteUser(uuid) {
    return this.delete(`/user/${uuid}`)
  }

  resendEmail(data) {
    return this.post(`/user/resend_email`, data)
  }

  getOrganizations(filter) {
    return this.get('/organization', filter);
  }

  getOrganization(uid) {
    return this.get(`/organization/${uid}`)
  }

  createOrganization(data) {
    return this.post(`/organization`, data)
  }

  updateOrganization(uid, data) {
    return this.put(`/organization/${uid}`, data)
  }

  deleteOrganization(uid) {
    return this.delete(`/organization/${uid}`)
  }
}

