import React, {useMemo, useState} from 'react'
import {Button} from "../../../ui-components";
import {InfoTooltipTrigger, PanelFooter, PanelPopover} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {changeCustomerErCallStatus} from "../../requests/er-thunk";
import CustomerDpInfo from "../CustomerDpInfo";
import BrigadeInfo from "../../brigade/brigadeInfo";
import CustomerActionsTooltip from "./customerActionsTooltip";
import {ER_CALL_STATUSES} from "../../../services/constants";
import CustomerDiagnoseForm from "./customer-diagnose-form";
import HospitalizationBrigadeInfo from "./HospitalizationBrigadeInfo";
import DpDcActionsPopover from "./DpDcActionsPopover";
import DcCancel from "../DcComponents/DcFinishSentry";
import DpFinishSentry from "./DpFinishSentry";


const CustomerErHospitalization = () => {
  const app = useAppSelector(state => state.app)
  const {er_call, request} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<number>(0)
  const selectedRequestBrigade = useMemo(() => {
    if (request) {
      return request?.requestedBrigades?.filter((b) => b.request_uuid === request?.uuid)
    }
  }, [request])
  return (
    <div id='on-way-request' className={`request-info-container ${er_call.status < 13 || er_call.status === 21 ? 'pb-80' : ''}`}>
      <HospitalizationBrigadeInfo/>
      <CustomerDpInfo/>
      {request && request?.requestedBrigades && selectedRequestBrigade.map((item) => {
        return <BrigadeInfo br={item}/>
      })}
  {er_call.status === 23 &&
    <PanelFooter className='footer'>
      <>
      {/*<Button type='contained' className={'minButton'} onClick={() => dispatch(changeCustomerErCallStatus(12))}>Завершить</Button>*/}
      <Button type='contained' className={'minButton'} onClick={() => dispatch(togglePopover('dpFinishSentry'))}>Завершить</Button>

      </>
      <>
        <Button type='outlined' className={'minButton'} onClick={() => {
          dispatch(togglePopover('diagnoseFormOpen'))
          setStatus(ER_CALL_STATUSES.hospitalizationRequest)
        }}>Госпитализация</Button>
      </>
      <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
      {er_call && er_call.status > 1 &&
        // @ts-ignore
        <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
    </PanelFooter>
    }
      <PanelPopover open={app.popoverFormStatuses.diagnoseFormOpen}
                    className='diagnose-popover'
                    title={'Добавить диагноз'}
                    handleClose={() => dispatch(togglePopover('diagnoseFormOpen'))}>
        <CustomerDiagnoseForm status={status}/>
      </PanelPopover>
      <PanelPopover title={'Вы уверены что хотите завершить дежурство?'}
                    open={app.popoverFormStatuses.dpFinishSentry}
                    handleClose={() => dispatch(togglePopover('dpFinishSentry'))}>
        <DpFinishSentry/>
      </PanelPopover>
      <DpDcActionsPopover/>
    </div>
  )
  }

  export default CustomerErHospitalization
