import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setApiError, togglePopover } from '../../admin/app-slice'
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import { formValidator } from './validator'

const initialState = {
  hospitals: [],
  hospital: null,
  form:{
    title: '',
    address:'',
    region:'',
    responsible_person_email:'',
    type: 1
  },
  validationFields:{

  } as any,
  formValid: false,
}

const hospitals = createSlice({
  name: 'hospitals',
  initialState: initialState,
  reducers: {
    setHospitals:(state, action)=>{
      state.hospitals = action.payload
      return state
    },
    setHospital: (state, action: PayloadAction<any>) => {
      state.hospital = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    resetForm: (state)=>{
      state.form = initialState.form
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    }
  }
});

export const {
  setHospitals,
  setHospital,
  handleFormChange,
  resetForm,
  validateInputs
} = hospitals.actions;


export const getHospitals = (region = 0) => {
  const url = region ? `/hospitals?region=${region}` : '/hospitals'
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const resp = await api.admin.get(url)
      dispatch(setHospitals(resp))
      return resp
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const createHospital = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminHospital = getState().adminHospitals

    if (adminHospital.formValid) {
      const data = {...adminHospital.form}
      try {
        let response: any
        if(data.formData) {
          delete data.avatar
        }
        delete data.formData

        response =  await api.admin.post('hospitals', data)
        dispatch(togglePopover('adminAddHospital'))
        await dispatch(getHospitals())
        await dispatch(clearForm())
        return response
      } catch (e) {
        console.error('failed to create hospital with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateHospital = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminHospital = getState().adminHospitals
    if (adminHospital.formValid) {
      const data = {...adminHospital.form}
      try {
        delete data.formData
        const res = await api.admin.put(`hospitals/${adminHospital.hospital.uuid}`, data)
        if (res && res.updated) {
          dispatch(togglePopover('adminAddHospital'))
          await dispatch(clearForm())
        }
        return res
      } catch (e) {
        console.error('failed to update hospital with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const selectHospital = (data) => {
  return (dispatch: AppDispatch) => {
    dispatch(setHospital(data))
  }
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const deleteHospital = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.delete(`/hospitals/${uuid}`)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const getCities = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.static.get('/cities')
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default hospitals.reducer;
