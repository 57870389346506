import React, {useEffect} from 'react'
import styles from './styles.module.scss'
import {HospitalIcon} from "../../../assets";
import {InfoText} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {getHospitalsList} from "../../../app/app-slice";

const HospitalInfo = () => {
  const dispatch = useAppDispatch()

  const {booking_offers, er_call} = useAppSelector(state => state.requests)
  const {hospitals} = useAppSelector(state => state.app)
  useEffect(() => {
    dispatch(getHospitalsList())
  }, [dispatch])
  const bookedHospital = booking_offers ? booking_offers : null
  let selectedHospital
  if (bookedHospital) {
    selectedHospital = hospitals.find(el => (el.uuid === bookedHospital.hospital_uuid))
  } else {
    selectedHospital = hospitals.find(el => (el.uuid === er_call.hospital_uuid))
  }
  return selectedHospital ? (
    <div id={styles.hospitalInfo}>
      <p className={styles.title}><HospitalIcon/> Больница</p>
      <div className={`${styles.container}`}>
        <div className={`${styles.wrapper}`}>
          <InfoText title={'Название больницы'} className={`${styles.info} mb-10`} text={selectedHospital?.title}/>
          <InfoText title={'Адрес'} className='mb-10' text={selectedHospital?.address}/>
          {er_call?.hospital_comment && <InfoText title={'Комментарий'} className='' text={er_call?.hospital_comment}/>}
          {bookedHospital?.comment && <InfoText title={'Комментарий'} className='' text={bookedHospital?.comment}/>}
        </div>
        <div className={`${styles.wrapper}`}>
          {bookedHospital?.contact_name && <InfoText title={'Ответственный оператор'} className={'mb-10'} text={bookedHospital?.contact_name}/>}
          {bookedHospital?.contact_phone && <InfoText title={'Контактный телефон'} className='' text={bookedHospital?.contact_phone}/>}
        </div>
      </div>
    </div>
  ) : null
}

export default HospitalInfo
