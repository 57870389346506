import React, {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {AttachIcon, FileIcon} from '../../assets';
import {PanelFooter} from "../../shared";
import {Input} from '../../ui-components';
import './panels.scss'
import {
  getFile, getMessages,
  markAsRead, resetCommentsCount, sendFile, sendMessage
} from "../requests/requests-slice";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {prepareFileUpload, requestClosed, setDate} from "../../services/utils";
import SocketContext from "../../core/socket-context";

const CommentsPanel = () => {
  const {messages, request} = useAppSelector(state => state.requests)
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext);
  const [message, setMessage] = useState<string>('')
  const commentsRef = useRef(null)

  useEffect(() => {
    getMessages()
    return () => {
      dispatch(resetCommentsCount(request.uuid))
      dispatch(markAsRead())
    }
  }, [dispatch])

  useEffect(() => {
    const container: any = commentsRef.current
    container.scrollTop = container.scrollHeight
  }, [messages])

  useEffect(() => {
    socket.on('new_comment', (e) => {
      dispatch(getMessages())
    })
  }, [])

  const fileUpload = (e) => {
    const data = prepareFileUpload(e)
    dispatch(sendFile(data))
      .then(res => {
        const container: any = commentsRef.current
        if (res) {
          container.scrollTop = container.scrollHeight
        }
      })
  }

  const send = (e) => {
    if (e.which === 13 && e.target.value.trim()) {
      const container: any = commentsRef.current
      dispatch(sendMessage(message))
        .then(res => {
          if (res) {
            container.scrollTop = container.scrollHeight
          }
        })
      setMessage('')
    }
  }

  return (
    <Fragment>
      <div id='comment-panel' ref={commentsRef}>
        {messages?.map((el, i) => {
          const sender = el.user_uuid === user.uuid ? 'Вы' : `Оператор ${el.first_name}`;
          const isRead = el.read?.includes(user.id) || el.user_uuid === user.uuid

          return <div key={i} className={`comment-wrapper ${!isRead ? 'new' : ''}`}>
            <div className="heading">
              <p className="sender">{sender}</p>
              <p className="date">{setDate(el.updated_at)}</p>
            </div>
            <div className="message">
              {el.message ? el.message :
                <p className='attachment'
                   onClick={() => dispatch(getFile(el.document_meta.fileName, el.document_meta.originalName))}>
                  <FileIcon/>{el.document_meta.originalName}
                </p>}
            </div>
          </div>
        })
        }
      </div>
      {!requestClosed(request?.status) && <PanelFooter className='comments-footer'>
        <Input value={message}
               autofocus
               className='text-input'
               onChange={e => setMessage(e.target.value)}
               onKeyUp={e => send(e)}
               placeholder='Оставить комментарий...'/>
        <label className='upload-icon' htmlFor='upload'>
          <AttachIcon/>
          <input name="file" type="file" id='upload' onChange={e => fileUpload(e)}/>
        </label>
      </PanelFooter>}
    </Fragment>
  )
}

export default CommentsPanel
