import React from 'react'
import moment from 'moment/moment'
import {EditIcon, FemaleIcon, MaleIcon} from "../../../assets";
import {InfoText} from '../../../shared'
import styles from './styles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {GENDER, REQUEST_STATUSES} from "../../../services/constants";
import {toggleEditRequest} from "../../../app/app-slice";

const PatientInfo = () => {
  const dispatch = useAppDispatch()
  const {request, er_call} = useAppSelector(state => state.requests)
  const {requestReasons} = useAppSelector(state => state.app)

  const fullName = `${request.last_name} ${request.first_name || '-'} ${request.middle_name || '-'}`
  const sex = GENDER.find(data => data.key === request.sex)
  const requestReason = requestReasons.find(val => val.id === request.request_reason)

  return (
    <div id={styles.patientInfo}>
      <p className={styles.title}>{request.sex === 1 ? <MaleIcon/> : <FemaleIcon/>} Пациент</p>
      {(request.status !== REQUEST_STATUSES.closed && er_call) && <EditIcon className={styles.editIcon} onClick={() => dispatch(toggleEditRequest())}/>}
      <div className={styles.wrapper}>
        <InfoText title='ФИО пациента' text={request.name || '-'}/>
        <InfoText title='Дата рождения' text={`${moment(request.birthday).format('DD/MM/YYYY')}`}/>
        <InfoText title='Номер полиса' text={request.policy_number || '-'}/>
      </div>
      <div className={styles.wrapper}>
        <InfoText title='Возраст / Пол' text={`${request.age} / ${sex?.type || '-'}`}/>
        <InfoText title='Вес' text={`${request.data.weight ? request.data.weight + ' КГ' : '-'}`}/>
        <InfoText title='Лежачий ' text={`${request.data.in_bed || '-'}`}/>
        <InfoText title='Телефон' text={request.contact_number || '-'}/>
      </div>
      <div className={styles.wrapper}>
        <InfoText title='Город' className={styles.city} text={request.city}/>
        <InfoText className={styles.addressInfo} title='Адрес' text={request.address}/>
        <InfoText className={`mb-20 ${styles.subway}`} title='Метро' text={request.data.subway || '-'}/>
      </div>
      <div className={styles.wrapper}>
        <InfoText title='Дом' text={request.data.house || '-'}/>
        <InfoText title='Подъезд' text={request.data.entrance || '-'}/>
        <InfoText title='Код Подъезда' text={request.data.buzzer || '-'}/>
      </div>
      <div className={`${styles.wrapper}`}>
        <InfoText title='Лифт' text={request.data.elevator || '-'}/>
        <InfoText title='Квартира' text={request.data.apartment || '-'}/>
        <InfoText title='Этаж' text={request.data.floor || '-'}/>
      </div>
      <div className={styles.divider}/>
      {/*<InfoText className='mb-20 mt-20' title='Повод обращения' text={requestReason?.name || '-'}/>*/}
      <InfoText className='mb-20 mt-20' title='Примечания' text={request.notes || '-'}/>
      <InfoText className='mb-20' title='Жалобы' text={request.complaints || '-'}/>
    </div>
  )
}

export default PatientInfo
