import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError, togglePopover, forceClosePopover } from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {formValidator} from "./validator";
import {toBase64} from "../../services/utils";
import {omit} from 'lodash'

const initialState = {
  emergencies: [],
  emergency: null,
  form:{
    name: '',
    phone:'',
    email:'',
    address:'',
    inn: '',
    abbr: '',
    avatar: '',
    preview_avatar: '',
    formData: '',
    cities: [1]
  },
  validationFields:{

  } as any,
  formValid: false,
}

const emergency = createSlice({
  name: 'adminEmergency',
  initialState: initialState,
  reducers: {
    setEmergencies: (state, action: PayloadAction<any>) => {
      state.emergencies = action.payload
      return state
    },
    setEmergency: (state, action: PayloadAction<any>) => {
      state.emergency = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    resetForm: (state)=>{
      state.form = initialState.form
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    },
  }
});

export const {
  setEmergencies,
  setEmergency,
  handleFormChange,
  validateInputs,
  resetForm
} = emergency.actions;

export const getEmergencies = (filter = {type:2}) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.get('organization', filter)
      dispatch(setEmergencies(res))
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const createEmergency = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminEmergency = getState().adminEmergency

    if (adminEmergency.formValid) {
      const data = {...adminEmergency.form}
      try {
        let response: any

        delete data.formData
        delete data.preview_avatar

        response = await api.admin.post('organization', {...data, type: 2})
        if(adminEmergency.form.formData) {
          const avatar = await dispatch(uploadAvatar(response.uuid))
          await api.admin.put(`organization/${response.uuid}`, {avatar: avatar.data})
        }
        await dispatch(togglePopover('adminAddOrganization'))
        await dispatch(getEmergencies())
        await dispatch(clearForm())
      } catch (e) {
        console.error('failed to create emergency with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateEmergency = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminEmergency = getState().adminEmergency

    if (adminEmergency.formValid) {
      const data = omit({...adminEmergency.form}, ['preview_avatar'])
      try {
        if(data.formData) {
          const response = await dispatch(uploadAvatar(adminEmergency.emergency.uuid))
          data.avatar = response.data
        }
        delete data.formData
        await api.admin.put(`organization/${adminEmergency.emergency.uuid}`, data)
        dispatch(forceClosePopover('adminAddOrganization'))
        await dispatch(clearForm())
        return await dispatch(getEmergencies())
      } catch (e) {
        console.error('failed to create emergency with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const selectEmergency = (data) => {
  return (dispatch: AppDispatch) => {
    if(data?.avatar) {
      dispatch(getAvatar(data.uuid, data.avatar))
    }
    dispatch(setEmergency(data))
  }
}

export const uploadAvatar = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {form} = getState().adminEmergency
    try {
      return await api.admin.upload(`organization/avatar/${uuid}`, form.formData)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const img =  await api.admin.get(`organization/avatar/${uuid}/${avatar}`)
      dispatch(handleFormChange({key: 'preview_avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const deleteOrganization = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.delete(`/organization/${uuid}`)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default emergency.reducer;
