import React from 'react'
import {BrigadeInfo, PatientInfo} from "../components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {InfoText} from "../../../shared";
import {Button} from '../../../ui-components';
import {handleFormChange, setRequestForm} from '../../requests/requests-slice';
import {getAddressesList, toggleRequestForm} from '../../../app/app-slice';
import {DiagnoseInfo, HospitalInfo} from "../../insurance-request/components";

const OnWayRequest = () => {
  const {er_call, request} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  return (
    <div id='on-way-request' className={`request-info-container ${er_call.status === 13 && !request.parent_request ? 'negative-margin' : ''}`}>
      {er_call.status === 13 &&
      <div className='change-er-info'>
        <p className="title">Замена СМП</p>
        <InfoText title={'Причина передачи'}
                  className='mb-15'
                  text={er_call.emergency_change_reason}/>
        {er_call.emergency_change_comment && <InfoText className='mb-15' title={'Комментарий'} text={er_call.emergency_change_comment}/>}
        {er_call.emergency_change_address && <InfoText className='mb-30' title={'Адрес'} text={er_call.emergency_change_address}/>}
        <Button fullWidth onClick={() => {
            dispatch(setRequestForm())
            dispatch(toggleRequestForm())
            if(er_call.emergency_change_address) {
              dispatch(handleFormChange({key: 'address', value: er_call.emergency_change_address}))
            }
            if(er_call.override_comment && er_call.emergency_change_comment) {
              dispatch(handleFormChange({key: 'complaints', value: er_call.emergency_change_comment}))
            }
            dispatch(getAddressesList(er_call.emergency_change_address || request.address))
        }} type='contained'>Заменить СМП</Button>
      </div>
      }
      <HospitalInfo/>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
    </div>
  )
}

export default OnWayRequest
