import React, {FC, Fragment, memo, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import './styles.scss'
import { useAppDispatch, useAppSelector } from '../../core/hooks'
import {resetAllPopovers, toggleRightMainPanel} from '../../app/app-slice'
import {resetState} from "../../modules/requests/requests-slice";

interface Props {
  handleClose: () => void,
  className?: string
  open: boolean
}

const RightBigPanel: FC<Props> = (props) => {
  const panelRoot: any = document.querySelector('#panel-root');
  const [open, setOpen] = useState<boolean>(props.open)
  const [openClass, setClass] = useState<string>('')
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)

  let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
  // const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
  // if(chromeAgent && safariAgent) safariAgent = false

  useEffect(() => {
    if (props.open) {
      setTimeout(() => setClass('open'), 10)
      setOpen(props.open)
      document.body.classList.add('overflow-hidden')
    } else {
      setClass('')
      setTimeout(() => {
        setOpen(props.open)
      }, 600)
      document.body.classList.remove('overflow-hidden')
      dispatch(resetAllPopovers())
      dispatch(resetState('hospitalizationForm'))
    }
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [props.open, dispatch])

  const handleEscKey = (event) => {
    if (event.key === 'Escape' && app.rightMainPanel) {
      dispatch(toggleRightMainPanel())
    }
  }

  const data = <Fragment>
    {open &&
      <div id='bigPanel-container'>
        <div className='panel-backdrop' onClick={props.handleClose ? props.handleClose : undefined}/>
        <div id='bigPanel-content'
             // style={{transform: safariAgent ?  'unset' : ""}}
             className={`
           ${props.className ? props.className : ''}
           ${open ? openClass : ''}
           `}>
          {props.children}
        </div>
      </div>}
  </Fragment>

  return (
    <Fragment>
      {panelRoot && ReactDOM.createPortal(
        data,
        panelRoot)}
    </Fragment>
  )
}

export default RightBigPanel
