import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./SuggestionCard.module.scss";
import LeftIcon from "../../../../assets/svgs/phone.svg";
import {ER_CALL_STATUSES} from "../../../../services/constants";
import {Timer} from "../../../../shared";

const SuggestionCard = ({
  suggestion,
  onClick,
  onResolveOrCancel,
  index,
  onClickItem,
  selectedIndex,
}) => {
  // console.log(suggestion, "suggestion");
  const [assignedAvatar, setAssignedAvatar] = useState(null);
  return (
    <div onClick={() => onClickItem(suggestion)} style={{ marginLeft: 15,width: '100%', height: '100%', paddingTop: 8, paddingBottom: 12, paddingLeft: 12, paddingRight: 12, background: 'white', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.08)', borderRadius: 12, border: '1px #E7E7E7 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
        <div style={{width: 32, height: 32, padding: 8, background: '#F1F6FF', borderRadius: 40, justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex'}}>
          {/*<div style={{width: 20, height: 20, position: 'relative'}}>*/}
          {/*  <div style={{width: 14.38, height: 14.38, left: 3.12, top: 2.50, position: 'absolute', background: '#365EDE'}}></div>*/}
          {/*</div>*/}
          <img src={LeftIcon} alt={""}/>
        </div>
        <div style={{flex: '1 1 0', color: '#192233', fontSize: 14,  fontWeight: '700',  wordWrap: 'break-word'}}>{suggestion?.customer_type ===2? suggestion.entity_name : suggestion?.name}</div>

        <Timer date={suggestion?.updated_at}
               request_uuid={suggestion?.uuid}
               column={"er_calls"}
               isSentry={true}
               timeLimit={300000}/>
      </div>
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
        <div style={{width: 38, paddingLeft: 4, paddingRight: 8, borderRight: '1px #E7E7E7 solid', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
          <div style={{color: '#192233', fontSize: 12,  fontWeight: '700',  wordWrap: 'break-word'}}>{suggestion?.city === "Москва" ? "МСК" : "СПБ"}</div>
        </div>
        <div style={{ paddingLeft: 4, paddingRight: 8, borderRight: '1px #E7E7E7 solid', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
          <div style={{color: '#192233', fontSize: 12,  fontWeight: '500',  wordWrap: 'break-word'}}>№ {suggestion?.request_number}</div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionCard;
