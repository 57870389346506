import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../admin/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getKeyValueStringFromObj, getRegion, IGlobalFilter} from "../../../services/utils";
import {omit} from 'lodash'

interface History {
  request_uuid: string
  address: string
  reason: string
  age: number
  sex: string
  created_date: string
  createdAt: string
  creator: string
  status: number
  number: string
  executor: string,
  name: string,
  policy_number: string,
  arrival_time: number,
  end_time: number,
  diagnosis: string,
  comment: string,
  end_date: string,
  org_name: string
}

interface IHistory {
  history: Array<History>
  filter: IGlobalFilter
  allDataLoaded: boolean
}

const initialState: IHistory = {
  history: [],
  filter: {
    limit: 50,
    offset: 0,
    q: '',
    from: '',
    to: '',
    data: {
      age: '',
      status: '',
    }
  },
  allDataLoaded: true
};

const history = createSlice({
  name: 'history',
  initialState: initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<Array<History>>) => {
      state.history = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: any}>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.filter.data![key] = data.value
      } else {
        state.filter[data.key] = data.value
      }
    },
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded =  action.payload
    }
  }
});

export const {
  setHistory,
  handleFilterChange,
  setDataLoaded
} = history.actions;

export const getHistory = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter} = getState().history
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      dispatch(handleFilterChange({key: 'offset', value: 0}))
      const res = await api.request.get('/history', {...updatedFilter, offset: 0, region, filter: additionalData})
      res.length < filter.limit  ? dispatch(setDataLoaded(true)) : dispatch(setDataLoaded(false))
      dispatch(setHistory(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const loadMore = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter, history} = getState().history
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/history', {...updatedFilter, region, filter: additionalData})
      const updatedHistory = [...history, ...res]
      filter.limit > res.length && dispatch(setDataLoaded(true))
      dispatch(setHistory(updatedHistory))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default history.reducer;
