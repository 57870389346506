import React, {useMemo} from 'react'
import {Button} from "../../../ui-components";
import {InfoTooltipTrigger, PanelFooter, PanelPopover} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {
  changeCustomerErCallStatus,
} from "../../requests/er-thunk";
import CustomerDpInfo from "../CustomerDpInfo";
import BrigadeInfo from "../../brigade/brigadeInfo";
import CustomerActionsTooltip from "./customerActionsTooltip";
import DiagnoseInfo from "../../er-request/components/diagnose-info";
import HospitalizationBrigadeInfo from "./HospitalizationBrigadeInfo";
import HospitalInfo from "../../er-request/components/hospital-info";
import DpDcActionsPopover from "./DpDcActionsPopover";
import {togglePopover} from "../../../app/app-slice";
import DpFinishSentry from "./DpFinishSentry";

const CustomerCancelHospitalization = () => {
  const app = useAppSelector(state => state.app)
  const {er_call, request} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const selectedRequestBrigade = useMemo(() => {
    if (request) {
      return request?.requestedBrigades?.filter((b) => b.request_uuid === request?.uuid)
    }
  }, [request])
  return (
    <div id='on-way-request' className={`request-info-container ${er_call.status < 13 || er_call.status === 21 ? 'pb-80' : ''}`}>
      {er_call && (er_call?.status ===9 || er_call?.status ===10 || er_call?.status ===11 || er_call?.status ===22) && <HospitalInfo/>}
      <DiagnoseInfo/>
      <HospitalizationBrigadeInfo/>
      <CustomerDpInfo/>
      {request && request?.requestedBrigades && selectedRequestBrigade.map((item) => {
        return <BrigadeInfo br={item}/>
      })}
      {er_call.status === 7 &&
        <PanelFooter className='footer'>
            <Button type='outlined'  onClick={() => {
              dispatch(changeCustomerErCallStatus(23))
            }}>Отказ от Гсп.</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      }
      {er_call.status === 9 &&
        <PanelFooter className='footer'>
            <Button type='contained'  onClick={() => {
              dispatch(changeCustomerErCallStatus(10))
            }}>Начать Госпитализацию</Button>
          <Button type='outlined'  onClick={() => {
              dispatch(changeCustomerErCallStatus(23))
            }}>Отказ от Гсп.</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      }
      {er_call.status === 10 &&
        <PanelFooter className='footer'>
            <Button type='contained'  onClick={() => {
              dispatch(changeCustomerErCallStatus(11))
            }}>Стацианирование</Button>
          <Button type='outlined'  onClick={() => {
              dispatch(changeCustomerErCallStatus(23))
            }}>Отказ от Гсп.</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      }
      {er_call.status === 22 &&
        <PanelFooter className='footer'>
            <Button type='contained'  onClick={() => {
              dispatch(changeCustomerErCallStatus(11))
            }}>Стацианирование</Button>
          <Button type='outlined'  onClick={() => {
              dispatch(changeCustomerErCallStatus(23))
            }}>Отказ от Гсп.</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      }

      {er_call.status === 11 &&
        <PanelFooter className='footer'>
            <Button type='contained'  onClick={() => {
              dispatch(togglePopover('dpFinishSentry'))
              // dispatch(changeCustomerErCallStatus(12))
            }}>Завершить</Button>
          <Button type='outlined'  onClick={() => {
              dispatch(changeCustomerErCallStatus(23))
            }}>На дежурство</Button>
          <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
          {er_call && er_call.status > 1 &&
            // @ts-ignore
            <CustomerActionsTooltip isDp id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>}
        </PanelFooter>
      }
      <PanelPopover title={'Вы уверены что хотите завершить дежурство?'}
                    open={app.popoverFormStatuses.dpFinishSentry}
                    handleClose={() => dispatch(togglePopover('dpFinishSentry'))}>
        <DpFinishSentry/>
      </PanelPopover>
      <DpDcActionsPopover/>
    </div>
  )
}

export default CustomerCancelHospitalization
