import React, {FC, ReactElement} from 'react'
import './no-data.scss'

interface Props {
  className?: string
  img: string
  title: string
}

const NoData: FC<Props> = (props) => {
  return (
    <div id='no-data-container' className={props.className ? props.className : ''}>
      <img src={props.img} alt='icon' />
      <p className="text">
        {props.title}
      </p>
    </div>
  )
}

export default NoData
