import React, {useCallback} from "react";
import styles from "./SuggestionCard.module.scss";
import {
  AmbulanceNewIcon, CheckBlueIcon,
  CheckGreenIcon, ErrorIconGradient,
  PendingIcon,
} from "../../../../assets";

const SuggestionCard = ({
  suggestion,
  onClick,
  request,
  onResolveOrCancel,
  index,
  onClickItem,
  selectedIndex,
}) => {

  const handleStatus = useCallback(() => {
    // eslint-disable-next-line default-case
    switch (suggestion.status) {
      case 1:
        return <PendingIcon />;
      case 2:
        return <CheckBlueIcon/>;
      case 3:
        if (suggestion?.paymentStatus === 0) {
          return <ErrorIconGradient />;
        }
        return <CheckGreenIcon />;
      default:
        break
    }
  }, [suggestion]);
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={() => {
        // dispatch(getCustomerSentrySingleRequests({uuid: suggestion.request_uuid, sentry_group_uuid: request?.sentry_group_uuid}))
        onClickItem(suggestion?.request_uuid);
        onClick(suggestion?.request_uuid);
      }}
      style={suggestion?.request_uuid === selectedIndex ? { border: "1px #365EDE solid" } : {}}
      className={styles.container}
    >
      <div className={styles.head}>
        <AmbulanceNewIcon/>
        <div className={styles.headText}>
          {suggestion?.sentry_brigade_type}
        </div>
        {handleStatus()}
      </div>
      <div className={styles.address}>
        {suggestion?.sentry_address}
      </div>
    </div>

  );
};

export default SuggestionCard;
