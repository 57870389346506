import React, {useState} from 'react'
import styles from "./styles.module.scss";
import InputMask from 'react-input-mask'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {brigadeTypes} from "../../../services/constants";
import {addAdditionalBrigade} from "../../requests/er-thunk";
import {useAppDispatch} from "../../../core/hooks";

const AdditionalBrigadeForm = () => {
  const [brigade_type, setBrigadeType] = useState<string>('')
  const [brigadeResponsible, setBrigadeResponsible] = useState<string>('')
  const [brigadeNumber, setBrigade] = useState<string>('')
  const [eta, setEta] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)

  const dispatch = useAppDispatch();

  const submit = (): void => {
    if(brigade_type && brigadeResponsible && brigadeNumber && eta) {
      setClickDisabled(true)
      dispatch(addAdditionalBrigade(brigade_type, brigadeResponsible, brigadeNumber, parseInt(eta), comment))
        .then(res => setClickDisabled(false))
    }
  }

  return (
    <div id={styles.replaceBrigadeForm}>
      <div className={`${styles.wrapper} mb-40`}>
        <Autocomplete
          className={styles.input}
          options={brigadeTypes}
          value={brigade_type}
          onChange={(data) => setBrigadeType(data.value)}
          onKeyUp={(e) => e.which === 13 && submit()}
          label={'Выбрать тип бригады'}
          optionLabel={'value'}
          optionValue={'value'}/>
        <Input label='Ответственный бригады' className={styles.input} value={brigadeResponsible}
               onChange={e => setBrigadeResponsible(e.target.value)}/>
      </div>
      <div className={`${styles.wrapper} mb-40`}>
        <Input label='Номер бригады'
               onChange={e => setBrigade(e.target.value)}
               onKeyUp={(e) => e.which === 13 && submit()}
               className={styles.input} value={brigadeNumber}/>
        <InputMask mask="999 минут" maskChar=" "
                   className={`${styles.input}`}
                   label='Время доезда'
                   onChange={e => setEta(e?.target.value)}
                   onKeyUp={(e) => e.which === 13 && submit()}
                   value={eta}>
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      </div>
      <Input label='Комментарий'
             onChange={e => setComment(e.target.value)}
             onKeyUp={(e) => e.which === 13 && submit()}
             className='mb-40' value={comment}/>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!brigade_type || !brigadeResponsible || !brigadeNumber || !eta}
              onClick={submit}
              type={'contained'}>Отправить</Button>
    </div>
  )
}

export default AdditionalBrigadeForm
