import React, {ChangeEvent, useState} from 'react'
import { Button, Input } from '../../ui-components'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import InputMask from 'react-input-mask'
import styles from './dispatchers.module.scss'
import {handleFormChange} from './dispatchers-slice';
import {AttachIcon} from '../../assets'
import {prepareFileUpload} from "../../services/utils";
import { Modal } from '../../shared'

const DispatcherForm = () => {

  const {form, validationFields} = useAppSelector(state => state.dispatchers)
  const [sizeLimitModelOpen, setSizeLimitModelOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  const removeAvatar = () => {
    dispatch(handleFormChange({key: 'avatar', value: null}))
    dispatch(handleFormChange({key: 'preview_avatar', value: null}))
    dispatch(handleFormChange({key: 'formData', value: null}))
  }

  // handle img preview and set form data before upload
  const handleImgUpload = (e) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > 10485760) {
        e.target.value = ''
        return setSizeLimitModelOpen(true)
      }
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        dispatch(handleFormChange({
          key: 'preview_avatar',
          value: reader.result as string
        }))
      }
      dispatch(handleFormChange({
        key: 'formData',
        value: prepareFileUpload(e)
      }))
      e.target.value = ''
    }
  }
  return (
    <div id={styles.dispatcherForm}>
      <div className={styles.wrapper}>
        <Input label='Фамилия'
               error={!validationFields.last_name}
               className={styles.input}
               onChange={handleInputChange}
               name='last_name' value={form.last_name}/>
        <Input label='Имя'
               className={styles.input}
               error={!validationFields.first_name}
               onChange={handleInputChange}
               name='first_name' value={form.first_name}/>
        <Input label='Отчество'
               className={styles.input}
               error={!validationFields.middle_name}
               onChange={handleInputChange}
               name='middle_name' value={form.middle_name}/>
      </div>
      <div className={styles.wrapper}>
        <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                   className={styles.input}
                   name='primary_phone'
                   error={!validationFields.primary_phone}
                   onChange={(e) => e && handleInputChange(e)}
                   value={form.primary_phone} label="Телефон">
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
        <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                   className={styles.input}
                   name='secondary_phone'
                   onChange={(e) => e && handleInputChange(e)}
                   value={form.secondary_phone} label="Доп. телефон">
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
        <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                   className={styles.input}
                   name='mobile_phone'
                   onChange={(e) => e && handleInputChange(e)}
                   value={form.mobile_phone} label="Моб. телефон">
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      </div>
      <div className={styles.wrapper}>
        <Input label='Почта'
               className={styles.input}
               error={!validationFields.email}
               onChange={handleInputChange}
               name='email' value={form.email}/>
      </div>
      {(form.avatar|| form.preview_avatar) &&
      <div className={styles.avatarWrapper}>
        <span onClick={removeAvatar} className={styles.removeIcon} />
        <img className={styles.avatar} src={form.preview_avatar || form.avatar} alt="img"/>
      </div>}
      <label htmlFor='img-upload' className={styles.imgUploadWrapper}>
        <AttachIcon/>
        <span>Загрузить фотографию</span>
        <input id='img-upload'
               accept="image/*"
               onChange={handleImgUpload}
               className='hidden' type='file'/>
      </label>
      <Modal open={sizeLimitModelOpen}
             closeIcon
             title={'Размер изображения'}
             handleClose={() => {
               setSizeLimitModelOpen(false)
             }}>
        <p className="modalText mb-50">Размер загружаемого изображения не должен превышать 10 МБ.</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => setSizeLimitModelOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
    </div>
  )
}

export default DispatcherForm
