import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../core/store";
import {ApiInstance} from "../services/api";
import {User} from "../admin-modules/users/IUsers";
import { setUser } from "../admin-modules/users/users-slice";


interface ApiError {
  status: number,
  text: string,
  error_id: number
}

interface App {
  user: User
  apiError: ApiError,
  adminAddOrganization: boolean
  adminAddUser: boolean
  adminAddHospital: boolean
  adminAddReason: boolean
}

const initialState: App = {
  user: {
    role: 0,
    first_name: '',
    last_name: '',
    email: '',
    status: 0
  },

  apiError: {
    status: 0,
    text: '',
    error_id: 0
  },
  adminAddOrganization: false,
  adminAddUser: false,
  adminAddHospital: false,
  adminAddReason: false
};

const adminApp = createSlice({
  name: 'adminApp',
  initialState: initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<ApiError| any>) => {
      if (action.payload.response) {
        state.apiError.status = action.payload.response.status
        state.apiError.text = action.payload.response.data?.error
        state.apiError.error_id = action.payload.response.data?.error_id
      } else if (action.payload.code) {
        state.apiError.status = action.payload.code
        state.apiError.text = action.payload.data?.error
        state.apiError.error_id = action.payload.data?.error_id
      } else {
        state.apiError.status = 0
        state.apiError.text = ''
        state.apiError.error_id = 0
      }
      return state
    },
    togglePopover: (state, action: PayloadAction<string>) => {
      state[action.payload] = !state[action.payload]
    },
    forceClosePopover:(state, action: PayloadAction<string>) => {
      state[action.payload] = false
    }
  }
});

export const {
  setApiError,
  togglePopover,
  forceClosePopover
} = adminApp.actions;

export const loginUser = (data: {email: string, password: string}) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.login(data)
      localStorage.setItem('token', res.token)
      dispatch(setUser(res.user))
    } catch (e) {
      return dispatch(setApiError(e))
    }
  }
};



export default adminApp.reducer;
