import React, { FC } from "react";

interface Props {
  className?: string
}

const RightBigPanelFooter: FC<Props> = (props) => {
  return (
    <div id='rightPanel-footer' className={props.className ? props.className : ''}>
      {props.children}
    </div>
  )
}

export default RightBigPanelFooter
