import React, {ChangeEvent, useEffect} from 'react'
import {Input} from "../../ui-components";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {handleFormChange, formFields} from './reasons-slice';
import '../../assets/styles/default-form.scss'

const ReasonForm = ({reason}) => {
  const {form, validationFields} = useAppSelector(state => state.adminReasons)
  const dispatch = useAppDispatch()
  useEffect(()=>{
    if(reason){
      for(const prop in reason){
        if(formFields.includes(prop)){
          dispatch(handleFormChange({key: prop, value:reason[prop]}))
        }
      }
    }
  }, [reason])

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  return (
    <div className="form-wrapper overflow-scroll">
      <div className="temp-03-form">
        <div className={'mb-40'}>
          <Input name='name'
                 error={!validationFields.name}
                 onChange={handleInputChange}
                 value={form.name} label="Название"/>
        </div>
      </div>
    </div>
  )
}

export default ReasonForm
