import React, {FC} from "react";
import './textarea.scss'
import {Props} from './ITextarea'

const Textarea: FC<Props> = (props) => {
  return (
    <div id='textarea-container'>
      {props.label && <p className='label'>{props.label}</p>}
      <textarea id={props.id}
                cols={props.cols ? props.cols : 50}
                placeholder={props.placeholder}
                name={props.name}
                readOnly={props.readonly}
                value={props.value}
                onChange={props.onChange ? props.onChange : undefined}
                onBlur={props.onBlur ? props.onBlur : undefined}
                className={
                  `textarea
                ${props.className ? props.className : ''}
                ${props.error ? 'error' : ''}`
                }
                rows={props.rows ? props.rows : 5}/>
    </div>
  )
}


export default Textarea
