import React, {FC, Fragment, useEffect, useState} from "react";
import './styles.scss'
import {CloseIcon} from "../../assets";

interface Props {
  className?: string
  height?: number
  title?: string
  open: boolean
  handleClose: () => void
}

const PanelPopover: FC<Props> = (props) => {
  const panelContent = document.querySelector('#panel-content');
  const panelFooter = document.querySelector('#panel-footer')
  const [open, setOpen] = useState<boolean>(props.open)
  const [openClass, setClass] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (props.open) {
      setClickDisabled(true)
      setTimeout(() => setClass('open'), 10)
      panelContent?.classList.add('overflow-hidden')
      panelFooter?.classList.add('hidden')
      setOpen(props.open)
      setTimeout(() => setClickDisabled(false), 1000)
    } else {
      setClickDisabled(true)
      setClass('')
      setTimeout(() => {
        setOpen(props.open)
        setClickDisabled(false)
      }, 600)
      panelContent?.classList.remove('overflow-hidden')
      panelFooter?.classList.remove('hidden')
    }
  }, [props.open, panelFooter, panelContent])

  return open ? (
    <Fragment>
      <div className='popover-backdrop' onClick={() => {!clickDisabled && props.handleClose()}}/>
      <div id='panel-popover' style={{height: props.height ? `${props.height}px` : ''}} className={`${props.className ? props.className : ''} ${openClass}`}>
        <div className="title-wrapper">
          <h3 className="title">{props.title}</h3>
          <CloseIcon className='close-icon'  onClick={props.handleClose ? props.handleClose : undefined}/>
        </div>
        {props.children}
      </div>
    </Fragment>
  ) : null
}

export default PanelPopover
