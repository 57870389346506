import React, {useEffect, useState} from 'react'
import {Modal, Panel, PanelFooter, PanelHeader, Table, TableBody, TableHead} from "../../shared";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  createDispatcher,
  deleteDispatcher,
  editDispatcher,
  getDispatchers,
  setData,
  resetForm,
  getAvatar
} from "./dispatchers-slice";
import {setDate} from "../../services/utils";
import {CloseIcon, EditIcon, SearchIcon} from "../../assets"
import styles from './dispatchers.module.scss'
import {Button, Input} from '../../ui-components';
import {toggleMainPanel} from '../../app/app-slice';
import DispatcherForm from "./dispatcher-form";
import {inputsValidator, isFormDataValid} from "../../services/globalValidators";
import {DISPATCHERS_TABLE_HEAD} from "../../services/constants";
import {formValidator} from './validator';
import _pick from "lodash/pick";


const DispatchersList = () => {

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [userExistsModalOpen, setUserExistsModalOpen] = useState<boolean>(false)
  const [sizeLimitModelOpen, setSizeLimitModelOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {list, form, validationFields, editState} = useAppSelector(state => state.dispatchers)
  const app = useAppSelector(state => state.app)

  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Диспетчеры'}))
    dispatch(getDispatchers())
  }, [dispatch])

  const serializedList = list.map((el) => {
    return [
      `${el.last_name} ${el.first_name}  ${el.middle_name}`,
      setDate(el.created_at),
      el.primary_phone,
      el.secondary_phone,
      el.mobile_phone,
      el.email,
      <EditIcon className={styles.editBtn} onClick={() => editUser(el.uuid)}/>
    ]
  })

  const create = () => {

    const data: any = [];

    for (const key in form) {
      data.push({key, value: form[key]})
    }

    const fields = inputsValidator(data, validationFields, formValidator)
    dispatch(setData({prop: 'validationFields', data: fields}))
    dispatch(setData({prop: 'formValid', data: isFormDataValid(fields)}))
    dispatch(createDispatcher())
      .then(res => {
        if (res.code === 400) {
          setUserExistsModalOpen(true)
        }
        if(res.code === 413) {
          setSizeLimitModelOpen(true)
        }
      })
  }

  const editUser = (id) => {
    const dispatcher = list.find(el => el.uuid === id)
    const form = _pick(dispatcher, ['uuid', 'first_name', 'last_name', 'middle_name', 'primary_phone', 'secondary_phone', 'mobile_phone', 'email'])
    dispatch(setData({prop: 'dispatcher', data: dispatcher}))
    dispatch(setData({prop: 'form', data: form}))
    dispatch(setData({prop: 'editState', data: true}))
    dispatch(toggleMainPanel())
    if (dispatcher?.avatar) {
      dispatch(getAvatar(dispatcher.uuid, dispatcher.avatar))
    }
  }

  const update = () => {
    const data: any = [];

    for (const key in form) {
      if (key === 'password') continue
      data.push({key, value: form[key]})
    }

    const fields = inputsValidator(data, validationFields, formValidator)
    dispatch(setData({prop: 'validationFields', data: fields}))
    dispatch(setData({prop: 'formValid', data: isFormDataValid(fields)}))
    dispatch(editDispatcher())
  }

  return (
    <div id={styles.dispatchersList}>
      <div className={styles.header}>
        <Input placeholder={'Поиск'} startIcon={<SearchIcon/>}/>
        <Button onClick={() => dispatch(toggleMainPanel())} type={'contained'}>Создать аккаунт</Button>
      </div>
      <Table>
        <TableHead>
          {DISPATCHERS_TABLE_HEAD.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
        </TableHead>
        <TableBody className='no-top-border'>
          {
            serializedList.map((data, i) => {
              return <div className='row' key={i}>
                {data.map((el, i) =>
                  <div key={i} className={`${styles.item} item`}>
                    <span>
                      {el}
                    </span>
                  </div>)}
              </div>
            })
          }
        </TableBody>
      </Table>
      <Panel handleClose={() => {
        dispatch(toggleMainPanel())
        dispatch(setData({prop: 'editState', data: false}))
        dispatch(resetForm())
      }} open={app.mainPanelOpen}>
        <PanelHeader className={styles.panelHeader} withBorder>
          <p className={styles.title}>{editState ? 'Редактировать' : 'Создать Диспетчера'}</p>
          <CloseIcon onClick={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        <DispatcherForm/>
        <PanelFooter className={styles.panelFooter}>
          {editState ?
            <>
              <Button type='contained' fullWidth onClick={update}>Сохранить</Button>
              {/*<Button type='outlined' className='ml-20 mr-20'*/}
              {/*        fullWidth onClick={() => setModalOpen(true)}>Удалить аккаунт</Button>*/}
              <Button className='ml-20'
                type='outlined' fullWidth onClick={() => {
                dispatch(toggleMainPanel())
                dispatch(setData({prop: 'editState', data: false}))
              }}>Отменить</Button>
            </> :
            <>
              <Button type='simple' onClick={() => {
                dispatch(toggleMainPanel())
                dispatch(setData({prop: 'editState', data: false}))
                dispatch(resetForm())
              }}>Отмена</Button>
              <Button type='contained' onClick={create}>Создать</Button>
            </>}
        </PanelFooter>
      </Panel>
      <Modal open={modalOpen}
             title={'Удалить аккаунт'}
             handleClose={() => setModalOpen(false)}>
        <p className="modalText mb-50">Вы уверены, что хотите удалить учетную запись пользователя?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => {
                    dispatch(deleteDispatcher())
                      .then(res => res && setModalOpen(false))
                  }}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
      <Modal open={userExistsModalOpen}
             closeIcon
             title={'Пользователь существует'}
             handleClose={() => {
               setUserExistsModalOpen(false)
             }}>
        <p className="modalText mb-50">Пользователь с таким адресом электронной почты уже существует.</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => setUserExistsModalOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
      <Modal open={sizeLimitModelOpen}
             closeIcon
             title={'Размер изображения'}
             handleClose={() => {
               setSizeLimitModelOpen(false)
             }}>
        <p className="modalText mb-50">Размер загружаемого изображения не должен превышать 10 МБ.</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => setSizeLimitModelOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
    </div>
  )
}

export default DispatchersList
