import React, {useCallback} from "react";
import Sentry from "./sentry";
import PayCard from "./PayCard";
import Brigade from "../brigade/brigade";
import {RightBigPanelFooter} from "../../shared";
import {Button} from "../../ui-components";
import {cancelCustomerOfferBrigadeRequests, sendCustomerSentrySingleRequests} from "../requests/er-thunk";
import {useAppDispatch, useAppSelector} from "../../core/hooks";

const CreatedSentryContent = ({request, selectedModalBrigade}) => {

  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const handleSubmit = useCallback(() => {
   dispatch(sendCustomerSentrySingleRequests())
  } , [dispatch])

  const handleClose = useCallback(() => {
    dispatch(cancelCustomerOfferBrigadeRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}
    }))
  } , [dispatch, request?.customer_uuid, request?.uuid])

  return  <>
    <div style={{width: "50%"}}>
      <Sentry request={request}/>
      <PayCard/>
    </div>
    <div style={{width: "50%"}}>
      <Brigade selectedModalBrigade={selectedModalBrigade} />
      <RightBigPanelFooter>
        <div style={{display: "flex", gap: 12, width: "100%"}}>
        <Button onClick={handleSubmit} type='contained' fullWidth>Отправить заявку в торги</Button>
        <Button onClick={handleClose} type='outlined' fullWidth>Отказать</Button>
        </div>
      </RightBigPanelFooter>
    </div>

  </>
}

export default CreatedSentryContent
