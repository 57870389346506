import {useEffect} from "react";
// @ts-ignore
import notificationSound from '../../assets/sounds/notification-sound-new.wav'
// @ts-ignore
import alertSound from '../../assets/sounds/alert-long-new.wav'
// @ts-ignore
import dashboardUpdateSound from "../../assets/sounds/dashboard-update-sound-new.wav";
// @ts-ignore
import commentSound from "../../assets/sounds/comment-sound.wav"
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import { stopNotificationSound } from './notifications-sound-manager-slice'

const playNotificationSound = new Audio(notificationSound)
const playTimerNotificationSound = new Audio(alertSound)
const playErDashboardNotificationSound = new Audio(dashboardUpdateSound)
const playCommentSound = new Audio(commentSound)

const NotificationSoundManager = () => {
  const dispatch = useAppDispatch()
  const { soundName } = useAppSelector(state => state.notificationSound)

  useEffect(()=>{
    playTimerNotificationSound.addEventListener('timeupdate', () =>{
      if (playTimerNotificationSound.currentTime >= 10) {

        playTimerNotificationSound.pause();
        playTimerNotificationSound.currentTime = 0
      }
    }, false);
    return () => {
      playTimerNotificationSound.addEventListener('timeupdate', ()=>{})
    }
  }, [])


  useEffect(()=>{
    switch (soundName){
      case 'socketNotificationSound':
        playNotificationSound.play().catch(console.warn)
        break;
      case 'timerNotificationSound':
        playTimerNotificationSound.play().catch(console.warn)
        break;
      case 'erDashboardNotificationSound':
        playErDashboardNotificationSound.play().catch(console.warn)
        break;
      case 'commentSound':
        playCommentSound.play().catch(console.warn)
    }

    if(soundName){
      dispatch(stopNotificationSound())
    }
  }, [soundName])

  return null
}


export default NotificationSoundManager
