import React, {useState} from 'react'
import {Button, Input} from "../../ui-components";
import {formValidator, ValidationFields} from "../../shared/validators/validator";
import {useAppDispatch} from "../../core/hooks";
import {inputsValidator, isFormDataValid} from "../../services/globalValidators";
import {resetPassword} from "../user/user-slice";
import { Modal } from '../../shared'

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean>(true)
  const [fields, setFields] = useState<object>({})
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [validationFields, setValidationFields] = useState<ValidationFields>({email: true})

  const dispatch = useAppDispatch()

  const validateForm = (email) => {
    setError(false)
    setEmail(email)
    const data = {
      key: 'email',
      value: email,
    }
    setFields(inputsValidator(data, validationFields, formValidator))
  }

  const submitForm = () => {
    setFormValid(isFormDataValid(fields))
    setValidationFields({...fields})

    if (isFormDataValid(fields)) {
      dispatch(resetPassword(email))
        .then(res => res && setSuccessModalOpen(true))
        .catch(error => {
          setError(true)
          console.log(error)
        })
    }
  }

  return (
    <div id='forgot-password-container' className='form-container'>
      <p className='title'>Забыли пароль</p>
      <p className='description'>
        Чтобы востановить пароль напишите почту и админ вам отправит запрос на изменения пароля
      </p>
      <Input label={'Почта'} value={email}
             error={!validationFields.email}
             onChange={(e) => validateForm(e.target.value)}
             onKeyUp={(e) => e.which === 13 && submitForm()}
             className='mb-50'/>
      <Button color='gradient' onClick={submitForm} className='button' type='contained'>Отправить</Button>
      {!formValid && <p className='text-error'>*Заполните все поля</p>}
      {error && <p className='text-error'>Пользователь с такой почтой не существует</p>}
      <Modal open={successModalOpen}
             closeIcon
             title={'Письмо отправлено'}
             handleClose={() => {
               setSuccessModalOpen(false)
             }}>
        <p>Письмо на восстановление пароля отправлено на {email}.</p>
      </Modal>
    </div>
  )
}

export default ForgotPassword
