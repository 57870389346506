import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import SuggestionsList from "../components/SuggestionsList";
import SuggestionCard from "../components/SuggestionCard";

const ModalSuggestionsListContainer = ({ onClick, list, setSelectedModalBrigade, request, selectedModalBrigade }) => {
  const [selectedIndex, setIndex] = useState(selectedModalBrigade);

  const onClickItem = useCallback((ind) => {
    setIndex(ind);
  }, []);

  useEffect(() => {
    // if (list && list.length && !selectedIndex) {
    //   setSelectedModalBrigade(list[0]?.request_uuid)
    // }

  }, [list, setSelectedModalBrigade, selectedIndex])

  return (
    <SuggestionsList length={list.length || 0}>
      {list?.map((suggestion, index) => (
        <SuggestionCard
          request={request}
          onClick={onClick}
          onResolveOrCancel={() => {}}
          key={suggestion.id}
          suggestion={suggestion}
          index={index}
          selectedIndex={selectedIndex}
          onClickItem={onClickItem}
        />
      ))}
    </SuggestionsList>
  );
};

ModalSuggestionsListContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ModalSuggestionsListContainer;
