import React from 'react'

interface Props {

}

const TableBody = (props) => {
  return (
    <div className={`table-body wrapper ${props.className || ''}`}>
        {props.children}
    </div>
  )
}


export default TableBody
