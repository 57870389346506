import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {cancelCustomerEarly} from "../../requests/er-thunk";
import InputMask from 'react-input-mask'

const DcEarlyClose = () => {
  const { request} = useAppSelector(state => state.requests)
  const [formList, setFormList] = useState<any>()

  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const submit = (): void => {
    if(formList) {
      setClickDisabled(true)
      // @ts-ignore
      dispatch(cancelCustomerEarly({subtract: formList, item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}})).then(res => setClickDisabled(false));
    }
  }
  return (
    <div id={styles.replaceBrigadeForm}>
      <InputMask mask="9999 мин" maskChar=" "
                 className={styles.input1}
                 label='Время (минуты. пример: 30, 45, 60, 90 минут)'
                 onChange={e => setFormList(parseInt(e?.target.value))}
                 value={formList}>
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!formList}
              onClick={submit}
              type={'contained'}>Досрочно завершить</Button>
    </div>
  )
}

export default DcEarlyClose
