import React, {FC} from 'react'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {PanelPopover} from "../../../shared";
import {AdditionalBrigadeForm, DelayForm, ReplaceBrigadeForm, ReplaceErForm, ReplaceHospitalForm} from "./index";

const ActionsPopovers: FC = () => {
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  return (
    <>
      <PanelPopover title={'Сообщить о задержке'}
                    open={app.popoverFormStatuses.delayFormOpen}
                    handleClose={() => dispatch(togglePopover('delayFormOpen'))}>
        <DelayForm/>
      </PanelPopover>
      <PanelPopover title={'Замена'}
                    open={app.popoverFormStatuses.replaceBrigadeFormOpen}
                    handleClose={() => dispatch(togglePopover('replaceBrigadeFormOpen'))}>
        <ReplaceBrigadeForm/>
      </PanelPopover>
      <PanelPopover title={'Причина передачи'}
                    open={app.popoverFormStatuses.replaceErFormOpen}
                    handleClose={() => dispatch(togglePopover('replaceErFormOpen'))}>
        <ReplaceErForm/>
      </PanelPopover>
      <PanelPopover title={'Добавить Дополнительную Бригаду'}
                    open={app.popoverFormStatuses.additionalBrigadeFormOpen}
                    handleClose={() => dispatch(togglePopover('additionalBrigadeFormOpen'))}>
        <AdditionalBrigadeForm/>
      </PanelPopover>
      <PanelPopover title={'Причина замены'}
                    open={app.popoverFormStatuses.replaceHospitalFormOpen}
                    handleClose={() => dispatch(togglePopover('replaceHospitalFormOpen'))}>
        <ReplaceHospitalForm/>
      </PanelPopover>
    </>
  )
}

export default ActionsPopovers
