import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {Button, Input} from "../../ui-components";
import {formValidator, ValidationFields} from "../../shared/validators/validator";
import {useAppDispatch, useQuery} from "../../core/hooks";
import {inputsValidator, isFormDataValid, validatePassword} from "../../services/globalValidators";
import {changePassword, checkToken} from "../user/user-slice";
import SocketContext from "../../core/socket-context";

const ResetPassword = () => {
  const socket = useContext(SocketContext);
  const [password, setPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [fields, setFields] = useState<object>({})
  const [validationFields, setValidationFields] = useState<ValidationFields>({password: true, repeatPassword: true})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean | null>(false)
  const [email, setEmail] = useState<string>('')

  const query = useQuery();
  const token = query.get('token');
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(checkToken(token as string))
      .then(res => {
        if (res?.user?.email) {
          setEmail(res.user.email)
        } else {
          history.push('/login')
        }
      })
  }, [dispatch, token])

  const validateForm = (p, passwordType) => {
    setErrorMessage('')
    if (passwordType === 'password') {
      setPassword(p)
    } else {
      setRepeatPassword(p)
    }
    const data = [
      {
        key: 'password',
        value: password,
      },
      {
        key: 'repeatPassword',
        value: repeatPassword,
      },
    ]

    setFields(inputsValidator(data, validationFields, formValidator))
  }

  const submitForm = () => {
    setFormValid(isFormDataValid(fields) && password === repeatPassword && validatePassword(password))
    setValidationFields({...fields})
    if (repeatPassword !== password && isFormDataValid(fields)) {
      setErrorMessage('*Пароли не совпадают.')
    } else if(!validatePassword(password)) {
      setErrorMessage('*Пароль не соответствует критериям.')
    } else if (!password || !repeatPassword) {
      setErrorMessage('*Заполните все поля.')
    }

    if (isFormDataValid(fields) && password === repeatPassword && token && validatePassword(password)) {
      dispatch(changePassword({token, password}))
        .then(res => {
          if(res){
            socket.connect()
            history.push('/dashboard')
          }
        })
        .catch(error => {
          console.log(error)
          setErrorMessage(error.text)
        })
    }
  }
  return (
    <div id='activation-container' className='form-container'>
      <p className='title'>Введите новый пароль</p>
      <p className='description'>Добро пожаловать <strong>{email}</strong></p>
      <p className='sub-description'>Пароль должен содержать как минимум 8 символов, одну цифру, одну заглавную и одну строчную букву.</p>
      <Input label={'Новый пароль'} value={password}
             onChange={(e) => validateForm(e.target.value, 'password')}
             onKeyUp={(e) => e.which === 13 && submitForm()}
             error={!validationFields.password}
             inputType='password' className='mb-30'/>
      <Input label={'Повторить  пароль'} value={repeatPassword}
             onChange={(e) => validateForm(e.target.value, 'repeatPassword')}
             error={!validationFields.repeatPassword || (!formValid && password !== repeatPassword)}
             inputType='password' className='mb-50'/>
      <Button color='gradient' onClick={submitForm} className='button' type='contained'>Сохранитъ</Button>
      {!formValid && <p className='text-error'>{errorMessage}</p>}
    </div>
  )
}

export default ResetPassword
