import React from 'react'
import {InfoTooltipTrigger, PanelFooter} from '../../../shared';
import {Button} from '../../../ui-components';
import {ActionsPopovers, ActionsTooltip, BrigadeInfo, DiagnoseInfo, HospitalInfo, PatientInfo} from "../components";
import {useAppDispatch} from "../../../core/hooks";
import { changeErCallStatus, endErCallFromHospitalizationFrom } from '../../requests/er-thunk'
import { ER_CALL_STATUSES } from '../../../services/constants'

const OnWayToHospitalRequest = () => {
  const dispatch = useAppDispatch()

  return (
    <div id='to-hospital' className='request-info-container pb-80'>
      <HospitalInfo/>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter className='d-flex space-between'>
        <Button type='contained' onClick={() => dispatch(changeErCallStatus(11))}>Стацианирование</Button>
        <Button type='outlined' className={'width-40'} onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedLeftInPlace))} >На месте</Button>
        <Button type='outlined' className={'width-40'} onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedRefusedHospital))}>Отказ от Гсп.</Button>
        {/*<div data-tip data-for='issue-menu'
             data-event='click'
             className='issue-btn'>
          {tooltipOpen ? <ArrowRightIcon/> : <ArrowDownIcon/>}
          Проблема
        </div>
        <TooltipBlue id='issue-menu'
                     offsetTop={-20}
                     offsetLeft={5}
                     afterHide={() => setTooltipOpen(false)}
                     afterShow={() => setTooltipOpen(true)}>
          <p onClick={() => dispatch(togglePopover('delayFormOpen'))}>Задержка</p>
          <p onClick={() => dispatch(togglePopover('replaceBrigadeFormOpen'))}>Замена борта</p>
        </TooltipBlue>*/}
        <InfoTooltipTrigger dataFor={'actions-menu'} className='ml-15'/>
        <ActionsTooltip id='actions-menu' offsetLeft={150} offsetTop={-20}/>
      </PanelFooter>
      <ActionsPopovers/>
    </div>
  )
}

export default OnWayToHospitalRequest
