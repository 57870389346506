import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Input, Radio, Textarea} from '../../../ui-components'
import styles from './styles.module.scss'
import {SearchIcon} from "../../../assets";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {handleHospitalizationFormChange} from '../../requests/requests-slice';
import {
  customerAssignHospital,
  customerSendBookingRequest,
} from '../../requests/insurance-thunk'
import {PanelFooter} from '../../../shared';
import {getHospitalsList} from "../../../app/app-slice";
import {log} from "util";

const CustomerHospitalsList = () => {
  const {hospitalizationForm} = useAppSelector(state => state.requests)
  const {hospitals, hospitalizationFlow} = useAppSelector(state => state.app)
  const [hospitalsList, setHospitals] = React.useState<any[]>([])
  const [isBookingHospitalization, setIsBookingHospitalization] = useState<boolean>(false)
  const [hospital, setHospital] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const dispatch = useAppDispatch()
  useEffect(() => {
    setIsBookingHospitalization(hospitalizationFlow)
    dispatch(getHospitalsList())
  }, [isBookingHospitalization, dispatch, hospitalizationFlow])

  useEffect(() => {
    setHospitals(hospitals)
  }, [hospitals])

  const handleAllCheck = () => {
    let hospitalIds: any
    hospitalIds = []
    if (hospitalizationForm.sendToAll) {
    } else {
      hospitalIds = hospitalsList.map(el => el.uuid)
    }
    dispatch(handleHospitalizationFormChange({key: 'hospital_ids', value: hospitalIds}))
    dispatch(handleHospitalizationFormChange({key: 'sendToAll', value: !hospitalizationForm.sendToAll}))
  }

  const handleCheckboxChange = (e, idx, uid) => {
    const hospitalIds = [...hospitalizationForm.hospital_ids as any]
    if (e.target.checked) {
      hospitalIds.push(uid)
    } else {
      const index = hospitalIds.findIndex(el => el === uid)
      hospitalIds.splice(index, 1)
    }
    dispatch(handleHospitalizationFormChange({key: 'hospital_ids', value: hospitalIds}))
  }

  const handleChange = (event) => {
    const q = event.target.value.toLowerCase()
    if (q) {
      const searchedHospitals = hospitals.filter((h) => h.title.toLowerCase().includes(q))
      setHospitals(searchedHospitals)
    } else {
      setHospitals(hospitals)
    }
  }
  return (
    <div id={styles.hospitalsList} className='request-info-container'>
      {
        isBookingHospitalization ? <>
          <div className={styles.header}>
            <Input
              className={styles.input}
              startIcon={<SearchIcon/>}
              placeholder={'Поиск по имени больницы'}
              onChange={(e) => handleChange(e)}
            />
            <Checkbox checked={hospitalizationForm.sendToAll}
                      className={styles.checkbox}
                      onClick={handleAllCheck}
                      label={'Выбрать все'}/>
          </div>
          <div className={styles.list}>
            {hospitalsList.map((el, i) => {
                let checked = hospitalizationForm.hospital_ids?.includes(el.uuid as string)
                return <div className={`${styles.item} ${checked ? styles.active : ''}`}
                            key={i}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={checked}
                    onClick={(e) => handleCheckboxChange(e, i, el.uuid)}
                    label={
                      <div className='ml-5'>
                        <p className={styles.name}>{el.title}</p>
                        <p>{el.address}</p>
                      </div>
                    }/>
                </div>
              }
            )}
          </div>
          <PanelFooter className='flex-column'>
            <Button type='contained' fullWidth disabled={!hospitalizationForm.hospital_ids?.length}
                    onClick={() => dispatch(customerSendBookingRequest())}>Выбрать</Button>
          </PanelFooter>
        </> :  <>
          <div className={styles.header}>
            {/*<Checkbox checked={hospitalizationForm.sendToAll}
                  className={styles.checkbox}
                  onClick={handleAllCheck}
                  label={'Выбрать все'}/>*/}
            <Input
              className={styles.input}
              startIcon={<SearchIcon/>}
              placeholder={'Поиск по имени больницы'}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className={styles.list}>
            {hospitalsList.map((el, i) => {
                //let checked = hospitalizationForm.hospital_ids?.includes(el.uuid as string)
                return <div className={`${styles.item} ${hospital === el.uuid ? styles.active : ''}`}
                            onClick={(e) => setHospital(el.uuid)}
                            key={i}>
                  <Radio
                    name='hospital'
                    checked={hospital === el.uuid}/>
                  <div className='ml-5'>
                    <p className={styles.name}>{el.title}</p>
                    <p>{el.address}</p>
                  </div>
                </div>
              }
            )}
          </div>
          <PanelFooter className='flex-column'>
            <Input placeholder={'Комментарий'}
                   className='mb-30'
                   onChange={(e) => setComment(e.target.value)}
                   value={comment}/>
            <Button type='contained' fullWidth disabled={!hospital}
                    onClick={() => dispatch(customerAssignHospital(hospital, comment))}>Выбрать</Button>
          </PanelFooter>
        </>
      }
    </div>
  )
}

export default CustomerHospitalsList
