import React, {ReactElement} from "react"
import './request-container.scss'
import {useAppSelector} from "../../core/hooks";
import cn from "classnames";
interface Props {
  title: string | ReactElement
  filter: ReactElement
  children: ReactElement[] | ReactElement
}

const RequestContainer = (props: Props) => {
  const {user} = useAppSelector(state => state.user)
  const { sentry} = useAppSelector(state => state.requests)

  return (
    <div className={cn("request-container", sentry && sentry.length > 0 && "request-containerSmall" )}>
      <div className="header">
        <p className="title">{props.title}</p>
        <p className="filter-list">{props.filter}</p>
      </div>
      <div className="body">
        {props.children}
      </div>
    </div>
  )
}

export default RequestContainer
