import React from "react"
import {Spinner} from "../../shared";

const WaitingOffers = () => {

  return  <div style={{    maxHeight: 200, borderBottom: "1px solid rgb(231, 231, 231)", display: "flex", justifyContent: "center", alignItems: "center"}}>
  <div style={{margin:10,width: '95%', padding: 16, background: '#F1F6FF', borderRadius: 16, justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
      <div style={{alignSelf: 'stretch', color: '#192233', fontSize: 14,  fontWeight: 700,  wordWrap: 'break-word'}}>Ожидание предложений</div>
    </div>
    <Spinner />
  </div>
  </div>
}

export default WaitingOffers
