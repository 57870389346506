import React, { FC } from 'react'
import styles from './styles.module.scss'
import {Datepicker} from "../index";
import { Moment } from 'moment'

interface Props {
  dateFrom?: string
  dateTo?: string
  classname?: string,
  initialValue?: Moment | string | null
  handleFromChange: Function
  handleToChange: Function
}

const DateRange: FC<Props> = (props) => {
  return (
    <div className={styles.dateRangeContainer}>
      <div className='d-flex'>
       От <Datepicker className='ml-20' initialValue={props.initialValue || ''} value={props.dateFrom || ''} readOnly={true} handleDateChange={props.handleFromChange}/>
      </div>
      <div className='d-flex ml-15'>
        До <Datepicker className='ml-20' initialValue={props.initialValue || ''} value={props.dateTo || ''} readOnly={true}  handleDateChange={props.handleToChange}/>
      </div>
    </div>
  )
}

export default DateRange
