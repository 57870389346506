import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {BrowserRouter} from "react-router-dom";
import store from "../core/store";
import {SocketProvider} from "../core/socket-context";
import {Provider} from "react-redux";
import SocketApi from "../services/socket/socket";

const socket = new SocketApi()

export default () => ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SocketProvider value={socket as any}>
          <App/>
        </SocketProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
