import React, { useEffect } from 'react'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  getDiagnosis,
  handleSearchChange
} from './diagnosis-slice'
import { TableBody, TableHead, Table, Search } from '../../shared'
import styles from '../../modules/left-panel/styles.module.scss'

const head = ['Код', 'Название', 'Код Родителя']


const Diagnosis = () => {
  const dispatch = useAppDispatch()
  const {diagnosis, query} = useAppSelector(state => state.adminDiagnosis)
  useEffect(() => {
    dispatch(getDiagnosis(query))
  }, [dispatch])

  const rows = diagnosis.map((val: any) => {
    return (
      <div className='row' key={val.id}>
        <div className='item'>{val.id}</div>
        <div className='item'  title={val.title}>{val.title}</div>
        <div className='item'>{val.parent_id}</div>
      </div>
    )
  })

  const filterChange = (value: string) => {
    dispatch(handleSearchChange({value}))
    dispatch(getDiagnosis(value))
  }

  return (
    <div id='diagnosis-list'>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange(e.target.value)
        }} value={query}/>
      </div>
      {
        dispatch.length && <Table>
          <TableHead>
            {head.map((el, i) => <div className='item' key={i}>{el}</div>)}
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      }
    </div>
  )
}

export default Diagnosis
