import React, { FC } from 'react'

interface Props {
  className?: string
}


const TableHead: FC<Props> = (props) => {
  return (
    <div className={`table-head wrapper ${props.className ? props.className : ''}`}>
      <div className="row">
        {props.children}
      </div>
    </div>
  )
}


export default TableHead
