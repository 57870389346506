import {ValidationFields} from "./IDispatchers"
import {validateEmail, validateLength, ValidationData, REMOVE_SYMBOLS_PATTERN} from "../../services/globalValidators";

export const formValidator = (validationFields: ValidationFields, data: ValidationData) => {
  const fields = {...validationFields}

  switch (data.key) {
    case 'first_name':
      fields.first_name = !!data.value
      break
    case 'last_name':
      fields.last_name = !!data.value
      break
    case 'middle_name':
      fields.middle_name = !!data.value
      break
    case 'email':
      fields.email = !!data.value && validateEmail(data.value)
      break
    case 'primary_phone':
      fields.primary_phone = !!data.value && validateLength(data.value.replace(REMOVE_SYMBOLS_PATTERN, ""), 11)
      break
  }

  return fields
}
