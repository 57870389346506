import React, {useCallback} from "react";
import styles from "../../../admin-modules/emergency/emergency.module.scss";
import {useAppDispatch} from "../../../core/hooks";
import {customerDownloadContract, customerUploadContract} from "../../requests/er-thunk";

const UploadPdf = ({contract}) => {
  const dispatch = useAppDispatch()

  const handleUploadFiles = useCallback(
    (e) => {
      // eslint-disable-next-line no-restricted-globals
      e.preventDefault();
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      const formData = new FormData();
      for (const f of e.target.files) {
        formData.append("file", f);
      }
      dispatch(customerUploadContract(formData))
      e.target.value = ""
    },

    [dispatch]
  );

  const handleDownloadFiles = useCallback(
    () => {
      dispatch(customerDownloadContract(contract?.customer_upload))
    }, [dispatch, contract]
  );
  return <div style={{width: "97%", margin: 8, padding: 16, background: '#F1F6FF', borderRadius: 16, justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
    <div style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
      {contract?.status === 2 && <div style={{alignSelf: 'stretch', color: '#192233', fontSize: 14, wordWrap: 'break-word'}}>Ожидает подтверждения
        от Заказчика</div>}
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
        {contract?.status === 4 &&<div
          onClick={handleDownloadFiles}
          style={{
          cursor: "pointer",
          flex: '1 1 0',
          height: 40,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 10,
          paddingBottom: 10,
          background: 'white',
          borderRadius: 8,
          border: '1px #BDC1CC solid',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 4,
          display: 'flex'
        }}>
          <div style={{
            flex: '1 1 0',
            height: 20,
            paddingLeft: 4,
            paddingRight: 4,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
            <div style={{
              flex: '1 1 0',
              textAlign: 'center',
              color: '#192233',
              fontSize: 14,
              wordWrap: 'break-word'
            }}>Просмотреть договор
            </div>
          </div>
        </div>}

        <label style={{flex: '1 1 0',cursor: "pointer", height: 40, paddingLeft: 12, paddingRight: 12, paddingTop: 10, paddingBottom: 10, background: 'white', borderRadius: 8, border: '1px #BDC1CC solid', justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex'}} htmlFor='img-upload' className={styles.imgUploadWrapper}>
          <div style={{flex: '1 1 0', justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex'}}>
            <div style={{flex: '1 1 0', height: 20, paddingLeft: 4, paddingRight: 4, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <div style={{flex: '1 1 0', textAlign: 'center', color: '#192233', fontSize: 14,   wordWrap: 'break-word'}}>Загрузить договор</div>
              <input id='img-upload'
                     accept="application/pdf"
                onChange={handleUploadFiles}
                     className='hidden' type='file'/>
            </div>
          </div>
        </label>


      </div>
    </div>
  </div>
}

export default UploadPdf
