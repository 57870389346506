// @ts-nocheck
import React, {Fragment, useState, memo} from 'react'
import styles from "./styles.module.scss";
import {AmbulanceIcon} from "../../../assets";
import {PrimaryModal, InfoText} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import { removeAdditionalBrigade } from '../../requests/er-thunk';
import {Button} from "../../../ui-components";

const DpHospitalizationBrigadeInfo = () => {
  const dispatch = useAppDispatch()
  const { request} = useAppSelector((state) => state.requests)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  return (
    <Fragment>
      <div className={styles.brigadeInfo}>
        <p className={styles.title}><AmbulanceIcon/> {request?.selectedBrigade?.status === 3 ? 'Дополнительная бригада' : 'Бригада'}</p>
        <div className={`${styles.wrapper}`}>
          <InfoText title={'Тип бригады'} text={request?.selectedBrigade?.brigade_type}/>
          <InfoText title={'Время доезда'} className='ml-20'
                    text={`${request?.selectedBrigade?.eta} ${request?.selectedBrigade?.delay ? `+ ${request?.selectedBrigade?.delay}` : ''} мин`}/>
          {request?.selectedBrigade.brigade_number &&
            <InfoText title={'Номер Бригады'} className='ml-15' text={request?.selectedBrigade?.brigade_number}/>}
          {request?.selectedBrigade?.brigade_responsible &&
            <InfoText title={'Ответственный Бригады'}  text={request?.selectedBrigade?.brigade_responsible}/>}
          {request?.selectedBrigade.comment && <InfoText title={'Комментарий'} className='ml-20 mt-20' text={request?.selectedBrigade.comment}/>}
        </div>
      </div>
      <PrimaryModal
        open={modalOpen}
        text={'Удалить бригаду'}
        description={'Вы уверены, что хотите удалить дополнительную бригаду?'}
        footer={
          <div className='d-flex space-between'>
            <Button type='contained' fullWidth onClick={() => {
              dispatch(removeAdditionalBrigade())
                .then(res => res && setModalOpen(false))
            }}>Да</Button>
            <Button type='outlined' fullWidth onClick={() => setModalOpen(false)} className='ml-30'>Нет</Button>
          </div>
        }
        handleClose={() => setModalOpen(false)}/>
    </Fragment>
  )
}

export default memo(DpHospitalizationBrigadeInfo)
