import React, { FC } from "react";
import './radio.scss'
import {Props} from './IRadio'

const Radio: FC<Props> = (props) => {
  return (
    <label className={`radio ${props.className ? props.className : ''}`}>
      <input type="radio" checked={props.checked} name="radio"/>
        <span className="checkmark" />
      {props.label && <span className="label-text">{props.label}</span>}
    </label>
  )
}

export default Radio
