

export const formValidator = (validationFields: any, data: any) => {
  switch (data.key) {
    case 'name':
      validationFields.name = !!data.value
      break
    case 'inn':
      validationFields.inn = !!data.value
      break
    case 'abbr':
      validationFields.abbr = !!data.value
      break
    case 'cities':
      validationFields.cities = !!data.value.length
  }

  return validationFields
}
