import React from 'react'
import {OffersList, PatientInfo} from "../components";
import './styles.scss'

const WaitingApproveRequest = () => {
  return (
    <div id='waitingApprove' className='request-info-container'>
      <OffersList/>
      <PatientInfo/>
    </div>
  )
}

export default WaitingApproveRequest
