import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import { useAppDispatch } from '../../../core/hooks'
import {replaceCustomerHospital} from "../../requests/insurance-thunk";
const CustomerReplaceHospitalForm = () => {
  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch();

  const submit = (): void => {
    if(comment) {
      setClickDisabled(true)
      dispatch(replaceCustomerHospital(comment))
        .then(res => setClickDisabled(false))
    }
  }
  return (
    <div id={styles.replaceBrigadeForm}>
        <Input label='Причина замены' className={`${styles.input} mb-40`} value={comment}
               onKeyUp={(e) => e.which === 13 && submit()}
               onChange={e => setComment(e.target.value)}/>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!comment}
              onClick={submit}
              type={'contained'}>Поменять Больницу</Button>
    </div>
  )
}

export default CustomerReplaceHospitalForm
