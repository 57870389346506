import React, {useCallback, useMemo, useState} from "react";
import Sentry from "./sentry";
import PayCard from "./PayCard";
import Brigade from "../brigade/brigade";
import PayInfo from "./PayInfo";
import { Button} from "../../ui-components";
import {
  InfoTooltipTrigger,
  Modal,
  Panel,
  PanelHeader,
  PanelPopover,
  PrimaryModal,
  RightBigPanelFooter,
  Spinner
} from "../../shared";
import styles from "./NewSentry.module.scss"
import OfferToEvent from "./OfferToEvent";
import cn from "classnames"
import WaitingOffers from "./WaitingOffers";
import Offers from "./offers";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  cancelCustomerOfferBrigadeRequests, changeCustomerDcErCallStatus,
  customerContractBeginRequests,
  customerContractFinishedRequests, customerPaymentDoneRequests, customerUpdatePaymentRequests
} from "../requests/er-thunk";
import CustomerActionsTooltip from "./DpComponents/customerActionsTooltip";
import CustomerDiagnoseInfo from "./DcComponents/CustomerDiagnoseInfo";
import {togglePopover} from "../../app/app-slice";
import CustomerHospitalizationForm from "./DcComponents/CustomerHospitalization-form";
import {BackArrowIcon, HospitalIcon} from "../../assets";
import CustomerHospitalsList from "./DcComponents/CustomerHospitals-list";
import {cancelCustomerBookings} from "../requests/insurance-thunk";
import CustomerHospitalsOffersList from "./DcComponents/customerHospitals-offers-list";
import {buildOffersAmountMessage} from "../../services/utils";
import CustomerReplaceHospitalForm from "./DcComponents/customerReplace-hospital-form";
import CustomerCommentsPanel from "./DpComponents/cutomer-comments-panel";
import HistoryPanel from "../er-request/history-panel";
import DpHospitalizationBrigadeInfo from "./DcComponents/DpHospitalizationBrigadeInfo";
import DiagnoseInfo from "../er-request/components/diagnose-info";
import UploadPdf from "./DcComponents/uploadPdf";
import DcHospitalInfo from "./DcComponents/DcHospitalInfo";
import CustomerDpInfo from "./CustomerDpInfo";
import DpDcActionsPopover from "./DpComponents/DpDcActionsPopover";
import DcCancel from "./DcComponents/DcFinishSentry";
const NewSentry = ({request, filteredPools, selectedModalBrigade, offersMade, contract, payment, er_call, booking_offers, pool}) => {
  const dispatch = useAppDispatch()
  const [openSentryModal, setOpenSentryModal] = useState(false)
  const [isScene, setScene] = useState(true)
  const [isCharacter, setCharacter] = useState(false)
  const {popoverFormStatuses} = useAppSelector(state => state.app)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const handleSubmit = useCallback(() => {
    if (!request.readyForContract && filteredPools && filteredPools?.length > 0) {
      setOpenSentryModal(true)
    } else if(er_call && er_call.status ===4){
      dispatch(changeCustomerDcErCallStatus(23))

    } else if (er_call && er_call.status ===23){
      dispatch(togglePopover('dcCancel'))
      // dispatch(changeCustomerDcErCallStatus(12))
    } else if(er_call && (er_call.status ===10 ||er_call.status ===9 ||er_call.status ===11) ){
      dispatch(togglePopover('replaceHospitalFormOpen'))
    } else if (request.readyForContract && !contract) {
      dispatch(customerContractBeginRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}
      }))
    } else if (contract && contract?.status === 4 ) {
      dispatch(customerContractFinishedRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}, data: {document_uuid: contract?.uuid}}))
    } else if (payment && payment.status === 1 && contract && contract.status === 5) {
      dispatch(customerUpdatePaymentRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}, data: {payment_uuid: payment?.uuid, status: 2}}))
    }
    else if (payment &&( payment.status === 2 || payment.status === 6)) {
      dispatch(customerPaymentDoneRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}, data: {payment_uuid: payment?.uuid}}))
    }
    else {
      dispatch(togglePopover('dcCancelOffer'))
    //   dispatch(cancelCustomerOfferBrigadeRequests({item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}
    // }))
    }
  }, [request?.readyForContract, request?.uuid, request?.customer_uuid, filteredPools?.length, er_call, contract, payment, dispatch])


  const selectedPayment = useMemo(() => {
    if (payment && payment?.data) {
      return   payment?.data[selectedModalBrigade];
    }
    return null
  }, [selectedModalBrigade, payment])

  return  <div className={styles.container}>
    <div style={{width: "45%", flexDirection: "column", overflow: "auto", height: "100%" }}>

        <div className={`${styles.header}`}>
          <button
            type='button'
            id='perConTab'
            className={cn(styles.tab, isScene && styles.active)}
            onClick={() => {
              setCharacter(false)
              setScene(true)
            }}
          >
            <span className={styles.tabName}>Информация</span>
          </button>
          <button
            disabled={!contract}
            type='button'
            id='perActTab'
            className={cn(styles.tab, { [styles.active]: isCharacter, [styles.disabled] : !contract })}
            onClick={() => {
              setScene(false)
              setCharacter(true)
            }}
          >
            <span className={styles.tabName} id='perActTitle'>
              История
            </span>
          </button>
        </div>
        <div className={styles.content}>
          {isScene &&
            <>{er_call && (er_call?.status ===12 || er_call?.status ===15|| er_call?.status ===17 ||er_call?.status ===18 ) ? <>
                <div style={{margin: 20}}>
                <CustomerDiagnoseInfo/>
                 <DcHospitalInfo/>
                  {request?.requestedBrigades && request?.requestedBrigades.length > 0 && <DpHospitalizationBrigadeInfo/>}
                  <Sentry isEditable={true} request={request} />
                  <PayCard/>
                  <Brigade isEditable={true} selectedModalBrigade={selectedModalBrigade}/>
                  {/*<CustomerDpInfo/>*/}
                </div>
              </>:

              <>
                {er_call?.status === 8 && <div className={styles.wrapper}>
                  <div className={styles.infoSWrapper}>
                    <p>Ожидание подтверждения больницы</p>
                    <span onClick={() => setModalOpen(!modalOpen)}>Отменить</span>
                  </div>
                  <Spinner/>
                </div>}
                {!er_call && contract && (contract.status === 1 ||  contract.status === 2 ||  contract.status === 3 ||  contract.status === 4) && <UploadPdf contract={contract}/>}
                {er_call && (er_call?.status ===9 || er_call?.status ===10 || er_call?.status ===11 || er_call?.status ===22) && <div style={{margin: 20}}> <DcHospitalInfo/> </div>}
                {er_call && er_call?.status ===7 &&<CustomerDiagnoseInfo/>}
                {er_call && (er_call?.status ===7 || er_call?.status ===8 || er_call?.status ===9 || er_call?.status ===10 || er_call?.status ===11 || er_call?.status ===22) && <div style={{
                  margin: 20
                }}><DiagnoseInfo/></div>}

                {er_call?.status === 16 &&
                  <>
                    <div className={styles.bookingOffers}>
                      <p className={styles.text}>
                        <HospitalIcon/> Получено {booking_offers?.length} {buildOffersAmountMessage(booking_offers?.length)}</p>
                      <span onClick={() => setModalOpen(!modalOpen)}>Отменить</span>
                      <Button type='contained'
                              onClick={() => dispatch(togglePopover('hospitalsOffersListOpen'))}
                              className='mt-30' fullWidth>Посмотреть</Button>
                    </div>
                    <Panel handleClose={() => dispatch(togglePopover('hospitalsOffersListOpen'))}
                           open={popoverFormStatuses.hospitalsOffersListOpen}>
                      <PanelHeader className='simple-header'>
                        <p className='hospitals-list-panel-title'><BackArrowIcon
                          onClick={() => dispatch(togglePopover('hospitalsOffersListOpen'))}/>Выбрать Больницу</p>
                      </PanelHeader>
                      <CustomerHospitalsOffersList/>
                    </Panel>
                  </>}
                {!request.readyForContract && er_call?.status !==8 &&  <>
                  {offersMade && Object.keys(offersMade)?.length > 0 && Object.keys(offersMade).find((key) => key === selectedModalBrigade && offersMade[key][0].brigade_uuid) ? Object.keys(offersMade).map(function(key, index) {
                    if (key === selectedModalBrigade && offersMade[key].length >0 ) {
                      return <Offers pool={offersMade[key]}/>
                    }

                  }) :  <WaitingOffers/>}
                </>}
                {request.readyForContract && <DpHospitalizationBrigadeInfo/>}
                <Sentry isEditable={request.status ===5 || request.status ===7} request={request} />
                <PayCard/>
                <Brigade isEditable={request.status ===5 || request.status ===7} selectedModalBrigade={selectedModalBrigade}/>
                {/*<BrigadeInfo/>*/}
                <PrimaryModal description={'Вы уверены, что хотите отменить все запросы?'}
                              text={'Отменить Бронирование'}
                              footer={
                                <div className='d-flex space-between'>
                                  <Button type='contained' fullWidth onClick={() => {
                                    dispatch(cancelCustomerBookings()).then(res => res && setModalOpen(!modalOpen))
                                  }}>Да</Button>
                                  <Button type='outlined' fullWidth onClick={() => setModalOpen(!modalOpen)}
                                          className='ml-30'>Нет</Button>
                                </div>
                              }
                              open={modalOpen}
                              handleClose={() => setModalOpen(!modalOpen)}/>

                <PanelPopover open={popoverFormStatuses.hospitalizationFormOpen}
                              className='hospitalization-popover'
                              title={'Бронирование Койки'}
                              handleClose={() => dispatch(togglePopover('hospitalizationFormOpen'))}>
                  <CustomerHospitalizationForm/>
                </PanelPopover>
                <Panel handleClose={() => dispatch(togglePopover('hospitalsListOpen'))}
                       open={popoverFormStatuses.hospitalsListOpen}>
                  <PanelHeader className='simple-header'>
                    <p className='hospitals-list-panel-title'><BackArrowIcon onClick={() => dispatch(togglePopover('hospitalsListOpen'))}/>Выбрать Больницу</p>
                  </PanelHeader>
                  <CustomerHospitalsList/>
                </Panel>

                <PanelPopover title={'Причина замены'}
                              open={popoverFormStatuses?.replaceHospitalFormOpen}
                              handleClose={() => dispatch(togglePopover('replaceHospitalFormOpen'))}>
                  <CustomerReplaceHospitalForm/>
                </PanelPopover>
                {request.status !==5 && request.status !==7 && er_call?.status !== 5 &&
                  er_call?.status !== 8 &&
                  er_call?.status !== 6 &&
                  er_call?.status !== 12 &&
                  er_call?.status !== 14 &&
                  er_call?.status !== 15 &&
                  er_call?.status !== 17&&
                  er_call?.status !== 18 && <RightBigPanelFooter>
                    <Button onClick={handleSubmit} type='contained' fullWidth>
                      {/*{request?.status!== 3 && filteredPools.length > 0 ?"Составить предложение" : "Отменить заявку"}*/}
                      {request.readyForContract && !contract && "Составить договор"}
                      {request.readyForContract && contract && contract?.status === 4 && "Утвердить договор"}
                      {request.readyForContract && contract &&( contract?.status === 1 || contract?.status === 2 || contract?.status === 3)  && "Отменить заявку"}
                      {request.readyForContract && payment && payment.status === 1 && contract && contract.status === 5 && "Перейти к оплате"}
                      {request.readyForContract && contract && contract?.status === 5 && payment && (payment.status === 2 ||payment.status === 6 ) && !er_call && "Оплата завершена"}
                      {!request.readyForContract && filteredPools.length === 0 && "Отменить заявку"}
                      {er_call && (er_call.status === 1 || er_call.status === 2 || er_call.status === 3 || er_call.status === 7 || er_call.status === 16 || er_call.status === 24 ) && "Отменить дежурство"}
                      {er_call && er_call.status === 4 && "Начать дежурство"}
                      {er_call && er_call.status === 23 && "Завершить дежурство"}
                      {er_call && (er_call.status === 10 || er_call.status === 9 || er_call.status === 22 || er_call.status === 11) && "Замена Госпиталя"}
                      {!request.readyForContract && filteredPools?.length > 0 && "Составить предложение"}
                    </Button>
                      <InfoTooltipTrigger dataFor={'actions-menu-customer'} className='ml-15'/>
                      <CustomerActionsTooltip id='actions-menu-customer' offsetLeft={150} offsetTop={-20}/>
                  </RightBigPanelFooter>}

                <DpDcActionsPopover/>
                <PanelPopover title={'Вы уверены что хотите завершить дежурство?'}
                              open={popoverFormStatuses.dcCancel}
                              handleClose={() => dispatch(togglePopover('dcCancel'))}>
                  <DcCancel/>
                </PanelPopover>

              </>
          }</>
            }
          {isCharacter &&
            <>
            <HistoryPanel isDpSentry selectedModalBrigade={selectedModalBrigade}/>
            </>}
        </div>

    </div>
    <div style={{width: "30%", height: "100%", overflow: "auto"}}>
     <PayInfo requestStatus={request?.status} requestType={request?.type} contract={contract} erCall={er_call} selectedPayment={selectedPayment} payment={payment}/>
    </div>
    <div style={{width: "26%", display: "block", overflow: "hidden"}}>
      <div style={{width: '100%', borderBottom: '1px #E7E7E7 solid', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
        <div style={{paddingLeft: 24, paddingRight: 24, paddingTop: 14, paddingBottom: 14, background: 'white',  justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
          <div style={{color: 'black', fontSize: 12,  fontWeight: 700, wordWrap: 'break-word'}}>Комментарии</div>
        </div>
      </div>
      {er_call && (er_call?.status !==12 && er_call?.status !==15 && er_call?.status !==17 &&er_call?.status !==18) && <CustomerCommentsPanel isDc isSentry={true}/>}
    </div>
    <Modal open={openSentryModal}
           closeIcon
           className={styles.modal}
           title={'Предложение о сопровождении мероприятия'}
           handleClose={() => {
             setOpenSentryModal(false)
           }}>
     <OfferToEvent request={request} setOpenSentryModal={setOpenSentryModal} offersMade={offersMade}/>
    </Modal>
  </div>
}

export default NewSentry
