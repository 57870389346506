import React from "react";
import DpNewRequest from "./DpNewRequest";
import AssignCustomerBrigadeRequest from "./assignCustomerBirgadeRequest";
import OnWayCustomerRequest from "./onWayCustomerRequest";
import {useAppSelector} from "../../../core/hooks";
import SentryStart from "./SentryStart";
import CustomerErHospitalization from "./CustomerErHospitalization";
import CustomerCancelHospitalization from "./CustomerCancelHozpitalization";
import HistoryPanel from "../../er-request/history-panel";
import CustomerCommentsPanel from "./cutomer-comments-panel";

const DpContent = () => {
  const { er_call, activePanel, request} = useAppSelector(state => state.requests)
  let Content: any

  if (activePanel === 'data') {
    if (!er_call) {
      return <div style={{
        display: "flex",
        flexDirection: "column",
        height: " 100%",
        overflow: "auto",
        width: "100%"
      }}>
          <DpNewRequest/>
      </div>
    } else {
      switch (er_call.status) {
        case 1:
          Content = <AssignCustomerBrigadeRequest/>
          break;
          case 2:
          case 3:
          Content = <OnWayCustomerRequest/>
          break;
          case 4:
          Content = <SentryStart/>
          break;
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 15:
          case 16:
          case 17:
          case 18:
          case 22:
          case 24:
          Content = <CustomerCancelHospitalization/>
          break;
          case 23:
          Content = <CustomerErHospitalization/>
          break;
      }
    }
  } else if (activePanel === 'comment'){
    Content = <CustomerCommentsPanel isSentry={false}/>
  } else {
    // @ts-ignore
    Content = <HistoryPanel isDpSentry selectedModalBrigade={request.uuid}/>
  }



  return <>
    {Content}
  </>
}

export default DpContent
