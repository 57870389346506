import React, {ChangeEvent, Fragment, useEffect, useMemo, useState} from 'react'
import {Input, Autocomplete, Button} from "../../ui-components";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import './request-form.scss'
import {Datepicker, PrimaryModal} from "../../shared";
import {editRequest, handleFormChange, validateInputs} from './requests-slice';
import {omit, uniqBy} from 'lodash'
import InputMask from 'react-input-mask'
import {LinkIcon} from "../../assets";
import {ER_CALL_STATUSES, GENDER, REGIONS} from "../../services/constants";
import {
  setData,
  getMetrosList,
  getAddressesList,
  toggleEditRequest
} from '../../app/app-slice'
import {debounce} from 'lodash'
import {getSingleRequest} from './insurance-thunk';
import {useHistory} from "react-router-dom";

const RequestForm = () => {
  const {form, validationFields, request} = useAppSelector(state => state.requests)
  const app = useAppSelector(state => state.app)
  const {user} = useAppSelector(state => state.user)
  const {er_call} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    form.region && dispatch(getMetrosList(form.region))
    form.address && dispatch(getAddressesList(form.address))
  }, [])

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  // handle select change
  const handleSelectChange = (key: string, value: string) => {
    dispatch(handleFormChange({key: key, value}))
  }

  const updateRequest = () => {
    const mainValidationData = omit(form, ['scheduled_date', 'sentry_date_from', 'sentry_date_to'])

    for (const k in mainValidationData) {
      if (typeof form[k] === 'object') {
        for (const j in form[k]) {
          dispatch(validateInputs({key: j, value: form.data[j]}))
        }
      }
      dispatch(validateInputs({key: k, value: form[k]}))
    }
    dispatch(editRequest(request.uuid))
  }

  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      setLoading(true)
      dispatch(getAddressesList(query))
        .then(res => res && setLoading(false))
    }, 1000),
    [dispatch]
  );

  let filteredRegions: any = []
  if (user.organization.cities?.some(el => el.city === 1)) {
    filteredRegions = REGIONS
  } else {
    filteredRegions = REGIONS.filter(el => user.organization.cities?.find(data => el.key === data.city))
  }
  return (
    <div className="form-wrapper request-info-container">
      {(form.uuid && !app.popoverFormStatuses.editRequestOpen) && (request?.parent_request_number || er_call?.status === ER_CALL_STATUSES.erReplacementRequired) ?
        <p className="connected-request" onClick={() => {
          if (request?.parent_request) {
            dispatch(getSingleRequest(request.parent_request, false))
            dispatch(toggleEditRequest())
          }
        }}><LinkIcon/>{`Связ. заявка № ${request?.parent_request_number || er_call?.request_number}`}</p> : ''}

      <div className="temp-03-form">
        {
          user.organization.type !== 2
            ?
            <>
              <div className='wrapper'>
                <Input name='name'
                       disabled={er_call?.status === ER_CALL_STATUSES.erReplacementRequired}
                       className='input-large mr-15'
                       error={!validationFields.name}
                       onChange={handleInputChange}
                       value={form.name} label="ФИО"/>
                <InputMask mask="99/99/9999" maskChar=""
                           disabled={er_call?.status === ER_CALL_STATUSES.erReplacementRequired}
                           name='birthday'
                           className={er_call?.status === ER_CALL_STATUSES.erReplacementRequired && 'input-disabled'}
                           placeholderText='ДД/ММ/ГГГГ'
                           error={!validationFields.birthday}
                           onFocus={e => e && e.preventDefault()}
                           onChange={(e) => e && handleInputChange(e)}
                           value={form.birthday} label="Дата Рождения">
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
                {/*<Input name='first_name'
                 error={!validationFields.first_name}
                 onChange={handleInputChange}
                 value={form.first_name} label="Имя"/>
          <Input name='middle_name'
                 onChange={handleInputChange}
                 value={form.middle_name} label="Отчество"/>*/}
              </div>
              <div className="wrapper">
                <Autocomplete error={!validationFields.city}
                              options={filteredRegions}
                              optionLabel={'type'}
                              optionValue={'type'}
                              disabled={app.popoverFormStatuses.editRequestOpen || er_call?.status === ER_CALL_STATUSES.erReplacementRequired}
                              onChange={val => {
                                handleSelectChange('city', val.type)
                                handleSelectChange('region', val.key)
                                if (form.region !== val.key) {
                                  dispatch(setData({data: [], prop: 'addresses'}))
                                  handleSelectChange('address', '')
                                }
                                dispatch(getMetrosList(val.key))
                              }}
                              value={form.city} label="Город"/>
                <Input name='address' onChange={handleInputChange}
                       disabled={!form.city}
                       error={!validationFields.address}
                       className='select-large ml-15 address-temp-info'
                       value={form.address}
                       label="Адрес"/>
                {/*<Autocomplete*/}
                {/*              options={uniqBy(app.addresses, 'fullAddress')}*/}
                {/*              className='select-large ml-15'*/}
                {/*              error={!validationFields.address}*/}
                {/*              isLoading={loading}*/}
                {/*              disabled={!form.city}*/}
                {/*              value={form.address}*/}
                {/*              onChange={val => handleSelectChange('address', val?.fullAddress)}*/}
                {/*              onInputChange={data => data && debouncedChangeHandler(data)}*/}
                {/*              label='Адрес'*/}
                {/*              noOptionsMessage={'Введите Адрес'}*/}
                {/*              optionValue={'fullAddress'}*/}
                {/*              optionLabel={'fullAddress'}/>*/}
              </div>
              <Input name='complaints' onChange={handleInputChange}
                     disabled={er_call?.status === ER_CALL_STATUSES.erReplacementRequired}
                     className='mb-40'
                     error={!validationFields.complaints}
                     value={form.complaints} label="Жалобы / дополнительная информация"/>
              {!app.popoverFormStatuses.editRequestOpen &&
                <div className={`wrapper ${form.type === 2 ? 'flex-start' : ''}`}>
                  <Input className={'bold'}
                         label={'Тип'}
                         disabled={true}
                         value={'Экстренный'}
                         error={!validationFields.type}/>
                  {/*{form.type === 2 &&
                    <Datepicker className='ml-20' error={!validationFields.scheduled_date}
                                handleDateChange={(date) => dispatch(handleFormChange({
                                  key: 'scheduled_date',
                                  value: date
                                }))}
                                value={form.scheduled_date} label={"Календарь"}/>}
                  {
                    form.type === 3 &&
                    <Fragment>
                      <Datepicker error={!validationFields.sentry_date_from}
                                  handleDateChange={(date) => dispatch(handleFormChange({
                                    key: 'sentry_date_from',
                                    value: date
                                  }))}
                                  value={form.sentry_date_from}
                                  label={"Дата Начала"}/>
                      <Datepicker error={!validationFields.sentry_date_to}
                                  handleDateChange={(date) => dispatch(handleFormChange({
                                    key: 'sentry_date_to',
                                    value: date
                                  }))}
                                  value={form.sentry_date_to}
                                  label={"Дата Окончания"}/>
                    </Fragment>
                  }*/}
                </div>}
            </> :
            <>
              <div className='wrapper flex-start'>

                {/*<Datepicker error={!validationFields.birthday}
                      inputDisabled
                      disableDates={'future'}
                      handleDateChange={(date) => dispatch(handleFormChange({key: 'birthday', value: date}))}
                      value={form.birthday}
                      initialView={'years'}
                      label={"Дата Рождения"}/>*/}
                <Input className='input-small'
                       onChange={handleInputChange}
                       name='data[weight]'
                       value={form.data.weight} label="Вес"/>
                <Autocomplete error={!validationFields.in_bed}
                              className='ml-15'
                              onChange={(val) => {
                                handleSelectChange('data[in_bed]', val.type)
                              }}
                              options={[{key: 1, type: 'Да'}, {key: 2, type: 'Нет'}]}
                              optionLabel={'type'}
                              optionValue={'type'}
                              label="Лежачий"
                              value={form.data.in_bed}/>
              </div>
              <div className="wrapper flex-start">
                <Input name='data[house]'
                       onChange={handleInputChange}
                       value={form.data.house} label="Дом"/>
                <Input name='data[apartment]'
                       className='ml-20'
                       onChange={handleInputChange}
                       value={form.data.apartment} label="Квартира"/>
                <Input name='data[buzzer]'
                       className='ml-20'
                       onChange={handleInputChange}
                       value={form.data.buzzer} label="Код Подъезда"/>
                <Input name='data[entrance]'
                       className='ml-20'
                       onChange={handleInputChange}
                       value={form.data.entrance} label="Подъезд"/>
              </div>
              <div className='wrapper'>
                <Input name='data[floor]'
                       className='input-small'
                       error={!validationFields.floor}
                       onChange={handleInputChange}
                       value={form.data.floor} label="Этаж"/>
                <Autocomplete className='flex-basis ml-5'
                              error={!validationFields.elevator}
                              options={[{key: 1, type: 'Да'}, {key: 2, type: 'Нет'}, {key: 3, type: 'Неизвестно'}]}
                              optionLabel={'type'}
                              optionValue={'type'}
                              onChange={val => handleSelectChange('data[elevator]', val.type)}
                              value={form.data.elevator} label="Лифт"/>
                <Autocomplete
                  className='ml-5'
                  value={form.data.subway}
                  options={app.metros}
                  isClearable
                  disabled={!form.city}
                  onChange={val => handleSelectChange('data[subway]', val.name)}
                  optionLabel={'name'}
                  optionValue={'name'}
                  label="Метро"/>
                {/*<InputMask mask="+7 (999) 999-99-99" maskChar=" "*/}
                {/*           name='contact_number'*/}
                {/*           error={!validationFields.contact_number}*/}
                {/*           onChange={(e) => e && handleInputChange(e)}*/}
                {/*           value={form.contact_number} label="Телефон">*/}
                {/*  {(inputProps) => <Input {...inputProps} />}*/}
                {/*</InputMask>*/}
              </div>
              <Autocomplete onChange={val => handleSelectChange('request_reason', val.id)}
                            className='mb-40'
                            options={app.requestReasons}
                            optionLabel={'name'}
                            optionValue={'id'}
                            error={!validationFields.request_reason}
                            value={form.request_reason} label="Повод"/>
            </>
        }
      </div>
      {app.popoverFormStatuses.editRequestOpen &&
        <Button className='edit-btn'
                onClick={updateRequest}
                type='contained'>Сохранить Изменения</Button>}
    </div>
  )
}

export default RequestForm
