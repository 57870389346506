import React, {useMemo} from "react";
import CreatedSentryContent from "./CreatedSentryContent";
import NewSentry from "./NewSentry";
import {useAppSelector} from "../../core/hooks";

const SentryContent = ({selectedModalBrigade}) => {

  const {
    request,
    pools,
    contract,
    offersMade,
    payment,
    pool,
    activePanel,
    er_call,
    booking_offers,
    insurance_org,
    emergency_responsible
  } = useAppSelector(state => state.requests)

  const filteredPools = useMemo(() => {
    if (pools && pools.length) {
      return pools.filter((el) => el.request_brigade && el.request_brigade )
    }
    return null
  }, [pools])

  return  <div style={{ overflow: "auto", height: "100%",borderTop: "1px solid #e7e7e7"}}>
    {request?.status === 1 && <div style={{display: "flex",}}><CreatedSentryContent selectedModalBrigade={selectedModalBrigade} request={request}/></div>}
    {request?.status !== 1 &&<NewSentry pool={pool} booking_offers={booking_offers} er_call={er_call} payment={payment} contract={contract} offersMade={offersMade} selectedModalBrigade={selectedModalBrigade} filteredPools={filteredPools} request={request}/>}

  </div>
}

export default SentryContent
