import React, {ReactElement, useEffect, useMemo, useState} from 'react'
import {
  Table,
  TableHead,
  TableBody,
  PanelHeader,
  PanelPopover,
  Panel,
  Search,
  DateRange,
  RightBigPanel, RightBigPanelHeader
} from '../../../../shared'
import {useAppDispatch, useAppSelector} from '../../../../core/hooks'
import {getErHistory, handleFilterChange, loadMoreData} from '../er-history-slice'
import './er-history.scss'
import {setData, toggleEditRequest, toggleMainPanel, toggleRightMainPanel} from '../../../../app/app-slice'
import {
  ER_HISTORY_HEAD,
  ER_HISTORY_HEAD_DC
} from "../../table-constants";
import Header from "../../../insurance-request/header";
import ErDpHeader from "../../../er-request/header";
import RequestForm from "../../../requests/request-form";
import styles from "../../styles.module.scss";
import {debounce} from 'lodash'
import moment from 'moment'
import {CSVLink} from "react-csv";
import {ExcelIcon, ExportIcon} from '../../../../assets'
import {useHistory} from "react-router-dom";
import { Button } from "../../../../ui-components";
import { ExportToExcel } from '../../exportToExcel'
import StatusTextEr from "../../../../shared/status-textEr/status-textEr";
import DpContent from "../../../sentry/DpComponents/DpContent";
import {
  getCustomerSentrySingleRequests,
  getCustomerSentrySingleRequestsCarouselSelected,
} from "../../../requests/er-thunk";
import SentryContent from "../../../sentry/SentryContent";
import ModalSuggestionsListContainer from "../../../CarouselForModal";

const ErHistories = () => {
  const dispatch = useAppDispatch()
  const {erHistory, filter, allDataLoaded} = useAppSelector(state => state.erHistory)
  const app = useAppSelector(state => state.app)
  const {user} = useAppSelector(state => state.user)
  const history = useHistory()
  const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  const [selectedModalBrigade, setSelectedModalBrigade] = useState<string>("")
  const {requests, request, sentry} = useAppSelector(state => state.requests)

  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])

  const isDc = user && user.role === 5
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'В исполнении'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getErHistory())
  }, [dispatch, history.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getErHistory()), 700),
    [dispatch]
  );

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMore = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMoreData())
  }

  const setStatusText = (status): ReactElement => {
    let statusText: any = ''
    switch (status) {
      case "Дежурство":
        statusText = <StatusTextEr color={'blue'} text={status}/>
        break
      case "Ожидает начало дежурство":
        statusText = <StatusTextEr color={'orange'} text={status}/>
        break
      case "Ожидает оплаты":
        statusText = <StatusTextEr color={'orange'} text={status}/>
        break
      case "Доп. оплата":
        statusText = <StatusTextEr color={'orange'} text={status}/>
        break
      case "Подтверждено":
        statusText = <StatusTextEr color={'blue'} text={status}/>
        break
      case "На пути в больницу":
        statusText = <StatusTextEr color={'green'} text={status}/>
        break
      case "Оплачено":
        statusText = <StatusTextEr color={'green'} text={status}/>
        break
      default :
        statusText = <StatusTextEr color={'gray'} text={status}/>
        break
    }
    return statusText
  }

  const headers = [
    {label: "Номер заявки", key: "number"},
    {label: "Контактное лицо", key: "name"},
    {label: "Дата оформления", key: "created_date"},
    {label: "Дата начала мероприятия", key: "start_date"},
    {label: "Дата окончания мероприятия", key: "end_date"},
    {label: "Выезд через", key: "eta"},
    {label: "Стоимость", key: "amount"},
    {label: "Статус оплаты", key: "payment_status"},
    {label: "Статус Заявки", key: "status"},
    {label: "Результат", key: "result"},
  ];
const headersDc = [
    {label: "Номер заявки", key: "number"},
    {label: "Заказчик", key: "name"},
    {label: "Номер телефона Заказчика", key: "phone"},
    {label: "Дата оформления", key: "created_date"},
    {label: "Дата начала мероприятия", key: "start_date"},
    {label: "Дата окончания мероприятия", key: "end_date"},
    {label: "Выезд через", key: "eta"},
    {label: "Стоимость", key: "amount"},
    {label: "Статус оплаты", key: "payment_status"},
    {label: "Статус Заявки", key: "status"},
    {label: "Результат", key: "result"},
  ];

  const xlsxHeaders = [
    "Номер заявки",
    "Контактное лицо",
    "Дата оформления",
    "Дата начала мероприятия",
    "Дата окончания мероприятия",
    "Выезд через",
    "Стоимость",
    "Статус оплаты",
    "Статус Заявки",
    "Результат",
  ];
  const xlsxHeadersDc = [
    "Номер заявки",
    "Заказчик",
    "Номер телефона Заказчика",
    "Дата оформления",
    "Дата начала мероприятия",
    "Дата окончания мероприятия",
    "Выезд через",
    "Стоимость",
    "Статус оплаты",
    "Статус Заявки",
    "Результат",
  ];

  const filtered = [] as any
  const csv = [] as any
  const xlsx = [] as any
  const uuids = [] as any
  const sentryGroupUuids = [] as any
  for (let ticket of erHistory) {
    uuids.push(ticket.request_uuid)
    sentryGroupUuids.push(ticket.sentry_group_uuid)
    if (isDc) {
      filtered.push({
        number: ticket.number,
        name: ticket.name,
        phone: ticket?.phone,
        created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        start_date: moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        end_date: moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        eta:  ticket?.eta,
        amount: ticket?.amount,
        payment_status: ticket?.payment_status,
        status:setStatusText(ticket.status),
        result:ticket?.result,
      })
      csv.push({
        number: ticket.number,
        name: ticket.name,
        phone: ticket?.phone,
        created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        start_date: moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        end_date: moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        eta:  ticket?.eta,
        amount: ticket?.amount,
        payment_status: ticket?.payment_status,
        status:ticket.status,
        result:ticket?.result,
      })
      xlsx.push([
        ticket.number,
        ticket.name || '',
        ticket?.phone || '-',
        moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        ticket?.eta,
        ticket?.amount,
        ticket?.payment_status,
        ticket.status,
        ticket?.result || "-",
      ])
    } else {
      filtered.push({
        number: ticket.number,
        name: ticket.name,
        created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        start_date: moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        end_date: moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        eta:  ticket?.eta,
        amount: ticket?.amount,
        payment_status: ticket?.payment_status,
        status:ticket.status,
        result:ticket?.result,
      })
      csv.push({
        number: ticket.number,
        name: ticket.name,
        created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        start_date: moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        end_date: moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        eta:  ticket?.eta,
        amount: ticket?.amount,
        payment_status: ticket?.payment_status,
        status:ticket.status,
        result:ticket?.result,
      })
      xlsx.push([
        ticket.number,
        ticket.name || '',
        moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
        moment(ticket.start_date).format('DD/MM/YYYY HH:mm'),
        moment(ticket.end_date).format('DD/MM/YYYY HH:mm'),
        ticket?.eta,
        ticket?.amount,
        ticket?.payment_status,
        ticket.status,
        ticket?.result || "-",
      ])
    }

  }

  const csvReport = {
    data: csv,
    headers: isDc? headersDc : headers,
    filename: 'В исполнении.csv'
  };

  const erList = isDc ? ER_HISTORY_HEAD_DC : ER_HISTORY_HEAD

  const bodyRow = filtered.map((el, i) => Object.values(el))
  return (
    <div id={styles.activeTicketsList}>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange('q', e.target.value)
          debouncedChangeHandler()
        }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getErHistory())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getErHistory())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'В исполнении СК', isDc? xlsxHeadersDc : xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {erList && erList.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filtered.map((el, i) =>
              <div onClick={() => {
                if(user && user.organization.type === 4) {

                  dispatch(getCustomerSentrySingleRequests({uuid: uuids[i], sentry_group_uuid: sentryGroupUuids[i]}))
                  setSelectedModalBrigade(uuids[i])
                } else {
                  dispatch(getCustomerSentrySingleRequests({uuid: uuids[i], sentry_group_uuid: sentryGroupUuids[i]}))
                  dispatch(toggleMainPanel())
                }

              }}  key={i} className='row'>
                {
                  bodyRow[i].map((el, index) =>
                    <div key={index} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMore} className={styles.loadMoreBtn}>Загрузить еще</Button>}

      {user && user.organization.type === 4 && <RightBigPanel handleClose={() => dispatch(toggleRightMainPanel())} open={app.rightMainPanel}>
        <RightBigPanelHeader>
          <Header isDc handleClose={() => dispatch(toggleRightMainPanel())}/>
        </RightBigPanelHeader>
        {request && request?.requestedBrigades?.length > 0 && <ModalSuggestionsListContainer
          onClick={(selectedUuid) => {
            // @ts-ignore
            dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid: selectedUuid}))
            setSelectedModalBrigade(selectedUuid)
          }}
          request={request}
          selectedModalBrigade={selectedModalBrigade}
          setSelectedModalBrigade={setSelectedModalBrigade}
          list={request?.requestedBrigades}/>}
        <SentryContent selectedModalBrigade={selectedModalBrigade}/>
      </RightBigPanel>}


      {user.role !== 5 &&<Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <ErDpHeader handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
         <DpContent/>
        <PanelPopover open={app.popoverFormStatuses.editRequestOpen}
                      handleClose={() => dispatch(toggleEditRequest())}
                      title={'Изменить данные пациента'}>
          <RequestForm/>
        </PanelPopover>
      </Panel>}
    </div>
  )
}

export default ErHistories
