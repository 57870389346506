import React, {useState} from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import InputMask from 'react-input-mask'
import {useAppDispatch} from "../../../core/hooks";
import {erCallDelay} from "../../requests/er-thunk";

const DelayForm = () => {
  const [delayTime, setDelayTime] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const submit = (): void => {
    if(delayTime) {
      setClickDisabled(true)
      dispatch(erCallDelay(parseInt(delayTime)))
        .then(res => setClickDisabled(false))
    }
  }

  return (
    <div id={styles.delayForm}>
      <InputMask mask="999 минут" maskChar=" "
                 className={`${styles.input}`}
                 label='Опоздание на'
                 onChange={e => setDelayTime(e?.target.value)}
                 onKeyUp={(e) => e.which === 13 && submit()}
                 value={delayTime}>
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
      <Button className={`${styles.btn} ${clickDisabled ? 'click-disabled' : ''}`}
              type='contained'
              onClick={submit}
              disabled={!delayTime}>Поменять</Button>
    </div>
  )
}

export default DelayForm
