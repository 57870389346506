import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../admin/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getKeyValueStringFromObj, getRegion, IGlobalFilter} from "../../../services/utils";
import {omit} from 'lodash'

interface Canceled {
  request_uuid: string
  address: string
  reason: string
  age: number
  sex: string
  created_date: string
  createdAt: string
  creator: string
  status: number
  number: string
  executor: string,
  name: string,
  policy_number: string,
  arrival_time: number,
  end_time: number,
  diagnosis: string,
  comment: string,
  end_date: string,
  org_name: string,
  reject_reason: string
}

interface ICanceled {
  canceled: Array<Canceled>
  filter: IGlobalFilter,
  allDataLoaded: boolean
}

const initialState: ICanceled = {
  canceled: [],
  filter: {
    limit: 50,
    offset: 0,
    q: '',
    from: '',
    to: '',
    data: {
      age: '',
    }
  },
  allDataLoaded: true
};

const canceled = createSlice({
  name: 'canceled',
  initialState: initialState,
  reducers: {
    setCanceled: (state, action: PayloadAction<Array<Canceled>>) => {
      state.canceled = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: any}>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.filter.data![key] = data.value
      } else {
        state.filter[data.key] = data.value
      }
    },
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded =  action.payload
    }
  }
});

export const {
  setCanceled,
  handleFilterChange,
  setDataLoaded
} = canceled.actions;

export const getCanceled = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter} = getState().canceled
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/canceled-history', {...updatedFilter,  offset: 0, region, filter: additionalData})
      dispatch(handleFilterChange({key: 'offset', value: 0}))
      res.length < filter.limit  ? dispatch(setDataLoaded(true)) : dispatch(setDataLoaded(false))
      dispatch(setCanceled(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const loadMoreData = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter, canceled} = getState().canceled
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/canceled-history', {...updatedFilter, region, filter: additionalData})
      const updatedData = [...canceled, ...res]
      filter.limit > res.length && dispatch(setDataLoaded(true))
      dispatch(setCanceled(updatedData))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default canceled.reducer;
