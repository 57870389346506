import React, {createRef, Fragment, useState} from 'react'
import {ArrowRightIcon, NotificationIcon} from "../../assets";
import ReactTooltip from "react-tooltip";
import {Notification} from "../../shared";
import './header-notification.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {TooltipBlue} from "../../shared/tooltips";
import {filterNotifications, markAllAsRead} from "./notifications-slice";
import {NOTIFICATIONS_FILTER} from "../../services/constants";

const HeaderNotification = () => {
  const [notificationActive, setNotificationActive] = useState<boolean>(false)
  const [filterText, setFilterText] = useState<string>('Все')
  const {list, unreadNotifications} = useAppSelector(state => state.notifications)
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()

  const filterNotification = (type: string, text: string) => {
    setFilterText(text)
    dispatch(filterNotifications({type, user_id: user.id}))
  }
  let filterTooltipRef: any = createRef()
  return (
    <Fragment>
      <div className="notification-wrapper"
           data-tip data-for='notifications'
           data-event='click'>
        <NotificationIcon className={`icon ${notificationActive ? 'active' : ''}`}/>
        {unreadNotifications > 0 && <div className="amount">{unreadNotifications}</div>}
      </div>
      <ReactTooltip arrowColor="transparent"
                    afterShow={() => setNotificationActive(true)}
                    afterHide={() => setNotificationActive(false)}
                    offset={{left: 190}}
                    globalEventOff={'click'}
                    className='notifications-tooltip'
                    id='notifications' place='bottom' effect='solid' clickable={true}>
        <div className="notification-header">
          <p className='title'>Уведомления</p>
          <p className='mark-all' onClick={() => dispatch(markAllAsRead())}>Отм. все как прочитанные</p>
          <p className='filter' data-tip data-for='notifications-filter'
             data-event='click'>{filterText} <ArrowRightIcon/></p>
          <TooltipBlue ref={filterTooltipRef} id='notifications-filter'>
            {
              NOTIFICATIONS_FILTER.map((el, i) =>
                <p key={i}
                   onClick={() => {
                     filterNotification(el.type, el.text)
                     filterTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text}</p>)
            }
          </TooltipBlue>
        </div>
        <div className="notifications-content">
          {
            list.map((el, i) => {
              return el.visible && <Notification message={el.message}
                                                 data={el.data}
                                                 request_uuid={el.data.request_uuid}
                                                 notification_uuid={el.uuid}
                                                 key={i}
                                                 read={el.read}
                                                 requestNumber={el.data.requestNumber}
                                                 patientName={el.data.patientName}
                                                 date={el.updated_at}/>
            })
          }
        </div>
      </ReactTooltip>
    </Fragment>
  )
}

export default HeaderNotification;
