import React, { useState } from 'react'
import {ActionsTooltip, BrigadeInfo, PatientInfo, DiagnoseForm, DiagnoseInfo, ActionsPopovers} from "../components";
import {InfoTooltipTrigger, PanelFooter, PanelPopover} from "../../../shared";
import {Button} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {ER_CALL_STATUSES} from '../../../services/constants'
import { togglePopover } from '../../../app/app-slice';
import './styles.scss'

const ArrivedRequest = () => {
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState<number>(0)

  return (
    <div id='arrived-request' className='request-info-container pb-80'>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter className='footer'>
        <Button type='contained' onClick={() => {
          dispatch(togglePopover('diagnoseFormOpen'))
          setStatus(ER_CALL_STATUSES.endedLeftInPlace)
        }}>На месте</Button>
        <Button type='outlined' onClick={() => {
          dispatch(togglePopover('diagnoseFormOpen'))
          setStatus(ER_CALL_STATUSES.hospitalizationRequest)
        }}>Госпитализация</Button>
        <Button type='contained' onClick={() => {
          dispatch(togglePopover('diagnoseFormOpen'))
          setStatus(ER_CALL_STATUSES.endedRefusedHospital)
        }}>Отказ от Гсп.</Button>
        <InfoTooltipTrigger dataFor={'actions-menu'} className='ml-15'/>
      </PanelFooter>
      <PanelPopover open={app.popoverFormStatuses.diagnoseFormOpen}
                    className='diagnose-popover'
                    title={'Добавить диагноз'}
                    handleClose={() => dispatch(togglePopover('diagnoseFormOpen'))}>
        <DiagnoseForm status={status}/>
      </PanelPopover>
      <ActionsTooltip id='actions-menu' offsetLeft={150} offsetTop={-20}/>
      <ActionsPopovers/>
    </div>
  )
}

export default ArrivedRequest
