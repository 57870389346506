import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";


interface Notifications {
  soundName: string
}

const initialState: Notifications = {
  soundName:''
};

const notifications = createSlice({
  name: 'notificationsSoundManager',
  initialState: initialState,
  reducers: {
    setNotificationSound: (state, action: PayloadAction<string>) => {
      state.soundName = action.payload
      return state
    },
    resetNotificationSound: (state, action: PayloadAction<any>) => {
      state.soundName = ''
      return state
    }
  }
});

export const {
  setNotificationSound,
  resetNotificationSound
} = notifications.actions;

export const playNotificationSound = (soundName:string) => {
  return (dispatch: AppDispatch) => {
    dispatch(setNotificationSound(soundName))
  }
};

export const stopNotificationSound = () => {
  return (dispatch: AppDispatch) => {
    dispatch(resetNotificationSound(''))
  }
};

export default notifications.reducer;
