import React, {FC, ReactElement} from 'react'
import {Modal} from "../index";
import styles from "./styles.module.scss";
import { IMainProps } from './interfaces';


interface Props extends IMainProps {
  description: string
  footer?: ReactElement | ReactElement[]
}

const PrimaryModal: FC<Props> = (props) => {
  return (
    <Modal open={props.open}
           className={styles.primaryModal}
           title={props.text}
           closeIcon={true}
           handleClose={props.handleClose}>
      <p className={styles.text}>{props.description}</p>
      {props.footer}
    </Modal>
  )
}

export default PrimaryModal
