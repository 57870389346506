import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {setApiError} from "../../admin/app-slice";
import {ErCall, Pool} from "./IRequests";
import {toggleMainPanel, togglePopover, toggleRightMainPanel} from "../../app/app-slice";
import {
  getAvatar,
  getMessages,
  setCustomerRequest,
  setErRequest,
  setRequestsList,
  setSentryRequestsList
} from "./requests-slice";
import {downloadFile, getRegion, toBase64} from "../../services/utils";
import { ER_CALL_STATUSES } from "../../services/constants";
import { getHistory } from '../left-panel/history/history-slice'
import customerApi from "../../services/api/customerApi";

export const getErSingleRequest = (request_uuid: string, togglePanel: boolean = true) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.request.get(`/er-single/${request_uuid}`)
      togglePanel && dispatch(toggleMainPanel())
      dispatch(setErRequest(res))

      res.er_call && await dispatch(getMessages())

      const organization = {...res.insurance_org}
      if (res.insurance_org.avatar) {
        const file = await dispatch(getAvatar(organization.uuid, organization.avatar))
        organization.avatar = toBase64(file.Body.data)
      }
      const updatedRes: any = {...res, insurance_org: organization}

      dispatch(setErRequest(updatedRes))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getErRequestsList = (uuid?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get('/emergency-dashboard-list', {region})

      dispatch(setRequestsList(res))

      if(uuid && uuid === request.uuid && mainPanelOpen) {
        dispatch(getErSingleRequest(uuid, false))
      }
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getDpErRequestsList = (uuid?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get('/customer-emergency-dashboard-list', {region})

      dispatch(setRequestsList(res))

      if(uuid && uuid === request.uuid && mainPanelOpen) {
        dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid}))
      }
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getSentryRequestsList = (uuid?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen, rightMainPanel } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get('/admin-emergency-dashboard-list', {region})
      dispatch(setSentryRequestsList(res))
      if(uuid && uuid === request.uuid && rightMainPanel) {
        // dispatch(getSingleRequest(uuid, false))
        dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid}))
        dispatch(getCustomerSentrySingleRequests({uuid: uuid, sentry_group_uuid: request?.sentry_group_uuid}))

      }
      // if(uuid && uuid === request.uuid && mainPanelOpen) {
      //   dispatch(getErSingleRequest(uuid, false))
      // }
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getCustomerSentrySingleRequests = (data?: any) => {

  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen, rightMainPanel } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get(`/customer-er-single/${data?.uuid}/${data?.sentry_group_uuid}`)
      dispatch(setCustomerRequest(res))
      if (!rightMainPanel) {
        dispatch(toggleRightMainPanel())
      }
      // if(uuid && uuid === request.uuid && mainPanelOpen) {
      //   dispatch(getErSingleRequest(uuid, false))
      // }
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getCustomerSentrySingleRequestsCarouselSelected = (data?: any) => {

  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get(`/customer-er-single/${data?.uuid}/${request?.sentry_group_uuid}`)
      dispatch(setCustomerRequest(res))
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const updateCustomerInfoRequests = (data?: any) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {

      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const res = await customerApi.request.put(`/update-customer/${data?.uuid}/${request?.customer_uuid}`, data?.data)
      if (res) {
        dispatch(getSentryRequestsList(request?.uuid))
        // dispatch(getCustomerSentrySingleRequests(request?.uuid))

      }
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const addCustomerPaymentRequests = (data?: any) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {

      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const res = await customerApi.request.post(`/add-amount/${request?.uuid}/${request?.customer_uuid}`, data)
      if (res) {
        dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid: request?.uuid}))
      }
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const removeCustomerPaymentRequests = (data?: any) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {

      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const res = await customerApi.request.post(`/subtract-amount/${request?.uuid}/${request?.customer_uuid}`, data)
      if (res) {
        dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid: request?.uuid}))
      }
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const confirmCustomerPaymentRequests = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {

      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const res = await customerApi.request.post(`/additional-payment/${request?.uuid}/${request?.customer_uuid}`)
      if (res) {
        dispatch(getSentryRequestsList())
        dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid: request?.uuid}))
      }
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const sendCustomerSentrySingleRequests = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {

      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await customerApi.request.post(`/process-offer/${request?.uuid}/${request?.customer_uuid}`)
      dispatch(toggleRightMainPanel())
      dispatch(getSentryRequestsList())
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const sendCustomerOfferBrigadeRequests = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      // @ts-ignore
      const res = await customerApi.request.post(`/select-er/${data?.requests[0].request_uuid}/${data?.requests[0].customer_uuid}`, {requests: data?.requests})
        data?.setOpenSentryModal(false)
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())
        // dispatch(getCustomerSentrySingleRequests(request))
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const cancelCustomerOfferBrigadeRequests = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      const res = await customerApi.request.post(`/close-request/${data?.item?.request_uuid}/${data?.item.customer_uuid}`)
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const cancelCustomerOfferBrigadePopupRequests = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      const res = await customerApi.request.post(`/close-request/${data?.item?.request_uuid}/${data?.item.customer_uuid}`, data.comment)
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const cancelCustomerEarly = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      const res = await customerApi.request.post(`/early-close/${data?.item?.request_uuid}/${data?.item.customer_uuid}`, {
        subtract: data.subtract
      })
      dispatch(togglePopover('earlyClose'))
      dispatch(getSentryRequestsList())
      dispatch(getCustomerSentrySingleRequestsCarouselSelected({uuid: data?.item?.request_uuid}))
      // dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const customerContractBeginRequests = (data?: any) => {
  return async (dispatch: AppDispatch,getState: any, api: ApiInstance) => {
    try {
      const {request} = getState().requests
      const res = await customerApi.request.post(`/contract-begin/${data?.item?.request_uuid}/${data?.item.customer_uuid}`)
      dispatch(getSentryRequestsList())
      dispatch(getCustomerSentrySingleRequests({uuid: data?.item?.request_uuid, sentry_group_uuid: request?.sentry_group_uuid}))

      // dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const customerUploadContract = (formData?: any) => {
  return async (dispatch: AppDispatch,getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.customer.upload(`/upload/admin/${request?.uuid}/${request?.customer_uuid}`, formData)
      dispatch(getSentryRequestsList())
      dispatch(getCustomerSentrySingleRequests({uuid: request?.uuid, sentry_group_uuid: request?.sentry_group_uuid}))
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const customerDownloadContract = (data?: any) => {
  return async (dispatch: AppDispatch,getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.customer.downloadPdf(`/download/${request?.uuid}/${data}/${request.customer_uuid}`)
      downloadFile(res, data)
      dispatch(getSentryRequestsList())
      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const customerUpdatePaymentRequests = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      const res = await customerApi.request.post(`/update-payment/${data?.item?.request_uuid}/${data?.item.customer_uuid}`, data.data)
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const customerContractFinishedRequests = (data?: any) => {
  return async (dispatch: AppDispatch,getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await customerApi.request.post(`/contract-finished/${data?.item?.request_uuid}/${data?.item.customer_uuid}`, data.data)
      dispatch(getSentryRequestsList())
      dispatch(getCustomerSentrySingleRequests({uuid: request?.uuid, sentry_group_uuid: request?.sentry_group_uuid}))

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const customerPaymentDoneRequests = (data?: any) => {
  return async (dispatch: AppDispatch, api: ApiInstance) => {
    try {
      const res = await customerApi.request.post(`/payment-done/${data?.item?.request_uuid}/${data?.item.customer_uuid}`, data.data)
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())

      return
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const erInterested = (data: Array<Pool>) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.request.post(`interested/${request.uuid}`, {brigades: data})
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(togglePopover('offerFormOpen'))
      dispatch(toggleMainPanel())

      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const customerErInterested = (data: Array<Pool>) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await customerApi.request.post(`/interested/${request.uuid}/${request?.customer_uuid}`, {brigades: data})
      // dispatch(setErRequest(res))
      // dispatch(getErRequestsList())
      dispatch(getDpErRequestsList())
      dispatch(togglePopover('offerFormOpen'))
      dispatch(toggleMainPanel())

      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const dpCancelErOffer = (brigade_uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await customerApi.request.post(`/emergency-delete-offer/${request.uuid}/${request.customer_uuid}`, {brigade_uuid})
      dispatch(getDpErRequestsList())
      dispatch(toggleMainPanel())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const cancelErOffer = (brigade_uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.request.post(`emergency-delete-offer/${request.uuid}`, {brigade_uuid})
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(toggleMainPanel())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const erApprove = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.request.post(`emergency-approve/${request.uuid}`)
      await dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      setTimeout(() => dispatch(togglePopover('acceptFormOpen')), 800)
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const erReject = (rejectReason: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      await api.request.post(`emergency-reject/${request.uuid}`, {rejection_reason: rejectReason})
      dispatch(getErRequestsList())
      dispatch(toggleMainPanel())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const assignBrigade = (brigade_number: string, brigade_responsible: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool} = getState().requests
    const brigade = pool.request_brigade.filter(el => el.status === 2)[0]
    try {
      const res = await api['er-call'].put(er_call.uuid, {
        brigade_number,
        brigade_responsible,
        brigade_uuid: brigade.uuid,
        status: 2
      })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(toggleMainPanel())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const assignCustomerBrigade = (brigade_number: string, brigade_responsible: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool, request} = getState().requests
    const brigade = pool.request_brigade.filter(el => el.status === 2)[0]
    try {
      const res = await api['er-call'].put(`/customer/${er_call.uuid}`, {
        brigade_number,
        brigade_responsible,
        brigade_uuid: brigade.uuid,
        status: 2
      })
      dispatch(setErRequest(res))
      dispatch(getDpErRequestsList(request.uuid))
      // dispatch(toggleMainPanel())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const editBrigade = (brigade_type: string, brigade_responsible: string, brigade_number: string, eta: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool} = getState().requests
    const brigade = pool.request_brigade.filter(el => el.status === 2)[0]

    try {
      const res = await api['er-call'].put(er_call.uuid, {
        brigade_type,
        brigade_responsible,
        brigade_number,
        eta,
        brigade_uuid: brigade.uuid,
      })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(togglePopover('editBrigadeFormOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const editCustomerBrigade = (brigade_type: string, brigade_responsible: string, brigade_number: string, eta: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool} = getState().requests
    const brigade = pool.request_brigade.filter(el => el.status === 2)[0]

    try {
      const res = await api['er-call'].put(`customer/${er_call.uuid}`, {
        brigade_type,
        brigade_responsible,
        brigade_number,
        eta,
        brigade_uuid: brigade.uuid,
      })
      dispatch(setCustomerRequest(res))
      dispatch(getDpErRequestsList())
      dispatch(togglePopover('editBrigadeFormOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const changeErCallStatus = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(er_call.uuid, {status})

      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const changeCustomerErCallStatus = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(`/customer/${er_call.uuid}`, {status})
      dispatch(setCustomerRequest(res))
      dispatch(getDpErRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const changeCustomerDcErCallStatus = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(`/customer/${er_call.uuid}`, {status})
      // dispatch(setCustomerRequest(res))
      dispatch(getSentryRequestsList())
      dispatch(toggleRightMainPanel())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const endErCallFromHospitalizationFrom = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].post(`close-from-hospitalization/${er_call.uuid}`, { status })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const customerEndErCallFromHospitalizationFrom = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].post(`/customer/close-from-hospitalization/${er_call.uuid}`, { status })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}



export const dpErCallDelay = (delay_time: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {pool, er_call} = getState().requests
    const brigade = pool.request_brigade.find(el => el.status === 2)
    let delay = brigade.delay ? brigade.delay + delay_time : delay_time
    try {
      const status = er_call.status >= ER_CALL_STATUSES.onRouteToHospital ? ER_CALL_STATUSES.onRouteToHospitalDelay : ER_CALL_STATUSES.onRouteDelay
      const res = await api['er-call'].put(`/customer/${er_call.uuid}`, {status, delay, brigade_uuid: brigade.uuid})
      dispatch(getDpErRequestsList())
      dispatch(togglePopover('delayFormOpen'))
      dispatch(toggleMainPanel())
      dispatch(toggleRightMainPanel())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}


export const changeClosedStatus = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].post(`change-closed-status/${er_call.uuid}`, { status })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const hospitalizationRequestFromClosed = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].post(`hospitalization-from-closed/${er_call.uuid}`)
      dispatch(setErRequest(res))
      dispatch(getHistory())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const replaceErCall = (emergency_change_reason: string, emergency_change_comment: string, emergency_change_address: string ) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(er_call.uuid, {
        emergency_change_reason,
        emergency_change_comment,
        emergency_change_address,
        override_comment: er_call.status >= ER_CALL_STATUSES.onRouteToHospital,
        status: 13
      })
      dispatch(togglePopover('replaceErFormOpen'))
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const erCallDelay = (delay_time: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {pool, er_call} = getState().requests
    const brigade = pool.request_brigade.find(el => el.status === 2)
    let delay = brigade.delay ? brigade.delay + delay_time : delay_time
    try {
      const status = er_call.status >= ER_CALL_STATUSES.onRouteToHospital ? ER_CALL_STATUSES.onRouteToHospitalDelay : ER_CALL_STATUSES.onRouteDelay
      const res = await api['er-call'].put(er_call.uuid, {status, delay, brigade_uuid: brigade.uuid})
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(togglePopover('delayFormOpen'))
      dispatch(toggleMainPanel())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const switchErCallBrigade = (brigade_type: string, brigade_responsible: string, brigade_number: string, delayTime: any) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool} = getState().requests
    const brigade = pool.request_brigade.find(el => el.status === 2)
    let delay = brigade.delay ? brigade.delay + delayTime : delayTime

    try {
      const status = er_call.status >= ER_CALL_STATUSES.onRouteToHospital ? ER_CALL_STATUSES.onRouteToHospitalDelay : ER_CALL_STATUSES.onRouteDelay
      const res = await api['er-call'].put(er_call.uuid, {
        status: status,
        brigade_type,
        brigade_responsible,
        brigade_number,
        delay,
        brigade_uuid: brigade.uuid,
        isForChange: true
      })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(togglePopover('replaceBrigadeFormOpen'))
      dispatch(toggleMainPanel())
      return true
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const addAdditionalBrigade = (brigade_type: string, brigade_responsible: string, brigade_number: string, eta: number, comment: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call, pool} = getState().requests
    const brigade = pool.request_brigade.filter(el => el.status === 2)[0]

    try {
      const res = await api['er-call'].put(er_call.uuid, {
        brigade_type,
        brigade_responsible,
        brigade_number,
        eta,
        comment,
        additional_brigade: true,
        brigade_uuid: brigade.uuid,
      })
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      dispatch(togglePopover('additionalBrigadeFormOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const removeAdditionalBrigade = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests

    try {
      const res = await api['er-call'].delete(`cancel-additional-brigade/${er_call.uuid}`)
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const addDiagnose = (data: ErCall) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests

    try {
      const res = await api['er-call'].put(er_call.uuid, data)
      dispatch(setErRequest(res))
      dispatch(togglePopover('diagnoseFormOpen'))
      dispatch(getErRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
export const addDiagnoseCustomer = (data: ErCall) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests

    try {
      const res = await api['er-call'].put(`/customer/${er_call.uuid}`, data)
      dispatch(setCustomerRequest(res))
      dispatch(getDpErRequestsList())
      dispatch(togglePopover('diagnoseFormOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const replaceHospital = (hospital_comment: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests

    try {
      // const res = await api['er-call'].post(`request-transfer-hospital/${er_call.uuid}`, {status: ER_CALL_STATUSES.hospitalizationRequest, hospital_comment})
      const res = await api['er-call'].put(er_call.uuid, {status: ER_CALL_STATUSES.hospitalizationRequest, hospital_comment})
      dispatch(togglePopover('replaceHospitalFormOpen'))
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const changeDispatcher = (dispatcher_uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(`/change-emergency-dispatcher/${er_call.uuid}`, {dispatcher_uuid})
      dispatch(setErRequest(res))
      dispatch(getErRequestsList())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
