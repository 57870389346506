// @ts-nocheck
import React from 'react'
import moment from 'moment/moment'
import styles from '../er-request/components/styles.module.scss'
import {InfoText} from "../../shared";
import {EditIcon, FemaleIcon, MaleIcon} from "../../assets";
import {REQUEST_STATUSES} from "../../services/constants";
import {toggleEditDpRequest, toggleEditRequest} from "../../app/app-slice";
import {useAppDispatch, useAppSelector} from "../../core/hooks";

const CustomerDpInfo = () => {
  const dispatch = useAppDispatch()
  const {request, er_call} = useAppSelector(state => state.requests)
  return (
    <div id={styles.patientInfo}>
      <p className={styles.title}>{request.sex === 1 ? <MaleIcon/> : <FemaleIcon/>}Заказчик</p>
      {(request.status !== REQUEST_STATUSES.closed && er_call) && <EditIcon className={styles.editIcon} onClick={() => dispatch(toggleEditDpRequest())}/>}
      <div className={styles.wrapper}>
        <InfoText title={'Контактное лицо'} text={request?.name || '-'}/>
        <InfoText title='Город' className={styles.city} text={request.city}/>
        {/*<InfoText title='Дата рождения' text={`${moment(request?.birthday).format('DD/MM/YYYY')}`}/>*/}
        {request?.customer_type === 2 && <InfoText title='Телефон' text={request?.contact_phone || '-'}/>}
        {request?.customer_type === 1 && <InfoText title='Телефон' text={request?.contact_number || '-'}/>}
      </div>
      <div className={styles.wrapper}>
      </div>
    </div>
  )
}

export default CustomerDpInfo
