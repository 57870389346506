import React, {createRef, useCallback, useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import './er-dashboard.scss'
import {
  Panel,
  PanelHeader,
  PanelPopover,
  RequestContainer,
} from "../../shared";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  attachAvatars,
  setData, toggleEditDpRequest,
  toggleEditRequest,
  toggleMainPanel,
  togglePopover,
} from '../../app/app-slice';
import {NoData} from '../../shared'
import Card from "./card";
import emptyCardImg from '../../assets/images/hospital-img-3.png'
import emptyCardImg1 from '../../assets/images/hospital-img-1.png'
import emptyCardImg2 from '../../assets/images/hospital-img-2.png'
import {
  getCustomerSentrySingleRequests, getDpErRequestsList,
  getErRequestsList,
  getErSingleRequest,
} from "../requests/er-thunk";
import Header from "../er-request/header";
import Content from "../er-request/content";
import SocketContext from "../../core/socket-context";
import {EditBrigadeForm, OffersList} from "../er-request/components";
import {ProblemTooltip, TooltipBlue} from "../../shared/tooltips";
import {
  EMERGENCY_APP_FILTER,
  EMERGENCY_ER_FILTER,
  ER_BIDDING_FILTER
} from "../../services/constants";
import {
  filterRequestsByErStatus,
  filterRequestsByStatus,
  setCustomerTabBadge,
  setInsuranceTabBadge
} from "../requests/requests-slice";
import {ArrowDownIcon, ArrowRightIcon, CheckmarkIcon} from "../../assets";
import ReactTooltip from "react-tooltip";
import {buildProblemsAmountMessage} from '../../services/utils';
import RequestForm from "../requests/request-form";
import { playNotificationSound } from '../notification-sound-manager/notifications-sound-manager-slice'
import SuggestionsListContainer from "../SuggestionsCarousel";
import DpContent from "../sentry/DpComponents/DpContent";
import DpRequestForm from "../requests/DpRequests/dpRequest-form";


const ErDashboard = () => {
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext);
  const {requests, request, sentry} = useAppSelector(state => state.requests)
  const app = useAppSelector(state => state.app)
  const [biddingFilterActive, setBiddingFilterActive] = useState(false)
  const [applicationsFilterActive, setApplicationsFilterActive] = useState(false)
  const [erCallsFilterActive, setErCallsFilterActive] = useState(false)
  const [applicationsFilterSelected, setApplicationsFilterSelected] = useState<string>('')
  const [erCallsFilterSelected, setErCallsFilterSelected] = useState<string>('')
  const [biddingFilterSelected, setBiddingFilterSelected] = useState<string>('')
  // const [selectedModalBrigade, setSelectedModalBrigade] = useState<string>("")
  const token = localStorage.getItem('token')
  const activeTab: any = sessionStorage.getItem("tab")
  // const user = localStorage.getItem('user')
  const {user} = useAppSelector(state => state.user)
  const history = useHistory()
  if (!user || !token) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    history.push('/login')
  }
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Главная'}))
    if (+activeTab === 1) {
      dispatch(getErRequestsList())
        .then(() => dispatch(attachAvatars()))
    }
    if (+activeTab === 2) {
      dispatch(getDpErRequestsList())
    }

  }, [activeTab, dispatch, history.location])

  useEffect(() => {
    socket.on('dashboard_update', (e) => {
     const isActive = sessionStorage.getItem("tab")
      // @ts-ignore
      if (+isActive === 1) {
        dispatch(getErRequestsList(e.uuid))
      } else {
        dispatch(setInsuranceTabBadge())
      }
    })
  }, [dispatch])

  useEffect(() => {
    socket.on('dashboard_update', (e) => {
      if(e.status === 0) {
        dispatch(playNotificationSound('erDashboardNotificationSound'))
      }
      if (app.mainPanelOpen && request?.uuid === e.uuid) {
        if (e.status === 4) {
          app.mainPanelOpen && dispatch(toggleMainPanel())
        }
      }
    })
  }, [request?.uuid, app.mainPanelOpen, dispatch])

  const problematicBidding = requests.bidding.filter(data => data.problematic === true)
  const problematicApplications = requests.applications.filter(data => data.problematic === true)
  const problematicErCalls = requests.er_calls.filter(data => data.problematic === true)

  const filterRequests = (column, value, filterType) => {
    switch (filterType) {
      case 'bidding':
        dispatch(filterRequestsByStatus({column, value}))
        break
      case 'applications':
        dispatch(filterRequestsByStatus({column, value}))
        break
      default:
        dispatch(filterRequestsByErStatus({column, value}))
        break
    }
  }

  const getCustomerSingle = useCallback((singleData: any) => {
    dispatch(getCustomerSentrySingleRequests(singleData))
  }, [dispatch])

  let applicationsTooltipRef: any = createRef()
  let erCallsTooltipRef: any = createRef()
  let biddingTooltipRef: any = createRef()
  return (
    <div id='er-dashboard'>
      {user && user?.role === 5 && sentry?.length > 0 && <div>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <SuggestionsListContainer getCustomerSingle={getCustomerSingle} list={sentry} onClick={() => {}}/>
      </div>}
      <div style={{display: "flex"}}>
      <RequestContainer
        title={
          <>
            Торги
            {problematicBidding.length > 0 &&
              <ProblemTooltip dataFor={'bidding'}
                              onClick={() => {
                                if (biddingFilterActive && biddingFilterSelected === 'Проблематичные') {
                                  setBiddingFilterActive(false)
                                  setBiddingFilterSelected('')
                                  return filterRequests('bidding', 0, 'bidding')
                                }
                                filterRequests('bidding', true, 'bidding')
                                setBiddingFilterActive(true)
                                setBiddingFilterSelected('Проблематичные')
                              }}
                              text={`${problematicBidding.length} ${buildProblemsAmountMessage(problematicBidding.length)}`}/>}
          </>}
        filter={
          <>
            <span data-tip
                  data-event='click'
                  data-for='applications-filter'
                  className={`filter-btn ${biddingFilterActive ? 'filter-active' : ''}`}>
              Фильтр{biddingFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
            <TooltipBlue offsetLeft={130} ref={biddingTooltipRef} id='applications-filter'>
              {ER_BIDDING_FILTER.map((el, i) =>
                <p key={i} className={`filter-item ${biddingFilterSelected === el.text ? 'active' : ''}`}
                   onClick={() => {
                     filterRequests('bidding', el.value, 'bidding')
                     setBiddingFilterActive(true)
                     setBiddingFilterSelected(el.text)
                     biddingTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text} {biddingFilterSelected === el.text && <CheckmarkIcon/>}</p>
              )}
            </TooltipBlue>
          </>
        }>
        {!requests.bidding.length ?
          <>
            <NoData img={emptyCardImg}
                    title={'Здесь видны торги от СК'}/>
          </> :
          <>
            {
              requests?.bidding.map((data: any) => {
                return data.visible && <Card key={data.uuid} onClick={() => {
                  // if(user && user?.role === 5) {
                  //   dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                  // }
                  if(user && (user?.role ===3 || user?.role === 2) && user?.organization?.type === 2  && activeTab == 2) {
                    dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                     dispatch(toggleMainPanel())
                    return
                  }
                  else if (activeTab !==2) {
                    dispatch(getErSingleRequest(data.uuid))

                  }
                }} data={data} column={'bidding'}/>
              })
            }
          </>
        }
      </RequestContainer>
      <RequestContainer
        title={<>
          Заявки и ответы
          {problematicApplications.length > 0 &&
            <ProblemTooltip dataFor={'applications'}
                            onClick={() => {
                              if (applicationsFilterActive && applicationsFilterSelected === 'Проблематичные') {
                                setApplicationsFilterActive(false)
                                setApplicationsFilterSelected('')
                                return filterRequests('applications', 0, 'applications')
                              }
                              filterRequests('applications', true, 'applications')
                              setApplicationsFilterActive(true)
                              setApplicationsFilterSelected('Проблематичные')
                            }}
                            text={`${problematicApplications.length} ${buildProblemsAmountMessage(problematicApplications.length)}`}/>}
        </>
        }
        filter={
          <>
            <span data-tip
                  data-event='click'
                  data-for='er-app-filter'
                  className={`filter-btn ${applicationsFilterActive ? 'filter-active' : ''}`}>
              Фильтр{applicationsFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
            <TooltipBlue offsetLeft={130} ref={applicationsTooltipRef} id='er-app-filter'>
              {EMERGENCY_APP_FILTER.map((el, i) =>
                <p key={i} className={`filter-item ${applicationsFilterSelected === el.text ? 'active' : ''}`}
                   onClick={() => {
                     filterRequests('applications', el.value, 'applications')
                     setApplicationsFilterActive(true)
                     setApplicationsFilterSelected(el.text)
                     applicationsTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text} {applicationsFilterSelected === el.text && <CheckmarkIcon/>}</p>
              )}
            </TooltipBlue>
          </>
        }>
        {!requests.applications.length ?
          <>
            <NoData img={emptyCardImg1}
                    title={'Процесс от СК будет показан в этой секции'}/>
          </> :
          <>
            {
              requests.applications.map((data: any) => {
                return data.visible && <Card key={data.uuid} onClick={() => {
                  // if(user && user?.role === 5) {
                  //   dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                  // } else
                    if(user && (user?.role ===3 || user?.role === 2) && user?.organization?.type === 2  && +activeTab === 2) {
                    dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                    dispatch(toggleMainPanel())
                    return
                  } else {
                    dispatch(getErSingleRequest(data.uuid))

                  }
                }} data={data} column={'applications'}/>
              })
            }
          </>
        }
      </RequestContainer>
      <RequestContainer title={
        <>
          В исполнении
          {problematicErCalls.length > 0 &&
            <ProblemTooltip dataFor={'er_calls'}
                            onClick={() => {
                              if (erCallsFilterActive && erCallsFilterSelected === 'Проблематичные') {
                                setErCallsFilterActive(false)
                                setErCallsFilterSelected('')
                                return filterRequests('er_calls', 0, 'er_calls')
                              }
                              filterRequests('er_calls', true, 'er_calls')
                              setErCallsFilterActive(true)
                              setErCallsFilterSelected('Проблематичные')
                            }}
                            text={`${problematicErCalls.length} ${buildProblemsAmountMessage(problematicErCalls.length)}`}/>}
        </>} filter={
        <>
            <span data-tip
                  data-event='click'
                  data-for='er-filter'
                  className={`filter-btn ${erCallsFilterActive ? 'filter-active' : ''}`}>
              Фильтр{erCallsFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
          <TooltipBlue offsetLeft={130} ref={erCallsTooltipRef} id='er-filter'>
            {EMERGENCY_ER_FILTER.map((el, i) =>
              <p key={i} className={`filter-item ${erCallsFilterSelected === el.text ? 'active' : ''}`}
                 onClick={() => {
                   filterRequests('er_calls', el.value, 'er_calls')
                   setErCallsFilterActive(true)
                   setErCallsFilterSelected(el.text)
                   erCallsTooltipRef.current.tooltipRef = null
                   ReactTooltip.hide()
                 }}>{el.text} {erCallsFilterSelected === el.text && <CheckmarkIcon/>}</p>
            )}
          </TooltipBlue>
        </>
      }>
        {!requests.er_calls.length ?
          <>
            <NoData img={emptyCardImg2}
                    title={'Запросы о госпитализации видны здесь'}/>
          </> :
          <>
            {
              requests.er_calls.map((data: any, i) => {
                return data.visible && <Card key={data.uuid} onClick={() => {
                  // if(user && user?.role === 5) {
                  //   dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                  // } else
                    if(user && (user?.role ===3 || user?.role === 2) && user?.organization?.type === 2 && +activeTab === 2) {
                    dispatch(getCustomerSentrySingleRequests({uuid: data.uuid, sentry_group_uuid: data?.sentry_group_uuid}))
                    dispatch(toggleMainPanel())
                    return
                  } else {
                    dispatch(getErSingleRequest(data.uuid))

                  }
                  // dispatch(getErSingleRequest(data.uuid))
                }} data={data} column={'er_calls'}/>
              })
            }
          </>
        }
      </RequestContainer>
      </div>
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        {+activeTab === 2 ?<DpContent/> : <Content/>}
        <PanelPopover open={app.popoverFormStatuses.editBrigadeFormOpen}
                      title={'Редактировать бригаду'}
                      handleClose={() => dispatch(togglePopover('editBrigadeFormOpen'))}>
          <EditBrigadeForm/>
        </PanelPopover>
        <PanelPopover open={app.popoverFormStatuses.editRequestOpen}
                      height={570}
                      handleClose={() => dispatch(toggleEditRequest())}
                      title={'Изменить данные пациента'}>
          <RequestForm/>
        </PanelPopover>
        <PanelPopover open={app.popoverFormStatuses.editDpRequestOpen}
                      height={570}
                      handleClose={() => dispatch(toggleEditDpRequest())}
                      title={'Изменить данные Заказчика'}>
          <DpRequestForm/>
        </PanelPopover>
      </Panel>
    </div>
  )
}

export default ErDashboard
