import React, {useState} from 'react'
import {Button, Input} from "../../ui-components";
import {inputsValidator, isFormDataValid} from "../../services/globalValidators";
import {formValidator, ValidationFields} from "../../shared/validators/validator";
import {login} from "../../modules/user/user-slice";
import {useAppDispatch} from "../../core/hooks";
import {useHistory} from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean | null>(null)
  const [validationFields, setValidationFields] = useState<ValidationFields>({password: true, email: true})

  const dispatch = useAppDispatch()
  const history = useHistory()

  const submitForm = () => {
    const data = [
      {
        key: 'password',
        value: password,
      },
      {
        key: 'email',
        value: email,
      },
    ]

    const fields = inputsValidator(data, validationFields, formValidator)
    const formValid = isFormDataValid(fields)
    setFormValid(formValid)
    setValidationFields({...fields})

    if (formValid) {
      dispatch(login(email, password, true))
        .then(res => res && history.push('/emergency'))
        .catch(error => {
          setFormValid(false)
          console.error('Failed to login:', error)
        })
    }
  }

  return (
    <div id='login-container' className='form-container'>
      <p className='title'>Войти</p>
      <Input label={'Почта'}
             inputType='email'
             value={email}
             onChange={e => setEmail(e.target.value)}
             error={!validationFields.email}
             className='mb-30'/>
      <Input label={'Пароль'}
             inputType='password'
             value={password}
             error={!validationFields.password}
             onChange={e => setPassword(e.target.value)}
             className='mb-15'/>
      <Button color='gradient' onClick={submitForm} className='button' type='contained'>Войти</Button>
      {formValid === false && <p className='text-error'>*Неверный логин или пароль.</p>}
    </div>
  )
}

export default Login
