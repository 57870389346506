import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {Organization, Organizations} from "./IOrganization";
import { toBase64 } from '../../services/utils'
import { handleFormChange } from '../emergency/emergency-slice'
import { getAvatar } from '../../modules/requests/requests-slice'

const initialState: Organizations = {
  organization: {
    abbr: '',
    inn: '',
    name: '',
    phone: '',
    address: '',
    email: '',
  },
}

const organizations = createSlice({
  name: 'organizations',
  initialState: initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload
    }
  }
});

export const {
  setOrganization,
} = organizations.actions;


export const getOrganization = (uid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const resp = await api.admin.get(`organization/${uid}`)
      if (resp.avatar) {
        const file = await dispatch(getAvatar(resp.uuid, resp.avatar))
        resp.avatar = toBase64(file.Body.data)
      }
      dispatch(setOrganization(resp))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};


export default organizations.reducer;
