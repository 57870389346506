export const formValidator = (validationFields: any, data: any) => {
  switch (data.key) {
    case 'title':
      validationFields.title = !!data.value
      break
    case 'address':
      validationFields.address = !!data.value
      break
    case 'region':
      validationFields.region = !!data.value
      break
    case 'responsible_person_email':
      validationFields.responsible_person_email = !!data.value
      break
  }

  return validationFields
}
