import React from 'react';
import {PatientInfo} from '../components'
import './styles.scss'

const PendingRequest = () => {
  return (
    <div id='pending-request' className='request-info-container'>
      <PatientInfo/>
    </div>
  )
}

export default PendingRequest
