import Carousel from "@brainhubeu/react-carousel";
import PropTypes from "prop-types";
import React, {useState} from "react";
import "./carousel.css";
import styles from "./SuggestionsList.module.scss";
import leftIcon from "../../../../assets/svgs/smallLeftArrow.svg";
import rightIcon from "../../../../assets/svgs/smallRightArrow.svg";

const SuggestionsList = ({ children, length }) => {

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };
  return (
    <div className={styles.container}>
      <div className={styles.carusel}>
        <Carousel
          draggable
          onChange={handleSelect}
          addArrowClickHandler
          arrowLeft={
            selectedIndex === 0 ? null : <img src={leftIcon} alt="" className={styles.arrow} />
          }
          arrowRight={
            selectedIndex === length - 1 || length < 3 ? null : (
              <img src={rightIcon} alt="" className={styles.arrow} />
            )
          }
          slidesPerPage={3}
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};

SuggestionsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuggestionsList;
