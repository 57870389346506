import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";

const initialState = {
  diagnosis: [],
  query: ''
}

const diagnosis = createSlice({
  name: 'diagnosis',
  initialState: initialState,
  reducers: {
    setDiagnosis:(state, action)=>{
      state.diagnosis = action.payload
      return state
    },
    handleSearchChange: (state, action: PayloadAction<{value: string}>) => {
      state.query = action.payload.value
    }
  }
});

export const {
  setDiagnosis,
  handleSearchChange
} = diagnosis.actions;


export const getDiagnosis = (q) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const resp = await api.admin.get(`diagnosis`, { q })
      dispatch(setDiagnosis(resp))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};


export default diagnosis.reducer;
