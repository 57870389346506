import React, { FC } from 'react'

interface Props {
  text: string,
  color: string
}

const PartnershipStatusText: FC<Props> = (props) => {
  const style = {
    color: props.color
  };
  return (
    <div className=''>
      <p title={props.text} style={style}>{props.text}</p>
    </div>
  )
}

export default PartnershipStatusText
