import {ValidationData, ValidationFields} from "./IRequests"
import {validateLength} from "../../services/globalValidators";
import moment from "moment";
import { changeDateFormat } from "../../services/utils";

const removeSybmolsRegEx = /[^a-zA-Z0-9]/g;

export const formValidator = (validationFields: ValidationFields, data: ValidationData) => {
  switch (data.key) {
    // case 'first_name':
    //   validationFields.first_name = !!data.value
    //   break
    // case 'last_name':
    //   validationFields.last_name = !!data.value
    //   break
    case 'name':
      validationFields.name = !!data.value
      break
    case 'birthday':
      const formattedDate = changeDateFormat(data.value)
      const currentYear = moment().year()
      const selectedYear = parseInt(moment(formattedDate).format('YYYY'))
      validationFields.birthday = data.value && (moment(formattedDate).isValid() && selectedYear <= currentYear && validateLength(formattedDate.replace(removeSybmolsRegEx, ""), 8));
      break
    case 'contact_number':
      validationFields.contact_number = data.value
      break
    // case 'sex':
    //   validationFields.sex = !!data.value
    //   break
    // case 'in_bed':
    //   validationFields.in_bed = !!data.value
    //   break
    case 'type':
      validationFields.type = !!data.value
      break
    // case 'policy_number':
    //   validationFields.policy_number = !!data.value
    //   break
    case 'city':
      validationFields.city = !!data.value
      break
    case 'address':
      validationFields.address = !!data.value
      break
    // case 'floor':
    //   validationFields.floor = !!data.value
    //   break
    // case 'elevator':
    //   validationFields.elevator = !!data.value
    //   break
    // case 'request_reason':
    //   validationFields.request_reason = !!data.value
    //   break
    case 'complaints':
      validationFields.complaints = !!data.value
      break
    case 'scheduled_date':
      validationFields.scheduled_date = !!data.value
      break
    case 'sentry_date_from':
      validationFields.sentry_date_from = !!data.value
      break
    case 'sentry_date_to':
      validationFields.sentry_date_to = !!data.value
      break
  }

  return validationFields
}
