import React, {ReactElement, useEffect, useMemo} from 'react'
import {
  Table,
  TableHead,
  TableBody,
  Search,
  DateRange,
  StatusText,
  PanelHeader,
  PanelPopover,
  Panel
} from '../../../../shared'
import {useAppDispatch, useAppSelector} from '../../../../core/hooks'
import {getActiveTickets, handleFilterChange, loadMoreData} from '../active-tickets-slice'
import './er-active-tickets.scss'
import {setData, toggleMainPanel, togglePopover} from '../../../../app/app-slice'
import {getErSingleRequest} from '../../../requests/er-thunk'
import {ACTIVE_TICKETS_HEAD, ACTIVE_TICKETS_STATUS_NAMES, ER_CALL_STATUS_FILTER} from '../../table-constants'
import styles from "../../styles.module.scss";
import {debounce} from 'lodash'
import Header from "../../../er-request/header";
import Content from "../../../er-request/content";
import {EditBrigadeForm} from "../../../er-request/components";
import moment, {Moment} from 'moment'
import {CSVLink} from "react-csv";
import {ExcelIcon, ExportIcon} from "../../../../assets"
import {useHistory} from "react-router-dom";
import {Autocomplete, Button, Input} from "../../../../ui-components";
import { ExportToExcel } from '../../exportToExcel'

const ErActiveTickets = () => {
  const dispatch = useAppDispatch()
  const {activeTickets, filter, allDataLoaded} = useAppSelector(state => state.activeTickets)
  const app = useAppSelector(state => state.app)
  const history = useHistory()
    const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'В исполнении'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getActiveTickets())
  }, [dispatch, history.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getActiveTickets()), 700),
    [dispatch]
  );

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMore = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMoreData())
  }

  const setStatusText = (status): ReactElement => {
    let statusText: any = ''
    switch (status) {
      case 1:
        statusText = <StatusText color={'blue'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 2:
        statusText = <StatusText color={'blue'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 3:
        statusText = <StatusText color={'orange'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 4:
        statusText = <StatusText color={'green'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 7:
        statusText = <StatusText color={'blue'} text='Ожидает подтверждения'/>
        break
      case 8:
        statusText = <StatusText color={'blue'} text='Ожидает подтверждения'/>
        break
      case 9:
        statusText = <StatusText color={'green'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 10:
        statusText = <StatusText color={'blue'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 11:
        statusText = <StatusText color={'gray'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 13:
        statusText = <StatusText color={'orange'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 16:
        statusText = <StatusText color={'blue'} text={'Ожидает подтверждения'}/>
        break
      case 19:
        statusText = <StatusText color={'orange'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 20:
        statusText = <StatusText color={'orange'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
      case 21:
        statusText = <StatusText color={'orange'} text={ACTIVE_TICKETS_STATUS_NAMES[status - 1]}/>
        break
    }
    return statusText
  }

  const headers = [
    {label: "Номер карточки", key: "number"},
    {label: "ФИО", key: "name"},
    {label: "Д/В", key: "age"},
    {label: "Полис", key: "policy_number"},
    {label: "Дата", key: "created_date"},
    {label: "Длительность прибытия", key: "arrival_time"},
    {label: "Жалобы", key: "complaints"},
    {label: "Результат", key: "status"},
    {label: "Название СК", key: "org_name"}
  ];


  const xlsxHeaders = [
    "Номер карточки",
    "ФИО",
    "Д/В",
    "Полис",
    "Дата",
    "Длительность прибытия",
    "Жалобы",
    "Результат",
    "Название СК"
  ];

  const filtered = [] as any
  const csv = [] as any
  const xlsx = [] as any
  const uuids = [] as any
  for (let ticket of activeTickets) {
    uuids.push(ticket.request_uuid)
    filtered.push({
      number: ticket.number,
      name: ticket.name,
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${ticket.arrival_time}`,
      complaints: ticket.complaints,
      status: setStatusText(ticket.status),
      org_name: ticket.org_name
    })
    csv.push({
      number: ticket.number,
      name: ticket.name || '',
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${ticket.arrival_time}`,
      complaints: ticket.complaints || '',
      status: ACTIVE_TICKETS_STATUS_NAMES[ticket.status - 1],
      org_name: ticket.org_name
    })
    xlsx.push([
      ticket.number,
      ticket.name || '',
      ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      ticket.policy_number,
      moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      `${ticket.arrival_time}`,
      ticket.complaints || '',
      ACTIVE_TICKETS_STATUS_NAMES[ticket.status - 1],
      ticket.org_name
    ])
  }

  const csvReport = {
    data: csv,
    headers: headers,
    filename: 'В исполнении.csv'
  };

  const bodyRow = filtered.map((el, i) => Object.values(el))
  return (
    <div id={styles.activeTicketsList}>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange('q', e.target.value)
          debouncedChangeHandler()
        }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getActiveTickets())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getActiveTickets())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <Input label='Возраст'
                 className='ml-20'
                 onChange={e => {
                   filterChange('data[age]', e.target.value)
                   debouncedChangeHandler()
                 }} value={filter.data!.age}/>
          <Autocomplete label='Статус'
                        isClearable
                        onChange={(data) => {
                          dispatch(filterChange('data[status]', data.key))
                          dispatch(getActiveTickets())
                        }}
                        value={filter.data!.status}
                        className={`${styles.selectWrapper} ml-20 mr-20`}
                        options={ER_CALL_STATUS_FILTER}
                        optionLabel={'value'} optionValue={'key'}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'В исполнении СМП', xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {ACTIVE_TICKETS_HEAD.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filtered.map((el, i) =>
              <div onClick={() => dispatch(getErSingleRequest(uuids[i]))} key={i} className='row'>
                {
                  bodyRow[i].map((el, index) =>
                    <div key={index} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMore} className={styles.loadMoreBtn}>Загрузить еще</Button>}
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        <Content/>
        <PanelPopover open={app.popoverFormStatuses.editBrigadeFormOpen}
                      title={'Редактировать бригаду'}
                      handleClose={() => dispatch(togglePopover('editBrigadeFormOpen'))}>
          <EditBrigadeForm/>
        </PanelPopover>
      </Panel>
    </div>
  )
}

export default ErActiveTickets
