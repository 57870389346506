import React, {useEffect, useMemo} from 'react'
import {Table, TableHead, TableBody, PanelHeader, Panel, Search, DateRange, Diagnose} from '../../../shared'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import Header from '../../insurance-request/header'
import Content from '../../insurance-request/content'
import {getCanceled, handleFilterChange, loadMoreData} from './insurance-cenceled-slice'
import {setData, toggleMainPanel} from '../../../app/app-slice'
import {getSingleRequest} from '../../requests/insurance-thunk'
import './insurance-canceled.scss'
import {
  CANCELED_TICKETS_STATUS_NAMES,
  CANCELED_HISTORY_HEAD
} from '../table-constants'
import {
  REQUEST_STATUSES
} from '../../../services/constants'
import styles from "../styles.module.scss";
import {debounce} from 'lodash'
import {ExcelIcon, ExportIcon} from '../../../assets'
import moment, {Moment} from 'moment'
import {CSVLink} from "react-csv";
import {useHistory} from "react-router-dom";
import {Button, Input} from "../../../ui-components";
import { ExportToExcel } from '../exportToExcel'

const InsuranceCanceled = () => {
  const dispatch = useAppDispatch()
  const {canceled, filter, allDataLoaded} = useAppSelector(state => state.canceled)
  const app = useAppSelector(state => state.app)
  const canceledNav = useHistory()
    const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Отмененые'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getCanceled())
  }, [dispatch, canceledNav.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getCanceled()), 700),
    [dispatch]
  );

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMore = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMoreData())
  }

  const headers = [
    {label: "Номер карточки", key: "number"},
    {label: "ФИО", key: "name"},
    {label: "Д/В", key: "age"},
    {label: "Полис", key: "policy_number"},
    {label: "Дата", key: "created_date"},
    {label: "Длительность прибытия", key: "arrival_time"},
    {label: "Длительность вызова", key: "end_time"},
    {label: "Диагноз", key: "diagnosis"},
    {label: "Результат", key: "status"},
    {label: "Комментарии", key: "comment"},
    {label: "Время отзвона", key: "end_date"},
    {label: "Название СМП", key: "org_name"},
    {label: "Причина отмены", key: "reject_reason"},
  ];

  const xlsxHeaders = [
    "Номер карточки",
    "ФИО",
    "Д/В",
    "Полис",
    "Дата",
    "Длительность прибытия",
    "Длительность вызова",
    "Диагноз",
    "Результат",
    "Комментарии",
    "Время отзвона",
    "Название СМП",
    "Причина отмены"
  ];

  const filteredCanceled = [] as any
  const csv = [] as any
  const xlsx = [] as any
  const uuids = [] as any
  for (let c of canceled) {
    uuids.push(c.request_uuid)
    filteredCanceled.push({
      number: c.number,
      name: c.name,
      age: c.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: c.policy_number,
      created_date: moment(c.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${c.arrival_time}`,
      end_time: `${c.end_time}`,
      diagnosis: c.diagnosis,
      status: <p className={styles.statusText} title={CANCELED_TICKETS_STATUS_NAMES[c.status - 1]}>
        {CANCELED_TICKETS_STATUS_NAMES[c.status - 1]}
      </p>,
      comment: c.comment,
      end_date: c.end_date ? moment(c.end_date).format('DD/MM/YYYY HH:mm') : '-',
      org_name: c.org_name,
      reject_reason: c.reject_reason
    })
    csv.push({
      number: c.number,
      name: c.name,
      age: c.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: c.policy_number,
      created_date: moment(c.created_date).format('DD/MM/YYYY HH:mm'),
      arrival_time: `${c.arrival_time}`,
      end_time: `${c.end_time}` || '',
      diagnosis: c.diagnosis,
      status: REQUEST_STATUSES[c.status - 1],
      comment: c.comment,
      end_date: c.end_date ? moment(c.end_date).format('DD/MM/YYYY HH:mm') : '-',
      org_name: c.org_name,
      reject_reason: c.reject_reason
    })
    xlsx.push([
      c.number,
      c.name,
      c.age < 18 ? 'Д(0-18)' : 'В(18+)',
      c.policy_number,
      moment(c.created_date).format('DD/MM/YYYY HH:mm'),
      `${c.arrival_time}`,
      `${c.end_time}` || '',
      c.diagnosis,
      REQUEST_STATUSES[c.status - 1],
      c.comment,
      c.end_date ? moment(c.end_date).format('DD/MM/YYYY HH:mm') : '-',
      c.org_name,
      c.reject_reason
    ])
  }

  const csvReport = {
    data: csv,
    headers: headers,
    filename: 'Отмененые.csv'
  };

  const bodyRow = filteredCanceled.map((el, i) => Object.values(el))
  return (
    <div id={styles.historyList}>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange('q', e.target.value)
          debouncedChangeHandler()
        }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getCanceled())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getCanceled())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <Input label='Возраст'
                 className="ml-20"
                 onChange={e => {
                   filterChange('data[age]', e.target.value)
                   debouncedChangeHandler()
                 }} value={filter.data!.age}/>
          <Diagnose value={filter.data!.diagnosis}
                    className="ml-20 mr-20"
                    handleChange={(value) => {
                      dispatch(filterChange('data[diagnosis]', value))
                      dispatch(getCanceled())
                    }}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'Отмененые', xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {CANCELED_HISTORY_HEAD.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filteredCanceled.map((el, i) =>
              <div key={i} onClick={() => dispatch(getSingleRequest(uuids[i]))} className='row'>
                {
                  bodyRow[i].map((el, index) =>
                    <div key={index} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMore} className={styles.loadMoreBtn}>Загрузить еще</Button>}
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        <Content/>
      </Panel>
    </div>
  )
}

export default InsuranceCanceled
