import React, { FC } from 'react'
import {ErrorIconGradient} from "../../assets";
import ReactTooltip from "react-tooltip";
import styles from './styles.module.scss'

interface Props {
  dataFor: string
  text: string
  className?: string
  onClick?: Function
}

const ProblemTooltip: FC<Props> = (props) => {
  return (
    <div onClick={() => props.onClick && props.onClick()} className={`${styles.problemTooltip} ${props.className ? props.className : ''}`}>
      <ErrorIconGradient className={styles.icon} data-tip data-for={props.dataFor}/>
      <ReactTooltip id={props.dataFor}
                    offset={{left: 22, top: 12}}
                    className={styles.tooltip} place='right' arrowColor='transparent' effect="solid">
        <span className={styles.text}>{props.text}</span>
      </ReactTooltip>
    </div>
  )
}

export default ProblemTooltip
