

export const formValidator = (validationFields: any, data: any) => {
  switch (data.key) {
    case 'first_name':
      validationFields.first_name = !!data.value
      break
    case 'last_name':
      validationFields.last_name = !!data.value
      break
    case 'middle_name':
      validationFields.middle_name = !!data.value
      break
    case 'email':
      validationFields.email = !!data.value
      break
    case 'primary_phone':
      validationFields.primary_phone = !!data.value
      break
  }

  return validationFields
}
