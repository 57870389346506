import React, {useEffect} from 'react'
import './panels.scss'
import {getActivities} from "../requests/requests-slice";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setDate} from "../../services/utils";

const HistoryPanel = () => {
  const {activities} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getActivities())
  }, [dispatch])

  return (
    <div id='history-panel'>
      {
        activities?.map((el, i) => {
          const offers: any = [];
          if (el.data.pools) {
            for (const offer of el.data.pools) {
              if(offer.status >= 2) {
                const brigades = offer.request_brigade.map((brigade) => {
                  return {...brigade, orgName: offer.organization.name}
                })
                offers.push(...brigades)
              }
            }
          }
          return <div key={i} className="text-wrapper">
            <p className="text">{el.message}</p>
            {offers.length > 0 && <div className="article-block">
              {offers?.map((element, i) => {
                return <article className={`${element.status === 2 ? 'selected' : ''}`} key={i}>
                  <div className="header">
                    <p className="title">{element.orgName}</p>
                    {element.status === 2 && <p className="selected">Выбрана</p>}
                  </div>
                  <div className="footer">
                    <p className="text">Приоритет: {el.data.priority}</p>
                    <p className="text">Бригада: {element.brigade_type}</p>
                    <p className="text">Время доезда: {element.eta} мин.</p>
                  </div>
                </article>
              })}
            </div>}
            <div className={'flex-row'}>
              {el.data?.priority && !el.data.pools && <p className={'priority mr-20'}>Приоритет: {el.data.priority}</p>}
              {el.data?.diagnosis && <p className={'priority mr-20'}>Диагноз: {el.data.diagnosis}</p>}
              {el.data?.diagnosis_details && <p className={'priority mr-20'}>Комментарий: {el.data.diagnosis_details}</p>}
              {el.data?.hospital_name && <p className={'priority mr-20'}>Стационар: {el.data.hospital_name}</p>}
              {el.data?.hospital_address && <p className={'priority mr-20'}>Адрес: {el.data.hospital_address}</p>}
              {el.data?.change_reason && <p className={'priority mr-20'}>Причина: {el.data.change_reason}</p>}
              {el.data?.change_comment && <p className={'priority mr-20'}>Комментарий: {el.data.change_comment}</p>}
              {el.data?.reject_reason && <p className={'priority mr-20'}>Комментарий: {el.data.reject_reason}</p>}
              {el.data?.hospital_comment && <p className={'priority mr-20'}>Причина замены: {el.data.hospital_comment}</p>}
              <p className="date">{setDate(el.updated_at)}</p>
            </div>
          </div>
        })
      }
    </div>
  )
}

export default HistoryPanel
