import React, { FC } from 'react'
import styles from './status-text.module.scss'
import {ArrowRightIcon} from "../../assets";

interface Props {
  color: 'gray' | 'blue' | 'green' | 'orange'
  text: string
}

const StatusText: FC<Props> = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.circle} ${styles[props.color]}`} />
      <p title={props.text} className={styles.text}>{props.text}</p>
    </div>
  )
}

export default StatusText
