import _pick from "lodash/pick";

export interface ValidationData {
  key: string,
  value: any
}

const WEBSITE_PATTERN: RegExp = /^(www\.)[a-zA-Z0-9 |.-/]+\.[a-zA-Z| /]+(\/[a-zA-Z0-9#]+\/?)*$/
const EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/
const PASSWORD_PATTERN: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
export const REMOVE_SYMBOLS_PATTERN: RegExp = /[^a-zA-Z0-9]/g;

// regexp pattern email validator
export const validateEmail = (data: string): boolean => {
  return EMAIL_PATTERN.test(data);
}

// string length validation by equation types
export const validateLength = (data: string, length: number, equationType: string = '='): boolean => {
  let valid: boolean

  switch (equationType) {
    case '=':
      valid = data.trim().length === length
      break
    case '<':
      valid = data.trim().length < length
      break
    case '>':
      valid = data.trim().length > length
      break
    default:
      valid = false;
  }
  return valid
}

// regexp pattern url validator
export const validateUrl = (data: string): boolean => {
  return WEBSITE_PATTERN.test(data)
}

// check if user has set up both name and surname
export const validateFullName = (data: string): boolean => {
  const splitted = data.split(' ').filter(el => el !== '');
  const l = splitted[1] && splitted[1].length > 1 ? splitted[1]?.trim() : true;

  return !(!l || splitted?.length < 2 || data.replace(/\s/g, '').length < 4)
}

// validate password to match given pattern
export const validatePassword = (data: string): boolean => PASSWORD_PATTERN.test(data)

// validate input group fields by given array properties
export const inputGroupValidator = (inputsGroup, data): boolean => {
  const fields = _pick(data, inputsGroup);
  let filledData = 0;

  for (let key in fields) {
    if (!fields[key]) {
      filledData--
    } else {
      filledData++
    }
  }

  return filledData === inputsGroup.length || filledData === -inputsGroup.length
}

// global inputs validator for whole app
export const inputsValidator = (data: Array<ValidationData> | ValidationData, fields, validator: Function): { [key: string]: boolean } => {
  if (Array.isArray(data)) {
    for (const elem of data) {
      fields = validator(fields, elem)
    }
  } else {
    fields = validator(fields, data)
  }
  return fields;
}

// check if form's validation data is valid
export const isFormDataValid = (data) => {
 return  Object.keys(data).every(k => data[k] !== false)
}
