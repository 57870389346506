import React, {Fragment, useEffect, useState} from 'react'
import {
  checkCustomerPublicToken,
  checkPublicToken,
  declineCustomerOffer,
  declineOffer
} from '../requests/requests-slice'
import { useAppDispatch, useQuery } from '../../core/hooks'
import { useLocation } from 'react-router-dom'

const BookingDecline = () => {
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)

  const location = useLocation()
  const isCustomer = location.pathname === '/customer-booking-reserve-decline'
  const query = useQuery();
  const token = query.get('token');
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isCustomer) {
      dispatch(checkPublicToken(token as string))
        .then(res => {
          if(res) {
            dispatch(declineOffer(token as string))
              .then()
              .catch(error => {
                setTokenExpired(false)
              })
          } else {
            setTokenExpired(true)
          }
        })
        .catch(error => {
          setTokenExpired(true)
        })
    } else {
      dispatch(checkCustomerPublicToken(token as string))
        .then(res => {
          if(res) {
            dispatch(declineCustomerOffer(token as string))
              .then()
              .catch(error => {
                setTokenExpired(false)
              })
          } else {
            setTokenExpired(true)
          }
        })
        .catch(error => {
          setTokenExpired(true)
        })
    }
  }, [dispatch, token])

  return (
    <div id='booking-decline-container' className='form-container'>
      {
        !tokenExpired ?
          <Fragment>
            <p className='description'>Уважаемый партнёр,</p>
            <p className='description mb-5'>Спасибо за ответ.</p>
            <p className='description'>Ваше отклонение было передано стрхавой компании.</p>
          </Fragment> :
          <Fragment>
            <p className='description'>Уважаемый партнёр,</p>
            <p className='description'>Запрос бронирование был отменен</p>
          </Fragment>
        }
    </div>
  )
}

export default BookingDecline
