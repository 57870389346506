import React, {FC} from 'react'
import ReactTooltip from "react-tooltip";
import styles from './styles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {changeErCallStatus, erReject, replaceHospital} from "../../requests/er-thunk";
import {ER_CALL_STATUSES, STATUS_LIST} from '../../../services/constants'
import {Modal} from '../../../shared';
import {Button, Input} from "../../../ui-components";

interface Props {
  id: string
  className?: string
  offsetLeft?: number
  offsetTop?: number
}

const ActionsTooltip: FC<Props> = (props) => {
  const {er_call, pool} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  const delayActionActive = (er_call.status === ER_CALL_STATUSES.onRoute || er_call.status === ER_CALL_STATUSES.onRouteDelay || er_call.status === ER_CALL_STATUSES.onRouteToHospital || er_call.status === ER_CALL_STATUSES.onRouteToHospitalDelay)
  const delayBtnDisabled = er_call.status !== ER_CALL_STATUSES.onRoute && er_call.status !== ER_CALL_STATUSES.onRouteDelay && er_call.status !== ER_CALL_STATUSES.onRouteToHospital && er_call.status !== ER_CALL_STATUSES.onRouteToHospitalDelay

  const actionList = [
    {
      key: 1,
      value: 'Задержка',
      action: () => delayActionActive && dispatch(togglePopover('delayFormOpen')),
      disabled: delayBtnDisabled
    },
    {
      key: 2,
      value: 'Замена борта',
      action: () => (er_call.status === ER_CALL_STATUSES.onRoute || er_call.status === ER_CALL_STATUSES.onRouteToHospital) && dispatch(togglePopover('replaceBrigadeFormOpen')),
      disabled: er_call.status !== ER_CALL_STATUSES.onRoute && er_call.status !== ER_CALL_STATUSES.onRouteToHospital
    },
    {
      key: 3,
      value: 'Требуется замена СМП',
      action: () => dispatch(togglePopover('replaceErFormOpen')),
      disabled: false
    },
    {
      key: 4,
      value: 'Вызов на себя',
      action: () => pool.request_brigade.length < 2 && dispatch(togglePopover('additionalBrigadeFormOpen')),
      disabled: pool.request_brigade.length > 1,
    },
    {
      key: 5,
      value: 'Замена госпиталя',
      action: () => er_call.status >= ER_CALL_STATUSES.onRouteToHospital && dispatch(togglePopover('replaceHospitalFormOpen')),
      disabled: er_call.status < ER_CALL_STATUSES.onRouteToHospital
    }
  ]

  return (
    <ReactTooltip arrowColor="transparent"
                  globalEventOff='click'
                  offset={{left: props.offsetLeft || 0, top: props.offsetTop || 0}}
                  className={`${styles.actionsTooltip} ${props.className ? props.className : ''}`}
                  id={props.id} place='top' effect='solid' clickable>
      <div className={styles.header}>
        {/*<p className={styles.actionsTitle}>Статусы</p>*/}
        <p className={styles.actionsTitle}>Действия</p>
      </div>
      <div className='d-flex'>
        {/*<div className={styles.list}>
          {STATUS_LIST.map((el, i) =>
            <p key={i}
               onClick={() => dispatch(changeErCallStatus(el.key))}
               className={`${styles.item} ${styles.disabled} ${el.key === er_call.status ? styles.disabled : ''}`}>{el.value}</p>)}
        </div>*/}
        <div className={styles.list}>
          {actionList.map((el, i) =>
            <p key={i}
               onClick={() => el.action()}
               className={`${styles.item} ${el.disabled ? styles.disabled : ''}`}>{el.value}</p>)}
        </div>
      </div>
    </ReactTooltip>
  )
}

export default ActionsTooltip
