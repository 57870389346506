import React, {Fragment, useEffect, useState} from 'react'
import {
  AmbulanceIcon,
  DashboardIcon,
  Logo,
  LogoSmall,
  PlusIcon,
  ShieldIcon,
  InsuranceIcon,
  DiagnosesIcon,
  HospitalsIcon,
  RootLogo
} from '../../assets'
import {Button} from "../../ui-components";
import {toggleRequestForm} from "../../app/app-slice";
import {Link} from "react-router-dom";
import {NavbarTooltip} from "./navbar-tooltip";


const adminNavBar = ({user, app, pathname}) => {
  return(
    <Fragment>
      <RootLogo className='type-icon'/>
      {!app.navbarMinimized ? <Logo className='logo'/> : <LogoSmall className='logo'/>}
      <div className="divider"/>
      <ul className='list-open'>
        <Link className={`item ${pathname.includes('/admin-center') && 'active'}`} to='/admin-center'>
          <span data-tip data-for="nav" className='icon'>
                <ShieldIcon />
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Скорая помощь'/>}
          {!app.navbarMinimized && <p>Центральный</p>}
        </Link>
        <Link className={`item ${pathname.includes('/emergency') && 'active'}`} to='/emergency'>
          <span data-tip data-for="nav" className='icon'>
                <AmbulanceIcon />
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Скорая помощь'/>}
          {!app.navbarMinimized && <p>Скорая помощь</p>}
        </Link>
        <Link className={`item ${pathname.includes('/insurance') && 'active'}`} to='/insurance'>
          <span data-tip data-for="nav" className='icon'>
              <InsuranceIcon/>
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Страховая'/>}
          {!app.navbarMinimized && <p>Страховая</p>}
        </Link>
        <Link className={`item ${pathname.includes('/diagnosis') && 'active'}`} to='/diagnosis'>
          <span data-tip data-for="nav" className='icon'>
              <DiagnosesIcon/>
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Диагнозы'/>}
          {!app.navbarMinimized && <p>Диагнозы</p>}
        </Link>
        <Link className={`item ${pathname.includes('/hospitals') && 'active'}`} to='/hospitals'>
          <span data-tip data-for="nav" className='icon'>
              <HospitalsIcon/>
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Больницы'/>}
          {!app.navbarMinimized && <p>Больницы</p>}
        </Link>
        <Link className={`item ${pathname.includes('/reasons') && 'active'}`} to='/reasons'>
          <span data-tip data-for="nav" className='icon'>
              <HospitalsIcon/>
          </span>
          {app.navbarMinimized && <NavbarTooltip id='nav4' text='Поводы'/>}
          {!app.navbarMinimized && <p>Поводы</p>}
        </Link>
      </ul>
    </Fragment>
  )

}

export default adminNavBar
