import React, {Fragment, useContext, useEffect, useRef, useState} from 'react'
import {AttachIcon, FileIcon} from '../../../assets';
import {PanelFooter} from "../../../shared";
import {Input} from '../../../ui-components';
import './panels.scss'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {
  customerMarkAsRead,
  getCustomerFile,
  getCustomerMessages,
  resetCommentsCount,
  sendCustomerFile,
  sendCustomerMessage,
} from "../../requests/requests-slice";
import {prepareFileUpload, setDate} from "../../../services/utils";
import cx from "classnames"
import SocketContext from "../../../core/socket-context";
const CustomerCommentsPanel = ({isSentry, isDc=false}) => {
  const {messages, request, er_call} = useAppSelector(state => state.requests)
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext);
  const [message, setMessage] = useState<string>('')
  const commentsRef = useRef(null)

  useEffect(() => {
    dispatch(getCustomerMessages())
    return () => {
      dispatch(resetCommentsCount(request.uuid))
      dispatch(customerMarkAsRead())
    }
  }, [dispatch, request])

  useEffect(() => {
    const container: any = commentsRef.current
    container.scrollTop = container.scrollHeight
  }, [messages])

  useEffect(() => {
    socket.on('customer_new_comment', (e) => {
      dispatch(getCustomerMessages())
    })
  }, [])

  const fileUpload = (e) => {
    const data = prepareFileUpload(e)
    dispatch(sendCustomerFile(data))
      .then(res => {
        const container: any = commentsRef.current
        if (res) {
          container.scrollTop = container.scrollHeight
        }
      })
  }

  const send = (e) => {
    if (e.which === 13 && e.target.value.trim()) {
      const container: any = commentsRef.current
      dispatch(sendCustomerMessage(message))
        .then(res => {
          if (res) {
            container.scrollTop = container.scrollHeight
          }
        })
      setMessage('')
    }
  }

  return (
    <Fragment>
      <div id='comment-panel' className={isDc ? "margin" : ""} ref={commentsRef}>
        {
          messages?.map((el, i) => {
            const sender = el.user_uuid === user.uuid ? 'Вы' : `Оператор ${el.first_name}`;
            const isRead = el.read?.includes(user.id) || el.user_uuid === user.uuid

            return (
              <div key={i} className={`comment-wrapper ${!isRead ? 'new' : ''}`}>
                <div className="heading">
                  <p className="sender">{sender}</p>
                  <p className="date">{setDate(el.updated_at)}</p>
                </div>
                <div className="message">
                  {el.message ? el.message :
                    <p className='attachment' onClick={() => dispatch(getCustomerFile(el.document_meta.fileName, el.document_meta.originalName))}>
                      <FileIcon/>{el.document_meta.originalName}</p>}
                </div>
              </div>)
          })
        }
      </div>
      { er_call && request && request.status < 4 && <PanelFooter className={cx('comments-footer',isSentry &&  "isSentry" ) }>
        <Input value={message}
               autofocus={!isSentry}
               className='text-input'
               onChange={e => setMessage(e.target.value)}
               onKeyUp={e => send(e)}
               placeholder='Оставить комментарий...'/>
        <label className='upload-icon' htmlFor='upload'>
          <AttachIcon/>
          <input name="file" type="file" id='upload' onChange={e => fileUpload(e)}/>
        </label>
      </PanelFooter>}
    </Fragment>
  )
}

export default CustomerCommentsPanel
