export const INSURANCE_CONNECTION_STATUSES = [
  {
    key: 1,
    value: 'Ожидает подтверждения'
  },
  {
    key: 2,
    value: 'Партнер'
  },
  {
    key: 4,
    value: 'Приостановлено'
  }
]

