import React, {useEffect, useState, Fragment} from 'react'
import {Button, Input} from "../../ui-components";
import './styles.scss'
import { useAppDispatch, useAppSelector, useQuery } from '../../core/hooks'
import {formValidator, ValidationFields} from '../../shared/validators/validator';
import {inputsValidator, isFormDataValid, validatePassword} from "../../services/globalValidators";
import {activateUser, checkToken} from '../user/user-slice';
import {useHistory} from 'react-router-dom';
import { setApiError } from '../../admin/app-slice'
import { Modal } from '../../shared'


const Activation = () => {
  const [password, setPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [fields, setFields] = useState<object>({})
  const [validationFields, setValidationFields] = useState<ValidationFields>({password: true, repeatPassword: true})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean>(false)
  const [tokenExpireError, setTokenExpireError] = useState<boolean>(false)
  const [goToLoginModal, setGoToLoginModal] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const query = useQuery();
  const token = query.get('token');
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(checkToken(token as string, true))
      .then(res => {
        if (res?.user?.email) {
          setEmail(res.user.email)
        } else if (res.data && res.data.error_id && res.data.error_id === 2) {
          setTokenExpireError(true)
        } else if (res.data && res.data.error_id && res.data.error_id === 27) {
          setGoToLoginModal(true)
        }
      })
  }, [dispatch, token])

  const validateForm = (p, passwordType) => {
    setErrorMessage('')
    if (passwordType === 'password') {
      setPassword(p)
    } else {
      setRepeatPassword(p)
    }
    setErrorMessage('')
    const data = [
      {
        key: 'password',
        value: password,
      },
      {
        key: 'repeatPassword',
        value: repeatPassword,
      },
    ]

    setFields(inputsValidator(data, validationFields, formValidator))
  }

  const submitForm = () => {
    setFormValid(isFormDataValid(fields) && password === repeatPassword && validatePassword(password))
    setValidationFields({...fields})
    if (repeatPassword !== password && isFormDataValid(fields)) {
      setErrorMessage('*Пароли не совпадают.')
    } else if(!validatePassword(password)) {
      setErrorMessage('*Пароль не соответствует критериям.')
    } else if (!password || !repeatPassword) {
      setErrorMessage('*Заполните все поля.')
    }

    if (isFormDataValid(fields) && password === repeatPassword && token && validatePassword(password)) {

      dispatch(activateUser(token, password))
        .then(res => res && history.push('/dashboard'))
        .catch(error => {
          if(error.code === 403) {
            history.push('/login')
          }
          setFormValid(false)
          setErrorMessage(error.text)
        })
    }
  }

  return (
    <div id='activation-container' className='form-container'>
      <p className='title'>Активация</p>
      { !tokenExpireError ?
        <Fragment>
          <p className='description'>Добро пожаловать <strong>{email}</strong>, чтобы войти в систему установите пароль.</p>
          <p className='sub-description'>Пароль должен содержать как минимум 8 символов, одну цифру, одну заглавную и одну строчную букву.</p>
          <Input label={'Новый пароль'} value={password}
                 onChange={(e) => validateForm(e.target.value, 'password')}
                 onKeyUp={(e) => e.which === 13 && submitForm()}
                 error={!validationFields.password}
                 inputType='password' className='mb-30'/>
          <Input label={'Повторить  пароль'} value={repeatPassword}
                 onChange={(e) => validateForm(e.target.value, 'repeatPassword')}
                 onKeyUp={(e) => e.which === 13 && submitForm()}
                 error={!validationFields.repeatPassword || (!formValid && password !== repeatPassword)}
                 inputType='password' className='mb-50'/>
          <Button color='gradient' onClick={submitForm} className='button' type='contained'>Активация</Button>
          {errorMessage && <p className='text-error'>{errorMessage}</p>}
        </Fragment>
        : <Fragment>
          <p className={'description mb-15'}>Срок действия ссылки активации истек.</p>
          <p className={'description'}>Детали можете уточнить с администратором.</p>
        </Fragment>}
      <Modal open={goToLoginModal}
             closeIcon
             title={'Ваша учетная запись уже активирована'}
             handleClose={() => {history.push('/login')}}>
        <p className="modalText mb-50">Нажмите на кнопку чтобы войти в систему</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => history.push('/login')}>Вход в систему</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Activation
