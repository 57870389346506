import React, {useState} from 'react'
import styles from './styles.module.scss'
import InputMask from 'react-input-mask'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {brigadeTypes} from "../../../services/constants";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {editBrigade, editCustomerBrigade} from "../../requests/er-thunk";

const EditBrigadeForm = () => {
  const {pool} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const mainBrigade = pool.request_brigade.find(el => el.status !== 3)

  const [brigade_type, setBrigadeType] = useState<string>(mainBrigade.brigade_type)
  const [brigade_responsible, setBrigadeResponsible] = useState<string>(mainBrigade.brigade_responsible)
  const [brigade_number, setBrigadeNumber] = useState<string>(mainBrigade.brigade_number)
  const [eta, setEta] = useState<number>(mainBrigade.eta)
  const {user} = useAppSelector(state => state.user)
  // edit brigade
  const edit = (): void => {
    if (user && user.organization.type === 2 && brigade_type && brigade_responsible && brigade_number && eta) {
      dispatch(editCustomerBrigade(brigade_type, brigade_responsible, brigade_number, eta))
    } else if(brigade_type && brigade_responsible && brigade_number && eta) {
      dispatch(editBrigade(brigade_type, brigade_responsible, brigade_number, eta))
    }
  }

  return (
    <div id={styles.replaceBrigadeForm}>
      <div className={`${styles.wrapper} mb-40`}>
        <Autocomplete
          className={styles.input}
          options={brigadeTypes}
          value={brigade_type}
          onChange={(data) => setBrigadeType(data.value)}
          label={'Выбрать тип бригады'}
          optionLabel={'value'}
          optionValue={'value'}/>
        <Input className={styles.input}
               onChange={e => setBrigadeResponsible(e.target.value)}
               value={brigade_responsible}
               label='Ответственный бригады)'/>
      </div>
      <div className={`${styles.wrapper} mb-40`}>
        <Input label='Номер бригады'
               onChange={e => setBrigadeNumber(e.target.value)}
               onKeyUp={(e) => e.which === 13 && edit()}
               className={styles.input} value={brigade_number}/>
        <InputMask mask="999 минут" maskChar=" "
                   className={`${styles.input}`}
                   onKeyUp={(e) => e.which === 13 && edit()}
                   label='Время доезда'
                   onChange={e => setEta(parseInt(e?.target.value))}
                   value={eta}>
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      </div>
      <Button fullWidth
              disabled={!brigade_type || !brigade_responsible || !brigade_number || !eta}
              onClick={edit}
              type={'contained'}>Сохранить изменения</Button>
    </div>
  )
}

export default EditBrigadeForm
