import React, { Fragment, useEffect, useState } from 'react'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import { TableBody, TableHead, Table, Panel, PanelHeader, PanelFooter, Modal, Search } from '../../shared'
import { CloseIcon, EditIcon, PlusIcon } from '../../assets'
import './hospitals.scss'
import { Autocomplete, Button } from '../../ui-components'
import { togglePopover } from '../../admin/app-slice'
import {
  clearForm,
  createHospital,
  setHospital,
  updateHospital,
  deleteHospital,
  validateInputs,
  getHospitals,
  selectHospital,
  getCities
} from './hospitals-slice'
import HospitalForm from './hospital-form'
import { CITIES } from '../../services/constants'
const head = ['ID', 'Название', 'Адрес', ' ']


const Hospitals = () => {
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.adminApp)
  const {hospitals, form, hospital} = useAppSelector(state => state.adminHospitals)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [hospitalsList, setHospitalsList] = useState<any[]>([])
  const [selectedRegion, setSelectedRegion] = useState<string>('')
  useEffect(() => {
    dispatch(getHospitals())
      .then(res => {
        if(res && res.length) {
          setHospitalsList(res)
          setSelectedRegion(CITIES[0].value)
        }
      })
  }, [dispatch])


  const submitForm = () => {

    for (const k in form) {
      dispatch(validateInputs({
        key: k,
        value: form[k]
      }))
    }
    if (hospital) {
      dispatch(updateHospital())
        .then((res) => {
          if (res && res.updated) {
            dispatch(getHospitals())
              .then(res => {
                if (res && res.length) {
                  setHospitalsList(res)
                }
              })
          }
        })
    } else {
      dispatch(createHospital())
        .then((res) => {
          if (res) {
            dispatch(getHospitals())
              .then(res => {
                if(res && res.length) {
                  setHospitalsList(res)
                }
              })
          }
        })
    }
  }

  const closePopover = async () => {
    await dispatch(togglePopover('adminAddHospital'))
    dispatch(clearForm())
    dispatch(setHospital(null))
  }

  const deleteSingleHospital = async (hospital) => {
    dispatch(clearForm())
    dispatch(setHospital(null))
    await dispatch(deleteHospital(hospital.uuid))
    setDeleteModalOpen(false)
    dispatch(togglePopover('adminAddHospital'))
    dispatch(getHospitals())
      .then(res => {
        if (res && res.length) {
          setHospitalsList(res)
        }
      })
  }

  const handleSelectRegion = (key) => {
    setSelectedRegion(CITIES[key - 1].value)
    dispatch(getHospitals(key === 1 ? undefined : key))
      .then(res => {
        if(res && res.length) {
          setHospitalsList(res)
        }
      })
  }

  const rows = hospitalsList.map((val: any) => {
    return (
      <div className='row' key={val.id}
           onClick={() => {
             dispatch(selectHospital(val))
             dispatch(togglePopover('adminAddHospital'))
           }}>
        <div className='item'>{val.id}</div>
        <div className='item title' title={val.title}>{val.title}</div>
        <div className='item title' title={val.address}>{val.address}</div>
        <div className='item'>
          <EditIcon/>
        </div>
      </div>
    )
  })

  const popoverTitle = hospital ? 'Сохранить' : 'Создать'

  const filterChange = async (value: string) => {
    if (value) {
      setSearchQuery(value)
      // @ts-ignore
      setHospitalsList(hospitals.filter(h => h.title.toLowerCase().includes(value.toLowerCase())))
    } else {
      setSearchQuery('')
      setHospitalsList(hospitals)
    }
  }

  return (
    <Fragment>
    <div id='hospitals-list'>
      <div className={'space-between d-flex mb-20'}>
        <Autocomplete className={'search'}
                      options={CITIES}
                      isClearable={false}
                      value={selectedRegion}
                      label={'Регион'}
                      onChange={(data) => handleSelectRegion(data.key)}
                      optionLabel={'value'}
                      optionValue={'value'}/>
        <div className={'flex-end d-flex full-width'}>
          <Search className={'mr-10'} handleChange={e => {
            filterChange(e.target.value)
          }} value={searchQuery}/>
          <Button type='contained'
                  color='secondary'
                  onClick={() => dispatch(togglePopover('adminAddHospital'))}
                  startIcon={<PlusIcon/>}>Добавить Больницу</Button>
        </div>
      </div>
      {
        <Table>
        <TableHead>
          {head.map((el, i) => <div className='item' key={i}>{el}</div>)}
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
      }
    </div>
      <Panel open={app.adminAddHospital}
             handleClose={() => closePopover()}>
        <PanelHeader className='simple-header'>
          <p>{popoverTitle} Скорую Помощь</p>
          <CloseIcon onClick={() => closePopover()}/>
        </PanelHeader>
        <HospitalForm hospital={hospital}/>
        <PanelFooter className='action-box'>
          {hospital && <Button type='contained' onClick={() => setDeleteModalOpen(true)} className='ml-20'>Удалить</Button>}
          <Button type='simple' onClick={() => closePopover()}>Отмена</Button>
          <Button type='contained' onClick={submitForm} className='ml-20'>{popoverTitle}</Button>
        </PanelFooter>
      </Panel>
      <Modal open={deleteModalOpen}
             closeIcon
             title={'Удалить больницу?'}
             handleClose={() => {
               setDeleteModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите удалить эту организацию?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => deleteSingleHospital(hospital)}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setDeleteModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default Hospitals
