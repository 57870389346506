import React, {useState} from 'react'
import styles from './styles.module.scss'
import {MedKitIcon} from "../../../assets";
import {InfoText} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {Autocomplete, Button} from "../../../ui-components";
import { setData, togglePopover } from '../../../app/app-slice'

const CustomerDiagnoseInfo = () => {
  const {er_call} = useAppSelector(state => state.requests)
  const {hospitals} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const [hospital, setHospital] = useState<string>('')

  const selectHospital = (type) => {
    if (type) {
      dispatch(togglePopover('hospitalizationFormOpen'))
    } else {
      dispatch(togglePopover('hospitalsListOpen'))
    }
    dispatch(setData({prop: 'hospitalizationFlow', data: type}))
  }

  return er_call && er_call.diagnosis ? (
    <div id={styles.diagnoseInfo} className={er_call.status === 7 ? styles.hospitalization : ''}>
      {er_call.status !== 7 && <p className={styles.title}><MedKitIcon/> Диагноз</p>}
      <div className={`${styles.wrapper}`}>
        <InfoText title={'Диагноз:'} className={`${styles.info} mb-10`} text={er_call.diagnosis}/>
        {er_call.diagnosis_details && <InfoText title={'Комментарий'} className='mb-10' text={er_call.diagnosis_details}/>}
        {/*{er_call.hospital_comment && <InfoText title={'Причина замены'} className='' text={er_call.hospital_comment}/>}*/}
      </div>
      {er_call.status === 7 &&
        <>
          {/*<Autocomplete options={hospitals}
                        label='Выбрать больницу'
                        value={hospital}
                        className='mb-20 mt-40'
                        onChange={(data) => setHospital(data.uuid)}
                        optionLabel={'title'}
                        optionValue={'uuid'}/>
          <Button type='contained' disabled={!hospital}
                  onClick={() => dispatch(assignHospital(hospital))}>Подтвердить</Button>*/}
          <div className={styles.hospitalButtons}>
            <Button type='contained'
                    onClick={() => selectHospital(true)}
                    className='mt-30'>Получить Предложение</Button>
            <Button type='contained'
                    onClick={() => selectHospital(false)}
                    className='mt-30'>Выбрать больницу</Button>
          </div>
        </>
      }
    </div>
  ) : null
}

export default CustomerDiagnoseInfo
