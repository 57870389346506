import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import { useAppDispatch, useAppSelector } from '../../../core/hooks'
import {replaceHospital as replaceHospitalEr} from "../../requests/er-thunk";
import {replaceHospital} from "../../requests/insurance-thunk";
import {isEmergencyOrg} from "../../../services/utils";

const ReplaceHospitalForm = () => {
  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const user = useAppSelector(state => state.user)

  const dispatch = useAppDispatch();

  const submit = (): void => {
    const replaceHospitalFunc = user.user.organization.type ? replaceHospitalEr : replaceHospital
    if(comment) {
      setClickDisabled(true)
      dispatch(replaceHospitalFunc(comment))
        .then(res => setClickDisabled(false))
    }
  }

  return (
    <div id={styles.replaceBrigadeForm}>
        <Input label='Причина замены' className={`${styles.input} mb-40`} value={comment}
               onKeyUp={(e) => e.which === 13 && submit()}
               onChange={e => setComment(e.target.value)}/>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!comment}
              onClick={submit}
              type={'contained'}>Поменять Больницу</Button>
    </div>
  )
}

export default ReplaceHospitalForm
