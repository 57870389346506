import React from "react";
import {AmbulanceBlue} from "../../assets";

const Offers = ({pool}) => {
    return <div style={{
      minHeight: 180,
      // maxHeight: 400,
      borderBottom: "1px solid rgb(231, 231, 231)",
      display: "flex",
      justifyContent: "center",
    }}>
      <div style={{
        width: '100%',
        paddingTop: 12,
        overflow: "auto",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'inline-flex'
      }}>
        <div style={{
          height: 48,
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 8,
          paddingBottom: 8,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 12,
          display: 'flex'
        }}>
          <div style={{justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
            <AmbulanceBlue/>
            <div style={{color: '#192233', fontSize: 16, fontWeight: 700, wordWrap: 'break-word'}}>Выбрать скорую</div>
          </div>
        </div>
        {pool.map((br) => {
          return  <div style={{
            alignSelf: 'stretch',
            height: 92,
            marginBottom: 12,
            paddingLeft: 24,
            paddingRight: 24,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 8,
            display: 'flex'
          }}>
            <div style={{
              alignSelf: 'stretch',
              padding: 12,
              borderRadius: 16,
              border: '1px #BDC1CC solid',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 8,
              display: 'inline-flex'
            }}>
              <div style={{
                flex: '1 1 0',
                height: 68,
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 8,
                display: 'flex'
              }}>
                <div style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 4,
                  display: 'inline-flex'
                }}>
                  <div style={{
                    alignSelf: 'stretch',
                    height: 20,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'flex'
                  }}>
                    <div style={{
                      alignSelf: 'stretch',
                      color: '#192233',
                      fontSize: 16,
                      fontWeight: 600,
                      wordWrap: 'break-word'
                    }}>{br?.organization_name}
                    </div>
                  </div>
                  <div style={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 24,
                    display: 'inline-flex'
                  }}>
                    <div style={{
                      flex: '1 1 0',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 2,
                      display: 'inline-flex'
                    }}>
                      <div style={{
                        alignSelf: 'stretch',
                        color: '#636F77',
                        fontSize: 12,
                        fontWeight: 500,
                        wordWrap: 'break-word'
                      }}>Цена
                      </div>
                      <div style={{
                        alignSelf: 'stretch',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 4,
                        display: 'inline-flex'
                      }}>
                        <div style={{
                          flex: '1 1 0',
                          height: 20,
                          color: '#192233',
                          fontSize: 14,
                          fontWeight: 500,
                          wordWrap: 'break-word'
                        }}>{br?.price} руб.
                        </div>
                      </div>
                    </div>
                    <div style={{
                      flex: '1 1 0',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 2,
                      display: 'inline-flex'
                    }}>
                      <div style={{
                        alignSelf: 'stretch',
                        color: '#636F77',
                        fontSize: 12,
                        fontWeight: 500,
                        wordWrap: 'break-word'
                      }}>Тип бригады
                      </div>
                      <div style={{
                        alignSelf: 'stretch',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 4,
                        display: 'inline-flex'
                      }}>
                        <div style={{
                          flex: '1 1 0',
                          height: 20,
                          color: '#192233',
                          fontSize: 14,
                          fontWeight: 500,
                          wordWrap: 'break-word'
                        }}>{br?.brigade_type}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 4,
                  display: 'inline-flex'
                }}>
                  {/*<div style={{*/}
                  {/*  paddingLeft: 8,*/}
                  {/*  paddingRight: 8,*/}
                  {/*  paddingTop: 6,*/}
                  {/*  paddingBottom: 6,*/}
                  {/*  background: '#E7E7E7',*/}
                  {/*  borderRadius: 8,*/}
                  {/*  justifyContent: 'flex-start',*/}
                  {/*  alignItems: 'center',*/}
                  {/*  display: 'inline-flex'*/}
                  {/*}}>*/}
                  {/*  <div style={{color: '#636F77', fontSize: 14, fontWeight: 700, wordWrap: 'break-word'}}>10 мин</div>*/}
                  {/*</div>*/}
                  <div style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 6,
                    paddingBottom: 6,
                    background: '#365EDE',
                    borderRadius: 8,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'inline-flex'
                  }}>
                    <div style={{color: 'white', fontSize: 14, fontWeight: 700, wordWrap: 'break-word'}}>{br?.eta} мин</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>

}

export default Offers
