import React, {FC, ReactElement, useEffect} from 'react'
import "./card.scss"
import {
  FemaleIcon,
  MaleIcon,
  TimeIcon,
  CommentIcon,
  AcceptedIcon,
  OfferIcon,
  WaitingIcon,
  ArrivedIcon,
  HospitalIcon,
  OnWayIcon,
  ErrorIcon,
  AmbulanceIcon, LinkIcon, Dash,
} from "../../assets";
import {Request} from "../requests/IRequests";
import {Timer} from '../../shared';
import { CITIES_ABBR, ER_CALL_STATUSES, TIME_LIMIT } from '../../services/constants'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {buildOffersAmountMessage} from "../../services/utils";
import {getSingleRequest} from "../requests/insurance-thunk";
import { setActivePanel } from '../requests/requests-slice';
import moment from "moment";

interface Props {
  data: Request
  column: string
  onClick: () => void
}

const Card: FC<Props> = (props) => {

  let Icon: ReactElement | null = null;
  let statusText: string = '';
  let genderIcon: ReactElement | null = null;
  let cardFooterClass: string = 'card-footer-basic'
  let timeLimit: number = 0;
  const {requestReasons, cardAvatars} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const {user} = useAppSelector(state => state.user)

  const {data}: any = props;
  let brigade: any
  if (data.er_call?.status >= 1) {
    brigade = data.pool[0].request_brigade.find(el => el.status === 2)
  }

  switch (data?.sex) {
    case 1:
      genderIcon = <MaleIcon className='mr-5'/>
      break;
    case 2:
      genderIcon = <FemaleIcon className='mr-5'/>
      break;
  }

  if (data.er_call) {
    if (user.organization.type === 4) {
      switch (data.er_call.status) {
        case 1:
          const selDate= new Date(data?.sentry_date_from)
          const then = moment(selDate).subtract(brigade.eta, "minutes")
          const diffInSeconds = then.diff(moment(data.er_call.updated_at), "millisecond");
          Icon = <AcceptedIcon className='mr-5'/>
          statusText = 'Ожидает выезда'
          cardFooterClass = 'card-footer-blue'
          timeLimit =diffInSeconds
          break
        case 2:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'В пути'
          cardFooterClass = 'card-footer-blue'
          timeLimit = brigade?.eta * 60000
          break
        case 3:
          Icon = <ErrorIcon className='mr-5'/>
          statusText = 'В пути'
          cardFooterClass = 'card-footer-orange'
          timeLimit = brigade?.eta * 60000 + brigade?.delay * 60000
          break
        case 4:
          Icon = <ArrivedIcon className='mr-5'/>
          statusText = 'Прибыл'
          cardFooterClass = 'card-footer-green'
          timeLimit = TIME_LIMIT.arrived
          break
        case 23:
          statusText = 'Дежурство'
          cardFooterClass = 'card-footer-green'
          Icon = <Dash className='mr-5'/>
          timeLimit = moment(data.sentry_date_to).valueOf() - moment(data.sentry_date_from).valueOf()
          break
        case 7:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Запрос госпитализации'
          cardFooterClass = 'card-footer-blue'
          timeLimit = TIME_LIMIT.sentryHospitalizationRequest
          break
        case 8:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Запрос бронирования'
          cardFooterClass = 'card-footer-blue'
          break
        case 16:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Предложение о бронировании'
          cardFooterClass = 'card-footer-green'
          break
        case 9:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Бронирование подтверждено'
          cardFooterClass = 'card-footer-green'
          timeLimit = TIME_LIMIT.bookingApproved
          break
        case 10:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'На пути в больницу'
          cardFooterClass = 'card-footer-blue'
          timeLimit = TIME_LIMIT.onRouteToHospital
          break
        case 22:
          Icon = <ErrorIcon className='mr-5'/>
          statusText = 'На пути в больницу'
          cardFooterClass = 'card-footer-orange'
          break
        case 11:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Стацианирование'
          cardFooterClass = 'card-footer-grey'
          timeLimit = TIME_LIMIT.stationing
          break
        case 5:
        case 6:
        case 12:
        case 14:
        case 15:
        case 17:
        case 18:
          statusText = 'вызов завершен'
          cardFooterClass = 'card-footer-grey'
          break
      }
    } else {
      switch (data.er_call.status) {
        case 1:
          Icon = <AcceptedIcon className='mr-5'/>
          statusText = 'Подтверждено'
          cardFooterClass = 'card-footer-blue'
          timeLimit = TIME_LIMIT.approved
          break
        case 2:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'В пути'
          cardFooterClass = 'card-footer-blue'
          timeLimit = brigade?.eta * 60000

          break
        case 3:
          Icon = <ErrorIcon className='mr-5'/>
          statusText = 'В пути'
          cardFooterClass = 'card-footer-orange'
          timeLimit = brigade?.eta * 60000 + brigade?.delay * 60000
          break
        case 4:
          Icon = <ArrivedIcon className='mr-5'/>
          statusText = 'Прибыл'
          cardFooterClass = 'card-footer-green'
          timeLimit = TIME_LIMIT.arrived
          break
        case 7:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Запрос госпитализации'
          cardFooterClass = 'card-footer-blue'
          timeLimit = TIME_LIMIT.hospitalizationRequest
          break
        case 8:
          Icon = <TimeIcon className='mr-5'/>
          statusText = 'Запрос бронирования'
          cardFooterClass = 'card-footer-blue'
          break
        case 9:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Бронирование подтверждено'
          cardFooterClass = 'card-footer-green'
          timeLimit = TIME_LIMIT.bookingApproved
          break
        case 10:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'На пути в больницу'
          cardFooterClass = 'card-footer-blue'
          timeLimit = TIME_LIMIT.onRouteToHospital
          break
        case 11:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Стацианирование'
          cardFooterClass = 'card-footer-grey'
          timeLimit = TIME_LIMIT.stationing
          break
        case 13:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Требуется замена СМП'
          cardFooterClass = 'card-footer-orange'
          timeLimit = TIME_LIMIT.erReplacementRequired
          break
        case 14:
          Icon = <OnWayIcon className='mr-5'/>
          statusText = 'Вызов завершен: Передан другой СМП'
          cardFooterClass = 'card-footer-grey'
          break
        case 16:
          Icon = <HospitalIcon className='mr-5'/>
          statusText = 'Предложение о бронировании'
          cardFooterClass = 'card-footer-green'
          break
        case 19:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Замена в процессе'
          cardFooterClass = 'card-footer-orange'
          break
        case 20:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Замена в пути'
          cardFooterClass = 'card-footer-orange'
          break
        case 21:
          Icon = <AmbulanceIcon className='mr-5'/>
          statusText = 'Замена прибыла'
          cardFooterClass = 'card-footer-orange'
          break
        case 22:
          Icon = <ErrorIcon className='mr-5'/>
          statusText = 'На пути в больницу'
          cardFooterClass = 'card-footer-orange'
          timeLimit = TIME_LIMIT.onRouteToHospital
          break
      }
    }

  } else {
    const offers = data.pool.filter(el => el?.status === 2)
    let offersCount: number = 0
    for (const offer of offers) {
      offersCount += offer.request_brigade.length
    }
    const confirmedOffers = data.pool.filter(el => el?.status === 3)
    if (data?.contract && data.status < 4) {
      if (data.contract.status === 1) {
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Подготовка договора'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.contractPending
      }
      if (data.contract.status === 2) {
        Icon = <WaitingIcon className='mr-5'/>
        statusText = 'Ожидает подтверждения договора'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.contractPending
      }
      if (data.contract.status === 4) {
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Договор подтвержден заказчиком'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.contractAccepted
      }
      if (data.contract.status === 5 && data?.payment.status === 1) {
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Договор утвержден'
        cardFooterClass = 'card-footer-blue'
      }

      if (data.contract.status === 5 && data?.payment.status === 2) {
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Ожидает оплаты'
        cardFooterClass = 'card-footer-orange'
      }
    } else if (confirmedOffers && !!confirmedOffers.length && !data?.contract) {
      Icon = <AcceptedIcon className='mr-5'/>
      statusText = 'Подтверждено'
      cardFooterClass = 'card-footer-blue'
      timeLimit = TIME_LIMIT.waitingContractBegin
    } else {
      switch (data?.status) {
        //TODO: complete all statuses scenarios
        case 1:
          timeLimit = data.type === 3 ? TIME_LIMIT.sentryPending  : TIME_LIMIT.pending
          break;
        case 2:
          timeLimit =data.type === 3 ? TIME_LIMIT.sentryInProcess : TIME_LIMIT.inProcess
            Icon = <OfferIcon className='mr-5'/>
          statusText = `${offersCount} ${buildOffersAmountMessage(offersCount)}`
          cardFooterClass = 'card-footer-blue'
          break;
        case 3:
          timeLimit = data.type === 3 ? TIME_LIMIT.sentryWaitingApproval:TIME_LIMIT.waitingApproval
          Icon = <WaitingIcon className='mr-5'/>
          statusText = 'Ожидает подтверждения'
          cardFooterClass = ''
          break;
          case 5:
          case 7:
          // Icon = <WaitingIcon className='mr-5'/>
          statusText = 'Дежурство отменен'
          cardFooterClass = 'card-footer-grey'
          break;
      }
    }

  }

  const requestReason = requestReasons.find(val => val.id === data.request_reason)

  const avatarData = cardAvatars.find(el => el.organizationUuid === data.insurance_org.uuid)

  const city = CITIES_ABBR.find(el => el.id === data.region)

  let timerDate: string

  /*if (data.status <= 3) {
    timerDate = data.created_at as string
  } else if (data.er_call) {
    timerDate = data.er_call.updated_at
  } else {
    timerDate = data.updated_at as string
  }*/
  if (user.organization.type === 4 && (brigade && data.er_call && (data.er_call.status === ER_CALL_STATUSES.onRouteDelay || data.er_call.status === ER_CALL_STATUSES.onRoute))) {
    timerDate = data.er_call.on_route_date
  } else if ((brigade && brigade.delay && data.er_call && data.er_call.status === ER_CALL_STATUSES.onRouteDelay)) {
    timerDate = data.updated_at
  } else if (data.er_call) {
    if (data.er_call.status === ER_CALL_STATUSES.sentryStart) {
      timerDate = data.sentry_date_from
    } else {
      timerDate = data.er_call.updated_at
    }
  } else if (data.contract) {
    timerDate = data.contract.updated_at
  } else {
    timerDate = data.updated_at
  }
  return (
    <div className='card-container' onClick={props.onClick}>
      <div className={`card-content ${data.status > 1 ? 'shadow' : ''}`}>
        <div className="d-flex space-between mb-10">
          <p className='patient-name'>{genderIcon}
            <p className='text' title={`${data.name}`}>
              {data?.customer_type === 2 ? data.entity_name : data?.name}</p>
          </p>
          <Timer date={timerDate}
                 request_uuid={data.uuid}
                 column={props.column}
                 timeLimit={timeLimit}/>
        </div>
        <div className='mb-10'>
          {data && data.contract && <div>
            {data.payment && data.paymentStatus === 1 ? <p className='greenPayed'>Оплачено</p> : <p className="orangeNotPayed">Не оплачено</p>}

          </div>}
          <p className='text'>{requestReason?.name}</p>
          {data?.parent_request &&
          <p className="connected-request mb-10 my-10"
             onClick={(e) => {
               dispatch(getSingleRequest(data?.parent_request))
               e.stopPropagation()
             }}>
            <LinkIcon/>{`Связ. заявка № ${data?.parent_request_number}`}
          </p>}
        </div>
        <div className='content-footer'>
          <p className="city">{city?.value}</p>
          <span className='stick'/>
          {data.er_call && <div className="org-info">
            {avatarData ? <img className="logo" src={avatarData.avatar} alt=''/> :
              <span className='logo'/>}
            <span className="name">{data.emergency_org?.name}</span>
          </div>}
          {data.er_call && <div className="stick"/>}
          <p className='card-number'>№ {data.request_number}</p>
          <div className="stick"/>
          {data?.unread_comments > 0 &&
          <p className='comments' onClick={() => dispatch(setActivePanel('comment'))}>
            <CommentIcon/>{data.unread_comments}
          </p>}
        </div>
      </div>
      {data.status > 1 &&
      <div className={`card-footer ${cardFooterClass}`}>
        <p className="left">{Icon} {statusText}</p>
        <p className='right'>
          {data.er_call?.status === 2 || data.er_call?.status === 3 ?
            <p className="time">{brigade.eta} {brigade.delay ? `+ ${brigade.delay}` : ''} мин</p> : null}
          <span className='arrow'> {'>'} </span>
        </p>
      </div>}
    </div>
  )
}

export default React.memo(Card)
