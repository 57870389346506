import React, { ChangeEvent, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { Autocomplete, Button, Input } from '../../ui-components'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {handleFormChange, formFields, validateInputs} from './users-slice';
import { CENTER_ROLE, USER_ROLES } from '../../services/constants'
import styles from "./users.module.scss";
import {AttachIcon} from "../../assets";
import {prepareFileUpload} from "../../services/utils";
import { Modal } from '../../shared'
import { setApiError } from '../../admin/app-slice'

const UserForm = ({user, organizationType}) => {
  const {form, validationFields} = useAppSelector(state => state.adminUsers)
  const app = useAppSelector(state => state.adminApp)
  const randomPass = Math.random().toString(36).slice(2)
  const [sizeLimitModelOpen, setSizeLimitModelOpen] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if (organizationType === 4) {
      handleSelectChange('role', 5)
      setDisable(true)
    }
    if(user){
      for(const prop in user){
        if(formFields.includes(prop)){
          dispatch(handleFormChange({key: prop, value:user[prop]}))
          dispatch(validateInputs({key: prop, value: user[prop]}))
        }
      }
      dispatch(handleFormChange({key:'password', value:''}))
    }
    else{
      dispatch(handleFormChange({key:'password', value:randomPass}))
    }
    if (app.apiError && app.apiError.error_id === 28) {
      setSizeLimitModelOpen(true)
      dispatch(setApiError({}))
    }
  }, [user, app.apiError])

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  const removeAvatar = () => {
    dispatch(handleFormChange({key: 'avatar', value: null}))
    dispatch(handleFormChange({key: 'preview_avatar', value: null}))
    dispatch(handleFormChange({key: 'formData', value: null}))
  }

  const handleSelectChange = (key, value) => dispatch(handleFormChange({key, value}))

  // handle img preview and set form data before upload
  const handleImgUpload = (e) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > 10485760) {
        e.target.value = ''
        return setSizeLimitModelOpen(true)
      }
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => dispatch(handleFormChange({key: 'avatar', value: reader.result as string}))
      dispatch(handleFormChange({key: 'formData', value: prepareFileUpload(e)}))
      e.target.value = ''
    }
  }

  return (
    <div className="form-wrapper overflow-scroll">
      <div className="temp-03-form">
        <div className={'mb-40'}>
          <Autocomplete className='select-small'
                        options={disable ? CENTER_ROLE : USER_ROLES}
                        onChange={(val) => handleSelectChange('role', val.key)}
                        optionLabel={'value'}
                        optionValue={'key'}
                        disabled={disable}
                        value={form.role} label="Тип оператора"/>
        </div>
        <div className={'wrapper'}>
          <Input name='last_name'
                 error={!validationFields.last_name}
                 onChange={handleInputChange}
                 value={form.last_name} label="Фамилия"/>
          <Input name='first_name'
                 error={!validationFields.first_name}
                 onChange={handleInputChange}
                 value={form.first_name} label="Имя"/>
          <Input name='middle_name'
                 error={!validationFields.middle_name}
                 onChange={handleInputChange}
                 value={form.middle_name} label="Отчество"/>
        </div>
        <div className={'wrapper'}>
          <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                     className={styles.input}
                     name='primary_phone'
                     error={!validationFields.primary_phone}
                     onChange={(e) => e && handleInputChange(e)}
                     value={form.primary_phone} label="Телефон">
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
          <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                     className={styles.input}
                     name='secondary_phone'
                     onChange={(e) => e && handleInputChange(e)}
                     value={form.secondary_phone} label="Доп. телефон">
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
          <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                     className={styles.input}
                     name='mobile_phone'
                     onChange={(e) => e && handleInputChange(e)}
                     value={form.mobile_phone} label="Моб. телефон">
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </div>
        <div className={'wrapper'}>
          <Input name='email'
                 error={!validationFields.email}
                 onChange={handleInputChange}
                 value={form.email} label="Почта"/>
        </div>
        {(form.avatar || form.preview_avatar) &&
        <div className={styles.avatarWrapper}>
          <span onClick={removeAvatar} className={styles.removeIcon} />
          <img className={styles.avatar} src={form.preview_avatar || form.avatar} alt="img"/>
        </div>}
        <label htmlFor='img-upload' className={styles.imgUploadWrapper}>
          <AttachIcon/>
          <span>Загрузить фотографию</span>
          <input id='img-upload'
                 accept="image/*"
                 onChange={handleImgUpload}
                 className='hidden' type='file'/>
        </label>
      </div>
      <Modal open={sizeLimitModelOpen}
             closeIcon
             title={'Размер изображения'}
             handleClose={() => {
               setSizeLimitModelOpen(false)
             }}>
        <p className="modalText mb-50">Размер загружаемого изображения не должен превышать 10 МБ.</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => setSizeLimitModelOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
    </div>
  )
}

export default UserForm
