import ReactTooltip from "react-tooltip";
import React, { FC, forwardRef } from "react";
import styles from './styles.module.scss'

interface Props {
  id: string
  className?: string
  offsetLeft?: number
  offsetTop?: number
  afterShow?: Function
  afterHide?: Function
  children: any
}

const SearchTooltip = (props: Props, ref) => {
  return (
    <ReactTooltip arrowColor="transparent"
                  afterShow={() => props.afterShow && props.afterShow()}
                  afterHide={() => props.afterHide && props.afterHide()}
                  ref={ref}
                  globalEventOff='click'
                  offset={{left: props.offsetLeft || 0, top: props.offsetTop || 0}}
                  className={`${styles.searchTooltip} ${props.className ? props.className : ''}`}
                  id={props.id} place='bottom' effect='solid' clickable>
      {props.children}
    </ReactTooltip>
  )
}

export default forwardRef(SearchTooltip)
