import React from "react";
import styles from "./PayCard.module.scss";
import {Autocomplete} from "../../ui-components";
import {CashIcon} from "../../assets";

const PayCard = () => {

  return <div className={styles.container}>
    <div className={styles.head} >
      <div className={styles.headWrapper} >
        <CashIcon/>
        <div className={styles.title} >Способ оплаты</div>
      </div>
    </div>
    <div className={styles.body} >
        <Autocomplete className={styles.select}
                      disabled
                      value={3}
                      options={[
                        { type: "Карта", key: 2 },
                        { type: "Наличные", key: 1 },
                        { type: "Перевод", key: 3 },
                      ]}
                      optionLabel={'type'}
                      optionValue={'key'}
                      label="Способ оплаты"/>
      </div>
  </div>
}

export default PayCard
