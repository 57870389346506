import React, {FC} from 'react'
import {Props} from './ICheckbox'
import './checkbox.scss'

const Checkbox: FC<Props> = (props) => {
  return (
    <label className={
      `checkbox ${props.className ? props.className : ''}
      ${props.placement ? props.placement : ''}
      ${props.error ? 'error' : ''}
      ${props.small ? 'small' : ''}`
    }>
      <input type="checkbox" checked={props.checked} onClick={props.onClick ? props.onClick : undefined}/>
      <span className={`checkbox-icon`}/>
      <span className='label-text'>{props.label}</span>
    </label>
  )
}

export default Checkbox
