import React from "react"
import styles from "./tab.module.scss"
import cx from "classnames"
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {ErrorIconGradient} from "../../assets";
import {clearCustomerTabBadge, clearInsuranceTabBadge} from "../../modules/requests/requests-slice";
import {useHistory} from "react-router-dom";

const Tab = ({selected, setSelectedTab}) => {
  const {isCustomer, isInsurance} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const history = useHistory()

  return <div className={styles.container} >
    <div onClick={() => {
      dispatch(clearInsuranceTabBadge())
      setSelectedTab(1)
      history.push('/dashboard')

    }}  className={cx(styles.item, selected === 1 && styles.selected)} >
      <div className={styles.text}>Страховые
      </div>
      <div className={styles.badge}>
        <div
          className={styles.badgeCount}
          >
          {isInsurance && <ErrorIconGradient/>}
        </div>
      </div>
    </div>
    <div onClick={() => {
      dispatch(clearCustomerTabBadge())
      setSelectedTab(2)
      history.push('/dashboard')

    }} className={cx(styles.item, selected === 2 && styles.selected)}  >
      <div
        className={styles.text}>Физ.
        Лица
      </div>
      <div className={styles.badge}>
        <div
          className={styles.badgeCount}>
          {isCustomer && <ErrorIconGradient/>}
        </div>
      </div>
    </div>
  </div>
}

export default Tab
