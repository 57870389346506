import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../core/store";
import {getAvatar, setRequestForm } from "../modules/requests/requests-slice";
import {ApiInstance} from "../services/api";
import uniqBy from 'lodash/uniqBy'
import {isEmergencyOrg, toBase64} from "../services/utils";

interface ApiError {
  status: number,
  text: string,
  error_id: number
}

interface Hospital {
  name: any;
  uuid?: string
  title: string
  address: string
}

interface Metro {
  id: number
  name: string
}

interface Reason {
  id: string
  name: string
}

interface Diagnosis {
  id: string
  title: string
}

interface AttachedAvatar {
  organizationUuid: string
  avatar: string
}

interface City {
  id: number,
  name: string
}

interface App {
  apiError: ApiError,
  pageTitle: string
  activeRegion: Partial<City>,
  navbarMinimized: boolean
  mainPanelOpen: boolean
  rightMainPanel: boolean
  requestFormOpen: boolean
  hospitals: Array<Hospital>
  requestReasons: Array<Reason>
  diagnoses: Array<Diagnosis>
  metros: Array<Metro>,
  addresses: Array<any>,
  cities: Array<any>,
  popoverFormStatuses: { [key: string]: boolean}
  errorModalOpen: boolean
  noConnectionModalOpen: boolean
  noPoolModelOpen: boolean
  noPoolRequest: any
  toaster: {
    visible: boolean
    message: string
    color: 'default' | 'green' | 'red'
  }
  cardAvatars: Array<AttachedAvatar>,
  hospitalizationFlow: boolean
}

const initialState: App = {
  pageTitle: 'Главная',
  activeRegion: {},
  navbarMinimized: false,
  mainPanelOpen: false,
  rightMainPanel: false,
  requestFormOpen: false,
  hospitals: [],
  requestReasons: [],
  diagnoses: [],
  metros: [],
  addresses: [],
  cities: [],
  popoverFormStatuses: {
    editRequestOpen: false,
    dpCancelOffer: false,
    dcCancelOffer: false,
    earlyClose: false,
    editDpRequestOpen: false,
    offerFormOpen: false,
    patientInfoFormOpen: false,
    acceptFormOpen: false,
    delayFormOpen: false,
    editBrigadeFormOpen: false,
    replaceBrigadeFormOpen: false,
    replaceErFormOpen: false,
    diagnoseFormOpen: false,
    additionalBrigadeFormOpen: false,
    hospitalizationFormOpen: false,
    hospitalsListOpen: false,
    hospitalsOffersListOpen: false,
    replaceHospitalFormOpen: false,
    dcCancel: false,
    dpFinishSentry: false,
  },
  errorModalOpen: false,
  noConnectionModalOpen: false,
  noPoolModelOpen: false,
  noPoolRequest: {},
  toaster: {
    visible: false,
    message: 'Puchikn @ngav sexani tak, mi or, mi or gmp.',
    color: "default"
  },
  cardAvatars: [],
  apiError: {
    status: 0,
    text: '',
    error_id: 0
  },
  hospitalizationFlow: false
};

const app = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<ApiError| any>) => {
      if (action.payload.response) {
        state.apiError.status = action.payload.response.status
        state.apiError.text = action.payload.response.data?.error
        state.apiError.error_id = action.payload.response.data?.error_id
      } else if (action.payload.code) {
        state.apiError.status = action.payload.code
        state.apiError.text = action.payload.data?.error
        state.apiError.error_id = action.payload.data?.error_id
      } else {
        state.apiError.status = 0
        state.apiError.text = ''
        state.apiError.error_id = 0
      }
      return state
    },
    toggleNavbarSize: (state) => {
      state.navbarMinimized = !state.navbarMinimized
      return state
    },
    toggleMainPanel: (state) => {
      state.mainPanelOpen = !state.mainPanelOpen
      return state
    },
    toggleRightMainPanel: (state) => {
      state.rightMainPanel = !state.rightMainPanel
      return state
    },
    toggleRequestForm: (state) => {
      state.requestFormOpen = !state.requestFormOpen
      return state
    },
    togglePopover: (state, action: PayloadAction<string>) => {
      state.popoverFormStatuses[action.payload] = !state.popoverFormStatuses[action.payload]
    },
    setData: (state, action: PayloadAction<{data: any, prop: string}>) => {
      state[action.payload.prop] = action.payload.data
    }
  }
});

export const {
  toggleNavbarSize,
  toggleMainPanel,
  toggleRightMainPanel,
  toggleRequestForm,
  togglePopover,
  setData,
  setApiError
} = app.actions;

export const toggleEditRequest = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(togglePopover('editRequestOpen'))
    dispatch(setRequestForm())
  }
};

export const toggleEditDpRequest = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(togglePopover('editDpRequestOpen'))
    dispatch(setRequestForm())
  }
};

export const getHospitalsList = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const {request} = getState().requests
      const region = request.region === 1 ? '' : request.region
      const res = await api.static.get('/hospitals', {region})
      dispatch(setData({data: res, prop: 'hospitals'}))
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const getRequestReasonsList = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.static.get('/request-reasons')
      dispatch(setData({data: res, prop: 'requestReasons'}))
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const getDiagnosesList = (query: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.static.get('/diagnosis', {q: query})
      dispatch(setData({data: res, prop: 'diagnoses'}))
      return res
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const getMetrosList = (region) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.static.get(`/metro?region=${region}`)
      dispatch(setData({data: res, prop: 'metros'}))
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const getAddressesList = (query: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const {form} = getState().requests
      const city = form.region === 2 ? 'Москва' : 'Санкт Петербург'
      const res = await api.static.get('/geocoder', {address: query, city: city})
      const updatedAddresses = [...res, {fullAddress: query}]
      dispatch(setData({data: updatedAddresses, prop: 'addresses'}))
      return true
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const getCitiesList = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.static.get('/cities')
      dispatch(setData({data: res, prop: 'cities'}))
      return res
    } catch (e) {
      console.log("Err", e)
    }
  }
}

export const resetAllPopovers = () => {
  return async (dispatch: AppDispatch,  getState: any) => {
    const {popoverFormStatuses} = getState().app
    for(const key in popoverFormStatuses) {
      if(popoverFormStatuses[key]) {
        dispatch(togglePopover(key))
      }
    }
  }
}

export const attachAvatars = () => {
  return async (dispatch: AppDispatch, getState: any) => {
    try{
      const {requests} = getState().requests
      const biddingOrHospitalization = isEmergencyOrg() ? [...requests.bidding] : [...requests.hospitalization]

      const filteredOrgAvatars = uniqBy([...biddingOrHospitalization, ...requests.er_calls, ...requests.applications]
        .filter((i) => i.organization ? i.organization.avatar : i.insurance_org.avatar), 'organization_uuid')

      const avatars = await Promise.all(filteredOrgAvatars.map(async (item) => {
        const organization = item.organization || item.insurance_org
        const avatarData = await dispatch(getAvatar(organization.uuid, organization.avatar))
        return {
          organizationUuid: organization.uuid,
          avatar: toBase64(avatarData.Body.data)
        }
      }))
      dispatch(setData({prop: 'cardAvatars', data: avatars}))
    }
    catch(error){
      console.error(error)
    }
  }
}

export default app.reducer;
