import React, { useState } from 'react'
import styles from './styles.module.scss'
import InputMask from 'react-input-mask'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {brigadeTypes} from "../../../services/constants";
import {switchErCallBrigade} from "../../requests/er-thunk";
import {useAppDispatch} from "../../../core/hooks";

const ReplaceBrigadeForm = () => {
  const [brigade_type, setBrigadeType] = useState<string>('')
  const [brigadeResponsible, setBrigadeResponsible] = useState<string>('')
  const [brigadeNumber, setBrigade] = useState<string>('')
  const [delayTime, setDelayTime] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)

  const dispatch = useAppDispatch();

  const switchBrigade = (): void => {
    if(brigade_type && brigadeResponsible && brigadeNumber && delayTime) {
      setClickDisabled(true)
      dispatch(switchErCallBrigade(brigade_type, brigadeResponsible, brigadeNumber, parseInt(delayTime.replace(/[^0-9]/g,""))))
        .then(res => setClickDisabled(false))
    }
  }

  return (
    <div id={styles.replaceBrigadeForm}>
      <div className={`${styles.wrapper} mb-40`}>
        <Autocomplete
          className={styles.input}
          options={brigadeTypes}
          value={brigade_type}
          onChange={(data) => setBrigadeType(data.value)}
          onKeyUp={(e) => e.which === 13 && switchBrigade()}
          label={'Выбрать тип бригады'}
          optionLabel={'value'}
          optionValue={'value'}/>
        <Input label='Ответственный бригады' className={styles.input} value={brigadeResponsible}
               onKeyUp={(e) => e.which === 13 && switchBrigade()}
               onChange={e => setBrigadeResponsible(e.target.value)}/>
      </div>
      <div className={`${styles.wrapper} mb-40`}>
        <Input label='Номер бригады'
               onChange={e => setBrigade(e.target.value)}
               onKeyUp={(e) => e.which === 13 && switchBrigade()}
               className={styles.input} value={brigadeNumber}/>
        <InputMask mask="+ 999 минут" maskChar=" "
                   className={`${styles.input}`}
                   onKeyUp={(e) => e.which === 13 && switchBrigade()}
                   label='Опоздание на'
                   onChange={e => setDelayTime(e?.target.value)}
                   value={delayTime}>
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      </div>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!brigade_type || !brigadeResponsible || !brigadeNumber || !delayTime}
              onClick={switchBrigade}
              type={'contained'}>Поменять Бригаду</Button>
    </div>
  )
}

export default ReplaceBrigadeForm
