import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../admin/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getKeyValueStringFromObj, getRegion, IGlobalFilter} from "../../../services/utils";
import {omit} from 'lodash'
import customerApi from "../../../services/api/customerApi";

interface ErHistory {
  eta: any;
  end_date: any;
  payment_status: any;
  result: any;
  created_date :string
  name :string
  phone? :string
  number :string
  request_uuid :string
  requested_date :string
  start_date :string
  status :string
  amount :string
  sentry_group_uuid :any
}

interface IActiveTickets {
  erHistory: Array<ErHistory>
  filter: IGlobalFilter,
  allDataLoaded: boolean
}

const initialState: IActiveTickets = {
  erHistory: [],
  filter: {
    limit: 50,
    offset: 0,
    q: '',
    from: '',
    to: '',
    data: {
      age: '',
      status: '',
    }
  },
  allDataLoaded: true
};

const erHistory = createSlice({
  name: 'erHistory',
  initialState: initialState,
  reducers: {
    setErHistory: (state, action: PayloadAction<Array<ErHistory>>) => {
      state.erHistory = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: any}>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.filter.data![key] = data.value
      } else {
        state.filter[data.key] = data.value
      }
    },
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded =  action.payload
    }
  }
});

export const {
  setErHistory,
  handleFilterChange,
  setDataLoaded
} = erHistory.actions;

export const getErHistory = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter} = getState().erHistory
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await customerApi.request.get('/er-history', {...updatedFilter,  offset: 0, filter: additionalData})
      dispatch(handleFilterChange({key: 'offset', value: 0}))
      res.length < filter.limit  ? dispatch(setDataLoaded(true)) : dispatch(setDataLoaded(false))
      dispatch(setErHistory(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};


export const loadMoreData = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter, erHistory} = getState().erHistory
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await customerApi.request.get('/er-history', {...updatedFilter, filter: additionalData})
      const updatedErHistory = [...erHistory, ...res]
      filter.limit > res.length && dispatch(setDataLoaded(true))
      dispatch(setErHistory(updatedErHistory))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default erHistory.reducer;
