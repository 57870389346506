import React, { FC } from 'react'
import './table.scss'

interface Props {
  className?: string
}

const Table: FC<Props> = (props) => {
  return (
    <div className={`temp03-table-container ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  )
}

export default Table
