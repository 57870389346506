import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Checkbox, Input, Radio } from '../../ui-components'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {handleFormChange, formFields} from './hospitals-slice';
import '../../assets/styles/default-form.scss'
import styles from '../../modules/insurance-request/components/styles.module.scss'
import { CITIES, ROOT_ADMIN_HOSPITAL_REGIONS } from '../../services/constants'
import {debounce} from 'lodash'
import { getAddressesList } from '../../app/app-slice'

const HospitalForm = ({hospital}) => {
  const {form, validationFields} = useAppSelector(state => state.adminHospitals)
  const app = useAppSelector(state => state.app)
  const [region, setRegion] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  useEffect(()=>{
    if(hospital){
      for(const prop in hospital){
        if(formFields.includes(prop)){
          if (prop === 'region') {
            for (const region of CITIES) {
              if (region.key === hospital.region) {
                setRegion(region.key)
              }
            }
          }
          dispatch(handleFormChange({key: prop, value:hospital[prop]}))
        }
      }
    }
  }, [hospital])

  const selectCity = (key) => {
    setRegion(key)
    dispatch(handleFormChange({key: 'region', value: key}))
  }

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  const handleAddressChange = (key: string, value: string) => {
    dispatch(handleFormChange({key: key, value}))
  }

  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      setLoading(true)
      if( query.length > 3) {
        dispatch(getAddressesList(query))
          .then(res => res && setLoading(false))
      } else {
        setLoading(false)
      }
    }, 1000),
    [dispatch]
  );

  return (
    <div className="form-wrapper overflow-scroll">
      <div className="temp-03-form">
        <div className={'mb-40'}>
          <Input name='title'
                 error={!validationFields.title}
                 onChange={handleInputChange}
                 value={form.title} label="Название"/>
        </div>
        <div className={'wrapper mb-40 flex-start'}>
          <Input name='responsible_person_email'
                 error={!validationFields.responsible_person_email}
                 onChange={handleInputChange}
                 value={form.responsible_person_email}
                 label="Почта"/>
        </div>
        <h4 className='mb-15'>Выбрать регионы (выбраны все по умолчанию)</h4>
        <div className="wrapper">
          {
            CITIES.map((el, i) => {
              if (el.key !== 1) {
                return <div key={i} onClick={() => selectCity(el.key)} className={`${styles.header} d-flex flex-center`}>
                  <Radio checked={region === el.key} name={'hospital'}/>
                  <p className={'hospital-request-title ml-10'}>{el.value}</p>
                </div>
              } else {
                return null
              }
            })
          }
        </div>
        <div>
          {/*<Autocomplete options={app.addresses}*/}
          {/*              error={!validationFields.address}*/}
          {/*              isLoading={loading}*/}
          {/*              value={form.address}*/}
          {/*              onChange={val => handleAddressChange('address', val?.fullAddress)}*/}
          {/*              onInputChange={data => debouncedChangeHandler(data)}*/}
          {/*              label='Адрес'*/}
          {/*              optionValue={'fullAddress'}*/}
          {/*              optionLabel={'fullAddress'} />*/}
          <Input name='address'
                 error={!validationFields.address}
                 onChange={handleInputChange}
                 value={form.address}
                 label="Адрес"/>
        </div>
      </div>
    </div>
  )
}

export default HospitalForm
