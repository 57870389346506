import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setApiError, togglePopover } from '../../admin/app-slice'
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import { formValidator } from './validator'

const initialState = {
  reasons: [],
  reason: null,
  form:{
    name: ''
  },
  validationFields:{

  } as any,
  formValid: false,
}

const reasons = createSlice({
  name: 'reasons',
  initialState: initialState,
  reducers: {
    setReasons:(state, action)=>{
      state.reasons = action.payload
      return state
    },
    setReason: (state, action: PayloadAction<any>) => {
      state.reason = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: string | number | FormData }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    resetForm: (state)=>{
      state.form = initialState.form
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    }
  }
});

export const {
  setReasons,
  setReason,
  handleFormChange,
  resetForm,
  validateInputs
} = reasons.actions;


export const getReasons = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const resp = await api.admin.get('/reasons')
      dispatch(setReasons(resp))
      return resp
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const createReason = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminReason = getState().adminReasons

    if (adminReason.formValid) {
      const data = {...adminReason.form}
      try {
        let response: any
        response = await api.admin.post('reasons', data)
        dispatch(togglePopover('adminAddReason'))
        await dispatch(clearForm())
        return response
      } catch (e) {
        console.error('failed to create reason with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateReason = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminReason = getState().adminReasons
    if (adminReason.formValid) {
      const data = {...adminReason.form}
      try {
        const res = await api.admin.put(`reasons/${adminReason.reason.id}`, data)
        dispatch(togglePopover('adminAddReason'))
        await dispatch(clearForm())
        return res
      } catch (e) {
        console.error('failed to update reason with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const selectReason = (data) => {
  return (dispatch: AppDispatch) => {
    dispatch(setReason(data))
  }
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const deleteReason = (id: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.delete(`/reasons/${id}`)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default reasons.reducer;
