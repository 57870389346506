import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../app/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {User} from "./IUsers";
import {toBase64} from "../../services/utils";
import {handleFormChange} from "../dispatchers/dispatchers-slice";

interface InitialState {
  user: User
}

const initialState: InitialState = {
  user: JSON.parse(localStorage.getItem('user') || '{}'),
}

const user = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User, token?: string }>) => {
      const data = action.payload
      state.user = data.user
      localStorage.setItem('user', JSON.stringify(data.user))
      data.token && localStorage.setItem('token', data.token)

      return state
    },
  }
});

export const {
  setUser,
} = user.actions;

export const login = (email: string, password: string, loginAsAdmin?:boolean) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = loginAsAdmin ? await api.admin.post('login', {email, password}) : await api.user.post('login', {email, password})
      dispatch(setUser(res))
      return true
    } catch (e) {
      dispatch(setApiError(e))
      throw e
    }
  }
}

export const activateUser = (token: string, password: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.user.post('activate', {token, password})
      dispatch(setUser(res))
      return res;
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const updateUser = (uuid: string, data: User) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.updateUser(uuid, data)
      dispatch(setUser(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const getUserAvatar = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {user} = getState().user
    try {
      const updatedUser = {...user}
      const img = await api.user.get(`avatar/${user.uuid}/${user.avatar}`)
      updatedUser.avatar = toBase64(img.Body.data)
      dispatch(setUser({user: updatedUser}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const resetPassword = (email: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.user.post('reset-password', {email})
      //TODO: some notification
    } catch (e) {
      dispatch(setApiError(e))
      throw e
    }
  }
};

export const changePassword = (data: {token: string, password: string}) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.user.post('change-password', data)
      dispatch(setUser(res))
      return res;
    } catch (e) {
      dispatch(setApiError(e))
      throw e
    }
  }
};

export const checkToken = (token: string, isForActivation = false) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.user.post(isForActivation ? `check-token?isForActivation=${isForActivation}` : 'check-token', {token});
      if (res && res.status === 2) {
        dispatch(setUser(res))
      }
      return res
    } catch (e) {
      return e
    }
  }
}

export const checkConnections = () => async (dispatch: AppDispatch, getState: any, api: ApiInstance)  =>  {
  try {
    return await api.partnerships.get('/check')
  } catch (e) {
    console.log(e, "E")
  }
}

export const logout = () => {
  return async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.href = '/login'
  }
}

export default user.reducer;
