import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {User} from "../user/IUsers";
import {Dispatchers} from "./IDispatchers";
import {toggleMainPanel} from "../../app/app-slice";
import {toBase64} from "../../services/utils";
import {checkToken} from "../user/user-slice";
import {omit} from 'lodash'

const initialState: Dispatchers = {
  list: [],
  dispatcher: {
    id: 0,
    uuid: '',
    status: 0,
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    preview_avatar: '',
    organization: {
      uuid: '',
      name: '',
      inn: '',
      cities: [],
    }
  },
  form: {
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    primary_phone: '',
    secondary_phone: '',
    mobile_phone: '',
    avatar: '',
    formData: ''
  },
  validationFields: {
    first_name: true,
    last_name: true,
    middle_name: true,
    email: true,
    primary_phone: true
  },
  formValid: true,
  editState: false
};

const dispatchers = createSlice({
  name: 'dispatchers',
  initialState: initialState,
  reducers: {
    setList: (state, action: PayloadAction<Array<User>>) => {
      state.list = action.payload
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    setData: (state, action: PayloadAction<{ prop: string, data: any }>) => {
      state[action.payload.prop] = action.payload.data
    },
    resetForm: (state) => {
      state.form = initialState.form

      return state
    }
  }
});

export const {
  setList,
  setData,
  handleFormChange,
  resetForm
} = dispatchers.actions;

export const getDispatchers = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.user.get('/dispatchers')
      dispatch(setList(res))
    } catch (e) {
      console.log("ERR", e)
    }
  }
};

export const createDispatcher = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {formValid, form} = getState().dispatchers
    const {user} = getState().user
    if (formValid) {
      const data = {...form}
      try {
        let response: any
        if(data.formData) {
          delete data.preview_avatar
        }
        delete data.formData
        response = await api.user.post('', {...data, role: 3, organization_uuid: user.organization_uuid})
        if(form.formData) {
          const avatar = await dispatch(uploadAvatar(response.uuid))
          await api.user.put('update', {avatar: avatar.data, uuid: response.uuid, role: 3, organization_uuid: user.organization_uuid})
        }
        dispatch(getDispatchers())
        dispatch(toggleMainPanel())
        dispatch(resetForm())
        return response;
      } catch (e) {
        console.log("ERR", e)
        return e
      }
    }
  }
}

export const editDispatcher = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {formValid, form} = getState().dispatchers
    const {user} = getState().user
    const token = localStorage.getItem('token')

    if (formValid) {
      const data = omit({...form}, ['preview_avatar'])
      try {
        if(data.formData) {
          const response = await dispatch(uploadAvatar(form.uuid))
          data.avatar = response.data
        }
        delete data.formData
        const res = await api.user.put('update', {...data, organization_uuid: user.organization_uuid})
        dispatch(setList(res))
        dispatch(checkToken(token as string))
        dispatch(toggleMainPanel())
      } catch (e) {
        console.log("ERR", e)
      }
    }
  }
}

export const deleteDispatcher = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {dispatcher} = getState().dispatchers
    try {
      const res = await api.user.delete('', {uuid: dispatcher.uuid})
      dispatch(setList(res))
      dispatch(toggleMainPanel())
      return res
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const uploadAvatar = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {form} = getState().dispatchers
    try {
      return await api.user.upload(`avatar/${uuid}`, form.formData)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const img =  await api.user.get(`avatar/${uuid}/${avatar}`)
      dispatch(handleFormChange({key: 'preview_avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export default dispatchers.reducer;
