import React, {Fragment, useState} from 'react'
import {Button} from "../../../ui-components";
import styles from "./styles.module.scss";
import {LoaderIcon, TrashIcon, AcceptedIconGradient} from "../../../assets";
import {Modal, Spinner} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {cancelErOffer} from "../../requests/er-thunk";

const OffersList = () => {

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [brigade_uuid, setBrigadeUuid] = useState<string>('')
  const {pool} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  return (
    <div id={styles.offersContainer}>
      {pool.request_brigade?.map((el, i) =>
        <div key={i} className={styles.offerWrapper}>
          <div className={styles.info}>
            <p className={styles.textMain}>
              Отправленное предложение {i + 1}
            </p>
            <div className='d-flex'>
              <p className={styles.text}>Бригада: {el.brigade_type}</p>
              <p className={styles.text}>Время доезда: {el.eta} мин.</p>
            </div>
          </div>
          <div className={styles.actionBox}>
            {el.approved_by  ? <AcceptedIconGradient className={styles.acceptedIcon}/> :
              <Fragment>
                <Spinner />
                <TrashIcon onClick={() => {
                  setBrigadeUuid(el.uuid)
                  setModalOpen(true)
                }} className={styles.trash}/>
              </Fragment>
            }
          </div>
        </div>
      )}
      <Modal open={modalOpen}
             closeIcon
             className={styles.cancelOfferModal}
             title={'Отменить Предложение'}
             handleClose={() => setModalOpen(false)}>
        <p className="modalText mb-50">Вы уверены, что хотите отменить предложение?</p>
        <div className='d-flex space-between'>
          <Button className={styles.modalBtn} type='contained' onClick={() => dispatch(cancelErOffer(brigade_uuid))}>Да</Button>
          <Button className={`ml-30 ${styles.modalBtn}`} type='outlined' onClick={() => setModalOpen(false)} >Нет</Button>
        </div>
      </Modal>
    </div>
  )
}

export default OffersList
