import React, { Fragment } from 'react'
import styles from "./styles.module.scss";
import {AmbulanceIcon} from "../../../assets";
import {InfoText} from "../../../shared";
import {useAppSelector} from "../../../core/hooks";
import {sortDataByDate} from "../../../services/utils";

const BrigadeInfo = () => {
  const {pools} = useAppSelector((state) => state.requests)

  const selectedPool = pools[0]

  return (
    <Fragment>
      {sortDataByDate(selectedPool.request_brigade).map((el, i) => {
        return (el.status !== 3 ? <div key={i} id={styles.brigadeInfo}>
          <p className={styles.title}><AmbulanceIcon/> {'Бригада'}</p>
          <div className={styles.wrapper}>
            <InfoText title={'Тип бригады'} text={el?.brigade_type}/>
            <InfoText title={'Время доезда'} className='ml-20' text={`${el?.eta} ${el.delay ? `+ ${el.delay}` : ''} мин`}/>
            {el.brigade_number && <InfoText title={'Номер Бригады'} className='ml-10' text={el.brigade_number}/>}
            {el.brigade_responsible && <InfoText title={'Ответственный Бригады'} className='mt-20' text={el.brigade_responsible}/>}
            {el.comment && <InfoText title={'Комментарий'} className='ml-20 mt-20' text={el.comment}/>}
          </div>
        </div> : null)
      })}
    </Fragment>
  )
}

export default BrigadeInfo
