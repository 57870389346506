import {io} from "socket.io-client";

const {REACT_APP_SOCKET_URL} = process.env

export default class SocketAPI {
  private socket:any
  private q = [] as Array<{
    event:string,
    cb: Function
  }>
  connect(){
    const token = localStorage.getItem('token')
    this.socket = io(REACT_APP_SOCKET_URL as string, {
      auth: {
        Bearer: `${token}`
      },
      transports: [ 'websocket', 'polling' ]
    });
    this.socket.on("connect", () => {
      for(const q of this.q){
        this.on(q.event, q.cb)
      }
      this.q = []
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve('disconnected');
      });
    });
  }

  emit(event: string, data: any) {
    return this.socket.emit(event, data)
  }

  on(event: string, cb: Function) {
    if(!this.socket){
      return this.q.push({event, cb})
    }
    return this.socket.on(event, cb);
  }
}
