import React, {Fragment, useState, memo} from 'react'
import styles from "./styles.module.scss";
import {AmbulanceIcon, EditIcon, TrashIcon} from "../../../assets";
import {PrimaryModal, InfoText} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import { togglePopover } from '../../../app/app-slice';
import {requestClosed, sortDataByDate} from '../../../services/utils';
import { removeAdditionalBrigade } from '../../requests/er-thunk';
import {Button} from "../../../ui-components";

const BrigadeInfo = () => {
  const dispatch = useAppDispatch()
  const {pool, request} = useAppSelector((state) => state.requests)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <Fragment>
      {sortDataByDate(pool.request_brigade).map((el, i) => {
        return <div key={i} id={styles.brigadeInfo}>
          <p className={styles.title}><AmbulanceIcon/> {el.status === 3 ? 'Дополнительная бригада' : 'Бригада'}</p>
          {(el.status !== 3 && !requestClosed(request.status)) && <EditIcon onClick={() => dispatch(togglePopover('editBrigadeFormOpen'))} className={styles.editIcon}/>}
          {(el.status === 3 && !requestClosed(request.status)) && <TrashIcon className={styles.trashIcon} onClick={() => setModalOpen(true)}/>}
          <div className={`${styles.wrapper}`}>
            <InfoText title={'Тип бригады'} text={el?.brigade_type}/>
            <InfoText title={'Время доезда'} className='ml-20'
                      text={`${el?.eta} ${el.delay ? `+ ${el.delay}` : ''} мин`}/>
            {el.brigade_number &&
            <InfoText title={'Номер Бригады'} className='ml-15' text={el.brigade_number}/>}
            {el.brigade_responsible &&
            <InfoText title={'Ответственный Бригады'} className='mt-20' text={el.brigade_responsible}/>}
            {el.comment && <InfoText title={'Комментарий'} className='ml-20 mt-20' text={el.comment}/>}
          </div>
        </div>
      })}
      <PrimaryModal
        open={modalOpen}
        text={'Удалить бригаду'}
        description={'Вы уверены, что хотите удалить дополнительную бригаду?'}
        footer={
          <div className='d-flex space-between'>
            <Button type='contained' fullWidth onClick={() => {
              dispatch(removeAdditionalBrigade())
                .then(res => res && setModalOpen(false))
            }}>Да</Button>
            <Button type='outlined' fullWidth onClick={() => setModalOpen(false)} className='ml-30'>Нет</Button>
          </div>
        }
        handleClose={() => setModalOpen(false)}/>
    </Fragment>
  )
}

export default memo(BrigadeInfo)
