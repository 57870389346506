import React, {useEffect} from 'react'
import styles from './toaster.module.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setData} from "../../app/app-slice";

const Toaster = () => {
  const dispatch = useAppDispatch()
  const {toaster} = useAppSelector(state => state.app)

  const hideToaster = (): void => {
    dispatch(setData({
      data: {...toaster, visible: false},
      prop: 'toaster'
    }))
  }

  useEffect(() => {
   setTimeout(() => hideToaster(), 5000)
  }, [])

  return (
    <div className={`${styles.toasterContainer} ${styles[toaster.color]}`}>
      <span className={styles.closeIcon} onClick={hideToaster}/>
      <p className={styles.text}>{toaster.message}</p>
    </div>
  )
}

export default Toaster
