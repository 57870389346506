import React, {ComponentType, useContext, useEffect, useState} from 'react';
import SocketContext from "../core/socket-context";
import {useAppDispatch, useAppSelector} from "../core/hooks";
import {setCustomerTabBadge, updateCommentsCount} from '../modules/requests/requests-slice';
import { updateNotifications } from '../modules/notifications/notifications-slice';
import { setData } from "../app/app-slice";
import { playNotificationSound } from '../modules/notification-sound-manager/notifications-sound-manager-slice'
import {
  ER_CONN_STATUS_MESSAGES,
  INSURANCE_CONN_STATUS_MESSAGES,
  ER_CALL_STATUSES
} from '../services/constants'
import {setStringValue} from "../services/utils";
import {getDpErRequestsList, getSentryRequestsList} from "../modules/requests/er-thunk";

export const withSocketInstance = (Component: ComponentType) => () => {
  const socket = useContext(SocketContext);
  const dispatch = useAppDispatch()
  const {user} = useAppSelector(state => state.user)
  let [triggerCount, setTriggerCount] = useState<number>(0)
  let [triggerAlarmCount, setTriggerAlarmCount] = useState<number>(0)
  let [triggerCommentCount, setCommentAlarmCount] = useState<number>(0)
  const listeners = [
    {
      listeners: ['new_comment'],
      action: (e) => {
        dispatch(updateCommentsCount(e.uuid))
      },
    },
    {
      listeners: ['customer_new_comment'],
      action: (e) => {
        dispatch(updateCommentsCount(e.uuid))
      },
    },
    {
      listeners: ['customer_admin_dashboard_update'],
      action: (e) => {
        dispatch(getSentryRequestsList(e.uuid))

      },
    },
    {
      listeners: ['customer_emergency_dashboard_update'],
      action: (e) => {
        const activeTab: any = sessionStorage.getItem("tab")
        if (+activeTab ===2 ) {
          dispatch(getDpErRequestsList(e?.uuid))
        } else {
          dispatch(setCustomerTabBadge())
        }
      },
    },
    {
      listeners: ['notification'],
      action: (notification) => {
        if (notification.data && notification.data.isAlarmNotification) {
          setTriggerAlarmCount(++triggerAlarmCount)
        } else {
          setTriggerCount(++triggerCount)
        }
        dispatch(updateNotifications(notification))
      },
    },
    {
      listeners: ['activity'],
      action: (activity) => {
         if (activity?.data && activity?.data?.status && (
          activity?.data.status === ER_CALL_STATUSES.onRoute ||
          activity?.data.status === ER_CALL_STATUSES.arrived ||
          activity?.data.status === ER_CALL_STATUSES.endedLeftInPlace ||
          activity?.data.status === ER_CALL_STATUSES.endedRefusedHospital ||
          activity?.data.status === ER_CALL_STATUSES.onRouteToHospital ||
          activity?.data.status === ER_CALL_STATUSES.stationing ||
          activity?.data.status === ER_CALL_STATUSES.ended ||
          activity?.data.status === ER_CALL_STATUSES.bookingOfferReceived ||
          activity?.data.status === ER_CALL_STATUSES.hospitalizationRequest
        )) {
          setTriggerCount(++triggerCount)
        } else if (activity?.data.status === ER_CALL_STATUSES.erReplacementRequired) {
          setTriggerAlarmCount(++triggerAlarmCount)
        } else if (activity?.data && activity?.data.newComment) {
           setCommentAlarmCount(++triggerCommentCount)
         }
      },
    },
    {
      listeners: ['connection'],
      action: (connection) => {
        const isEmergency = user.organization.type === 2
        let msg: string = ''

        if(isEmergency) {
          msg = setStringValue(ER_CONN_STATUS_MESSAGES[connection.status], connection?.organization)
        } else {
          msg = setStringValue(INSURANCE_CONN_STATUS_MESSAGES[connection.status], connection?.organization)
        }

        const data = {
          visible: true,
          color: 'default',
          message: msg
        }
        dispatch(setData({data, prop: 'toaster'}))
      },
    }]

  useEffect(() => {
    if(triggerCount){
      dispatch(playNotificationSound('socketNotificationSound'))
      setTriggerCount(0)
    } else if (triggerAlarmCount) {
      dispatch(playNotificationSound('timerNotificationSound'))
      setTriggerAlarmCount(0)
    } else if (triggerCommentCount) {
      dispatch(playNotificationSound('commentSound'))
      setCommentAlarmCount(0)
    }
  }, [triggerCount, triggerAlarmCount, triggerCommentCount])

  useEffect(() => {
    for (const listener of listeners) {
      for (const eventName of listener.listeners) {
        socket.on(eventName, listener.action)
      }
    }
  }, [])

  return <Component/>
}

export default withSocketInstance;

