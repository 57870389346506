import React, {ChangeEvent, useEffect} from 'react'
import InputMask from 'react-input-mask'
import {Checkbox, Input} from "../../ui-components";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {handleFormChange, formFields} from './admin-center-slice';
import '../../assets/styles/default-form.scss'
import {AttachIcon} from "../../assets";
import {prepareFileUpload} from "../../services/utils";
import styles from './admin.module.scss'
import {CITIES} from "../../services/constants";

const AdminCenterForm = ({admin}) => {
  const {form, validationFields} = useAppSelector(state => state.adminCenter)
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if(admin){
      for(const prop in admin){
        if(formFields.includes(prop)){
          dispatch(handleFormChange({key: prop, value:admin[prop]}))
        }
      }
    }
  }, [admin])

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  const handleCheckboxChange = (e, key) => {
    let cities: Array<number> = [...form.cities]
    if (e.target.checked) {
      if(key === 1) {
        cities = []
      } else {
        const allCitiesIndex = cities.findIndex(el => el === 1)
        if(allCitiesIndex !== -1) {
          cities.splice(allCitiesIndex, 1)
        }
      }
      cities.push(key)
    } else {
      const index = cities.findIndex(el => el === key)
      cities.splice(index, 1)
    }
    dispatch(handleFormChange({key: 'cities', value: cities.length === CITIES.length - 1 ? [1] : cities}))
  }
  // handle img preview and set form data before upload
  const handleImgUpload = (e) => {
    const reader = new FileReader();

    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => dispatch(handleFormChange({key: 'preview_avatar', value: reader.result as string}))
    dispatch(handleFormChange({key: 'formData', value: prepareFileUpload(e)}))
  }

  return (
    <div id={styles.adminForm} className="overflow-scroll">
      <div className="temp-03-form">
        <div className={'mb-40'}>
          <Input name='name'
                 error={!validationFields.name}
                 onChange={handleInputChange}
                 value={form.name} label="Название"/>
        </div>
        <div className={'wrapper'}>
          <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                     className={styles.input}
                     name='phone'
                     error={!validationFields.phone}
                     onChange={(e) => e && handleInputChange(e)}
                     value={form.phone} label="Телефон">
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
          <Input name='email'
                 error={!validationFields.email}
                 onChange={handleInputChange}
                 value={form.email} label="Почта"/>
          <Input name='address'
                 error={!validationFields.address}
                 onChange={handleInputChange}
                 value={form.address} label="Адрес"/>
        </div>
        <div className={'wrapper'}>
          <Input name='abbr'
                 error={!validationFields.abbr}
                 onChange={handleInputChange}
                 value={form.abbr} label="Аббревиатура"/>
          <Input name='inn'
                 error={!validationFields.inn}
                 onChange={handleInputChange}
                 value={form.inn} label="Номер лицензии"/>
        </div>
        <h4 className='mb-15'>Выбрать регионы (выбраны все по умолчанию)</h4>
        <div className="wrapper">
          {

            CITIES.map((el, i) => {
              const checked: boolean = form.cities?.some(key => key === el.key)
              return <Checkbox checked={checked}
                               error={!validationFields.cities}
                               label={el.value}
                               onClick={(e) => handleCheckboxChange(e, el.key)}/>
            })
          }
        </div>
        {(form.avatar || form.preview_avatar) &&
          <div className={styles.avatarWrapper}>
            {/*<span onClick={removeAvatar} className={styles.removeIcon} />*/}
            <img className={styles.avatar} src={form.preview_avatar || form.avatar} alt="img"/>
          </div>}
        <label htmlFor='img-upload' className={styles.imgUploadWrapper}>
          <AttachIcon/>
          <span>Загрузить фотографию</span>
          <input id='img-upload'
                 accept="image/*"
                 onChange={handleImgUpload}
                 className='hidden' type='file'/>
        </label>
      </div>
    </div>
  )
}

export default AdminCenterForm
