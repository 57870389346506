import React from 'react';

const SocketContext = React.createContext({
  on:(eventName: string, cb: any) => null,
  connect:()=> null
});

export const SocketProvider = SocketContext.Provider

export default SocketContext;

