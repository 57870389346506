import moment from 'moment'
import {USER} from "./constants";

export interface IGlobalFilter {
  q?: string
  limit?: number
  offset?: number
  from?: string
  to?: string
  data?: { [key: string]: any }
}

// check if current user organization is emergency type
export const isEmergencyOrg = () => USER.organization.type === 2

// convert date-time to string
export const setDate = (date, format = 'DD/MM/YYYY, HH:mm') => moment(date).format(format)

export const prepareFileUpload = (e): FormData => {
  const formData = new FormData();
  for (const f of e.target.files) {
    formData.append("file", f);
  }
  return formData;
}

export const downloadFile = (file, fileName) => {
  const url = window.URL.createObjectURL(file.data)
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
}

export const buildOffersAmountMessage = (condition): string => {
  let text = ''

  if (condition === 1) {
    text = 'предложение'
  } else if (condition > 1 && condition < 5) {
    text = 'предложения'
  } else {
    text = 'предложений'
  }

  return text;
}

export const buildProblemsAmountMessage = (condition): string => {
  let text = ''

  if (condition === 1) {
    text = 'проблема'
  } else if (condition > 1 && condition < 5) {
    text = 'проблемы'
  } else {
    text = 'проблем'
  }

  return text;
}

export const toBase64 = (arr) => {
  return `data:image/jpeg;base64, ${btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
}

// check if request is not active anymore
export const requestClosed = (status: number): boolean => {
  return status === 4
}

// change format to DD/MM/YYYY
export const changeDateFormat = (date: string) => {
  const formattedDate = date.split('/')
  return `${formattedDate[1]}/${formattedDate[0]}/${formattedDate[2]}`
}

export const sortDataByDate = ([...data]: Array<Object>, dateType: string = 'created_at'): Array<any> => {
  return data.sort((a, b) => {
    const dateA = new Date(a[dateType])
    const dateB = new Date(b[dateType])

    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  })
}

export const setStringValue = (stringToReplace, stringData): string => {
  return stringToReplace.replace(/\[.*?\]/g, stringData);
}

export const getQueryProps = (prop) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return prop ? params[prop] : params
}


export const getRegion = () => {
  let region = getQueryProps('regionId')
  region = region === '1' ? '' : region
  return region
}

// get query string like data from object
export const getKeyValueStringFromObj = (obj): string => {
  return Object.keys(obj).map(key => {
    if (obj[key]) {
      return key + '=' + obj[key]
    }
    return undefined
  }).filter(el => el !== undefined).join(',');
}
