import React, {FC, useEffect, useRef, useState} from 'react'
import "react-datetime/css/react-datetime.css";
import './datepicker.scss'
import DateTime, {ViewMode} from 'react-datetime';
import {Input} from '../../ui-components';
import moment, {Moment} from 'moment';
import {CalendarIcon, ArrowDownIcon} from '../../assets';
import 'moment/locale/ru';

interface Props {
  label?: string
  className?: string
  value: Moment | string | null
  initialValue?: Moment | string | null
  name?: string
  error?: boolean
  disableDates?: string
  inputDisabled?: boolean
  handleDateChange: Function
  initialView?: ViewMode
  readOnly?: boolean
}

const Datepicker: FC<Props> = (props) => {
  const {label, value, name, error, initialValue} = props
  const inputProps = {
    readOnly: true,
    className: `custom-input ${props.className ? props.className : ''}`,
  }

  const yesterday = moment().subtract(1, 'day');
  let valid = (current) => current.isAfter(yesterday);

  if (props.disableDates) {
    if(props.disableDates === 'future') {
      const today = moment();
      valid = (current) => current.isBefore(today);
    }
  }

  const renderInput = (props) => <Input startIcon={<CalendarIcon/>}
                                        name={name}
                                        error={error}
                                        onKeyDown={(e) => {
                                          if(props.readOnly) {
                                            e.preventDefault()
                                          }
                                        }}
                                        endIcon={<ArrowDownIcon/>}
                                        label={label} {...props}/>

  return (
    <DateTime dateFormat={'DD/MM/YYYY'}
              isValidDate={props.disableDates ? valid : () => true}
              inputProps={inputProps}
              onNavigate={() => 'years'}
              initialViewMode={props.initialView}
              closeOnSelect={true}
              timeFormat={false}
              value={moment(new Date(value as any)) || ''}
              initialValue={new Date(initialValue as any) || ''}
              onChange={(date) => {
                props.handleDateChange(date)
              }}
              renderInput={renderInput}/>
  )
}

export default Datepicker
