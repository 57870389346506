import React, {useCallback, useState} from "react";
import { Button } from "../../ui-components";
import styles from './OfferToEvent.module.scss'
import {useAppDispatch} from "../../core/hooks";
import OfferToEventItem from "./OfferToEventItem";
import {sendCustomerOfferBrigadeRequests} from "../requests/er-thunk";

const OfferToEvent = ({offersMade, setOpenSentryModal, request}) => {
  const dispatch = useAppDispatch()
  const [selectedOptionForRequest, setSelectedOptionForRequest] = useState([])

  const handleSubmit = useCallback(() => {

    if (selectedOptionForRequest.length > 0) {
      dispatch(sendCustomerOfferBrigadeRequests({requests: selectedOptionForRequest, setOpenSentryModal}))
    }else {
      setOpenSentryModal(false)
    }

  }, [dispatch, selectedOptionForRequest, setOpenSentryModal])

  const changeOption = useCallback((dataValue) => {
    const newArray = selectedOptionForRequest?.filter((el: any) =>  el?.request_uuid !== dataValue?.item[0].request_uuid)
    if (+dataValue.value === 1) {
      // @ts-ignore
      setSelectedOptionForRequest([...newArray])
      return
    }else if  (+dataValue.value === 2) {
      // @ts-ignore
      setSelectedOptionForRequest([...newArray, {...dataValue?.item[0], cancel: true }])
      return
    } else {
      const selectedItem = dataValue?.item.find((el) => el.brigade_uuid === dataValue.value)
      // @ts-ignore
      setSelectedOptionForRequest([...newArray, selectedItem])

    }
  }, [selectedOptionForRequest])
  return <>
    <div className={styles.container} >
      <div className={styles.content} >
        <div className={styles.title} >Стоимость услуг *</div>
        <div className={styles.itemContainer}>
          {
            Object.keys(offersMade).map(function(key, index) {
              return <OfferToEventItem request={request} elId={index} changeOption={changeOption} item={offersMade[key]}/>
            })}
        </div>
        {/*<div className={styles.infoText}>*  Цена вызова может измениться, если бригада останется на месте больше часа. Цена вызова может измениться в зависимости от нужды госпитализации</div>*/}
      </div>
    </div>

  {/*  <div className={styles.footerContainer}>*/}
  {/*  <div className={styles.footerContent} >*/}
  {/*    <div className={styles.footerTitle} >Условия договора</div>*/}
  {/*    <div className={styles.footerDescription} >Sollicitudin ut in eget dolor adipiscing elit nullam fermentum sit. Hac vel pharetra hendrerit dignissim orci adipiscing. Sed sit fusce urna at in. Lectus pretium cursus vitae quam duis. At nam fusce turpis ornare. Fermentum facilisi in et pellentesque varius nisi egestas nisl. Nunc lectus sed vulputate eu adipiscing arcu. Lorem morbi nibh iaculis ultricies cursus vel blandit facilisis. Magna mauris sit mauris at adipiscing in. <br/><br/>Cursus mauris duis aliquet sit mi sed ultrices consectetur. Etiam diam neque ipsum felis. Phasellus vel turpis aliquam donec. Quis enim scelerisque porttitor mauris consectetur ut. Id tellus gravida id ultricies ante et volutpat scelerisque nec. Sodales donec convallis a condimentum diam aliquet tortor posuere. Consectetur suspendisse lectus semper ultrices commodo. Cras pellentesque pellentesque egestas nunc dui dignissim. <br/></div>*/}
  {/*  </div>*/}
  {/*</div>*/}
    <div className='d-flex space-between'>
      <Button type='contained'
              fullWidth
              onClick={ handleSubmit}
              >Отправить заказчику</Button>

    </div>
  </>
}

export default OfferToEvent
