import React from 'react'
import styles from './styles.module.scss'
import {MedKitIcon} from "../../../assets";
import {InfoText} from "../../../shared";
import {useAppSelector} from "../../../core/hooks";

const DiagnoseInfo = () => {
  const {er_call} = useAppSelector(state => state.requests)
  return er_call.diagnosis ? (
    <div id={styles.diagnoseInfo}>
      <p className={styles.title}><MedKitIcon/> Диагноз</p>
      <div className={`${styles.wrapper}`}>
        <InfoText title={'Диагноз:'} className={`${styles.info} mb-10`} text={er_call.diagnosis}/>
        {er_call.diagnosis_details && <InfoText title={'Комментарий'} className='mb-10' text={er_call.diagnosis_details}/>}
        {/*{er_call.hospital_comment && <InfoText title={'Причина замены'} text={er_call.hospital_comment}/>}*/}
      </div>
    </div>
  ) : null
}

export default DiagnoseInfo
