import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError, togglePopover } from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {formValidator} from "./validator";
import {toBase64} from "../../services/utils";
import {omit} from 'lodash'

const initialState = {
  admins: [],
  adminCenter: null,
  form:{
    name: '',
    phone:'',
    email:'',
    address:'',
    inn: '',
    abbr: '',
    avatar: '',
    preview_avatar: '',
    formData: '',
    cities: []
  },
  validationFields:{

  } as any,
  formValid: true,
}

const adminCenter = createSlice({
  name: 'adminCenter',
  initialState: initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<any>) => {
      state.admins = action.payload
      return state
    },
    setAdmin: (state, action: PayloadAction<any>) => {
      state.adminCenter = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    resetForm: (state)=>{
      state.form = initialState.form
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    },
  }
});

export const {
  setAdmins,
  setAdmin,
  handleFormChange,
  validateInputs,
  resetForm
} = adminCenter.actions;

export const getAdmins = (filter = {type:4}) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.get('organization', filter)
      dispatch(setAdmins(res))
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const createAdmin = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminCenter = getState().adminCenter

    if (adminCenter.formValid) {
      const data = {...adminCenter.form}
      try {
        let response: any

        delete data.formData
        delete data.preview_avatar

        response =  await api.admin.post('organization', {...data, type: 4})
        if(adminCenter.form.formData) {
          const avatar = await dispatch(uploadAvatar(response.uuid))
          await api.admin.put(`organization/${response.uuid}`, {avatar: avatar.data})
        }
        dispatch(togglePopover('adminAddOrganization'))
        await dispatch(getAdmins())
        await dispatch(clearForm())
      } catch (e) {
        console.error('failed to create admin with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateAdmin = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminCenter = getState().adminCenter

    if (adminCenter.formValid) {
      const data = omit({...adminCenter.form}, ['preview_avatar'])
      try {
        if(data.formData) {
          const response = await dispatch(uploadAvatar(adminCenter.adminCenter.uuid))
          data.avatar = response.data
        }
        console.log('adminCenter', adminCenter)
        delete data.formData
        await api.admin.put(`organization/${adminCenter.adminCenter.uuid}`, data)
        dispatch(togglePopover('adminAddOrganization'))
        await dispatch(clearForm())
        return await dispatch(getAdmins())
      } catch (e) {
        console.error('failed to create admin with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const selectAdmin = (data) => {
  return (dispatch: AppDispatch) => {
    if(data?.avatar) {
      dispatch(getAvatar(data.uuid, data.avatar))
    }
    dispatch(setAdmin(data))
  }
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const uploadAvatar = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {form} = getState().adminCenter
    try {
      return await api.admin.upload(`organization/avatar/${uuid}`, form.formData)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const img =  await api.admin.get(`organization/avatar/${uuid}/${avatar}`)
      dispatch(handleFormChange({key: 'preview_avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const deleteAdmin = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.delete(`/organization/${uuid}`)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default adminCenter.reducer;
