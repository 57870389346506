import React, { FC } from 'react'
import {Input} from "../../ui-components";
import {SearchIcon} from '../../assets'
import styles from './styles.module.scss'

interface Props {
  placeholder?: string
  value: string
  className?: string
  handleChange: (...args) => void
}

const Search: FC<Props> = (props) => {
  return (
    <Input placeholder={props.placeholder || 'Поиск по имени или номеру'}
           className={`${styles.search} ${props.className ? props.className : ''.trim()}`}
           startIcon={<SearchIcon/>}
           onChange={props.handleChange} value={props.value}/>
  )
}

export default Search
