import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../../app/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getRegion} from "../../../services/utils";

import {IPartnerships, Partnership, Filter} from "../IPartnership";

const initialState: IPartnerships = {
  partnerships: [],
  filter: {
    name: '',
    priority: 0,
    status: 0
  }
};

const partnerships = createSlice({
  name: 'partnerships',
  initialState: initialState,
  reducers: {
    setPartnerships: (state, action: PayloadAction<Array<Partnership>>) => {
      for (const key in action.payload) {
        action.payload.forEach(el => el.visible = true)
      }
      state.partnerships = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{ key: string, value: string }>) => {
      state.filter[action.payload.key] = action.payload.value
    },
    filterData: (state) => {
      const filter = state.filter
      let updatedData = state.partnerships
      updatedData.forEach(el => {
        let nameMatched = true
        let statusMatched = true
        let priorityMatched = true
        for (const key in filter) {
          if (key === 'name') {
            el.name.toLowerCase().includes(filter[key]!.toLowerCase()) ? nameMatched = true : nameMatched = false
          } else if (key === 'status' && filter[key]) {
            el.status === filter[key] ?statusMatched = true :statusMatched = false
          } else if (key === 'priority' && filter[key]) {
            el.priority === filter[key] ? priorityMatched = true : priorityMatched = false
          }
        }
        el.visible = nameMatched && statusMatched && priorityMatched
      })

      state.partnerships = updatedData
      return state
    }
  }
});

export const {
  setPartnerships,
  handleFilterChange,
  filterData
} = partnerships.actions;

export const getPartnerships = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {activeRegion} = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const res = await api.partnerships.get('/insurance', {region})
      dispatch(setPartnerships(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const sendConnectionInvitation = (data) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.post('', data)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const suspendConnection = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.put('/suspend', {uuid})
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const unsuspendConnection = (uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.put('/unsuspend', {uuid})
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const cancelConnection = (uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.delete('', {uuid})
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const updateConnection = (data) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.partnerships.put('', data)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const updateFilter = (key: string, value: any) => async (dispatch) => {
  dispatch(handleFilterChange({key, value}))
  return true
}

export default partnerships.reducer;
