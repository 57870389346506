import React, {FC, useEffect, useRef} from 'react'
import {Props} from './ISelect'
import Select, {components} from 'react-select';
import './select.scss'
import {ArrowDownIcon, CloseIcon} from "../../assets";
import checkmark from '../../assets/svgs/checkmark.svg'

const Autocomplete: FC<Props> = (props) => {
  const customStyles = {
    container: (provided) => ({...provided, height: 'min-content'}),
    control: (provided, state) => {
      return ({
        ...provided,
        cursor: 'text',
        backgroundColor: 'transparent',
        border: 0,
        borderBottom: `1px solid ${props.error ? '#D93433' : '#999'}`,
        boxShadow: 'unset',
        borderRadius: 'unset',
        minHeight: 'unset',
        height: '35px',
        '&:focus-within': {borderColor: '#365EDE'},
        '&:hover': {borderBottom: `1px solid ${props.error ? 'red' : '#999'}`},
      })
    },
    placeholder: (provided) => ({
      ...provided,
      display: 'none'
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontFamily: 'Manrope Regular',
      lineHeight: '22px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 0 0 0'
    }),
    indicatorsContainer: (provided, {selectProps}) => {
      return ({
        display: 'flex',
        alignItems: 'center'
      })
    },
    indicatorSeparator: () => ({display: 'none'}),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      svg: {
        width: '27px',
        height: '27px',
        fill: '#365EDE'
      },
      padding: '0 0 2px 0'
    }),
    clearIndicator: (provided) => ({
      cursor: 'pointer',
      svg: {
        width: '20px',
        height: '20px',
      },
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '12px 0'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '16px',
      boxShadow: '0 12px 16px 0 rgba(0,0,0,0.1)',
      //backgroundColor: "#365EDE",
      minWidth: '100px',
      padding: '0',
      zIndex: '100'
    }),
    option: (provided, {isSelected, isFocused}) => ({
      ...provided,
      //color: '#fff',
      fontFamily: 'Manrope Medium',
      cursor: 'pointer',
      backgroundColor: isSelected ? '#2d4fbc' : isFocused ? '#fff' : '',
      '&:hover': {
        backgroundColor: '#2d4fbc',
        color: '#fff'
      },
      '&:after': {
        content: "''",
        background: `url(${checkmark}) no-repeat center`,
        position: 'absolute',
        display: isSelected ? 'inline-flex' : 'none',
        right: '12px',
        width: '15px',
        height: '15px'
      }
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'black'
    })
  }

  const labelRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if(props.value) {
      labelRef.current!.style.transform = 'translateY(-25px)'
      labelRef.current!.style.fontSize = '12px'
    }
  },[props.value])

  const labelBlurAnimation = (e) => {
    if(props.value) {
      labelRef.current!.style.transform = 'translateY(-25px)'
      labelRef.current!.style.fontSize = '12px'
    } else {
      labelRef.current!.style.transform = 'translateY(0)'
      labelRef.current!.style.fontSize = '14px'
    }
  }

  const labelFocusAnimation = () => {
    labelRef.current!.style.transform = 'translateY(-25px)'
    labelRef.current!.style.fontSize = '12px'
  }

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <CloseIcon/>
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div onKeyUp={props.onKeyUp ? props.onKeyUp : undefined}
         className={`select-wrapper ${props.className ? props.className : ''}`}>
      {props.label && <span ref={labelRef} className={`label ${props.error ? 'label-error' : ''} ${props.disabled ? 'label-disabled' : ''}`}>{props.label}</span>}
      <Select options={props.options}
              name='select-name'
              placeholder={'VALOD'}
              components={{ClearIndicator, DropdownIndicator}}
              isClearable={props.isClearable}
              isLoading={props.isLoading}
              isDisabled={props.disabled}
              noOptionsMessage={() => props.noOptionsMessage || 'Нет Данных'}
              value={props.options.filter((el: any) => el[props.optionValue] === props.value)}
              //menuIsOpen
              getOptionLabel={option => option[props.optionLabel]}
              getOptionValue={option => option[props.optionValue]}
              styles={customStyles}
              onBlur={(e) => {
                props.onBlur ? props.onBlur() : undefined
                props.label && labelBlurAnimation(e)
              }}
              menuPlacement={props.menuPlacement}
              onFocus={() => props.label && labelFocusAnimation()}
              onInputChange={value => props.onInputChange && props.onInputChange(value)}
              onChange={(value) => props.onChange ? props.onChange(value || ''): undefined}/>
    </div>
  )
}

export default Autocomplete
