import React, { Fragment, useEffect, useState } from 'react'
import { Table, TableHead, TableBody, Panel, PanelHeader, PanelFooter, Modal } from '../../shared'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {Button} from "../../ui-components";
import { CloseIcon, PlusIcon, EditIcon, EmailIcon } from '../../assets'
import { setApiError, togglePopover } from '../../admin/app-slice'
import {
  getUsers,
  selectUser,
  clearForm,
  updateUser,
  createUser,
  validateInputs,
  setOrganizationUuid,
  deleteUser,
  resendEmail
} from './users-slice'
import {setDate} from '../../services/utils'
import UserFrom from './user-form'

const Users = ({organizationUuid, organizationType}) => {
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.adminApp)
  const {users, user, form} = useAppSelector(state => state.adminUsers)
  const head = ['id', 'ФИО', 'Телефон', 'Доп. Телефон', 'Моб. Телефон', 'Почта', 'Дата', 'Статус', '']
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [sendMailModalOpen, setSendMailModalOpen] = useState<boolean>(false)
  const [userExistsModalOpen, setUserExistsModalOpen] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  useEffect(() => {
    dispatch(setOrganizationUuid(organizationUuid))
    dispatch(getUsers({organization_uuid: organizationUuid}))
    if (app.apiError && app.apiError.error_id === 16) {
      setUserExistsModalOpen(true)
      dispatch(setApiError({}))
    }
  }, [dispatch, app.apiError])

  const submitForm = async () => {

     for (const k in form) {
      dispatch(validateInputs({key: k, value: form[k]}))
     }

     if(user){
       return dispatch(updateUser())
     }
     return dispatch(createUser())
  }

  const closePopover = () => {
    dispatch(clearForm())
    dispatch(selectUser(null))
    dispatch(togglePopover('adminAddUser'))
  }

  const resendEmailToUser = async (email) => {
    await dispatch(resendEmail(email))
    setSendMailModalOpen(false)
  }

  const openResendEmailPopup = (email) => {
    setSendMailModalOpen(true)
    setEmail(email)
  }

  const deleteDispatcher = async (user) => {
    dispatch(clearForm())
    dispatch(selectUser(null))
    await dispatch(deleteUser(user.uuid))
    setDeleteModalOpen(false)
    dispatch(togglePopover('adminAddUser'))
    dispatch(getUsers({organization_uuid: organizationUuid}))
  }
  const rows = users.map((val: any) => {
    return (
      <div className='row' key={val.id}>
        <div className='item half-width' onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{val.id}</div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>
          {val.last_name} {val.first_name} {val.middle_name}
        </div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{val.primary_phone}</div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{val.secondary_phone}</div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{val.mobile_phone}</div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{val.email}</div>
        <div className='item'  onClick={() => {
          dispatch(selectUser(val))
          dispatch(togglePopover('adminAddUser'))
        }}>{setDate(val.created_at, 'DD/MM/YY')}</div>
        <div className='item'>
          {
            val && val.status === 1 ? <EmailIcon className={'ml-15'} onClick={() => openResendEmailPopup(val.email)}/> : 'Активирован'
          }
        </div>
        <div className='item half-width'>
          <EditIcon  onClick={() => {
            dispatch(selectUser(val))
            dispatch(togglePopover('adminAddUser'))
          }}/>
        </div>
      </div>
    )
  })

  const popoverTitle = user ? 'Сохранить' : 'Создать'

  return (
    <Fragment>
      <div id='emergency-list'>
        <div className={'mb-20 flex-end d-flex'}>
          <Button type='contained'
                  color='secondary'
                  onClick={() => {
                    dispatch(selectUser(null))
                    dispatch(togglePopover('adminAddUser'))
                  }}
                  startIcon={<PlusIcon/>}>Добавить Пользователя</Button>
        </div>
        {users.length && <Table>
          <TableHead>
            {head.map((el, i) => <div className={`item ${(el === 'id' || el === 'Статус') ? 'half-width' : ''} `} key={i}>{el}</div>)}
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table> || null}
      </div>
      <Panel open={app.adminAddUser}
             handleClose={() => closePopover()}>
        <PanelHeader className='simple-header'>
          <p>{popoverTitle} Пользователя</p>
          <CloseIcon onClick={() => closePopover()}/>
        </PanelHeader>
        <UserFrom user={user} organizationType={organizationType}/>
        <PanelFooter className='action-box'>
          {user && user!['status'] === 1 && <Button type='contained' onClick={() => openResendEmailPopup(user.email)} className='ml-20'>Переслать</Button>}
          {user && <Button type='contained' onClick={() => setDeleteModalOpen(true)} className='ml-20'>Удалить</Button>}
          <Button type='simple' onClick={() => closePopover()}>Отмена</Button>
          <Button type='contained' onClick={submitForm} className='ml-20'>{popoverTitle}</Button>
        </PanelFooter>
      </Panel>
      <Modal open={deleteModalOpen}
             closeIcon
             title={'Удалить пользевателья?'}
             handleClose={() => {
               setDeleteModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите удалить учетную запись пользователя?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => deleteDispatcher(user)}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setDeleteModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
      <Modal open={sendMailModalOpen}
             closeIcon
             title={'Отправить почту?'}
             handleClose={() => {
               setSendMailModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите переслать письмо?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => resendEmailToUser(email)}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setSendMailModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
      <Modal open={userExistsModalOpen}
             closeIcon
             title={'Пользователь существует'}
             handleClose={() => {
               setUserExistsModalOpen(false)
             }}>
        <p className="modalText mb-50">Пользователь с таким адресом электронной почты уже существует.</p>
        <div className='d-flex'>
          <Button fullWidth
                  type='contained'
                  onClick={() => setUserExistsModalOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default Users
