import React, {useEffect, useState, useRef, useMemo} from 'react'
import {togglePopover} from "../../../app/app-slice";
import {PanelFooter, PanelPopover} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {Button} from "../../../ui-components";
import './styles.scss'
import {CircleGradientIcon} from "../../../assets";
import {customerErInterested} from "../../requests/er-thunk";
import {requestClosed} from '../../../services/utils';
import CustomerDpInfo from "../CustomerDpInfo";
import BrigadeInfo from "../../brigade/brigadeInfo";
import DpOffersList from "./DpOffersList";
import BrigadeForm from "./brigade-form";
import DpCancelOffer from "./DpCancelOffer";
import CustomerReplaceHospitalForm from "../DcComponents/customerReplace-hospital-form";

const DpNewRequest = () => {
  const [formList, setFormList] = useState<any>([{brigade_type: '', eta: '', price: ""}])
  const [dataEmpty, setDataEmpty] = useState<boolean>(false)
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)
  const {request, pool, er_call} = useAppSelector(state => state.requests)
  const offersRef = useRef(null)
  useEffect(() => {
    const res = formList.map(el => Object.values(el).some(val => !val))
    setDataEmpty(res.some(el => el === true))
  }, [formList])

  const setData = (key, value, index) => {
    const list = [...formList]
    list[index][key] = value
    setFormList(list)
  }

  const addForm = async () => {
    const list = [...formList]
    list.push({
      brigade_type: '',
      price: "",
      eta: ''
    })
    await setFormList(list)
    setScroll()
  }

  const setScroll = () => {
    const container: any = offersRef.current
    container.querySelectorAll('div #panel-popover')[0].scrollTop = container.querySelectorAll('div #panel-popover')[0].scrollHeight
  }
  const removeForm = (idx: number) => {
    const list = [...formList]
    list.splice(idx, 1)
    setFormList(list)
  }

  const selectedRequestBrigade = useMemo(() => {
    if (request) {
      return request?.requestedBrigades?.filter((b) => b.request_uuid === request?.uuid)
    }
  }, [request])


  return (
    <div id='new-request' className='request-info-container pb-80'>
      {pool && pool.request_brigade && <DpOffersList/>}
      <CustomerDpInfo/>
      {request && request?.requestedBrigades && selectedRequestBrigade.map((item) => {
        return <BrigadeInfo br={item}/>
      })}
      {!requestClosed(request?.status) &&pool?.status !== 3 &&
        <PanelFooter className='footer'>
          <div style={{display: "flex", gap: 12, width: "100%"}}>
          <Button onClick={() => {
            if (er_call && er_call.status === 1) {
              dispatch(togglePopover('acceptFormOpen'))
            }else {
              dispatch(togglePopover('offerFormOpen'))
            }
          }}
                  className='offer-button'
                  type='contained'>{er_call && er_call.status === 1 ? "Назначить Бригаду": "Отправитъ Предложение"}</Button>
          <Button onClick={() => {dispatch(togglePopover('dpCancelOffer'))}}
                  className='offer-button'
                  type='outlined'>Отказ от заявки</Button>
          </div>
        </PanelFooter>}
      <div ref={offersRef}>
        <PanelPopover open={app.popoverFormStatuses.offerFormOpen}
                      handleClose={() => dispatch(togglePopover('offerFormOpen'))}
                      title={'Создатъ предложение'}>
          {formList.map((el, i) => <BrigadeForm brigade_type={el.brigade_type}
                                                listCount={formList.length}
                                                price={el.price}
                                                handleFormRemove={() => removeForm(i)}
                                                key={i}
                                                handleInputChange={(key, value) => setData(key, value, i)}
                                                eta={el.eta}/>)}

          <div className='offer-form-footer'>
            <p className='add-btn' onClick={addForm}><CircleGradientIcon/>Добавить</p>
            {pool?.status !== 3 && <Button type='contained' disabled={dataEmpty} fullWidth
                     onClick={() => {
                       setClickDisabled(true)
                       dispatch(customerErInterested(formList))
                         .then(res => setClickDisabled(false))
                     }}
                     className={`send-btn ${clickDisabled ? 'click-disabled' : ''}`}>Отправитъ Предложение</Button>}
          </div>
        </PanelPopover>
        <PanelPopover title={'Отказ'}
                      open={app.popoverFormStatuses.dpCancelOffer}
                      handleClose={() => dispatch(togglePopover('dpCancelOffer'))}>
          <DpCancelOffer request={request}/>
        </PanelPopover>
      </div>
    </div>
  )
}

export default DpNewRequest
