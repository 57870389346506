import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import { useAppDispatch } from '../../../core/hooks'
import {cancelDpOfferRequests} from "../../requests/insurance-thunk";
const DpCancelOffer = ({request}) => {
  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const submit = (): void => {
    if(comment) {
      setClickDisabled(true)
      dispatch(cancelDpOfferRequests({comment: {rejection_reason: comment}, item: request }))
        .then(res => setClickDisabled(false))
    }
  }
  return (
    <div id={styles.replaceBrigadeForm}>
      <Input label='Причина отказа' className={`${styles.input} mb-40`} value={comment}
             onKeyUp={(e) => e.which === 13 && submit()}
             onChange={e => setComment(e.target.value)}/>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!comment}
              onClick={submit}
              type={'contained'}>Отказ</Button>
    </div>
  )
}

export default DpCancelOffer
