import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError, togglePopover } from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {formValidator} from "./validator";
import {toBase64} from "../../services/utils";
import {omit} from 'lodash'

const initialState = {
  insurances: [],
  insurance: null,
  form:{
    name: '',
    phone:'',
    email:'',
    address:'',
    inn: '',
    abbr: '',
    avatar: '',
    preview_avatar: '',
    formData: '',
    cities: []
  },
  validationFields:{

  } as any,
  formValid: true,
}

const emergency = createSlice({
  name: 'adminInsurance',
  initialState: initialState,
  reducers: {
    setInsurances: (state, action: PayloadAction<any>) => {
      state.insurances = action.payload
      return state
    },
    setInsurance: (state, action: PayloadAction<any>) => {
      state.insurance = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    resetForm: (state)=>{
      state.form = initialState.form
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    },
  }
});

export const {
  setInsurances,
  setInsurance,
  handleFormChange,
  validateInputs,
  resetForm
} = emergency.actions;

export const getInsurances = (filter = {type:3}) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.get('organization', filter)
      dispatch(setInsurances(res))
      return res
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const createInsurance = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminInsurance = getState().adminInsurance

    if (adminInsurance.formValid) {
      const data = {...adminInsurance.form}
      try {
        let response: any

        delete data.formData
        delete data.preview_avatar

       response =  await api.admin.post('organization', {...data, type: 3})
        if(adminInsurance.form.formData) {
          const avatar = await dispatch(uploadAvatar(response.uuid))
          await api.admin.put(`organization/${response.uuid}`, {avatar: avatar.data})
        }
        dispatch(togglePopover('adminAddOrganization'))
        await dispatch(getInsurances())
        await dispatch(clearForm())
      } catch (e) {
        console.error('failed to create emergency with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateInsurance = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminInsurance = getState().adminInsurance

    if (adminInsurance.formValid) {
      const data = omit({...adminInsurance.form}, ['preview_avatar'])
      try {
        if(data.formData) {
          const response = await dispatch(uploadAvatar(adminInsurance.insurance.uuid))
          data.avatar = response.data
        }
        delete data.formData
        await api.admin.put(`organization/${adminInsurance.insurance.uuid}`, data)
        dispatch(togglePopover('adminAddOrganization'))
        await dispatch(clearForm())
        return await dispatch(getInsurances())
      } catch (e) {
        console.error('failed to create emergency with error:', e)
        dispatch(setApiError(e))
      }
    }
  }
};

export const selectInsurance = (data) => {
  return (dispatch: AppDispatch) => {
    if(data?.avatar) {
      dispatch(getAvatar(data.uuid, data.avatar))
    }
    dispatch(setInsurance(data))
  }
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const uploadAvatar = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {form} = getState().adminInsurance
    try {
      return await api.admin.upload(`organization/avatar/${uuid}`, form.formData)
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const img =  await api.admin.get(`organization/avatar/${uuid}/${avatar}`)
      dispatch(handleFormChange({key: 'preview_avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const deleteOrganization = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.delete(`/organization/${uuid}`)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default emergency.reducer;
