import React, {FC, useMemo, useState} from 'react'
import styles from './styles.module.scss'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {addDiagnoseCustomer} from "../../requests/er-thunk";
import {debounce} from 'lodash'
import {getDiagnosesList} from "../../../app/app-slice";

interface Props {
  status: number
}
const CustomerDiagnoseForm: FC<Props> = ({status}) => {
  const [diagnosis, setDiagnosis] = useState<string>('')
  const [diagnosis_details, setDiagnosisDetails] = useState<string>('')
  const {diagnoses} = useAppSelector(state => state.app)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      setLoading(true)
      dispatch(getDiagnosesList(query))
        .then(res => setLoading(false))
    }, 1000),
    [dispatch]
  );

  return (
    <div id={styles.replaceBrigadeForm}>
      <Autocomplete
        className='mb-40'
        options={diagnoses}
        value={diagnosis}
        isLoading={loading}
        onChange={(data) => setDiagnosis(data?.title)}
        onInputChange={(value) => value && debouncedChangeHandler(value)}
        menuPlacement={'top'}
        label={'Диагноз МКБ10'}
        optionLabel={'title'}
        noOptionsMessage={'Введите диагноз'}
        optionValue={'title'}/>
      <Input label='Добавить комментарий' className='mb-40' value={diagnosis_details}
             onChange={e => setDiagnosisDetails(e.target.value)}/>
      <Button fullWidth disabled={!diagnosis || !status}
              onClick={() => dispatch(addDiagnoseCustomer({status, diagnosis, diagnosis_details}))}
              type={'contained'}>Добавить диагноз</Button>
    </div>
  )
}

export default CustomerDiagnoseForm
