import React, { FC } from 'react'
import './tooltip-select.scss'
import {TooltipBlue} from "../../shared/tooltips";

interface Props {
  value: string | number
  options: Array<any>
  optionValue: string
  onChange: Function
  label: string
  error?: boolean
}


const TooltipSelect: FC<Props> = (props) => {
  return (
    <>
      <input data-tip data-for='select'
             data-event='click'
             value={props.value}/>
      <TooltipBlue id='select'>
        {props.options.map((el, i) => {
          return <p key={i} onClick={() => props.onChange(el)}>{el[props.optionValue]}</p>
        })}
      </TooltipBlue>
    </>
  )
}

export default TooltipSelect
