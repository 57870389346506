import Instances from './instances'
import {Api} from "./base";


export interface ApiInstance {
  [key: string]: Api | any
}

const { REACT_APP_API_URL } = process.env

const ApiEndpoints = Instances as {
  [key:string]:any
}

const customerApi = {} as ApiInstance


for(const prop of Object.keys(ApiEndpoints)){
  const routeName = prop.replace(/^[a-z]|[A-Z]/g, function(v, i) {
    return  i === 0 ? v.toLowerCase() : "-" + v.toLowerCase();
  })

  customerApi[routeName] = new ApiEndpoints[prop](`${REACT_APP_API_URL}/customer`)
}

export default customerApi

