import React, {FC, useMemo, useState} from 'react'
import {Autocomplete} from "../../ui-components";
import {getDiagnosesList} from "../../app/app-slice";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {debounce} from 'lodash'
import styles from "./styles.module.scss";

interface Props {
  value: string
  className?: string
  handleChange: (...args) => void
}

const Diagnose: FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const {diagnoses} = useAppSelector(state => state.app)
  const [loading, setLoading] = useState<boolean>(false)

  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      setLoading(true)
      dispatch(getDiagnosesList(query))
        .then(res => setLoading(false))
    }, 1000),
    [dispatch]
  );

  return (
    <Autocomplete
      className={`${styles.select} ${props.className ? props.className : ''}`}
      options={diagnoses}
      value={props.value}
      isClearable
      isLoading={loading}
      onChange={(data) => props.handleChange(data?.title)}
      onInputChange={(value) => value && debouncedChangeHandler(value)}
      label={'Диагноз МКБ10'}
      optionLabel={'title'}
      noOptionsMessage={'Введите диагноз'}
      optionValue={'title'}/>
  )
}

export default Diagnose
