import React, { FC } from 'react'
import styles from  './spinner.module.scss'

interface Props {
  className?: string
}

const Spinner: FC<Props> = (props) => {
  return (
    <div id={styles.loading} className={props.className ? props.className : ''}>
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
      <div className={styles.bubble} />
    </div>
  )
}

export default Spinner
