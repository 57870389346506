import React, { useMemo, useState} from 'react'
import {PanelFooter, PanelPopover} from '../../../shared';
import {Button, Input} from '../../../ui-components';
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import './styles.scss'
import {togglePopover} from '../../../app/app-slice';
import {assignCustomerBrigade} from "../../requests/er-thunk";
import DpOffersList from "./DpOffersList";
import CustomerDpInfo from "../CustomerDpInfo";
import BrigadeInfo from "../../brigade/brigadeInfo";

const AssignCustomerBrigadeRequest = () => {
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const [brigade, setBrigade] = useState<string>('')
  const [brigadeResponsible, setBrigadeResponsible] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const {request, pool, er_call} = useAppSelector(state => state.requests)
  const selectedRequestBrigade = useMemo(() => {
    return request?.requestedBrigades.filter((b) => b.request_uuid === request?.uuid)
  }, [request])
  return (
    <div id='assign-brigade-request' className='request-info-container pb-80'>
      {pool && pool.request_brigade && <DpOffersList/>}
      <CustomerDpInfo/>
      {request && request?.requestedBrigades && selectedRequestBrigade.map((item) => {
        return <BrigadeInfo br={item}/>
      })}
      <PanelFooter className='footer'>
        <Button onClick={() => dispatch(togglePopover('acceptFormOpen'))} type='contained'>Назначить Бригаду</Button>
      </PanelFooter>
      <PanelPopover open={app.popoverFormStatuses.acceptFormOpen}
                    height={265}
                    title={'Отправить бригаду'}
                    handleClose={() => dispatch(togglePopover('acceptFormOpen'))}>
        <div className="wrapper">
          <Input label='Номер борта' value={brigade}
                 onChange={e => setBrigade(e.target.value)}/>
          <Input label='Ответственный бригады' value={brigadeResponsible}
                 onChange={e => setBrigadeResponsible(e.target.value)}/>
        </div>
        <div className='action-box'>
          <Button type='contained'
                  className={clickDisabled ? 'click-disabled' : ''}
                  onClick={() => {
                    setClickDisabled(true)
                    dispatch(assignCustomerBrigade(brigade, brigadeResponsible))
                      .then(res => setClickDisabled(false))
                  }} disabled={!brigade || !brigadeResponsible}>Выбрать бригаду</Button>
          <Button type='outlined' onClick={() => dispatch(togglePopover('acceptFormOpen'))}>Выбрать позже</Button>
        </div>
      </PanelPopover>
    </div>
  )
}

export default AssignCustomerBrigadeRequest
