import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { setApiError } from "../../../admin/app-slice";
import {AppDispatch} from "../../../core/store";
import {ApiInstance} from "../../../services/api";
import {getKeyValueStringFromObj, getRegion, IGlobalFilter} from "../../../services/utils";
import {omit} from 'lodash'

interface ActiveTickets {
  request_uuid: string
  address: string
  reason: string
  age: number
  sex: string
  created_date: string
  creator: string
  status: number
  number: string
  executor: string
  name: string
  policy_number: string
  arrival_time: number
  complaints: string
  org_name: string
}

interface IActiveTickets {
  activeTickets: Array<ActiveTickets>
  filter: IGlobalFilter,
  allDataLoaded: boolean
}

const initialState: IActiveTickets = {
  activeTickets: [],
  filter: {
    limit: 50,
    offset: 0,
    q: '',
    from: '',
    to: '',
    data: {
      age: '',
      status: '',
    }
  },
  allDataLoaded: true
};

const activeTickets = createSlice({
  name: 'activeTickets',
  initialState: initialState,
  reducers: {
    setActiveTickets: (state, action: PayloadAction<Array<ActiveTickets>>) => {
      state.activeTickets = action.payload
      return state
    },
    handleFilterChange: (state, action: PayloadAction<{key: string, value: any}>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.filter.data![key] = data.value
      } else {
        state.filter[data.key] = data.value
      }
    },
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded =  action.payload
    }
  }
});

export const {
  setActiveTickets,
  handleFilterChange,
  setDataLoaded
} = activeTickets.actions;

export const getActiveTickets = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter} = getState().activeTickets
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/requests-for-info?isForActive=true', {...updatedFilter,  offset: 0, region, filter: additionalData})
      dispatch(handleFilterChange({key: 'offset', value: 0}))
      res.length < filter.limit  ? dispatch(setDataLoaded(true)) : dispatch(setDataLoaded(false))
      dispatch(setActiveTickets(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};


export const loadMoreData = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {filter, activeTickets} = getState().activeTickets
    const { activeRegion } = getState().app
    const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
    try {
      const additionalData = getKeyValueStringFromObj(filter.data)
      const updatedFilter = omit(filter, ['data'])
      const res = await api.request.get('/requests-for-info?isForActive=true', {...updatedFilter, region, filter: additionalData})
      const updatedTickets = [...activeTickets, ...res]
      filter.limit > res.length && dispatch(setDataLoaded(true))
      dispatch(setActiveTickets(updatedTickets))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default activeTickets.reducer;
