import React, {FC, useEffect} from 'react'
import {ArrowDownIcon, CheckmarkIcon, CloseIcon} from "../../assets";
import './header.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setActivePanel} from '../requests/requests-slice';
import {requestClosed, setDate} from "../../services/utils";
import {getDispatchers} from "../dispatchers/dispatchers-slice";
import {TooltipBlue} from "../../shared";
import {changeDispatcher} from "../requests/er-thunk";
import {ER_CALL_STATUSES} from "../../services/constants";

interface Props {
  handleClose: () => void
}

const Header: FC<Props> = (props) => {
  const {
    request,
    pool,
    activePanel,
    er_call,
    insurance_org,
    contract,
    emergency_responsible
  } = useAppSelector(state => state.requests)
  const {list} = useAppSelector(state => state.dispatchers)
  const {user} = useAppSelector(state => state.user)
  const selectedTab = sessionStorage.getItem("tab")
  const activeTab: any = sessionStorage.getItem("tab")
  const dispatch = useAppDispatch()
  useEffect(() => {
    user.role === 2 && dispatch(getDispatchers())
    return () => {
      dispatch(setActivePanel('data'))
    }
  }, [dispatch])

  let status: string
  let statusClass: string = ''

  if (!pool?.status) {
    status = "Дежурство"
    if (request?.status === 2) {
      status = 'Новая заявка'
    }
  } else if (er_call) {
    switch (er_call.status) {
      case ER_CALL_STATUSES.onApproval:
        if (activeTab && +activeTab ===2) {
          status= "Ожидание выезда"
          statusClass = 'blue-gradient'
        } else {
          status = 'Подтверждено'
        }
        break
      case ER_CALL_STATUSES.onRoute:
        status = 'В пути'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.onRouteDelay:
        status = 'В пути'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.arrived:
        status = 'Прибыл'
        statusClass = 'green-gradient'
        break
      case ER_CALL_STATUSES.endedLeftInPlace:
        status = 'Оставлен на месте'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.endedRefusedHospital:
        status = 'Отказ от госпитализации'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.hospitalizationRequest:
      case ER_CALL_STATUSES.bookingRequestSent:
      case ER_CALL_STATUSES.bookingOfferReceived:
        status = 'Ожидает подтверждения'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.bookingApproved:
        status = 'Бронирование подтверждено'
        statusClass = 'green-gradient'
        break
      case ER_CALL_STATUSES.onRouteToHospital:
        status = 'На пути в больницу'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.stationing:
        status = 'Стацианирование'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.ended:
        if (activeTab && +activeTab === 2 ) {
          status="Дежурство завершено"
          statusClass = 'grey-gradient'
        } else {
          status = 'Вызов завершен'
          statusClass = 'grey-gradient'
        }
        break
      case ER_CALL_STATUSES.erReplacementRequired:
        status = 'Требуется замена СМП'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.endedTransferred:
        status = 'Вызов завершен: Передан другой СМП'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.closed:
        status = 'Вызов отменен'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.closedWithPayment:
        status = 'Вызов отменен: оплатить'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.closedWOPayment:
        status = 'Вызов отменен: без оплаты'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.transferInProcess:
        status = 'Замена в процессе'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.transferOnRoute:
        status = 'Замена в пути'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.transferArrived:
        status = 'Замена прибыла'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.onRouteToHospitalDelay:
        status = 'На пути в больницу'
        statusClass = 'orange-gradient'
        break
      case 23:
        status = 'Дежурство'
        statusClass = 'green-gradient'
        break
      case 24:
        status = 'Ожидает выезда'
        statusClass = 'blue-gradient'
        break
      default:
        status = 'По умолчанию ER'
    }

  } else {
    switch (pool?.status) {
      case 1:
        status = 'Новая заявка'
        break
      case 3:
        if (contract) {
          status = 'Ожидает оплаты'
          statusClass = 'blue-gradient'
        } else {
          status = 'Подтверждено'
        }
        break
      case 2:
        if (selectedTab && +selectedTab ===2 ) {
          status = 'Ожидает подтверждения'
        } else {
          status = pool?.approved_by ? 'СК ждет подтверждения' : 'Ожидает подтверждения'

        }
        break
      default:
        status = 'По умолчанию'
    }
  }

  const asteriskRequestNumber = (number) => {
    const groups = number.split('-')
    // const firstGroupLength = groups[0].length
    groups[0] = '*'.repeat(3)
    return  groups.join('-')
  }
  const responsible = `${emergency_responsible?.first_name} ${emergency_responsible?.last_name}`

  return (
    <div id='header'>
      <div className={`request-info ${statusClass ? statusClass : ''}`}>
        <div className="title-wrapper">
          <div className="status-wrapper">
             <p className="title">Статус</p>
            <p className="text">{status}</p>
          </div>
          <div className="actions">
            <CloseIcon onClick={props.handleClose}/>
          </div>
        </div>
        <div className="info-wrapper">
          <div className="info-text">
            {emergency_responsible &&
              <>
                <p className="label">Ответственный:</p>
                <p className="text responsible-person"
                   data-tip data-for='dispatchers-menu'
                   data-event='click'>{responsible} {user.role === 2 && <ArrowDownIcon/>}</p>
                {(!requestClosed(request?.status) && user.role === 2) &&
                  <TooltipBlue offsetLeft={80} offsetTop={1} id='dispatchers-menu'>
                    {list.map((el, i) =>
                      <p className={`${emergency_responsible?.id === el.id ? 'active' : ''}`} onClick={() => dispatch(changeDispatcher(el.uuid))}
                         key={i}>{el.first_name} {el.last_name} {emergency_responsible?.id === el.id && <CheckmarkIcon/>}</p>
                    )}
                  </TooltipBlue>}
              </>}
          </div>
          {activeTab && +activeTab !==2 && <p className="company-name">
            {insurance_org?.name}
            {insurance_org?.avatar && <img src={insurance_org.avatar} alt=''/>}
          </p>}
        </div>
        <div className="info-wrapper">
          <div className="info-text">
            <p className="label">Создан:</p>
            <p className="text">
              {setDate(request?.created_at)}
              {er_call?.status >= 2 ? `· Процесс Начался: ${setDate(er_call?.updated_at)}` : null}
            </p>
          </div>
          <p className="request-number">№ {activeTab && +activeTab ===2 ? asteriskRequestNumber(request?.request_number) :request?.request_number}</p>
        </div>
      </div>
      <div className="panel">
        {pool?.status && <p className={`item ${activePanel === 'data' ? 'active' : ''}`}
            onClick={() => dispatch(setActivePanel('data'))}>Информация</p>}
        {er_call && <p className={`item ${activePanel === 'comment' ? 'active' : ''}`}
                       onClick={() => dispatch(setActivePanel('comment'))}>Комментарии {request?.unread_comments ? `(${request?.unread_comments})` : ''}</p>}
        {er_call && <p className={`item ${activePanel === 'history' ? 'active' : ''}`}
                       onClick={() => dispatch(setActivePanel('history'))}>История</p>}
      </div>
    </div>
  )
}

export default Header
