import React, { forwardRef } from "react";
import cx from "classnames";
import styles from "./Switcher.module.scss";

const Switcher = forwardRef(function Input(
  {
    error,
    disabled,
    className,
    label,
    value,
    defaultChecked,
    onChange,
    id,
    checked,
    name,
    register,
    ...rest
  },
  ref
) {
  return (
    <div
      role="presentation"
      className={cx(className, {
        [styles.toogleContainerInEditMode]: !disabled,
        [styles.toogleContainerWithoutEditMode]: disabled,
      })}
    >
      <div className={styles.darkToogle}>
        <input
          type="checkbox"
          id={name}
          value={value}
          checked={defaultChecked}
          // @ts-ignore
          name={name}
          // @ts-ignore
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          className={styles.toogleCheck}
        />

        <label className={styles.darkLabel} htmlFor={name} />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
});

Switcher.defaultProps = {
  id: undefined,
  name: undefined,
  onChange: undefined,
  disabled: false,
  className: null,

  value: undefined,
  error: false,
  checked: false,
  label: null,
  defaultChecked: false,
};


export default Switcher;
