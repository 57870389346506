import React, { useEffect, useState, Fragment, useRef } from 'react'
import {BrigadeForm, PatientInfo} from '../components'
import {togglePopover} from "../../../app/app-slice";
import {PanelFooter, PanelPopover} from "../../../shared";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {Button} from "../../../ui-components";
import './styles.scss'
import {values, isEmpty} from 'lodash'
import {CircleGradientIcon} from "../../../assets";
import {erInterested} from "../../requests/er-thunk";
import {requestClosed} from '../../../services/utils';

const NewRequest = () => {
  const [formList, setFormList] = useState<any>([{brigade_type: '', eta: ''}])
  const [dataEmpty, setDataEmpty] = useState<boolean>(false)
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)
  const {request} = useAppSelector(state => state.requests)
  const offersRef = useRef(null)

  useEffect(() => {
    const res = formList.map(el => Object.values(el).some(val => !val))
    setDataEmpty(res.some(el => el === true))
  }, [formList])

  const setData = (key, value, index) => {
    const list = [...formList]
    list[index][key] = value
    setFormList(list)
  }

  const addForm = async () => {
    const list = [...formList]
    list.push({
      brigade_type: '',
      eta: ''
    })
    await setFormList(list)
    setScroll()
  }

  const setScroll = () => {
    const container: any = offersRef.current
    container.querySelectorAll('div #panel-popover')[0].scrollTop = container.querySelectorAll('div #panel-popover')[0].scrollHeight
  }
  const removeForm = (idx: number) => {
    const list = [...formList]
    list.splice(idx, 1)
    setFormList(list)
  }


  return (
    <div id='new-request' className='request-info-container pb-80'>
      <PatientInfo/>
      {!requestClosed(request?.status) &&
      <PanelFooter className='footer'>
        <Button onClick={() => dispatch(togglePopover('offerFormOpen'))}
                className='offer-button'
                type='contained'>Отправить Предложение</Button>
      </PanelFooter>}
      <div ref={offersRef}>
        <PanelPopover open={app.popoverFormStatuses.offerFormOpen}
                      handleClose={() => dispatch(togglePopover('offerFormOpen'))}
                      title={'Создатъ предложение'}>
          {formList.map((el, i) => <BrigadeForm brigade_type={el.brigade_type}
                                                listCount={formList.length}
                                                handleFormRemove={() => removeForm(i)}
                                                key={i}
                                                handleInputChange={(key, value) => setData(key, value, i)}
                                                eta={el.eta}/>)}

          <div className='offer-form-footer'>
            <p className='add-btn' onClick={addForm}><CircleGradientIcon/>Добавить</p>
            <Button type='contained' disabled={dataEmpty} fullWidth
                    onClick={() => {
                      setClickDisabled(true)
                      dispatch(erInterested(formList))
                        .then(res => setClickDisabled(false))
                    }}
                    className={`send-btn ${clickDisabled ? 'click-disabled' : ''}`}>Отправитъ Предложение</Button>
          </div>
        </PanelPopover>
      </div>
    </div>
  )
}

export default NewRequest
