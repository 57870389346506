import React, {FC, useCallback} from 'react'
import './notification.scss'
import {useHistory} from 'react-router-dom';
import {OfferIcon} from "../../assets";
import {setDate} from '../../services/utils';
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {getSingleRequest} from "../../modules/requests/insurance-thunk";
import {getCustomerSentrySingleRequests, getErSingleRequest} from "../../modules/requests/er-thunk";
import {markAsRead} from "../../modules/notifications/notifications-slice";
import {toggleMainPanel} from "../../app/app-slice";

interface Props {
  request_uuid: string
  notification_uuid: string
  message: string
  patientName: string
  read: Array<number>
  date: string
  requestNumber: string,
  data: any
  isCustomer?: any,
}

const Notification: FC<Props> = (props: any) => {
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch();
  const isRead = props.read?.includes(user.id)
  const history = useHistory()

  const handleClickNotification = useCallback(() => {
    dispatch(markAsRead(props?.request_uuid))
    if (props?.data?.connection) {
      history.push('/partnership')
    } else if (props?.data?.isRejected) {
      history.push('/rejected')
    } else if (user?.organization?.type === 3) {
      dispatch(getSingleRequest(props.request_uuid))
    } else if (props?.data?.isCustomer) {
      if (user.organization.type === 4) {
        dispatch(getCustomerSentrySingleRequests({uuid: props?.data?.request_uuid, sentry_group_uuid: props?.data?.sentry_uuid}))
      } else {
        dispatch(getCustomerSentrySingleRequests({uuid: props?.data?.request_uuid, sentry_group_uuid: props?.data?.sentry_uuid}))
        dispatch(toggleMainPanel())

      }
    } else {
      dispatch(getErSingleRequest(props?.request_uuid))
    }

  }, [dispatch, history, props, user?.organization?.type])

  return (
    <div className={`notification ${!isRead ? 'new' : ''}`}
         onClick={handleClickNotification}>
      <OfferIcon className='icon icon-offer'/>
      <div className="content">
        <p className="content-title">{props.message}</p>
        <div className="wrapper">
          {!props.data?.connection && <div className="data">
            <p className="title">Номер</p>
            <p className="text">№ {props.requestNumber}</p>
          </div>
          }
          {!props.data?.connection && <div className="data">
            <p className="title">ФИО пациента</p>
            <p className="text">{props.patientName}</p>
            </div>
          }
          <p className="time">{setDate(props.date)}</p>
        </div>
      </div>
    </div>
  )
}

export default Notification
