import React, {FC} from 'react'
import {Autocomplete, Input} from "../../../ui-components";
import styles from './styles.module.scss'
import InputMask from 'react-input-mask'
import {CloseIcon} from "../../../assets";
import {brigadeTypes} from "../../../services/constants";

interface Props {
  brigade_type: string
  eta: number
  price: number,
  listCount: number
  handleInputChange: Function
  handleFormRemove: () => void
}

const BrigadeForm: FC<Props> = (props) => {

  return (
    <div id={styles.brigadeFormWrapper}>
      <div className={styles.form}>
        {props.listCount > 1 && <CloseIcon className={styles.removeIcon} onClick={props.handleFormRemove}/>}
        <p className={styles.brigadeFormTitle}>Предложение</p>
        <div className='d-flex space-between' style={{gap: 6}}>
          <Autocomplete
            className={styles.input}
            options={brigadeTypes}
            value={props.brigade_type}
            onChange={(data) => props.handleInputChange('brigade_type', data.value)}
            label={'Выбрать тип бригады'}
            optionLabel={'value'}
            optionValue={'value'}/>
          <InputMask mask="999 минут" maskChar=" "
                     className={styles.input}
                     label='Время доезда (м.)'
                     onChange={e => props.handleInputChange('eta', parseInt(e?.target.value))}
                     value={props.eta}>
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
          <InputMask mask="99999999999999" maskChar=" "
                     className={styles.inputPrice}
                     label='Цена'
                     onChange={e => props.handleInputChange('price', parseInt(e?.target.value))}
                     value={props.price}>
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </div>
      </div>
    </div>
  )
}

export default BrigadeForm
