import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {cancelDpOfferRequests} from "../../requests/insurance-thunk";
import {cancelCustomerOfferBrigadePopupRequests} from "../../requests/er-thunk";
const DcCancelSentryPopup = () => {
  const { request} = useAppSelector(state => state.requests)

  const [comment, setComment] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const submit = (): void => {
    if(comment) {
      setClickDisabled(true)
      // @ts-ignore
      dispatch(cancelCustomerOfferBrigadePopupRequests({comment: {rejection_reason: comment}, item: {request_uuid: request?.uuid, customer_uuid: request?.customer_uuid}})).then(res => setClickDisabled(false));
    }
  }

  return (
    <div id={styles.replaceBrigadeForm}>
      <Input label='Причина отмены/досрочном завершении' className={`${styles.input} mb-40`} value={comment}
             onKeyUp={(e) => e.which === 13 && submit()}
             onChange={e => setComment(e.target.value)}/>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              disabled={!comment}
              onClick={submit}
              type={'contained'}>Отказ</Button>
    </div>
  )
}

export default DcCancelSentryPopup
