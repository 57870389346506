import React from 'react'
import styles from './styles.module.scss'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {DEPARTMENTS, ROOM_TYPES} from "../../../services/constants";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {handleHospitalizationFormChange} from "../../requests/requests-slice";
import { setData, togglePopover } from '../../../app/app-slice'


const CustomerHospitalizationForm = () => {
  const FEVER = [{label: 'Да', value: 1}, {label: 'Нет', value: 2}]
  const {hospitalizationForm} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  const handleInputChange = (key: string, value: string | number) => {
    dispatch(handleHospitalizationFormChange({key, value}))
  }
  // confirm hospital department data
  const submit = (): void => {
    if(hospitalizationForm.temperature && hospitalizationForm.room_type) {
      dispatch(togglePopover('hospitalsListOpen'))
    }
  }

  return (
    <div id={styles.hospitalizationForm}>
      <div className={styles.wrapper}>
        <Autocomplete className={styles.input}
                      options={FEVER} label={'ОРВ, Температура'}
                      onKeyUp={(e) => e.which === 13 && submit()}
                      value={hospitalizationForm.temperature}
                      onChange={(data) => handleInputChange('temperature', data.value)}
                      optionLabel={'label'} optionValue={'value'}/>
        <Autocomplete className={styles.input}
                      onKeyUp={(e) => e.which === 13 && submit()}
                      options={ROOM_TYPES}
                      value={hospitalizationForm.room_type}
                      label={'Тип палаты'}
                      onChange={(data) => handleInputChange('room_type', data.key)}
                      optionLabel={'type'} optionValue={'key'}/>
        <Autocomplete className={styles.input}
                      onKeyUp={(e) => e.which === 13 && submit()}
                      options={DEPARTMENTS}
                      menuPlacement={'top'}
                      onChange={data => handleInputChange('department', data.type)}
                      value={hospitalizationForm.department}
                      label={'Профиль'}
                      optionLabel={'type'} optionValue={'type'}/>
      </div>
      <Input label={'Добавить комментарий'}
             value={hospitalizationForm.comment}
             onKeyUp={(e) => e.which === 13 && submit()}
             onChange={(e) => handleInputChange('comment', e.target.value)}
             className='mb-40'/>
      <Button
        disabled={!hospitalizationForm.temperature || !hospitalizationForm.room_type}
        onClick={submit}
        type='contained' fullWidth>Выбрать</Button>
    </div>
  )
}

export default CustomerHospitalizationForm
