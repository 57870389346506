import React, { useState } from 'react'
import {BrigadeInfo, DiagnoseInfo, HospitalizationForm, HospitalsList, PatientInfo} from "../components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import './styles.scss'
import {Panel, PanelFooter, PanelHeader, PanelPopover, PrimaryModal, Spinner} from "../../../shared";
import {togglePopover} from "../../../app/app-slice";
import {BackArrowIcon} from "../../../assets";
import {Button} from "../../../ui-components";
import {cancelBookings, sendBookingRequest} from '../../requests/insurance-thunk';

const HospitalizationRequest = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const {er_call, request} = useAppSelector(state => state.requests)
  const {popoverFormStatuses} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  return (
    <div id='closed-request' className={`request-info-container ${er_call.status === 7 && !request.parent_request ? 'negative-margin' : ''}`}>
      {er_call.status === 8 && <div className='wrapper'>
        <div className='info-wrapper'>
          <p>Ожидание подтверждения больницы</p>
          <span onClick={() => setModalOpen(!modalOpen)}>Отменить</span>
        </div>
        <Spinner/>
      </div>}
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelPopover open={popoverFormStatuses.hospitalizationFormOpen}
                    className='hospitalization-popover'
                    title={'Бронирование Койки'}
                    handleClose={() => dispatch(togglePopover('hospitalizationFormOpen'))}>
        <HospitalizationForm/>
      </PanelPopover>
      <Panel handleClose={() => dispatch(togglePopover('hospitalsListOpen'))}
             open={popoverFormStatuses.hospitalsListOpen}>
        <PanelHeader className='simple-header'>
          <p className='hospitals-list-panel-title'><BackArrowIcon onClick={() => dispatch(togglePopover('hospitalsListOpen'))}/>Выбрать Больницу</p>
        </PanelHeader>
        <HospitalsList/>
      </Panel>
      <PrimaryModal description={'Вы уверены, что хотите отменить все запросы?'}
                    text={'Отменить Бронирование'}
                    footer={
                      <div className='d-flex space-between'>
                        <Button type='contained' fullWidth onClick={() => {
                          dispatch(cancelBookings()).then(res => res && setModalOpen(!modalOpen))
                        }}>Да</Button>
                        <Button type='outlined' fullWidth onClick={() => setModalOpen(!modalOpen)}
                                className='ml-30'>Нет</Button>
                      </div>
                    }
                    open={modalOpen}
                    handleClose={() => setModalOpen(!modalOpen)}/>
    </div>
  )
}

export default HospitalizationRequest
