import React, {FC, useEffect, useState} from 'react'
import {CloseIcon, DotsIcon, ArrowDownIcon, LinkIcon, CheckmarkIcon, AcceptedIcon} from "../../assets";
import './header.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {TooltipBlue} from "../../shared/tooltips";
import {setActivePanel} from '../requests/requests-slice';
import {changeDispatcher, closeAndCreateNewRequest, closeRequest, getSingleRequest} from "../requests/insurance-thunk";
import {Button, Input} from "../../ui-components";
import {Modal} from "../../shared";
import {requestClosed, setDate} from "../../services/utils";
import {getDispatchers} from "../dispatchers/dispatchers-slice";
import {ER_CALL_STATUSES, REQUEST_STATUSES} from "../../services/constants";

interface Props {
  handleClose: () => void
  isDc?: boolean
}

const Header: FC<Props> = (props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [confirmClose, setConfirmClose] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<string>('')
  const {request, activePanel, payment, er_call, insurance_responsible, emergency_org, pool, offersMade, contract} = useAppSelector(state => state.requests)
  const {list} = useAppSelector(state => state.dispatchers)
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    user.role === 2 && dispatch(getDispatchers())
    return () => {
      dispatch(setActivePanel('data'))
    }
  }, [dispatch])
  let status: string
  let statusClass: string = ''
  if (user.organization.type === 4) {
    status = "ss"
    if (er_call) {
      switch (er_call.status) {
        case 1:
        case 24:
          status = 'Ожидает выезда'
          statusClass = 'blue-gradient'
          break
        case 3:
          status = 'В пути'
          statusClass = 'orange-gradient'
          break
        case 2:
          status = 'В пути'
          statusClass = 'blue-gradient'
          break
        case 4:
          status = 'Прибыл'
          statusClass = 'green-gradient'
          break
        case 23:
          status = 'Дежурство'
          statusClass = 'green-gradient'
          break
        case 7:
          status = 'Запрос госпитализации'
          statusClass = 'blue-gradient'
          break
        case 8:
          status = 'Запрос бронирования'
          statusClass = 'blue-gradient'
          break
        case 16:
          status = 'Предложение о бронировании'
          statusClass = 'green-gradient'
          break
        case 9:
          status = 'Бронирование подтверждено'
          statusClass = 'green-gradient'
          break
        case 10:
          status = 'На пути в больницу'
          statusClass = 'blue-gradient'
          break
        case 22:
          status = 'На пути в больницу'
          statusClass = 'orange-gradient'
          break
        case 11:
          status = 'Стацианирование'
          statusClass = 'grey-gradient'
          break
        case 5:
        case 6:
        case 12:
        case 14:
        case 15:
        case 17:
        case 18:
          status = 'Дежурство завершено'
          statusClass = 'grey-gradient'
          break

        default:
          status = 'По умолчанию ER'
      }
    } else if (contract && contract?.status && request.status < 4) {
      if (contract.status === 1) {
        status = "Подготовка договора"
        statusClass = 'blue-gradient'
      }
      if (contract.status === 2) {
        status = "Ожидает подтверждения договора"
        statusClass = 'blue-gradient'
      }
      if (contract.status === 4) {
        status = "Договор подтвержден заказчиком"
        statusClass = 'blue-gradient'
      }
      if (contract.status === 5 && payment.status === 1) {
        status = "Договор утвержден"
        statusClass = 'blue-gradient'
      }
      if (contract.status === 5 && payment.status === 2) {
        status = "Ожидает оплаты"
        statusClass = 'orange-gradient'
      }
      if (contract.status === 5 && payment.status === 6) {
        status = "Ожидает оплаты"
        statusClass = 'orange-gradient'
      }
// @ts-ignore
    } else if (request?.readyForContract && !contract && request.status < 4 ) {
      status = "Подтверждено заказчиком"
      statusClass = 'blue-gradient'
    } else if (!pool?.status) {
      status = "Дежурство"
      if (request.status === 1) {
        status = 'Новая заявка'
      }else if (request?.status === 2 && !offersMade[request.uuid][0].eta) {
        status = 'Новая заявка'
      } else if (request?.status === 2) {
        status = 'Получено предложение'
      }
      else if (request?.status === 3) {
        status = 'Ожидает Подтверждения'
      } else if (request.status === 5 || request.status === 7) {
        // Icon = <WaitingIcon className='mr-5'/>
        status = 'Дежурство отменен'
        statusClass = 'grey-gradient'
      }

    }


  } else if (er_call) {
    switch (er_call.status) {
      case ER_CALL_STATUSES.onApproval:
        status = 'Подтверждено'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.onRoute:
        status = 'В пути'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.onRouteDelay:
        status = 'В пути'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.arrived:
        status = 'Прибыл'
        statusClass = 'green-gradient'
        break
      case ER_CALL_STATUSES.endedLeftInPlace:
        status = 'Оставлен на месте'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.endedRefusedHospital:
        status = 'Отказ от госпитализации'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.hospitalizationRequest:
        status = 'Запрос госпитализации'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.bookingRequestSent:
        status = 'Запрос бронирования'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.bookingApproved:
        status = 'Бронирование подтверждено'
        statusClass = 'green-gradient'
        break
      case ER_CALL_STATUSES.onRouteToHospital:
        status = 'На пути в больницу'
        statusClass = 'blue-gradient'
        break
      case ER_CALL_STATUSES.stationing:
        status = 'Стацианирование'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.ended:
        status = 'Вызов завершен'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.erReplacementRequired:
        status = 'Требуется замена СМП'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.endedTransferred:
        status = 'Вызов завершен: Передан другой СМП'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.closed:
        status = 'Вызов отменен'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.bookingOfferReceived:
        status = 'Предложение о бронировании'
        statusClass = 'green-gradient'
        break
      case ER_CALL_STATUSES.closedWithPayment:
        status = 'Вызов отменен: оплатить'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.closedWOPayment:
        status = 'Вызов отменен: без оплаты'
        statusClass = 'grey-gradient'
        break
      case ER_CALL_STATUSES.transferInProcess:
        status = 'Замена в процессе'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.transferOnRoute:
        status = 'Замена в пути'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.transferArrived:
        status = 'Замена прибыла'
        statusClass = 'orange-gradient'
        break
      case ER_CALL_STATUSES.onRouteToHospitalDelay:
        status = 'На пути в больницу'
        statusClass = 'orange-gradient'
        break

      default:
        status = 'По умолчанию ER'
    }

  } else {
    switch (request?.status) {
      case REQUEST_STATUSES.pending:
        status = 'Новая заявка'
        break
      case REQUEST_STATUSES.inProcess:
        status = 'Получено предложение'
        break
      case REQUEST_STATUSES.selected:
        status = 'Ожидает Подтверждения'
        break
      case REQUEST_STATUSES.closed:
        status = 'Заявка закрыта'
        break
      default:
        status = ''
    }
  }


  const responsible = `${insurance_responsible?.first_name} ${insurance_responsible?.last_name}`
  return (
    <div id='header'>
      <div className={`request-info ${statusClass ? statusClass : ''}`}>
        <div className="title-wrapper">
          <div className="status-wrapper">
            <p className="title">Статус</p>
            <p className="text">{status}</p>
          </div>
          <div className="actions">
            {!props?.isDc && request?.status < 4 &&
              <>
                <DotsIcon data-tip data-for='request-menu'
                          data-event='click'/>
                <TooltipBlue offsetLeft={80} offsetTop={1} id='request-menu'>
                  <p className='close-request-btn' onClick={() => setModalOpen(true)}>Отменить заявку</p>
                </TooltipBlue>
              </>
            }
            <CloseIcon onClick={props.handleClose}/>
          </div>
        </div>
        <div className="info-wrapper">
          {!props?.isDc && <div className="info-text">
            <p className="label">Ответственный:</p>
            <p className="text responsible-person"
               data-tip data-for='dispatchers-menu'
               data-event='click'>{responsible} {user.role === 2 && <ArrowDownIcon/>}</p>
            {(!requestClosed(request?.status) && user.role === 2) &&
              <TooltipBlue offsetLeft={80} offsetTop={1} id='dispatchers-menu'>
                {list.map((el, i) =>
                  <p className={`${insurance_responsible?.id === el.id ? 'active' : ''}`}
                     onClick={() => dispatch(changeDispatcher(el.uuid))}
                     key={i}>{el.first_name} {el.last_name} {insurance_responsible?.id === el.id &&
                    <CheckmarkIcon/>}</p>
                )}
              </TooltipBlue>}
          </div>}
          {emergency_org &&
            <p className="company-name">
              {emergency_org.name}
              {emergency_org.avatar && <img src={emergency_org.avatar} alt={''}/>}
            </p>}
        </div>
        <div className="info-wrapper">
          <div className="info-text">
            <p className="label">Создан:</p>
            <p className="text">
              {setDate(request?.created_at)}
              {!props.isDc && er_call?.status >= 2 ? `· Процесс Начался: ${setDate(er_call.updated_at)}` : null}
              {props.isDc && er_call?.arrival_time ? `· Процесс Начался: ${setDate(er_call.arrival_time)}` : null}
            </p>
          </div>
          <p className="request-number">№ {request?.request_number}</p>
        </div>
      </div>
      <div className="panel">

        {!props?.isDc && <p className={`item ${activePanel === 'data' ? 'active' : ''}`}
            onClick={() => dispatch(setActivePanel('data'))}>Информация</p>}
        {er_call && !props?.isDc && <p className={`item ${activePanel === 'comment' ? 'active' : ''}`}
                       onClick={() => dispatch(setActivePanel('comment'))}>Комментарии {request.unread_comments ? `(${request.unread_comments})` : ''}</p>}
        {er_call && !props?.isDc &&  <p className={`item ${activePanel === 'history' ? 'active' : ''}`}
                       onClick={() => dispatch(setActivePanel('history'))}>История</p>}
      </div>
      <Modal open={modalOpen}
             closeIcon
             className='close-request-modal'
             title={'Отменить заявку'}
             handleClose={() => {
               setModalOpen(false)
               setConfirmClose(false)
               setRejectReason('')
             }}>
        {!confirmClose ?
          <>
            <p className="text mb-40">Вы уверены, что хотите отменить заявку?</p>
            <div className='d-flex space-between'>
              <Button type='contained' onClick={() => setConfirmClose(true)}>Да</Button>
              <Button type='outlined' onClick={() => setModalOpen(false)} className='ml-30'>Нет</Button>
            </div>
          </> :
          <>
            <p className="text mb-30">Укажите причины отмены</p>
            <Input placeholder='Добавить комментарий'
                   className='mb-30' value={rejectReason}
                   onChange={e => setRejectReason(e.target.value)}/>
            <div className='d-flex space-between mb-20'>
              <Button type='contained' disabled={!rejectReason}
                      onClick={() => dispatch(closeRequest(rejectReason))}>Подтвердить</Button>
              <Button type='outlined' onClick={() => setConfirmClose(false)} className='ml-30'>Отменить</Button>
            </div>
            <Button type='contained' fullWidth disabled={!rejectReason}
                    onClick={() => dispatch(closeAndCreateNewRequest(rejectReason))}>Подтвердить, создать новую
              заявку</Button>
          </>}
      </Modal>
    </div>
  )
}

export default Header
