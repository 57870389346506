import React, {FC, useCallback} from 'react'
import ReactTooltip from "react-tooltip";
import styles from './styles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {
  cancelCustomerOfferBrigadeRequests, confirmCustomerPaymentRequests,
} from "../../requests/er-thunk";
import {ER_CALL_STATUSES} from '../../../services/constants'

interface Props {
  id: string
  className?: string
  offsetLeft?: number
  offsetTop?: number
}

const CustomerActionsTooltip: FC<Props> = (props) => {
  const {er_call, pool, request, payment} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const {user} = useAppSelector(state => state.user)
  // @ts-ignore
  const {isDp} = props
  const delayActionActive = (er_call?.status === ER_CALL_STATUSES.onRoute || er_call?.status === ER_CALL_STATUSES.onRouteDelay || er_call?.status === ER_CALL_STATUSES.onRouteToHospital || er_call?.status === ER_CALL_STATUSES.onRouteToHospitalDelay)
  const delayBtnDisabled = er_call?.status !== ER_CALL_STATUSES.onRoute && er_call?.status !== ER_CALL_STATUSES.onRouteDelay && er_call?.status !== ER_CALL_STATUSES.onRouteToHospital && er_call?.status !== ER_CALL_STATUSES.onRouteToHospitalDelay
  const actionList = [
    {
      isShow: isDp && true,
      key: 1,
      value: 'Задержка',
      action: () => delayActionActive && dispatch(togglePopover('delayFormOpen')),
      disabled: delayBtnDisabled
    },
    {
      isShow:isDp && true,
      key: 5,
      value: 'Замена госпиталя',
      action: () => er_call.status >= ER_CALL_STATUSES.onRouteToHospital && dispatch(togglePopover('replaceHospitalFormOpen')),
      disabled: er_call?.status < ER_CALL_STATUSES.onRouteToHospital
    },
    {
      isShow:!isDp && true,
      key: 6,
      value: 'Отменить заявку',
      // @ts-ignore
      action: () =>  dispatch(togglePopover("dcCancelOffer")),
      disabled: false
    },
    {
      // @ts-ignore
      isShow: user && user?.role === 5 && user.organization.type === 4,
      key: 7,
      value: 'Оплачено',
      // @ts-ignore
      action: () => payment.status ===6 && dispatch(confirmCustomerPaymentRequests()),
      // @ts-ignore
      disabled: (payment?.status !==6 || request.paymentStatus !== 0) || !er_call
    },
    {
      isShow:!isDp && request && er_call && er_call.status === 23,
      key: 8,
      value: "Досрочное завершение" ,
      // @ts-ignore
      action: () =>  dispatch(togglePopover("earlyClose")),
      disabled: false
    },
  ]
  return (
    <ReactTooltip arrowColor="transparent"
                  globalEventOff='click'
                  offset={{left: props.offsetLeft || 0, top: props.offsetTop || 0}}
                  className={`${styles.actionsTooltip} ${props.className ? props.className : ''}`}
                  id={props.id} place='top' effect='solid' clickable>
      <div className={styles.header}>
        <p className={styles.actionsTitle}>Действия</p>
      </div>
      <div className='d-flex'>
        <div className={styles.list}>
          {actionList.map((el, i) =>{
            if (el?.isShow) {
            return  <p key={i}
                 onClick={() => el.action()}
                 className={`${styles.item} ${el.disabled ? styles.disabled : ''}`}>{el.value}</p>
            }
          })}
        </div>
      </div>
    </ReactTooltip>
  )
}

export default CustomerActionsTooltip
