import React, { useState } from 'react'
import {BrigadeInfo, DiagnoseInfo, PatientInfo} from "../components";
import { HospitalInfo } from '../../er-request/components'
import { useAppDispatch, useAppSelector } from '../../../core/hooks'
import { ER_CALL_STATUSES } from '../../../services/constants'
import {
  changeClosedStatus,
  endErCallFromHospitalizationFrom,
  hospitalizationRequestFromClosed, removeAdditionalBrigade
} from '../../requests/er-thunk'
import { Button } from '../../../ui-components'
import { PanelFooter, PrimaryModal } from '../../../shared'
import { togglePopover } from '../../../app/app-slice'

const ClosedRequest = () => {
  const {er_call} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [typeOfRequest, setTypeOfRequest] = useState<string>('')

  const setRequestType = (type) => {
    setTypeOfRequest(type)
    setModalOpen(true)
  }

  const handleSubmit = () => {
    switch (typeOfRequest) {
      case 'hospitalization':
        dispatch(hospitalizationRequestFromClosed())
        break
      case 'leftInPlace':
        dispatch(changeClosedStatus(ER_CALL_STATUSES.endedLeftInPlace))
        break
      case 'leftWOHospitalization':
        dispatch(changeClosedStatus(ER_CALL_STATUSES.endedRefusedHospital))
    }
    setModalOpen(false)
    setTypeOfRequest('')
  }

  const handleCancel = () => {
    setModalOpen(false)
    setTypeOfRequest('')
  }

  return (
    <div id='closed-request' className='request-info-container'>
      {er_call.status >= ER_CALL_STATUSES.bookingApproved && <HospitalInfo/>}
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter className='footer'>
        { er_call.status === ER_CALL_STATUSES.endedRefusedHospital &&
          <>
            <Button type='outlined' className={'width-40'} onClick={() => setRequestType('leftInPlace')}>На месте</Button>
            <Button type='outlined' className={'width-40'} onClick={() => setRequestType('hospitalization')}>Госпитализация</Button>
          </>
        }
        {er_call.status === ER_CALL_STATUSES.endedLeftInPlace &&
          <>
            <Button type='outlined' className={'width-40'} onClick={() => setRequestType('leftWOHospitalization')}>Отказ от Гсп.</Button>
            <Button type='outlined' className={'width-40'} onClick={() => setRequestType('hospitalization')}>Госпитализация</Button>
          </>
        }
      </PanelFooter>
      <PrimaryModal
        open={modalOpen}
        text={'Изменение статуса'}
        description={'Вы уверены, что хотите изменить статус уже закрытого заказа?'}
        footer={
          <div className='d-flex space-between'>
            <Button type='contained' fullWidth onClick={() => {handleSubmit()}}>Да</Button>
            <Button type='outlined' fullWidth onClick={() => handleCancel()} className='ml-30'>Нет</Button>
          </div>
        }
        handleClose={() => setModalOpen(false)}/>
    </div>
  )
}

export default ClosedRequest
