import React, {useMemo} from "react"
import BrigadeItem from "./brigadeItem";
import {useAppSelector} from "../../core/hooks";

const Brigade = ({selectedModalBrigade, isEditable=false}) => {
  const {
    request,
    pool,
    activePanel,
    er_call,
    insurance_org,
    emergency_responsible
  } = useAppSelector(state => state.requests)

  // const filteredBrigade = request.requestedBrigades.filter((el) => el.request_uuid === selectedModalBrigade)
  const filteredBrigade = useMemo(() => {
   return  request?.requestedBrigades?.filter((el) => el?.request_uuid === selectedModalBrigade)
  }, [selectedModalBrigade, request]);
  return <>
    {filteredBrigade && filteredBrigade?.map((br, index) => {
      return <BrigadeItem isEditable={isEditable} request={request} key={index} brigade={br} />
      })
    }
  </>
}

export default Brigade
