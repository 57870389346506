import React from "react";
import styles from "./brigadeInfo.module.scss";
import {AmbulanceNewIcon} from "../../assets";
import {InfoText} from "../../shared";
import moment from "moment/moment";

const BrigadeInfo = ({br}) => {
  return <div className={styles.container}>
    <div className={styles.head} >
      <div className={styles.headWrapper} >
        <AmbulanceNewIcon className='mr-5'/>
        <div className={styles.title} >{br?.sentry_brigade_type}</div>
      </div>
    </div>
    <div className={styles.body}>
      <div className={styles.wrapper}>
        <InfoText className={styles.dcText} title={'Дата мероприятия'} text={`${moment(br?.sentry_date_from).format('DD/MM/YYYY')}`}/>
        <InfoText className={styles.dcText} title={'Время'} text={br?.time}/>
        <InfoText className={styles.dcText} title={'Длительность (ч)'} text={br?.duration}/>
      </div>
      <div className={styles.wrapper}>
        <InfoText className={styles.dcText} title={'Дата окончания'} text={`${moment(br?.sentry_date_to).format('DD/MM/YYYY')}`}/>
        <InfoText className={styles.dcText} title={'Время'} text={`${moment(br?.sentry_date_to).format('HH:mm')}`}/>
        <InfoText className={styles.dcText} title={'Тип мероприятия'} text={br?.sentry_type}/>
      </div>
      <div className={styles.wrapper}>
        <InfoText className={styles.dcText} title={'Адрес  мероприятия'} text={br?.sentry_address}/>
        <InfoText className={styles.dcText} title={'Взрослые'} text={br?.adults_count}/>
        <InfoText className={styles.dcText} title={'Дети'} text={br.children_count}/>
      </div>

      <div className={styles.wrapper}>
        <InfoText className={styles.dcText} title={'Место базирования бригады'} text={br?.sentry_brigade_location}/>
        <InfoText className={styles.dcText} title={'Комната приема пищи'} text={br?.food ? "Да" : "Нет"}/>
        <InfoText className={styles.dcText} title={'Наличие санузлов'} text={br?.wc ? "Да" : "Нет"}/>
      </div>
    </div>
  </div>
}

export default BrigadeInfo
