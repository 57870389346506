import React, { useState } from 'react'
import styles from './styles.module.scss'
import {Button, Input} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {
  changeCustomerErCallStatus
} from "../../requests/er-thunk";
import {togglePopover} from "../../../app/app-slice";
const DpFinishSentry = () => {
  const { request} = useAppSelector(state => state.requests)

  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const submit = (): void => {
    setClickDisabled(true)
    dispatch(changeCustomerErCallStatus(12))
    dispatch(togglePopover('dpFinishSentry'))
  }

  return (
    <div id={styles.replaceBrigadeForm}>
      <Button fullWidth
              className={clickDisabled ? 'click-disabled' : ''}
              onClick={submit}
              type={'contained'}>Да</Button>
    </div>
  )
}

export default DpFinishSentry
