import React, {Fragment, useState} from 'react'
import {OffersList, PatientInfo} from "../components";
import './styles.scss'
import {Autocomplete, Button, Input} from "../../../ui-components";
import {PanelFooter, PanelPopover} from '../../../shared'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {approveOffer} from "../../requests/insurance-thunk";
import './styles.scss'
import {GENDER} from "../../../services/constants";
import {togglePopover} from "../../../app/app-slice";

const OfferRequest = () => {
  const [brigadeUuid, setBrigadeUuid] = useState<string>('')
  const [requestUuid, setRequestUuid] = useState<string>('')
  const [executorUuid, setExecutorUuid] = useState<string>('')
  const [policy_number, setPolicyNumber] = useState<string>('')
  const [contact_number, setContactNumber] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [sex, setSex] = useState<number | null>(null)

  const dispatch = useAppDispatch()
  const {pools} = useAppSelector(state => state.requests)
  const {popoverFormStatuses} = useAppSelector(state => state.app)
  const hasApproved = pools.some(el => el.approved_by)


  return (
    <div id='offer-request' className='request-info-container pb-80'>
      <OffersList selectedOffer={brigadeUuid}
                  handleSelectChange={(data) => {
                    setBrigadeUuid(data.uuid)
                    setRequestUuid(data.request_uuid)
                    setExecutorUuid(data.organization_uuid)
                  }}/>
      <PatientInfo/>
      <PanelPopover open={popoverFormStatuses.patientInfoFormOpen}
                    title={'Информация о пациенте'}
                    handleClose={() => dispatch(togglePopover('patientInfoFormOpen'))}>
        <div className="wrapper">
          <Input name='policy_number'
                 onChange={e => setPolicyNumber(e.target.value)}
                 value={policy_number} label="Номер Полиса"/>
          <Input className='ml-5'
                 onChange={e => setContactNumber(e.target.value)}
                 name='contact_number'
                 value={contact_number} label="Телефон"/>
          <Autocomplete options={GENDER}
                        onChange={(val) => setSex(val?.key)}
                        optionLabel={'type'}
                        optionValue={'key'}
                        value={sex} label="Пол"/>
        </div>
        <Input className='mb-40'
               onChange={e => setNotes(e.target.value)}
               value={notes} label="Примечание"/>
        <Button type='contained'
                disabled={!contact_number || !policy_number}
                onClick={() => {
                  dispatch(approveOffer(requestUuid, executorUuid, brigadeUuid, contact_number, policy_number, sex, notes))
                  /*if (contact_number && policy_number) {
                    dispatch(updatePatientInfo(contact_number, policy_number, sex, notes))
                      .then(res => {
                        if(res) {
                          dispatch(approveOffer(requestUuid, executorUuid, brigadeUuid))
                        }
                      })
                  }*/
                }}
                fullWidth>Подтвердить</Button>
      </PanelPopover>
      {!hasApproved &&
      <Fragment>
        <PanelFooter className='footer'>
          <Button className='offer-button'
                  disabled={!brigadeUuid || !executorUuid || !requestUuid}
                  onClick={() => {
                    if (brigadeUuid && executorUuid && requestUuid) {
                      dispatch(togglePopover('patientInfoFormOpen'))
                    }
                  }}
                  type='contained'>Выбрать</Button>
        </PanelFooter>
      </Fragment>}
    </div>
  )
}

export default OfferRequest
