import React, {FC} from 'react'
import styles from './info-text.module.scss'

interface Props {
  title: string
  text: any
  className?: string
}

const InfoText: FC<Props> = (props) => {
  return (
    <div className={`${styles.wrapper} ${props.className ? props.className : ''}`}>
      <span className={styles.title}>
        {props.title}
      </span>
      <p title={props.text} className={styles.text}>
        {props.text}
      </p>
    </div>
  )
}

export default InfoText
