import React, {useContext, useState} from 'react'
import {Button, Checkbox, Input} from "../../ui-components";
import {inputsValidator, isFormDataValid} from "../../services/globalValidators";
import {formValidator, ValidationFields} from "../../shared/validators/validator";
import {login} from "../user/user-slice";
import {useAppDispatch} from "../../core/hooks";
import {Link, useHistory} from 'react-router-dom';
import {withSocketInstance} from "../../hoc/socket-hoc";
import SocketContext from "../../core/socket-context";
import { setApiError } from '../../app/app-slice'

const Login = () => {
  const socket = useContext(SocketContext);
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean | null>(null)
  const [validationFields, setValidationFields] = useState<ValidationFields>({password: true, email: true})
  const dispatch = useAppDispatch()
  const history = useHistory()

  const submitForm = () => {
    const data = [
      {
        key: 'password',
        value: password,
      },
      {
        key: 'email',
        value: email,
      },
    ]

    const fields = inputsValidator(data, validationFields, formValidator)
    const formValid = isFormDataValid(fields)
    setFormValid(formValid)
    setValidationFields({...fields})

    if (formValid) {
      dispatch(login(email, password))
        .then(res => {
          if(res){
            dispatch(setApiError({}))
            socket.connect()
            history.push('/dashboard')
          }
        })
        .catch(error => {
          setFormValid(false)
          console.error('Failed to login:', error)
        })
    }
  }

  return (
    <div id='login-container' className='form-container'>
      <p className='title'>Войти</p>
      <Input label={'Почта'}
             inputType='email'
             value={email}
             onChange={e => setEmail(e.target.value)}
             onKeyUp={(e) => e.which === 13 && submitForm()}
             error={!validationFields.email}
             className='mb-30'/>
      <Input label={'Пароль'}
             inputType='password'
             value={password}
             error={!validationFields.password}
             onChange={e => setPassword(e.target.value)}
             onKeyUp={(e) => e.which === 13 && submitForm()}
             className='mb-30'/>
      <div className="d-flex space-between mb-30">
        {/*<Checkbox label='Запомни меня'/>*/}
        <Link className='forgot-password-link' to='/forgot-password'>Забыли пароль?</Link>
      </div>
      <Button color='gradient'
              onClick={submitForm} className='button' type='contained'>Войти</Button>
      {formValid === false && <p className='text-error'>*Неверный логин или пароль.</p>}
    </div>
  )
}

export default withSocketInstance(Login)
