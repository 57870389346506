import React from 'react'
import {ActionsPopovers, ActionsTooltip, BrigadeInfo, DiagnoseInfo, HospitalInfo, PatientInfo} from "../components";
import {InfoTooltipTrigger, PanelFooter} from "../../../shared";
import { Button } from '../../../ui-components';
import {changeErCallStatus} from "../../requests/er-thunk";
import {useAppDispatch} from "../../../core/hooks";

const StationingRequest = () => {
  const dispatch = useAppDispatch()

  return (
    <div id='stationing-request' className='request-info-container pb-80'>
      <HospitalInfo/>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter>
        <Button fullWidth type='contained' onClick={() => dispatch(changeErCallStatus(12))}>Завершить</Button>
        <InfoTooltipTrigger dataFor={'actions-menu'} className='ml-15'/>
        <ActionsTooltip id='actions-menu' offsetLeft={150} offsetTop={-20}/>
      </PanelFooter>
      <ActionsPopovers/>
    </div>
  )
}

export default StationingRequest
