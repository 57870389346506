import React, {ChangeEvent, useCallback} from 'react'
import {Input, Autocomplete, Button} from "../../../ui-components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import './dpRequest-form.scss'
import {handleFormChange} from '../requests-slice';
import {omit, pick} from 'lodash'
import InputMask from 'react-input-mask'
import {CUSTOMERSREGIONS, ER_CALL_STATUSES} from "../../../services/constants";
import styles from "../../sentry/sentry.module.scss";
import {getCustomerSentrySingleRequests, updateCustomerInfoRequests} from "../er-thunk";
import {toggleEditDpRequest} from "../../../app/app-slice";

const DpRequestForm = () => {
  const {form, validationFields, request} = useAppSelector(state => state.requests)
  const app = useAppSelector(state => state.app)
  const {er_call} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()
  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    dispatch(handleFormChange({key: element.name, value: element.value}))
  }

  // handle select change
  const handleSelectChange = (key: string, value: string) => {
    dispatch(handleFormChange({key: key, value}))
  }


  const isValid = (data) => {
    return Object.keys(data).every((k) => {
      return data[k]
    })
  }

  const updateRequest = useCallback(() => {
    const mainValidationData = pick(form, ['name', 'contact_number', 'contact_phone', 'entity_name'])

    if (isValid(omit(mainValidationData, ['comment']))) {
      dispatch(updateCustomerInfoRequests({
        uuid: request?.uuid,
        data: {
          name: form.name,
          contact_number: form.contact_number,
          // @ts-ignore
          contact_phone: form.contact_phone,
          // @ts-ignore
          city: form.city === 1 ? "Москва" : "Санкт Петербург",
          // @ts-ignore
          entity_name : request?.entity_name && form.entity_name
        }
      })).then(res => {
        // @ts-ignore
        if(res) {
          dispatch(toggleEditDpRequest())
          // @ts-ignore
          dispatch(getCustomerSentrySingleRequests({uuid: request.uuid, sentry_group_uuid: request?.sentry_group_uuid}))
        }
      })
    } else {
      // setErrorMessage('*Заполните все поля.')
    }
  }, [dispatch, form, request, request?.uuid])

  return (
    <div className="form-wrapper request-info-container">
      <div className="temp-03-form">
        <div style={{display: "flex", flexDirection: "column"}}>
        {app.popoverFormStatuses.editDpRequestOpen &&
          <div  style={{display: "flex", flexDirection: "column", gap: 28}}>
            <Input name='name'
                   disabled={er_call?.status === ER_CALL_STATUSES.erReplacementRequired}
                   className='input-large mr-15'
                   error={!validationFields.name}
                   onChange={handleInputChange}
                   value={form.name} label="ФИО"/>
            {/*<InputMask mask="99/99/9999" maskChar=""*/}
            {/*           disabled={true}*/}
            {/*           name='birthday'*/}
            {/*           className={er_call?.status === ER_CALL_STATUSES.erReplacementRequired && 'input-disabled'}*/}
            {/*           placeholderText='ДД/ММ/ГГГГ'*/}
            {/*           error={!validationFields.birthday}*/}
            {/*           onFocus={e => e && e.preventDefault()}*/}
            {/*           onChange={(e) => e && handleInputChange(e)}*/}
            {/*           value={form.birthday} label="Дата Рождения">*/}
            {/*  {(inputProps) => <Input {...inputProps} />}*/}
            {/*</InputMask>*/}
            <Autocomplete className={styles.select}
                          options={CUSTOMERSREGIONS}
                          disabled
                          onChange={(val) => handleSelectChange('city', val.key)}
                          optionLabel={'type'}
                          optionValue={'key'}
                          value={form.city === "Москва" ? 1 : 2}
                          error={!form.city}
                          label="Город"/>
            <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                       className={styles.input}
                       name='contact_phone'
              // @ts-ignore
                       value={form?.contact_phone}
                       disabled
                       onChange={handleInputChange}
                       label="Номер телефона">
              {(inputProps) => <Input disabled {...inputProps} />}
            </InputMask>
          </div>}
        </div>
      </div>
      {app.popoverFormStatuses.editDpRequestOpen &&
        <Button className='edit-btn'
                onClick={updateRequest}
                type='contained'>Сохранить Изменения</Button>}
    </div>
  )
}

export default DpRequestForm
