import React, {Fragment, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  getOrganization
} from './organization-slice'
import './organiztion.scss'
import {BackArrowIcon} from "../../assets";
import {EmailIcon} from "../../assets";
import {LocationIcon} from "../../assets";
import {PhoneIcon} from "../../assets";



import Users from '../users/users'
import { useHistory } from 'react-router-dom'

const Organization = ({match}) => {
  const {uuid} = match.params
  const dispatch = useAppDispatch()
  const {organization} = useAppSelector(state => state.adminOrganization)
  useEffect(() => {
    dispatch(getOrganization(uuid))
  }, [])

  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }
  return organization ? (
    <Fragment>
      <div className={'flex-row d-flex head'}>
        <BackArrowIcon className={'back'} onClick={() => goBack()}/>
        <div>
          <h3>{organization.name}</h3>
        </div>
      </div>
      <div className={'organization-container d-flex flex-row'}>
      <div className={'flex-row d-flex'}>
        <div className={'flex-row d-flex left'}>
          {
            organization.avatar ? <img className={'logo'} src={organization.avatar} alt=''/>
            : <span className='logo'/>
          }
          <div className={'d-flex flex-column'}>
            <p className={'license'}>Лицензия №:</p>
            <p>{organization.inn}</p>
          </div>
        </div>
        <div className={'separator'}/>
        <div className={'flex-column d-flex right'}>
          <div className={'flex-row d-flex'}>
            <p className={'half-width'}><EmailIcon/> {organization.email}</p>
            <p className={'half-width'}><PhoneIcon/> {organization.phone}</p>
          </div>
          <div>
            <p><LocationIcon/> {organization.address}</p>
          </div>
        </div>
      </div>
      </div>
      <Users organizationUuid={uuid} organizationType={organization.type}/>
    </Fragment>
  ) : null
}

export default Organization
