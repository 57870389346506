import React, {FC, memo, ReactElement, useEffect, useState} from 'react'
import styles from './search.module.scss'
import {StatusText, Table, TableBody, TableHead} from "../index";
import {ACTIVE_TICKETS_STATUS_NAMES, GENDER, SEARCH_HEAD} from "../../services/constants";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setDate} from "../../services/utils";
import {getErSingleRequest} from "../../modules/requests/er-thunk";
import { getSingleRequest } from '../../modules/requests/insurance-thunk';

interface Props {
  tooltipToggled: boolean
}

const Search: FC<Props> = (props) => {
  const [activeTab, setActiveTab] = useState('pending')
  const {searchData} = useAppSelector((state) => state.requests)
  const {user} = useAppSelector(state => state.user)
  const {requestReasons} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setActiveTab('pending')
  }, [props.tooltipToggled])

  const requestFunction = user.organization.type === 3 ? getSingleRequest : getErSingleRequest

  let pendingUids: Array<string> = [];
  let activeUids: Array<string> = [];

  const pendingList = searchData.applications.map((el): Array<any> => {
    pendingUids.push(el.uuid)
    // const requestReason = requestReasons.find(val => val.id === el.request_reason)
    const requestReason = el.complaints
    const sex = GENDER.find(data => data.key === el.sex)
    let offersCount: number = 0
    if(Array.isArray(el.pool)) {
      const offers = el.pool?.filter(data => data.status === 2)
      for (const offer of offers) {
        offersCount += offer.request_brigade.length
      }
    } else {
      offersCount = el.pool.request_brigade?.length || 0
    }

    const setStatusText = (status, count): ReactElement => {
      if (count > 0 && status !== 3) {
        return <StatusText color={'gray'} text={`${count} предложения`}/>
      } else if (status === 1) {
        return  <StatusText color={'gray'} text={'Новая заявка'}/>
      } else {
        return <StatusText color={'blue'} text={'Ожидает подтверждения '}/>
      }
    }

    return [
      el.request_number,
      el.address,
      requestReason,
      `${el.age} . ${sex?.type}`,
      setDate(el.created_at, 'DD/MM/YYYY'),
      `${el.name}`,
      setStatusText(el.status, offersCount)
    ]
  })

  const activeList = searchData.er_calls.map((el): Array<any> => {
    activeUids.push(el.uuid)
    const requestReason = el.complaints
    const sex = GENDER.find(data => data.key === el.sex)
    return [
      el.request_number,
      el.address,
      requestReason,
      `${el.age} ・ ${sex?.type}`,
      setDate(el.created_at, 'DD/MM/YYYY'),
      `${el.name}`,
      'responsible',
      ACTIVE_TICKETS_STATUS_NAMES[el.er_call.status - 1]
    ]
  })

  return (
    <div className={styles.search}>
      <div className={styles.tabs}>
        <p className={`${activeTab === 'pending' ? styles.active : ''}`}
           onClick={() => setActiveTab('pending')}>
          Заявки и предложения <span className={`${styles.caseCount}`}>{searchData.applications.length}</span>
        </p>
        <p className={`${activeTab === 'active' ? styles.active : ''}`}
           onClick={() => setActiveTab('active')}>
          В Исполнении <span className={styles.caseCount}>{searchData.er_calls.length}</span>
        </p>
      </div>
      <Table className={styles.table}>
        <TableHead className={styles.tableHead}>
          {SEARCH_HEAD.map((el, i) =>
            <div  className={`${styles.item} item`} key={i}>
              <span>
                {el}
              </span>
            </div>)}
        </TableHead>
        <TableBody className='no-top-border'>
          {activeTab === 'pending' ?
            pendingList.map((data, i) => {
              return <div className='row' onClick={() => dispatch(requestFunction(pendingUids[i]))} key={i}>
                {data.map((el, i) =>
                  <div key={i} className={`${styles.item} item`}>
                    <span title={el as string}>
                      {el}
                    </span>
                  </div>)}
              </div>
            }) :
            activeList.map((data, i) => {
              return <div className='row' onClick={() => dispatch(requestFunction(activeUids[i]))} key={i}>
                {data.map((el, i) =>
                  <div key={i} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                  </div>)}
              </div>
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}

export default memo(Search)
