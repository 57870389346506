import React, {useState} from 'react'
import {BrigadeInfo, HospitalsOffersList, PatientInfo} from "../components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import './styles.scss'
import {Panel, PanelHeader, PrimaryModal} from "../../../shared";
import {togglePopover} from "../../../app/app-slice";
import {BackArrowIcon, HospitalIcon} from "../../../assets";
import {Button} from "../../../ui-components";
import {cancelBookings} from '../../requests/insurance-thunk'
import {buildOffersAmountMessage} from "../../../services/utils";

const HospitalizationRequest = () => {
  const {booking_offers, request} = useAppSelector(state => state.requests)
  const {popoverFormStatuses} = useAppSelector(state => state.app)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  return (
    <div id='closed-request' className={`request-info-container ${!request.parent_request ? 'negative-margin' : ''}`}>
      <div className="booking-offers">
        <p className='text'>
          <HospitalIcon/> Получено {booking_offers?.length} {buildOffersAmountMessage(booking_offers?.length)}</p>
        <span onClick={() => setModalOpen(!modalOpen)}>Отменить</span>
        <Button type='contained'
                onClick={() => dispatch(togglePopover('hospitalsOffersListOpen'))}
                className='mt-30' fullWidth>Посмотреть</Button>
      </div>
      <BrigadeInfo/>
      <PatientInfo/>
      <Panel handleClose={() => dispatch(togglePopover('hospitalsOffersListOpen'))}
             open={popoverFormStatuses.hospitalsOffersListOpen}>
        <PanelHeader className='simple-header'>
          <p className='hospitals-list-panel-title'><BackArrowIcon
            onClick={() => dispatch(togglePopover('hospitalsOffersListOpen'))}/>Выбрать Больницу</p>
        </PanelHeader>
        <HospitalsOffersList/>
      </Panel>
      <PrimaryModal description={'Вы уверены, что хотите отменить все запросы?'}
                    text={'Отменить Бронирование'}
                    footer={
                      <div className='d-flex space-between'>
                        <Button type='contained' fullWidth onClick={() => {
                          dispatch(cancelBookings()).then(res => res && setModalOpen(!modalOpen))
                        }}>Да</Button>
                        <Button type='outlined' fullWidth onClick={() => setModalOpen(!modalOpen)}
                                className='ml-30'>Нет</Button>
                      </div>
                    }
                    open={modalOpen}
                    handleClose={() => setModalOpen(!modalOpen)}/>
    </div>
  )
}

export default HospitalizationRequest
