import React, {FC, ReactElement} from "react";

interface Props {
  withBorder?: boolean
  className?: string
  children: ReactElement | ReactElement[]
}

const PanelHeader: FC<Props> = (props) => {
  return (
    <div id='panel-header'
         className={`
         ${props.withBorder ? 'bottom-border' : ''}
         ${props.className ? props.className : ''}
         `}>
      {props.children}
    </div>
  )
}

export default PanelHeader
