import React from 'react'
import {BrigadeInfo, DiagnoseInfo, PatientInfo} from "../components";
import {useAppDispatch} from "../../../core/hooks";
import { endErCallFromHospitalizationFrom } from '../../requests/er-thunk'
import { PanelFooter, Spinner } from '../../../shared'
import { Button } from '../../../ui-components'
import { ER_CALL_STATUSES } from '../../../services/constants'

const HospitalizationRequest = () => {
  const dispatch = useAppDispatch()
  return (
    <div id='hospitalization-request' className='request-info-container'>
      <div className="wrapper">
        <div className='info-wrapper'>
          <p>Ожидание подтверждения госпиталя СК</p>
          {/*<span onClick={() => dispatch(changeErCallStatus(4))}>Отменить</span>*/}
        </div>
        <Spinner/>
      </div>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      <PanelFooter className='footer'>
        <Button type='outlined' className={'width-40'} onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedLeftInPlace))} >На месте</Button>
        <Button type='outlined' className={'width-40'} onClick={() => dispatch(endErCallFromHospitalizationFrom(ER_CALL_STATUSES.endedRefusedHospital))}>Отказ от Гсп.</Button>
      </PanelFooter>
    </div>
  )
}

export default HospitalizationRequest
