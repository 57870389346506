import React, {useState} from 'react'
import {
  ActionsPopovers,
  ActionsTooltip,
  BrigadeInfo,
  DiagnoseInfo,
  HospitalInfo,
  PatientInfo,
} from "../components";
import {Button} from "../../../ui-components";
import {ArrowDownIcon, ArrowRightIcon} from "../../../assets";
import {InfoTooltipTrigger, PanelFooter} from "../../../shared";
import {TooltipBlue} from "../../../shared/tooltips";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {changeErCallStatus} from "../../requests/er-thunk";


const OnWayRequest = () => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const app = useAppSelector(state => state.app)
  const {er_call} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  return (
    <div id='on-way-request' className={`request-info-container ${er_call.status < 13 || er_call.status === 21 ? 'pb-80' : ''}`}>
      <HospitalInfo/>
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      {er_call.status < 13 &&
      <>
        <PanelFooter className='footer'>
          <Button type='contained' fullWidth onClick={() => dispatch(changeErCallStatus(4))}>Прибыл</Button>
          {/*<div data-tip data-for='issue-menu'
               data-event='click'
               className='issue-btn'>
            {tooltipOpen ? <ArrowRightIcon/> : <ArrowDownIcon/>}
            Проблема
          </div>
          <TooltipBlue id='issue-menu'
                       offsetTop={-20}
                       offsetLeft={5}
                       afterHide={() => setTooltipOpen(false)}
                       afterShow={() => setTooltipOpen(true)}>
            <p onClick={() => dispatch(togglePopover('delayFormOpen'))}>Задержка</p>
            <p onClick={() => dispatch(togglePopover('replaceBrigadeFormOpen'))}>Замена борта</p>
          </TooltipBlue>*/}
          <InfoTooltipTrigger dataFor={'actions-menu'} className='ml-15'/>
        </PanelFooter>
      </>}
      {er_call.status === 21 &&
        <PanelFooter>
          <Button type='contained' onClick={() => dispatch(changeErCallStatus(14))} fullWidth>Передать другой СМП</Button>
        </PanelFooter>
      }
      <ActionsTooltip id='actions-menu' offsetLeft={150} offsetTop={-20}/>
      <ActionsPopovers/>
    </div>
  )
}

export default OnWayRequest
