import React, {ChangeEvent, useCallback, useState} from "react";
import {Autocomplete, Input} from "../../ui-components";
import InputMask from 'react-input-mask'
import {CUSTOMERSREGIONS} from "../../services/constants";
import {MaleIcon, SaveIcon} from "../../assets";
import styles from "./sentry.module.scss"
import {updateCustomerInfoRequests} from "../requests/er-thunk";
import {useAppDispatch} from "../../core/hooks";
import {omit} from "lodash";

const Sentry = ({request, isEditable = false}: any) => {
  const dispatch = useAppDispatch()
  const [form, setForm] = useState<any>({
    name: request.name,
    contact_number: request?.contact_number,
    contact_phone: request?.contact_phone,
    entity_name: request?.entity_name,
    city: request.city === "Москва" ? 1 : 2,
  })
  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    switch (element.name) {
      case 'name':
        setForm({
          ...form,
          name: element.value
        })
        break
      case 'entity_name':
        setForm({
          ...form,
          entity_name: element.value
        })
        break
    }
  }
  const handleSelectChange = (key: string, value: string) => {
    switch (key) {
      case 'city':
        setForm({
          ...form,
          city: value
        })
        break
    }
  }
  const isValid = (data) => {
    return Object.keys(data).every((k) => {
      return data[k]
    })
  }

  const handleSubmit = useCallback(() => {
    if (request?.customer_type === 1) {
      dispatch(updateCustomerInfoRequests({
        uuid: request?.uuid,
        data: {
          name: form.name,
          contact_number: form?.contact_number,
          contact_phone: form?.contact_phone,
          city: form.city === 1 ? "Москва" : "Санкт Петербург",
        }
      })).then(res => {
        // @ts-ignore
        if(res && res.accepted) {
          // setAcceptSuccess(true)
        }
      })
    } else if (isValid(omit(form, ['comment']))) {
      dispatch(updateCustomerInfoRequests({
        uuid: request?.uuid,
        data: {
          name: form.name,
          contact_number: form.contact_number,
          contact_phone: form.contact_phone,
          city: form.city === 1 ? "Москва" : "Санкт Петербург",
          entity_name : request?.entity_name && form.entity_name
        }
      })).then(res => {
        // @ts-ignore
        if(res && res.accepted) {
          // setAcceptSuccess(true)
        }
      })
    } else {
      // setErrorMessage('*Заполните все поля.')
    }
  }, [dispatch, form, request?.entity_name, request?.uuid])

  return <div className={styles.container}>
    <div className={styles.head}>
      <div className={styles.headWrapper} >
        <MaleIcon  className='mr-5'/>
        <div className={styles.text}>Данные заказчика</div>
        {!isEditable && <SaveIcon style={{cursor: "pointer"}} onClick={handleSubmit}/>}
      </div>
    </div>
    <div className={styles.entity}>
      {request?.customer_type === 1 ? <Input name='name'
                                             disabled={true}
                                             className={styles.input}
                                             value={request?.customer?.name}
                                             label="Имя"/>:
        <Input name='entity_name'
              className={styles.input}
              value={form.entity_name}
              error={!form.entity_name}
              onChange={handleInputChange}
               disabled={isEditable}
              label="Название юридического лица"/>}
      <div className={styles.wrapper}>
        <Autocomplete className={styles.select}
                      options={CUSTOMERSREGIONS}
                      onChange={(val) => handleSelectChange('city', val.key)}
                      optionLabel={'type'}
                      optionValue={'key'}
                      value={form.city}
                      disabled
                      error={!form.city}
                      label="Город"/>
        {request?.customer_type === 1 ? <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                    className={styles.input}
                    name='contact_number'
                    value={request.customer.primary_phone}
                    disabled
                    onChange={handleInputChange}
                    label={"Номер телефона"}>
          {(inputProps) => <Input disabled {...inputProps} />}
        </InputMask> : <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                                  className={styles.input}
                                  name='contact_number'
                                  value={form.contact_number}
                                  disabled
                                  onChange={handleInputChange}
                                  label={"Телефон юридического лица"}>
          {(inputProps) => <Input disabled {...inputProps} />}
        </InputMask>}
      </div>
    </div>
    {request?.customer_type === 2 ? <div className={styles.individual}>
      <Input name='name'
             onChange={handleInputChange}
             value={form.name}
             error={!form.name}
             className={styles.input}
             disabled={isEditable}
             label="Контактное лицо"/>

      <InputMask mask="+7 (999) 999-99-99" maskChar=" "
                 className={styles.input}
                 name='contact_phone'
                 value={form.contact_phone}
                 onChange={handleInputChange}
                 disabled
                 label="Номер телефона">
        {(inputProps) => <Input disabled {...inputProps} />}
      </InputMask>
    </div> :

      <div className={styles.individual}>
    <Input name='name'
           onChange={handleInputChange}
           value={form.name}
           error={!form.name}
           disabled={isEditable}
           className={styles.input}
           label="Контактное лицо"/>

    <InputMask mask="+7 (999) 999-99-99" maskChar=" "
               className={styles.input}
               name='contact_phone'
               value={form.contact_number}
               onChange={handleInputChange}
               disabled
               label="Номер телефона">
      {(inputProps) => <Input disabled {...inputProps} />}
    </InputMask>
  </div>
    }
  </div>
}

export default Sentry
