import React, {FC} from 'react'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {togglePopover} from '../../../app/app-slice';
import {PanelPopover} from "../../../shared";
import DpDelayForm from "./DpDelayForm";
import DcReplaceDelayForm from "./DcReplaceDelayForm";
import CustomerReplaceHospitalForm from "../DcComponents/customerReplace-hospital-form";
import DpCancelOffer from "./DpCancelOffer";
import DcCancelSentryPopup from "../DcComponents/DcCancelSentryPopup";
import DcEarlyClose from "../DcComponents/DcEarlyClose";

const DpDcActionsPopover: FC = () => {
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  return (
    <>
      <PanelPopover title={'Сообщить о задержке'}
                    open={app.popoverFormStatuses.delayFormOpen}
                    handleClose={() => dispatch(togglePopover('delayFormOpen'))}>
        <DpDelayForm/>
      </PanelPopover>
      <PanelPopover title={'Замена'}
                    open={app.popoverFormStatuses.replaceHospitalFormOpen}
                    handleClose={() => dispatch(togglePopover('replaceHospitalFormOpen'))}>
        <CustomerReplaceHospitalForm/>
      </PanelPopover>
      <PanelPopover title={'Укажите причину (отмены/досрочном завершении)'}
                    open={app.popoverFormStatuses.dcCancelOffer}
                    handleClose={() => dispatch(togglePopover('dcCancelOffer'))}>
        <DcCancelSentryPopup/>
      </PanelPopover>
      <PanelPopover title={'Укажите время досрочного завершения'}
                    open={app.popoverFormStatuses.earlyClose}
                    handleClose={() => dispatch(togglePopover('earlyClose'))}>
        <DcEarlyClose/>
      </PanelPopover>
    </>
  )
}

export default DpDcActionsPopover
