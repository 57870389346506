import React, {FC, Fragment} from "react"
import ButtonProps from './IButton'
import './button.scss'

const Button: FC<ButtonProps> = (props) => {
  return (
    <div id={props.id}
         onClick={props.onClick && !props.disabled ? props.onClick : undefined}
         className={
           `btn-wrapper
            ${props.className ? props.className : ''}
            ${props.small ? 'small' : ''}
            ${props.fullWidth ? 'full-width' : ''}
            ${props.halfWidth ? 'half-width' : ''}
            ${!props.type ? 'default' : props.type}
            ${props.color ? props.color : ''}
            ${props.disabled ? 'disabled' : ''}
         `}>
      {props.href ?
        <a className='d-flex' href={props.href}>
          {props.startIcon}
          <button className={`${props.startIcon ? 'ml-5' : ''} ${props.endIcon ? 'mr-5' : ''}`}>
            {props.children}
          </button>
          {props.endIcon}
        </a> :
        <Fragment>
          {props.startIcon && <span className='start-icon'>{props.startIcon}</span>}
          <button className={`${props.startIcon ? 'ml-5' : ''} ${props.endIcon ? 'mr-5' : ''}`}>
            {props.children}
          </button>
          {props.endIcon && <span className='end-icon'>{props.endIcon}</span>}
        </Fragment>}
    </div>
  )
}

export default Button
