import { validateEmail } from "../../services/globalValidators";

export interface ValidationData {
  key: string
  value: string
}

export interface ValidationFields {
  email?: boolean
  password?: boolean
  repeatPassword?: boolean
}

export const formValidator = (validationFields: ValidationFields, data: ValidationData) => {
  switch (data.key) {
    case 'email':
      validationFields.email = !!data.value && validateEmail(data.value)
      break
    case 'password':
      validationFields.password = !!data.value && validatePassword(data.value)
      break
    case 'repeatPassword':
      validationFields.repeatPassword = !!data.value && validatePassword(data.value)
  }

  return validationFields
}

export const validatePassword = (password: string): boolean => {
  return password.length >= 6;
}
