import React, {FC, useEffect, useRef} from 'react'
import {Props} from './IInput'
import './input.scss'

const Input: FC<Props> = (props) => {
  const randomId = Math.floor(1000 + Math.random() * 9000)
  const labelRef = useRef<HTMLSpanElement>(null)
  const placeholderTextRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (props.value && labelRef.current) {
      labelRef.current!.style.transform = 'translateY(-25px)'
      labelRef.current!.style.fontSize = '12px'
    }
  }, [props.value])

  const labelBlurAnimation = (e) => {
    if (labelRef.current) {
      if (e.target.value) {
        labelRef.current!.style.transform = 'translateY(-25px)'
        labelRef.current!.style.fontSize = '12px'
      } else {
        labelRef.current!.style.transform = 'translateY(0)'
        labelRef.current!.style.fontSize = '14px'
      }
    }
  }

  const labelFocusAnimation = () => {
    if (labelRef.current) {
      labelRef.current!.style.transform = 'translateY(-25px)'
      labelRef.current!.style.fontSize = '12px'
    }
  }

  return (
    <div id='input-container'
         className={`d-flex flex-column ${props.className || ''}`}>
      <label htmlFor={`${randomId}`} id={props.id}
             onClick={() => props.onClick && props.onClick()}
             className={
               `input-wrapper
           ${props.type ? props.type : ''}
           ${props.disabled ? 'input-disabled' : ''}
           ${props.error ? 'error' : ''}
           `}>
        {props.startIcon}
        {props.label && <span ref={labelRef} onClick={(e) => e.stopPropagation()}
                              className={`label ${props.startIcon ? 'ml-25' : ''} ${props.disabled ? 'input-disabled' : ''}`}>{props.label}</span>}
        {(props.placeholderText && !props.value) && <span ref={placeholderTextRef} className='placeholder-text hidden'>{props.placeholderText}</span>}
        <input id={`${randomId}`} type={`${props.inputType ? props.inputType : 'text'}`}
               className={`
             ${props.startIcon ? 'pl-10' : ''}
             ${props.disabled ? 'input-disabled' : ''}
             ${props.endIcon ? 'pr-10' : ''}`}
               name={props.name}
               placeholder={props.placeholder}
               onChange={props.onChange ? props.onChange : undefined}
               onBlur={e => {
                 props.onBlur && props.onBlur()
                 placeholderTextRef.current?.classList.add('hidden')
                 labelBlurAnimation(e)
               }}
               readOnly={props.readonly}
               onFocus={e => {
                 placeholderTextRef.current?.classList.remove('hidden')
                 props.onFocus && props.onFocus()
                 labelFocusAnimation()
               }}
               onKeyUp={props.onKeyUp ? props.onKeyUp : undefined}
               onKeyDown={props.onKeyDown ? props.onKeyDown : undefined}
               disabled={props.disabled}
               autoFocus={props.autofocus}
               value={props.value}/>
        {props.endIcon}
      </label>
    </div>
  )
}

export default Input
