import React, {useEffect, useState} from 'react'
import {Modal, PanelFooter, PrimaryModal, TooltipBlue} from '../../../shared';
import {Button, Input} from '../../../ui-components';
import {OffersList, PatientInfo} from "../components";
import {omit} from 'lodash'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {cancelErOffer, erApprove, erReject} from "../../requests/er-thunk";
import './styles.scss'
import {ArrowDownIcon, ArrowRightIcon} from "../../../assets";
import {setRequestForm, validateInputs} from "../../requests/requests-slice";

const AcceptRequest = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [offerModalOpen, setOfferModalOpen] = useState<boolean>(false)
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const [confirmClose, setConfirmClose] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const {pool, form} = useAppSelector(state => state.requests)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setRequestForm())
  },[])

  const approvedBrigade = pool.request_brigade.find(el => el.approved_by)

  const acceptRequest = () => {
    // const mainValidationData = omit(form, ['scheduled_date', 'sentry_date_from', 'sentry_date_to', 'sex'])
    //
    // for (const k in mainValidationData) {
    //   if (typeof form[k] === 'object') {
    //     for (const j in form[k]) {
    //       dispatch(validateInputs({
    //         key: j,
    //         value: form.data[j]
    //       }))
    //     }
    //   }
    //
    //   dispatch(validateInputs({
    //     key: k,
    //     value: form[k]
    //   }))
    // }
    setClickDisabled(true)
    dispatch(erApprove())
      // .then(res => {
      //   if(res === false) {
      //     setErrorModalOpen(true)
      //   }
      //   setClickDisabled(false)
      // })
  }

  return (
    <div id='accept-request' className='request-info-container pb-80'>
      <OffersList/>
      <PatientInfo/>
      <PanelFooter className='accept-footer'>
        <Button
          className={clickDisabled ? 'click-disabled' : ''}
          fullWidth
          onClick={acceptRequest} type='contained'>Подтвердить</Button>
        <div data-tip data-for='issue-menu'
             data-event='click'
             className='issue-btn'>
          {tooltipOpen ? <ArrowRightIcon/> : <ArrowDownIcon/>}
          Проблема
        </div>
        <TooltipBlue id='issue-menu'
                     offsetTop={-20}
                     offsetLeft={5}
                     afterHide={() => setTooltipOpen(false)}
                     afterShow={() => setTooltipOpen(true)}>
          <p onClick={() => setOfferModalOpen(!offerModalOpen)}>Отменить Выбранную</p>
          <p onClick={() => setModalOpen(true)}>Отменить Участие</p>
        </TooltipBlue>
      </PanelFooter>
      <Modal open={modalOpen}
             closeIcon
             className='decline-request-modal'
             title={'Отменить заявку'}
             handleClose={() => {
               setModalOpen(false)
               setConfirmClose(false)
               setRejectReason('')
             }}>
        {!confirmClose ?
          <>
            <p className="text mb-40">Вы уверены, что хотите отменить заявку?</p>
            <div className='d-flex space-between'>
              <Button type='contained' onClick={() => setConfirmClose(true)}>Да</Button>
              <Button type='outlined' onClick={() => setModalOpen(false)} className='ml-30'>Нет</Button>
            </div>
          </> :
          <>
            <p className="text mb-30">Укажите причину отклонения</p>
            <Input placeholder='Добавить комментарий'
                   className='mb-30' value={rejectReason}
                   onChange={e => setRejectReason(e.target.value)}/>
            <div className='d-flex space-between'>
              <Button type='contained' disabled={!rejectReason}
                      onClick={() => dispatch(erReject(rejectReason))}>Подтвердить</Button>
              <Button type='outlined' onClick={() => setConfirmClose(false)} className='ml-30'>Отменить</Button>
            </div>
          </>}
      </Modal>
      <PrimaryModal description={'Вы уверены, что хотите отменить предложение?'}
                    text={'Отменить Предложение'}
                    open={offerModalOpen} handleClose={() => setOfferModalOpen(!offerModalOpen)}
                    footer={
                      <div className='d-flex space-between'>
                        <Button type='contained' fullWidth onClick={() => dispatch(cancelErOffer(approvedBrigade.uuid))}>Да</Button>
                        <Button type='outlined' fullWidth onClick={() => setOfferModalOpen(!offerModalOpen)} className='ml-30'>Нет</Button>
                      </div>
                    }/>
      <Modal open={errorModalOpen}
             closeIcon
             className='close-request-modal'
             handleClose={() => setErrorModalOpen(false)}>
        <p className="text mb-40">Вы сможете окончательно подтвердить запрос после того как страховая компания окончательно заполнит форму пациента.</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  onClick={() => setErrorModalOpen(false)}>Закрыть</Button>
        </div>
      </Modal>
    </div>
  )
}

export default AcceptRequest
