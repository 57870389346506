import React from 'react'
import {BrigadeInfo, DiagnoseInfo, HospitalInfo, PatientInfo} from "../components";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {Button} from "../../../ui-components";
import {PanelFooter, PanelPopover} from "../../../shared";
import {ER_CALL_STATUSES, REQUEST_STATUSES} from "../../../services/constants";
import {togglePopover} from "../../../app/app-slice";
import {ReplaceHospitalForm} from "../../er-request/components";

const ClosedRequest = () => {
  const {er_call, request} = useAppSelector(state => state.requests)
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  return (
    <div id='closed-request' className={`request-info-container ${er_call.status >= ER_CALL_STATUSES.onRouteToHospital ? 'pb-80' : ''}`}>
      {er_call.status >= 9 && <HospitalInfo/>}
      <DiagnoseInfo/>
      <BrigadeInfo/>
      <PatientInfo/>
      {(er_call.status >= ER_CALL_STATUSES.onRouteToHospital && request.status !== REQUEST_STATUSES.closed) &&
        <PanelFooter>
          <Button onClick={() => dispatch(togglePopover('replaceHospitalFormOpen'))} fullWidth type='contained'>Замена Госпиталя</Button>
        </PanelFooter>}
      <PanelPopover title={'Причина замены'}
                    open={app.popoverFormStatuses.replaceHospitalFormOpen}
                    handleClose={() => dispatch(togglePopover('replaceHospitalFormOpen'))}>
        <ReplaceHospitalForm/>
      </PanelPopover>
    </div>
  )
}

export default ClosedRequest
