import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './header.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setData, toggleNavbarSize} from '../../app/app-slice';
import {MenuIcon, SearchIcon, AvatarIcon, ArrowDownIcon} from "../../assets";
import {Input} from "../../ui-components";
import HeaderNotification from "../../modules/notifications/header-notfication";
import {TooltipBlue} from "../../shared/tooltips"
import {Modal, SearchTooltip, RequestsSearch} from "../../shared";
import {Link} from 'react-router-dom';
import {debounce} from 'lodash'
import {searchRequests} from "../../modules/requests/requests-slice";
import {logout} from '../../modules/user/user-slice';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Tab from "./tab";
import {getDpErRequestsList, getErRequestsList} from "../../modules/requests/er-thunk";


const Header = (props) => {
  const {type, history} = props as any
  const dispatch = useAppDispatch()

  const {user} = useAppSelector(state => state.user)
  const {pageTitle, cities, activeRegion} = useAppSelector(state => state.app)
  const {searchData} = useAppSelector((state) => state.requests)

  const [searchActive, setSearchActive] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [tooltipToggled, setTooltipToggled] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(1)
  const [orgCities, setOrgCities] = useState<Array<any>>([])
  const [date, setDate] = useState<string>(moment().format('dd, MMM DD, HH:mm'))
  let searchTooltipRef: any = useRef(null)
  const initialSelected = sessionStorage.getItem("tab")

  // useEffect(() => {
  //   if (user && user.role ===3 && user?.organization?.type === 2) {
  //     if (selectedTab=== 1) {
  //       sessionStorage.setItem("tab", String(1))
  //       dispatch(getErRequestsList())
  //     } else if (selectedTab=== 2) {
  //       sessionStorage.setItem("tab", String(2))
  //       dispatch(getDpErRequestsList())
  //     }
  //   }
  // }, [dispatch, selectedTab, user])

  useEffect(() => {

    const timer = setInterval(() => {
      setDate(moment().format('dd, MMM DD, HH:mm:ss'));
    }, 1000);
    return () => {
      clearInterval(timer)
    }
  }, []);

  useEffect(() => {
      if (initialSelected) {
        if (+initialSelected === 1 && user.role !==1 && user.role !==5) {
          setSelectedTab(1)
          sessionStorage.setItem("tab", String(1))
          dispatch(getErRequestsList())
        } else if (+initialSelected === 2 && user.role !==1) {
          setSelectedTab(2)
          sessionStorage.setItem("tab", String(2))
          dispatch(getDpErRequestsList())
        }
      } else {
        sessionStorage.setItem("tab", String(1))
      }
  }, [dispatch, initialSelected]);



  const debouncedChangeHandler = useMemo(
    () => debounce((query) => {
      dispatch(searchRequests(query))
    }, 700),
    [dispatch]
  );

  const requestsSearch = (e) => {
    setSearchValue(e.target.value)
    debouncedChangeHandler(e.target.value)
    ReactTooltip.show(searchTooltipRef)
  }

  const changeRegion = (data) => {
    const path = history.location.pathname
    dispatch(setData({data, prop: 'activeRegion'}))
    return history.replace(`${path}?regionId=${data.id}`)
  }

  useEffect(() => {
    if (type !== 'admin') {
      if (user.organization.cities?.some(el => el.city === 1)) {
        setOrgCities(cities)
      } else {
        const filteredCities = cities.filter(el => user.organization.cities?.find(data => el.id === data.city))
        setOrgCities(filteredCities)
        for (const city of filteredCities) {
          if (city.id !== 1) {
            changeRegion(city)
          }
        }
      }
    }
  }, [cities])

  const handleTab = useCallback((selected) => {
      if (selected=== 1) {
        sessionStorage.setItem("tab", String(1))
        setSelectedTab(1)
        dispatch(getErRequestsList())
      } else if (selected=== 2) {
        setSelectedTab(2)
        sessionStorage.setItem("tab", String(2))
        dispatch(getDpErRequestsList())
      }
  }, [dispatch])

  return (
    <header className={`hidden-mobile ${type}`}>
      <div className="left">
        <MenuIcon className="burger-menu" onClick={() => dispatch(toggleNavbarSize())}/>
        <h2 className="page-title">{pageTitle}</h2>
        {type !== 'admin' &&
          <>
            <h3 data-tip data-for='cities'
                data-event='click' className="city">{activeRegion?.name} {orgCities.length > 1 && <ArrowDownIcon/>}</h3>
            {orgCities.length > 1 && <TooltipBlue id='cities'>
              {orgCities.map((el, i) => {
                return <p key={i}
                          onClick={() => changeRegion(el)}
                          className='city-item'>{el.name}</p>
              })}
            </TooltipBlue>}
          </>}
        {user && (user?.role ===3 || user?.role === 2) && user?.organization?.type === 2 && <Tab setSelectedTab={handleTab} selected={selectedTab}/>}
        <p className="date">{date}</p>
      </div>
      <div className="right">
        {
          type !== 'admin' &&
          <Fragment>
            { searchActive ?
              <Input className='search-input'
                     value={searchValue}
                     autofocus
                     placeholder='Поиск'
                     onChange={requestsSearch}
                     onFocus={() => {
                       (searchData.applications.length > 0 || searchData.er_calls.length > 0 || searchData.hospitalization.length > 0) && setTimeout(() => ReactTooltip.show(searchTooltipRef), 300)
                     }}
                     startIcon={<SearchIcon data-tip data-for='search'
                                            data-delay-show='850'
                                            ref={ref => searchTooltipRef = ref}/>}/> :
              <SearchIcon className="search-icon"
                          onClick={() => setSearchActive(true)}/>
            }
            <SearchTooltip id='search' afterHide={() => setTooltipToggled(!tooltipToggled)}>
              <RequestsSearch tooltipToggled={tooltipToggled}/>
            </SearchTooltip>
            <HeaderNotification/>
          </Fragment>
        }
        <p className="user-name">{user.first_name} {user.last_name}</p>
        <div data-tip data-for='user-menu'
             data-event='click'>
          {user.avatar ? <img alt='' className="avatar" src={user.avatar}/> : <AvatarIcon className="avatar"/>}
        </div>
        <TooltipBlue id='user-menu' offsetLeft={85}>
          <ul className='menu-list'>
            {user.role === 2 && <li>
              <Link to='/partnership'>Сотрудничество</Link>
            </li>}
            {user.role === 2 && <li>
              <Link to='/dispatchers'>Диспетчеры</Link>
            </li>}
            <li>
              <Link to='#' onClick={() => setModalOpen(true)}>Служба поддержки</Link>
            </li>
            <div className="line"/>
            <li onClick={() => {
              sessionStorage.clear()
              dispatch(logout())
            }}><Link to='/log-out'>Выход</Link></li>
          </ul>
        </TooltipBlue>
      </div>
      <Modal open={modalOpen}
             closeIcon
             title={'Контактная информация'}
             handleClose={() => {
               setModalOpen(false)
             }}>
        <p className="text">Вы можете связаться с администрацией позвонив в службу поддержки по номеру +7 (903)
          729-04-42 (Viber, WhatsApp),
          или можете связаться с нами по электронной почте: <a className="mail"
                                                               href="mailto:support@temp03.ru">support@temp03.ru</a>.
        </p>
      </Modal>
    </header>
  )
}

export default React.memo(Header)
