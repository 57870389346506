import React, {useEffect, useMemo} from 'react'
import {Table, TableHead, TableBody, Search, DateRange} from '../../../shared'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getRejectedOffers, handleFilterChange, loadMoreData} from './er-rejects-slice'
import './er-rejects.scss'
import {setData} from "../../../app/app-slice";
import styles from "../styles.module.scss";
import {debounce} from 'lodash'
import moment, {Moment} from 'moment'
import {CSVLink} from "react-csv";
import {ExcelIcon, ExportIcon} from '../../../assets'
import {useHistory} from "react-router-dom";
import { ACTIVE_TICKETS_STATUS_NAMES, REJECT_TICKETS_STATUS_NAMES } from '../table-constants'
import {Button, Input} from "../../../ui-components";
import { ExportToExcel } from '../exportToExcel'


const ErRejects = () => {
  const dispatch = useAppDispatch()
  const {rejectedOffers, filter, allDataLoaded} = useAppSelector(state => state.rejectedOffers)
  const history = useHistory()
    const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Отказы'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getRejectedOffers())
  }, [dispatch, history.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getRejectedOffers()), 700),
    [dispatch]
  );

  const headers = [
    {label: "Номер карточки", key: "number"},
    {label: "ФИО", key: "name"},
    {label: "Д/В", key: "age"},
    {label: "Полис", key: "policy_number"},
    {label: "Дата", key: "created_date"},
    {label: "Повод", key: "reason"},
    {label: "Отказ заявки", key: "status"},
    {label: "Название СК", key: "insurance_org"},
    {label: "ФИО диспетчера СК", key: "creator"},
    {label: "ФИО диспетчера СМП", key: "executor"},
    {label: "Причина отказа", key: "reject_reason"},
    {label: "Время отказа", key: "reject_date"}
  ];


  const xlsxHeaders = [
    "Номер карточки",
    "ФИО",
    "Д/В",
    "Полис",
    "Дата",
    "Повод",
    "Отказ заявки",
    "Название СК",
    "ФИО диспетчера СК",
    "ФИО диспетчера СМП",
    "Причина отказа",
    "Время отказа"
  ];

  const csv = [] as any
  const xlsx = [] as any
  const filtered = [] as any
  for (let ticket of rejectedOffers) {
    filtered.push({
      number: ticket.number,
      name: ticket.name,
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      reason: ticket.reason,
      status: REJECT_TICKETS_STATUS_NAMES[ticket.status - 1],
      insurance_org: ticket.insurance_org,
      creator: ticket.creator,
      executor: ticket.executor,
      reject_reason: ticket.reject_reason,
      reject_date: moment(ticket.reject_date).format('DD/MM/YYYY HH:mm')
    })
    csv.push({
      number: ticket.number,
      name: ticket.name,
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      reason: ticket.reason,
      status: REJECT_TICKETS_STATUS_NAMES[ticket.status - 1],
      insurance_org: ticket.insurance_org,
      creator: ticket.creator,
      executor: ticket.executor,
      reject_reason: ticket.reject_reason,
      reject_date: moment(ticket.reject_date).format('DD/MM/YYYY HH:mm')
    })
    xlsx.push([
      ticket.number,
      ticket.name,
      ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      ticket.policy_number,
      moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      ticket.reason,
      REJECT_TICKETS_STATUS_NAMES[ticket.status - 1],
      ticket.insurance_org,
      ticket.creator,
      ticket.executor,
      ticket.reject_reason,
      moment(ticket.reject_date).format('DD/MM/YYYY HH:mm')
    ])
  }

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMore = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMoreData())
  }

  const head = ['Номер карточки', 'ФИО', 'Д/В', 'Полис', 'Дата', 'Повод', 'Отказ заявки', 'Название СК', 'ФИО диспетчера СК', 'ФИО диспетчера СМП', 'Причина отказа', 'Время отказа']

  const csvReport = {
    data: csv,
    headers: headers,
    filename: 'Отказы.csv'
  };

  const bodyRow = filtered.map((el, i) => Object.values(el))
  return (
    <div id={styles.rejectedTicketsList}>
      <div className={styles.filterHeader}>
        <Search handleChange={e => {
          filterChange('q', e.target.value)
          debouncedChangeHandler()
        }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getRejectedOffers())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getRejectedOffers())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <Input label='Возраст'
                 className='ml-20 mr-20'
                 onChange={e => {
                   filterChange('data[age]', e.target.value)
                   debouncedChangeHandler()
                 }} value={filter.data!.age}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'Отказы', xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {head.map((el, i) => <div className={`${styles.item} item`} key={i}><span>{el}</span></div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filtered.map((el, i) => <div key={i} className='row'>
                {
                  bodyRow[i].map((el, idx) =>
                    <div key={idx} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMore} className={styles.loadMoreBtn}>Загрузить еще</Button>}
    </div>
  )
}

export default ErRejects
