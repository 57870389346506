import React, {useState} from 'react'
import styles from './styles.module.scss'
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {Button, Radio} from "../../../ui-components";
import {InfoText, PanelFooter} from "../../../shared";
import { PAYMENT_TYPES, ROOM_TYPES } from '../../../services/constants'
import {customerAcceptBooking} from "../../requests/insurance-thunk";

const CustomerHospitalsOffersList = () => {
  const {booking_offers} = useAppSelector(state => state.requests)
  const {hospitals} = useAppSelector(state => state.app)
  const [offer, setOffer] = useState<string>('')
  const dispatch = useAppDispatch()

  return (
    <div id={styles.bookingOffers} className='request-info-container pb-80'>
      {
        booking_offers?.map((el, i) => {
          const hospital = hospitals.find(data => data.uuid === el.hospital_uuid)
          const roomType = ROOM_TYPES.find(data => data.key === el.room_type)
          const paymentType = PAYMENT_TYPES.find(data => data.key === el.payment_type)
          return (
            <div key={i} className={styles.item}>
              <div onClick={() => setOffer(el.uuid)} className={styles.header}>
                <Radio checked={offer === el.uuid} name={'hospital'}/>
                <p className={'hospital-request-title'}>{hospital?.title.toLowerCase()}</p>
              </div>
              <div className={styles.wrapper}>
                <InfoText title={'Номер брони'} text={el.booking_number}/>
                <InfoText title={'Тип Палаты'} text={roomType?.type}/>
                <InfoText title={'Профиль'} text={el.department}/>
              </div>
              <div className={styles.wrapper}>
                <InfoText title={'ОМС/ДМС'} text={paymentType?.type}/>
                <InfoText title={'Контактное имя'} text={el.contact_name}/>
                <InfoText title={'Контактный телефон'} text={el.contact_phone}/>
              </div>
              {el.comment && <InfoText title={'Комментарии'} text={el.comment}/>}
            </div>)
        })
      }
      <PanelFooter className='action-box'>
        <Button type='contained' fullWidth className={'mr-10'}
                disabled={!offer}
                onClick={() => dispatch(customerAcceptBooking(offer))}>Выбрать</Button>
      </PanelFooter>
    </div>
  )
}

export default CustomerHospitalsOffersList
