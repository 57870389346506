import React, { useEffect, Fragment, useState } from 'react'
import {Redirect, Route, Router, Switch, useHistory, useLocation} from 'react-router-dom';
import {isEmpty} from 'lodash'
import './app.scss'
import {withSocketInstance} from '../hoc/socket-hoc'
import { useAppDispatch, useAppSelector } from '../core/hooks'
import {ReactComponent as Logo} from "../assets/svgs/header/logo.svg";
import Login from "../admin-modules/login/login";
import Navbar from "../shell/navbar/navbar";
import Header from "../shell/header/header";
import Emergency from '../admin-modules/emergency/emergency'
import Insurance from '../admin-modules/insurance/insurance'
import Organization from '../admin-modules/organization/organization'
import Diagnosis from '../admin-modules/diagnosis/diagnosis'
import Hospitals from '../admin-modules/hospitals/hospitals'
import Reasons from '../admin-modules/reasons/reasons'
import { logout } from '../modules/user/user-slice'
import admin from './index'
import { Button } from '../ui-components'
import { Modal } from '../shared'
import { setApiError } from './app-slice'
import AdminCenter from '../admin-modules/admin-center/admin-center'

function App() {

  const {user} = useAppSelector(state => state.user)
  const {adminApp} = useAppSelector(state => state)
  const history = useHistory()
  const {pathname} = useLocation();
  const dispatch = useAppDispatch()
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isEmpty(user) && pathname !== '/login') {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.href = '/login'
    }
    if (adminApp.apiError.status && adminApp.apiError.text) {
      setErrorModalOpen(true)
    }
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = 'admin-fav-310x310.png'
    document.title = 'Админ Тэмп 03'
    document.body.classList.add('admin-body')

  }, [pathname, adminApp])

  const closeErrorModal = () => {
    setErrorModalOpen(false)
    dispatch(setApiError({}))
  }

  return (
    <Fragment>
      {!isEmpty(user) ? <div className='content-wrapper'>
          <Navbar type={'admin'}/>
          <div className='admin container'>
            <Header type={'admin'}/>
            <section className='data-section'>
              <Router history={history}>
                <Switch>
                  <Route exact path="/">
                    <Redirect to='emergency'/>
                  </Route>
                  <Route path="/emergency">
                      <Emergency/>
                  </Route>
                  <Route path="/insurance">
                    <Insurance/>
                  </Route>
                  <Route path="/admin-center">
                    <AdminCenter/>
                  </Route>
                  <Route path="/diagnosis">
                    <Diagnosis/>
                  </Route>
                  <Route path="/hospitals">
                    <Hospitals/>
                  </Route>
                  <Route path="/reasons">
                    <Reasons/>
                  </Route>
                  <Route path="/organization/:uuid" component={Organization}/>
                </Switch>
              </Router>
            </section>
          </div>
        </div> :
        <div className='sign-in-wrapper'>
          <div className="forms-container">
            <Logo className='logo'/>
            <Router history={history}>
              <Switch>
                <Route exact path="/">
                  <Redirect to={`login`}/>
                </Route>
                <Route path="/login" component={Login}/>
              </Switch>
            </Router>
          </div>
          <div className='image-container'>
            <div className={`background er-background`}/>
          </div>
        </div>}
      <Modal open={errorModalOpen}
             closeIcon
             title={'Ошибка'}
             handleClose={() => {
               closeErrorModal()
             }}>
        <p className="modalText mb-50">{adminApp.apiError.text}</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={closeErrorModal}>Закрыть</Button>
        </div>
      </Modal>
      <div id='modal-root'/>
      <div id="panel-root"/>
    </Fragment>
  )
}

export default withSocketInstance(App);
