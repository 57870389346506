import React, { FC } from 'react'
import ReactTooltip from "react-tooltip";

interface Props {
  id: string,
  text: string
}

export const NavbarTooltip: FC<Props> = (props) => {
  return (
    <ReactTooltip id={props.id} place='right' className='customized-tooltip' effect="solid">
      <span>{props.text}</span>
    </ReactTooltip>
  )
}
