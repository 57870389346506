import React, {useCallback, useEffect, useState} from "react";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import styles from "./brigadeItem.module.scss";
import {Autocomplete, Input, Switcher} from "../../ui-components";
import InputMask from "react-input-mask";
import {brigadeTypes, CUSTOMERSREGIONS, SENTRYEVENTS} from "../../services/constants";
import {AmbulanceNewIcon, SaveIcon} from "../../assets";
import {useAppDispatch} from "../../core/hooks";
import moment, {duration} from "moment/moment";
import {updateCustomerInfoRequests} from "../requests/er-thunk";
import {omit} from "lodash";
const BrigadeItem = ({brigade, request, isEditable})  => {
  const dispatch = useAppDispatch()
  const [updateData, setUpdateData] = useState({
    sentry_date_from: moment(brigade?.sentry_date_from).format('DD/MM/YYYY'),
    time: brigade?.time,
    duration: brigade?.duration,
    sentry_date_to: moment(brigade?.sentry_date_to).format('DD/MM/YYYY HH:mm'),
    address: brigade?.sentry_address,
    sentry_brigade_type: brigade?.sentry_brigade_type,
    sentry_type: brigade?.sentry_type,
    adults_count: brigade?.adults_count,
    children_count: brigade?.children_count,
    sentry_brigade_location: brigade?.sentry_brigade_location,
    wc: brigade?.wc,
    food: brigade?.food,
  })

  useEffect(() => {
    setUpdateData({
      sentry_date_from: moment(brigade?.sentry_date_from).format('DD/MM/YYYY'),
      time: brigade?.time,
      duration: brigade?.duration,
      sentry_date_to: moment(brigade?.sentry_date_to).format('DD/MM/YYYY HH:mm'),
      address: brigade?.sentry_address,
      sentry_brigade_type: brigade?.sentry_brigade_type,
      sentry_type: brigade?.sentry_type,
      adults_count: brigade?.adults_count,
      children_count: brigade?.children_count,
      sentry_brigade_location: brigade?.sentry_brigade_location,
      wc: brigade?.wc,
      food: brigade?.food,
    })
  }, [brigade])
  const isValid = (data) => {
    const dataWithoutFoodAndWC = { ...data };
    if (dataWithoutFoodAndWC.duration < 1) {
      return  false
    }
    delete dataWithoutFoodAndWC.food;
    delete dataWithoutFoodAndWC.wc;
    delete dataWithoutFoodAndWC.adults_count;
    delete dataWithoutFoodAndWC.children_count;
    return Object.keys(dataWithoutFoodAndWC).every((k) => {
        return data[k]
    })
  }
  const handleUpdate = useCallback( () => {
    if (isValid(omit(updateData, ['comment1']))) {
      dispatch(updateCustomerInfoRequests({uuid: brigade?.request_uuid, data:updateData})).then(res => {
        // @ts-ignore
        if(res && res.accepted) {
          // setAcceptSuccess(true)
        }
      })
    } else {
      // setErrorMessage('*Заполните все поля.')
    }
  }, [dispatch, brigade?.request_uuid, updateData])

  return <div className={styles.container}>
    <div className={styles.head} >
      <div className={styles.headWrapper} >
        <AmbulanceNewIcon className='mr-5'/>
        <div className={styles.title} >{brigade?.sentry_type}</div>
        {!isEditable && <SaveIcon style={{cursor: "pointer"}} onClick={handleUpdate}/>}
      </div>
    </div>
    <div className={styles.body}>
      <div className={styles.bodyHeadWrapper} >
        <InputMask mask="99/99/9999"
                   maskChar=""
                   className={styles.input}
                   name='sentry_date_from'
                   placeholderText='ДД/ММ/ГГГГ'
                   error={!updateData.sentry_date_from}
                   onFocus={e => e && e.preventDefault()}
                   onChange={(e) => e && setUpdateData({...updateData, sentry_date_from: e?.target?.value})}
                   value={updateData.sentry_date_from}
                   disabled={isEditable}
                   label="Дата мероприятия">
          {(inputProps) => <Input disabled={isEditable} {...inputProps} />}
        </InputMask>
        <div style={{width: 300,  paddingBottom: 4, borderBottom: '1px #BDC1CC solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'inline-flex'}}>
          <div style={{    position: "relative",
            marginTop: -20, alignSelf: 'stretch', color: '#505D66', fontSize: 12,  fontWeight: 500,  wordWrap: 'break-word'}}>Время</div>
          <TimePicker disabled={isEditable}  className={[styles.timePicker]} required clearIcon secondPlaceholder={"Time"} format={"H:mm"} disableClock  onChange={(e) => setUpdateData({...updateData, time: e})} value={updateData?.time} />
        </div>
        <Input className={styles.input}
               error={!updateData?.duration || updateData.duration ==0}
               inputType={"number"}
               label='Длительность (ч)'
               onChange={(e) => setUpdateData({...updateData, duration: e?.target?.value})}
               value={updateData?.duration}
               disabled={isEditable}></Input>
        {/*<InputMask mask="9999" maskChar=" "*/}
        {/*           className={styles.input}*/}
        {/*           error={!updateData?.duration}*/}
        {/*           label='Длительность (мин)'*/}
        {/*           onChange={(e) => setUpdateData({...updateData, duration: parseInt(e?.target?.value)})}*/}
        {/*           value={updateData?.duration}*/}
        {/*           disabled={isEditable}*/}
        {/*>*/}
        {/*  {(inputProps) => <Input disabled={isEditable}  {...inputProps} />}*/}
        {/*</InputMask>*/}

      </div>
      <div style={{alignSelf: 'stretch', padding: 12, background: '#F1F6FF', borderRadius: 8, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
        <div style={{color: '#192233', fontSize: 12,  fontWeight: 500,  wordWrap: 'break-word'}}>Дата и время завершения: </div>
        <div style={{flex: '1 1 0', color: '#192233', fontSize: 12,  fontWeight: 500,  wordWrap: 'break-word'}}>{moment(brigade?.sentry_date_to).format('DD/MM/YYYY HH:mm')}</div>
      </div>
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', gap: 16, display: 'inline-flex'}}>
      <Autocomplete className={styles.select}
                    options={CUSTOMERSREGIONS}
                    optionLabel={'type'}
                    optionValue={'key'}
                    value={request.city === "Москва" ? 1 : 2}
                    disabled
                    label="Город"/>
      <Input name='inn'
             className={styles.input}
             value={updateData?.address}
             error={!updateData?.address}
             disabled={isEditable}
             onChange={(e) => setUpdateData({...updateData, address: e?.target?.value})}
             label="Адрес  мероприятия"/>
      </div>
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex'}}>
        <Autocomplete
                      options={brigadeTypes}
                      className={styles.input}
                      onChange={(val) => setUpdateData({...updateData, sentry_brigade_type: val.value})}
                      optionLabel={'value'}
                      optionValue={'value'}
                      disabled={isEditable}
                      value={updateData?.sentry_brigade_type}
                      label="Профиль бригады"/>
        <Autocomplete  className={styles.input}
                      options={SENTRYEVENTS}
                     onChange={(val) => setUpdateData({...updateData, sentry_type: val.value})}
                      optionLabel={'value'}
                       optionValue={'value'}
                       disabled={isEditable}
                       value={updateData?.sentry_type}
                      label="Тип мероприятия"/>
      </div>
      <div style={{alignSelf: 'stretch', height: 72, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex'}}>
        <div style={{color: '#192233', fontSize: 14,  fontWeight: 500,  wordWrap: 'break-word'}}>Число участников</div>
        <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex'}}>

          <InputMask mask="9999" maskChar=" "
                     className={styles.input}
                     label="Взрослые"
                     disabled={isEditable}
                     error={updateData?.adults_count < 0}
                     onChange={(e) => setUpdateData({...updateData, adults_count: e?.target?.value})}
                     value={updateData?.adults_count}
          >
            {(inputProps) => <Input disabled={isEditable}  {...inputProps} />}
          </InputMask>
          <InputMask mask="9999" maskChar=" "
                     className={styles.input}
                     label="Дети"
                     disabled={isEditable}
                     error={updateData?.children_count< 0}
                     onChange={(e) => setUpdateData({...updateData, children_count: e?.target?.value})}
                     value={updateData?.children_count}
          >
            {(inputProps) => <Input disabled={isEditable}  {...inputProps} />}
          </InputMask>
        </div>
      </div>
      <div style={{alignSelf: 'stretch', height: 48, paddingTop: 2, paddingBottom: 8, paddingLeft: 4, paddingRight: 4,  flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>

        <Input name='inn'
               error={!updateData?.sentry_brigade_location}
               className={styles.input}
               disabled={isEditable}
               value={updateData?.sentry_brigade_location}
               onChange={(e) => setUpdateData({...updateData, sentry_brigade_location: e?.target?.value})}
               label="Место базирования бригады"/>
      </div>
      <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex'}}>
        <Switcher  disabled={isEditable} defaultChecked={updateData?.wc} id={"uniq"}  onChange={(event) => setUpdateData({...updateData, wc: !updateData.wc})} label="Наличие санузлов" name="ws"  />
        <Switcher  disabled={isEditable} defaultChecked={updateData?.food} id={"uniq1"}  onChange={(event) => setUpdateData({...updateData, food: !updateData.food})} label="Комната приема пищи" name="food" />
      </div>
    </div>
  </div>
}

export default BrigadeItem
