import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";

interface Notification {
  uuid: string
  organization_uuid: string
  message: string
  data?: any
  read: Array<number>
  visible: boolean
  updated_at: string
}

interface Filter {
  limit: number
  offset: number
}

interface Notifications {
  list: Array<Notification>
  filter: Filter
  unreadNotifications: number
}

const initialState: Notifications = {
  list: [],
  filter: {
    limit: 50,
    offset: 0
  },
  unreadNotifications: 0
};

const notifications = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<{ data: Array<Notification>, unreadCount: number }>) => {

      state.list = action.payload.data.map(el => {
        return {...el, visible: true}
      })
      state.unreadNotifications = action.payload.unreadCount
      return state
    },
    updateNotifications: (state, action: PayloadAction<Notification>) => {
      const notification = {...action.payload, visible: true}
      state.list.unshift(notification)
      state.unreadNotifications++
      return state
    },
    filterNotifications: (state, action: PayloadAction<{ type: string, user_id: number }>) => {
      const data = action.payload
      switch (data.type) {
        case 'unread':
          state.list.forEach(el => el.visible = !el.read?.includes(data.user_id))
          break;
        case 'read':
          state.list.forEach(el => el.visible = el.read?.includes(data.user_id))
          break
        case 'all':
          state.list.forEach(el => el.visible = true)
      }
      return state
    }
  }
});

export const {
  setNotifications,
  updateNotifications,
  filterNotifications
} = notifications.actions;

export const getNotifications = (filter?: Filter) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {user} = getState().user
    try {
      const res = await api.notification.get('', filter)
      const unreadNotifications = res.filter(el => !el.read?.includes(user.id))
      dispatch(setNotifications({data: res, unreadCount: unreadNotifications.length}))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export const markAllAsRead = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {user} = getState().user
    try {
      const res = await api.notification.post('mark-as-read')
      const unreadNotifications = res.filter(el => !el.read?.includes(user.id))
      dispatch(setNotifications({data: res, unreadCount: unreadNotifications.length}))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}


export const markAsRead = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {user} = getState().user
    try {
      const res = await api.notification.post(`mark-as-read/${uuid}`)
      const unreadNotifications = res.filter(el => !el.read?.includes(user.id))
      dispatch(setNotifications({data: res, unreadCount: unreadNotifications.length}))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
};

export default notifications.reducer;
