import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import './navbar.scss'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {setData, toggleNavbarSize, toggleRequestForm} from '../../app/app-slice';
import UserNavBar from './user-navbar'
import AdminNavBar from './admin-navbar'
import {resetForm} from "../../modules/requests/requests-slice";
import {checkConnections} from "../../modules/user/user-slice";

const Navbar = ({type}) => {
  const app = useAppSelector(state => state.app)
  const {user} = useAppSelector(state => state.user)
  const {pathname} = useLocation();
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (window.innerWidth < 1450) {
      dispatch(toggleNavbarSize())
    }
  }, [dispatch])

  return (
    <nav className={`hidden-mobile ${app.navbarMinimized ? 'navbar-minimized' : ''}`}>
      <div className="wrapper">
        {type === 'admin' ? <AdminNavBar
          app={app}
          user={user}
          pathname={pathname}
        /> : <UserNavBar app={app}
                         user={user}
                         pathname={pathname}
                         onRequestFormToggle={() => {
                           dispatch(checkConnections())
                             .then(res => {
                               if(!res.length) {
                                 dispatch(setData({data: true, prop: 'noConnectionModalOpen'}))
                               } else {
                                 dispatch(resetForm())
                                 dispatch(toggleRequestForm())
                               }
                             })
                         }}/>}
      </div>
    </nav>
  )
}

export default React.memo(Navbar)
