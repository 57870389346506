import React, { Fragment, useEffect, useState } from 'react'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import { TableBody, TableHead, Table, Panel, PanelHeader, PanelFooter, Modal, Search } from '../../shared'
import { CloseIcon, EditIcon, PlusIcon } from '../../assets'
import './reasons.scss'
import { Button } from '../../ui-components'
import { togglePopover } from '../../admin/app-slice'
import {
  clearForm,
  createReason,
  setReason,
  updateReason,
  deleteReason,
  getReasons,
  selectReason,
  validateInputs
} from './reasons-slice'
import ReasonForm from './reasons-form'
const head = ['ID', 'Название',' ']


const Reasons = () => {
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.adminApp)
  const {reasons, form, reason} = useAppSelector(state => state.adminReasons)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [reasonsList, setReasonsList] = useState<any[]>([])
  useEffect(() => {
    dispatch(getReasons())
      .then(res => {
        if(res && res.length) {
          setReasonsList(res)
        }
      })
  }, [dispatch])

  const submitForm = () => {

    for (const k in form) {
      dispatch(validateInputs({key: k, value: form[k]}))
    }
    if(reason){
      dispatch(updateReason())
        .then((res) => {
          if (res) {
            dispatch(getReasons())
              .then(res => {
                if (res && res.length) {
                  setReasonsList(res)
                }
              })
          }
        })
    }
    dispatch(createReason())
      .then((res) => {
        if (res) {
          dispatch(getReasons())
            .then(res => {
              if (res && res.length) {
                setReasonsList(res)
              }
            })
        }
      })
  }

  const closePopover = () => {
    dispatch(setReason(null))
    dispatch(clearForm())
    dispatch(togglePopover('adminAddReason'))
  }

  const deleteSingleReason = async (reason) => {
    dispatch(clearForm())
    dispatch(setReason(null))
    await dispatch(deleteReason(reason.id))
    setDeleteModalOpen(false)
    dispatch(togglePopover('adminAddReason'))
    dispatch(getReasons())
  }

  const rows = reasonsList.map((val: any) => {
    return (
      <div className='row' key={val.id} onClick={() => {
        dispatch(selectReason(val))
        dispatch(togglePopover('adminAddReason'))
      }}>
        <div className='item'>{val.id}</div>
        <div className='item title' title={val.name}>{val.name}</div>
        <div className='item'>
          <EditIcon/>
        </div>
      </div>
    )
  })

  const filterChange = async (value: string) => {
    if (value) {
      setSearchQuery(value)
      // @ts-ignore
      setReasonsList(reasons.filter(h => h.name.toLowerCase().includes(value.toLowerCase())))
    } else {
      setSearchQuery('')
      setReasonsList(reasons)
    }
  }

  const popoverTitle = reason ? 'Сохранить' : 'Создать'

  return (
    <Fragment>
      <div id='hospitals-list'>
        <div className={'mb-20 flex-end d-flex'}>
          <Search handleChange={e => {
            filterChange(e.target.value)
          }} value={searchQuery}/>
          <Button type='contained'
                  color='secondary'
                  onClick={() => dispatch(togglePopover('adminAddReason'))}
                  startIcon={<PlusIcon/>}>Добавить Повод</Button>
        </div>
        <Table>
            <TableHead>
              {head.map((el, i) => <div className='item' key={i}>{el}</div>)}
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
      </div>
      <Panel open={app.adminAddReason}
             handleClose={() =>closePopover()}>
        <PanelHeader className='simple-header'>
          <p>{popoverTitle} Скорую Помощь</p>
          <CloseIcon onClick={() => closePopover()}/>
        </PanelHeader>
        <ReasonForm reason={reason}/>
        <PanelFooter className='action-box'>
          {reason && <Button type='contained' onClick={() => setDeleteModalOpen(true)} className='ml-20'>Удалить</Button>}
          <Button type='simple' onClick={() => closePopover()}>Отмена</Button>
          <Button type='contained' onClick={submitForm} className='ml-20'>{popoverTitle}</Button>
        </PanelFooter>
      </Panel>
      <Modal open={deleteModalOpen}
             closeIcon
             title={'Удалить повод?'}
             handleClose={() => {
               setDeleteModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите удалить эту организацию?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={() => deleteSingleReason(reason)}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setDeleteModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default Reasons
