import './assets/styles/index.scss';
import Admin from './admin'
import App from './app'
let loaded = false;

if (process.env.REACT_APP_TYPE === "admin") {
  loaded = true;
  Admin()
}
if (process.env.REACT_APP_TYPE === "app") {
  loaded = true;
  App()
  if(process.env.REACT_APP_ENABLE_TAWKIO){
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/6218bbbe1ffac05b1d7bb4e4/1fso9qovg';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      // @ts-ignore
      s0.parentNode.insertBefore(s1,s0);
    })();
  }
}

if (!loaded) {
  throw new Error("Unrecognized brand id: " + process.env.REACT_APP_TYPE);
}
