export const ACTIVE_TICKETS_HEAD = ['Номер карточки', 'ФИО', 'Д/В', 'Полис', 'Дата', 'Длительность прибытия', 'Жалобы', 'Результат', 'Название организации']
export const ER_HISTORY_HEAD = [
  "Номер заявки",
  "Контактное лицо",
  "Дата оформления",
  "Дата начала мероприятия",
  "Дата окончания мероприятия",
  "Выезд через",
  "Стоимость",
  "Статус оплаты",
  "Статус Заявки",
  "Результат",]

export const ER_HISTORY_HEAD_DC = [
  "Номер заявки",
  "Заказчик",
  "Номер телефона Заказчика",
  "Дата оформления",
  "Дата начала мероприятия",
  "Дата окончания мероприятия",
  "Выезд через",
  "Стоимость",
  "Статус оплаты",
  "Статус Заявки",
  "Результат",]

export const INSURANCE_PENDING_TICKETS_HEAD = ['Номер карточки', 'ФИО', 'Д/В', 'Полис', 'Дата', 'Жалобы', 'Текущий статус']

export const ER_PENDING_TICKETS_HEAD = ['Номер карточки', 'ФИО', 'Д/В', 'Полис', 'Дата', 'Жалобы', 'Текущий статус', 'Название СК']

export const HISTORY_HEAD = ['Номер карточки', 'ФИО', 'Д/В', 'Полис', 'Дата', 'Длительность прибытия', 'Длительность вызова', 'Диагноз', 'Результат', 'Комментарии', 'Время отзвона', 'Название организации']

export const REJECT_TICKETS_STATUS_NAMES = [
  '',
  '',
  '',
  'СМП отправила предложение, но удалила',
  'СК отменила/отклонила предложение',
  'СМП не отправила предложение'
]

export const ACTIVE_TICKETS_STATUS_NAMES = [
  'Подтверждено',
  'В пути',
  'В пути (задержка)',
  'Прибыл',
  'Вызов завершен: Оставлен на месте',
  'Вызов завершен: Отказ от госпитализации',
  'Запрос госпитализации',
  'Запрос бронирования',
  'Бронирование подтверждено',
  'На пути в больницу',
  'Стационирование',
  'Вызов завершен: Госпитализация',
  'Требуется замена СМП',
  'Вызов завершен: Передана другой СМП',
  'Вызов отменен',
  'Предложение о бронировании',
  'Вызов отменен, оплатить',
  'Вызов отменен, без оплаты',
  'Замена в процессе',
  'Замена в пути',
  'Замена прибыла'
]

export const HISTORY_STATUS_FILTER = [
  {
    key: 5,
    value:  'Вызов завершен: Оставлен на месте',
  },
  {
    key: 6,
    value: 'Вызов завершен: Отказ от госпитализации',
  },
  {
    key: 12,
    value: 'Вызов завершен: Госпитализация',
  },
  {
    key: 14,
    value: 'Вызов завершен: Передана другой СМП',
  },
  {
    key: 15,
    value: 'Вызов отменен',
  },
  {
    key: 17,
    value: 'Вызов отменен, оплатить',
  },
  {
    key: 18,
    value: 'Вызов отменен, без оплаты',
  }
]

export const ER_CALL_STATUS_FILTER = [
  {
    key: 1,
    value:  'Подтверждено',
  },
  {
    key: 2,
    value:  'В пути',
  },
  {
    key: 3,
    value: 'В пути (задержка)',
  },
  {
    key: 4,
    value: 'Прибыл',
  },
  {
    key: 7,
    value: 'Запрос госпитализации',
  },
  {
    key: 8,
    value: 'Запрос бронирования',
  },
  {
    key: 9,
    value: 'Бронирование подтверждено',
  },
  {
    key: 10,
    value: 'На пути в больницу',
  },
  {
    key: 11,
    value: 'Стационирование',
  },
  {
    key: 13,
    value: 'Требуется замена СМП',
  },
  {
    key: 16,
    value: 'Предложение о бронировании',
  },
  {
    key: 19,
    value: 'Замена в процессе',
  },
  {
    key: 20,
    value: 'Замена в пути',
  },
  {
    key: 21,
    value: 'Замена прибыла',
  },
  {
    key: 22,
    value: 'На пути в больницу(задержка)',
  }
]

export const PENDING_STATUS_FILTER = [
  {
    key: 1,
    value: 'Новая Заявка'
  },
  {
    key: 2,
    value: 'Предложение'
  },
  {
    key: 3,
    value: 'Ожидает подтверждения'
  }
]

export const CANCELED_TICKETS_STATUS_NAMES = [
  '',
  '',
  '',
  '',
  'Отменён',
  ''
]

export const CANCELED_HISTORY_HEAD = [
  'Номер карточки',
  'ФИО',
  'Д/В',
  'Полис',
  'Дата',
  'Длительность прибытия',
  'Длительность вызова',
  'Диагноз',
  'Результат',
  'Комментарии',
  'Время отзвона',
  'Название организации',
  'Причина отмены'
]
