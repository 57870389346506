import React, {useEffect, useMemo} from 'react'
import {
  Table,
  TableHead,
  TableBody,
  Search,
  DateRange,
  StatusText,
  PanelHeader,
  PanelPopover,
  Panel
} from '../../../../shared'
import {useAppDispatch, useAppSelector} from '../../../../core/hooks'
import {getPendingTickets, handleFilterChange, loadMoreData} from '../pending-tickets-slice'
import {setData, toggleMainPanel, togglePopover} from '../../../../app/app-slice'
import {getErSingleRequest} from '../../../requests/er-thunk'
import './er-pending-tickets.scss'
import {ER_PENDING_TICKETS_HEAD, PENDING_STATUS_FILTER} from '../../table-constants'
import styles from "../../styles.module.scss";
import {debounce} from 'lodash'
import Header from "../../../er-request/header";
import Content from "../../../er-request/content";
import {EditBrigadeForm} from "../../../er-request/components";
import {buildOffersAmountMessage} from "../../../../services/utils";
import moment, {Moment} from 'moment'
import {CSVLink} from "react-csv";
import {ExcelIcon, ExportIcon} from '../../../../assets'
import {useHistory} from "react-router-dom";
import {Autocomplete, Button, Input} from "../../../../ui-components";
import { ExportToExcel } from '../../exportToExcel'

const ErPendingTickets = () => {
  const dispatch = useAppDispatch()
  const {pendingTickets, filter, allDataLoaded} = useAppSelector(state => state.pendingTickets)
  const app = useAppSelector(state => state.app)
  const history = useHistory()
    const today = moment().utc().endOf('day')
  const initialRange = moment().subtract(30, 'days').utc().startOf('day')
  useEffect(()=>{
    document.body.classList.add('scrollable')
    return ()=>{
      document.body.classList.remove('scrollable')
    }
  }, [])
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Заявки'}))
    dispatch(filterChange('from', initialRange))
    dispatch(filterChange('to', today))
    dispatch(getPendingTickets())
  }, [dispatch, history.location])

  const debouncedChangeHandler = useMemo(
    () => debounce(() => dispatch(getPendingTickets()), 700),
    [dispatch]
  );

  const filterChange = (key: string, value: any) => dispatch(handleFilterChange({key, value}))

  const loadMore = () => {
    const offset = filter.offset as number
    const limit = filter.limit as number
    dispatch(filterChange('offset', offset + limit))
    dispatch(loadMoreData())
  }

  const setStatusText = (status, count) => {
    if (count > 0 && status !== 3) {
      return <StatusText color={'blue'} text={`${count} ${buildOffersAmountMessage(count)}`}/>
    } else if (status === 1) {
      return <StatusText color={'gray'} text={'Новая заявка'}/>
    } else {
      return <StatusText color={'gray'} text={'Ожидает подтверждения'}/>
    }
  }

  const headers = [
    {label: "Номер карточки", key: "number"},
    {label: "ФИО", key: "name"},
    {label: "Д/В", key: "age"},
    {label: "Полис", key: "policy_number"},
    {label: "Дата", key: "created_date"},
    {label: "Жалобы", key: "complaints"},
    {label: "Текущий статус", key: "status"},
    {label: "Название СК", key: "org_name"}
  ];

  const xlsxHeaders = [
    "Номер карточки",
    "ФИО",
    "Д/В",
    "Полис",
    "Дата",
    "Жалобы",
    "Текущий статус",
    "Название СК"
  ];

  const filtered = [] as any
  const csv = [] as any
  const xlsx = [] as any
  const uuids = [] as any
  for (let ticket of pendingTickets) {
    uuids.push(ticket.request_uuid)
    const statusForExport = (ticket.status !== 3 && ticket.offers_count > 0) ?
      `${ticket.offers_count} ${buildOffersAmountMessage(ticket.offers_count)}`
      : ticket.status === 1 ? 'Новая заявка' : 'Ожидает подтверждения'
    filtered.push({
      number: ticket.number,
      name: ticket.name,
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      complaints: ticket.complaints,
      status: setStatusText(ticket.status, ticket.offers_count),
      org_name: ticket.org_name
    })
    csv.push({
      number: ticket.number,
      name: ticket.name,
      age: ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
      policy_number: ticket.policy_number,
      created_date: moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
      complaints: ticket.complaints,
      status: statusForExport,
      org_name: ticket.org_name
    })
    xlsx.push([
       ticket.number,
       ticket.name,
       ticket.age < 18 ? 'Д(0-18)' : 'В(18+)',
       ticket.policy_number,
       moment(ticket.created_date).format('DD/MM/YYYY HH:mm'),
       ticket.complaints,
       statusForExport,
       ticket.org_name
    ])
  }

  const csvReport = {
    data: csv,
    headers: headers,
    filename: 'Заявки.csv'
  };

  const bodyRow = filtered.map((el, i) => Object.values(el))
  return (
    <div id={styles.pendingTicketsList}>
      <div className={styles.filterHeader}>
          <Search handleChange={e => {
            filterChange('q', e.target.value)
            debouncedChangeHandler()
          }} value={filter.q as string}/>
        <div className={styles.filtersWrapper}>
          <DateRange
            handleFromChange={(date) => {
              dispatch(filterChange('from', moment(date).utc().startOf('d')))
              dispatch(getPendingTickets())
            }}
            handleToChange={(date) => {
              dispatch(filterChange('to', moment(date).add(1, 'd').utc().endOf('d')))
              dispatch(getPendingTickets())
            }}
            dateFrom={filter.from}
            dateTo={moment(filter.to).subtract(1, 'd').toString()}/>
          <Input label='Возраст'
                 className='ml-20'
                 onChange={e => {
                   filterChange('data[age]', e.target.value)
                   debouncedChangeHandler()
                 }} value={filter.data!.age}/>
          <Autocomplete label='Статус'
                        isClearable
                        onChange={(data) => {
                          dispatch(filterChange('data[status]', data.key))
                          dispatch(getPendingTickets())
                        }}
                        value={filter.data!.status}
                        className={`${styles.selectWrapper} ml-20 mr-20`}
                        options={PENDING_STATUS_FILTER}
                        optionLabel={'value'} optionValue={'key'}/>
          <CSVLink {...csvReport}><ExportIcon/></CSVLink>
          <div onClick={() => ExportToExcel(xlsx, 'Заявки СМП', xlsxHeaders)}><ExcelIcon/></div>
        </div>
      </div>
      <Table className={'left-table-wrapper'}>
        <div className={'left-table-container fit-to-screen'}>
          <TableHead>
            {ER_PENDING_TICKETS_HEAD.map((el, i) => <div className={`${styles.item} item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody className='no-top-border'>
            {filtered.map((el, i) =>
              <div key={i} onClick={() => dispatch(getErSingleRequest(uuids[i]))} className='row'>
                {
                  bodyRow[i].map((el, idx) =>
                    <div key={idx} className={`${styles.item} item`}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </div>
      </Table>
      {!allDataLoaded && <Button onClick={loadMore} className={styles.loadMoreBtn}>Загрузить еще</Button>}
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        <Content/>
        <PanelPopover open={app.popoverFormStatuses.editBrigadeFormOpen}
                      title={'Редактировать бригаду'}
                      handleClose={() => dispatch(togglePopover('editBrigadeFormOpen'))}>
          <EditBrigadeForm/>
        </PanelPopover>
      </Panel>
    </div>
  )
}

export default ErPendingTickets
