import React, {useCallback, useMemo, useState} from "react";
import styles from "./OfferToEvent.module.scss";
import {Autocomplete, Input} from "../../ui-components";
import moment from "moment";

const OfferToEventItem = ({item, changeOption, elId, request }) => {
  const [selectedOption, setSelectedOption] = useState()
  const handleOptions = useCallback((item) => {
    const seldOption = item.map((el) => {
      if (el.brigade_type) {
        return  {key: el.brigade_uuid, value :`${el.brigade_type} (${el.eta}  мин), ${el.price} руб`}
      } else {
        return {key: "2", value: "Отказ"}
      }
    })
    if (item.length > 1) {
      seldOption.push({key: "2", value: "Отказ"})

    }else {
      seldOption.push({key: "1", value: "нет предложений"})
    }
    return seldOption
  }, [])

  const selectedPrice = useMemo(() => {
    const a = item.find((el) => el.brigade_uuid === selectedOption)
    if (a) {
      return a.price
    }
    return "0"
  }, [item, selectedOption])

  const selectedTime = useMemo(() => {
    const a = request.requestedBrigades.find((el) => el.request_uuid === item[0].request_uuid)
    if (a) {
      return a
    }
  }, [request.requestedBrigades, item])



  return <div className={styles.itemWrapper} >
    <div className={styles.description} >{`Дежурство бригады №${elId + 1} ${moment(selectedTime?.sentry_date_from).format('DD/MM/YYYY HH:mm')}`}</div>

    <Autocomplete className={styles.input}
                  options={handleOptions(item)}
                  disabled={item[0].brigade_status === 2}
                  onChange={(val) => {
                    setSelectedOption(val.key)
                    changeOption({value: val.key, item})
                  }}
                  // error={!form.department}
                  optionLabel={'value'}
                  optionValue={'key'}
                  value={item[0].brigade_status === 2 ? item[0].brigade_uuid  :selectedOption}
                  label="Выбрать бригаду"/>

    <Input name='inn'
           disabled={true}
           className={styles.inputPrice}
           value={item[0].brigade_status === 2 ? item[0]?.price: selectedPrice}
           label="стоимость"/>
  </div>
}

export default OfferToEventItem
