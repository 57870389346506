import React, {FC} from 'react'
import {DotsIcon} from "../../assets";
import styles from './info-tooltip-trigger.module.scss'

interface Props {
  className?: string
  dataFor: string
}

const InfoTooltipTrigger: FC<Props> = (props) => {
  return (
    <div
      data-tip data-for={props.dataFor}
      data-event='click'
      className={`${styles.infoTrigger} ${props.className ? props.className : ''}`}>
      <DotsIcon/>
    </div>
  )
}

export default InfoTooltipTrigger
